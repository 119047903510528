import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  buttonStyle,
  colors,
  fontWeights,
} from "@util/constants";
import { Maybe, Scalars } from "@graphql-types";

import { ButtonTheme } from "@util/types";
import { Container } from "@util/standard";
import { Link as GatsbyLink } from "gatsby";
import { Loading } from "@global";
import React, { ReactNode } from "react";
import { getLink } from "./link";
import styled from "styled-components";
import { mediaQuery } from "@styles/mediaQueries";

const StyledButton = styled.button<{
  theme: any;
  minHeight?: number;
  width?: string;
  padding?: string;
  tabletMargin?: string;
  mobileMargin?: string;
  margin?: string;
}>`
  text-align: center;
  cursor: pointer;
  user-select: none;
  position: relative;
  font-weight: ${fontWeights.semibold};
  line-height: 1.2;
  overflow: hidden;
  border-radius: 100px;

  color: ${props => props.theme.text};
  background-color: transparent;

  ${({ width }) => width && `width: ${width};`}
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}` : `49`)}px;
  ${({ margin }) =>
    margin &&
    `
    margin: ${margin};
  `}

  &:focus {
    outline: 0;
  }

  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.bg};
    border-radius: 10rem;
    z-index: 0;
    transition: 0.3s background-color ease-out;
  }
  &:hover:before {
    background-color: transparent;
  }

  &:after {
    background-color: ${props => props.theme.hoverBg ?? colors.transparent};
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    min-width: 100%;
    position: absolute;
    left: -5px;
    top: 5px;
    transform: translate(-100%, -100%);
    border-radius: 0 100% 100% 0;
    box-sizing: border-box;

    transform-origin: bottom left;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: 1;
  }
  &:hover:after {
    transform: scale(3, 3);
  }

  span {
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: ${props => (props.padding ? props.padding : `14px 40px`)};
    border: ${props => props.theme && `2px solid ${props.theme.border}`};
    width: 100%;
    border-radius: 100px;
  }

  &:hover {
    opacity: 1;
    color: ${props => props.theme.hoverText ?? colors.transparent};

    span {
      border: ${props => props.theme && `2px solid ${props.theme.hoverBorder}`};
    }
  }

  &[disabled],
  &.disabled,
  &[aria-disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  svg {
    max-height: 14px;
    vertical-align: middle;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletMargin }) =>
      tabletMargin &&
      `
      margin: ${tabletMargin};
    `}
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileMargin }) =>
      mobileMargin &&
      `
      margin: ${mobileMargin};
    `}
  }
`;

const Loader = () => (
  <Container justifyContent="center" alignItems="center">
    <Loading />
    <p style={{ margin: "0 0 0 5px" }}>Loading...</p>
  </Container>
);

interface Props {
  theme?: ButtonTheme;
  disabled?: boolean;
  onClick?: (args?: any) => void;
  minHeight?: number;
  margin?: string;
  padding?: string;
  tabletMargin?: string;
  mobileMargin?: string;
  type?: "button" | "submit" | "reset";
  width?: string;
  linkText?: Maybe<Scalars["String"]>;
  internalSlug?: string;
  externalLink?: Maybe<Scalars["String"]>;
  internalLink?: Maybe<any>;
  internalLinkQuery?: Maybe<string>;
  loading?: boolean;
  icon?: JSX.Element;
  className?: string;
  children?: ReactNode;
  state?: any;
}

const Button = ({
  theme = "base",
  onClick,
  linkText,
  children,
  internalLink,
  externalLink,
  loading,
  icon,
  internalSlug,
  width,
  internalLinkQuery,
  disabled,
  type,
  state,
  ...rest
}: Props) => {
  const handleOnClick = (e: any) => {
    if (onClick) {
      onClick(e);
    }
  };

  const to = internalSlug ?? getLink(internalLink, internalLinkQuery);
  const isPercentageWidth = width && width.indexOf("%") > -1;

  if (!to && !externalLink && !onClick && !type) return null;

  if (to) {
    return (
      <GatsbyLink
        className="buttonLink"
        to={to}
        style={{
          width: isPercentageWidth ? width : "fit-content",
        }}
        state={state}
      >
        <StyledButton
          width={isPercentageWidth ? "100%" : width}
          theme={buttonStyle[theme]}
          onClick={handleOnClick}
          type={type}
          disabled={disabled ?? loading}
          {...rest}
        >
          <span>
            {loading ? (
              <Loader />
            ) : (
              <>
                {linkText ?? children}
                {icon}
              </>
            )}
          </span>
        </StyledButton>
      </GatsbyLink>
    );
  }

  if (externalLink) {
    return (
      <a
        className="buttonLink"
        href={externalLink}
        style={{
          width: isPercentageWidth ? width : "fit-content",
        }}
        target="_blank"
      >
        <StyledButton
          width={isPercentageWidth ? "100%" : width}
          theme={buttonStyle[theme]}
          onClick={handleOnClick}
          type={type}
          disabled={disabled ?? loading}
          {...rest}
        >
          <span>
            {loading ? (
              <Loader />
            ) : (
              <>
                {linkText ?? children}
                {icon}
              </>
            )}
          </span>
        </StyledButton>
      </a>
    );
  }

  return (
    <StyledButton
      width={width}
      theme={buttonStyle[theme]}
      onClick={handleOnClick}
      type={type}
      {...rest}
      disabled={disabled ?? loading}
    >
      <span>
        {loading ? (
          <Loader />
        ) : (
          <>
            {linkText ?? children}
            {icon}
          </>
        )}
      </span>
    </StyledButton>
  );
};

export default Button;
