import React, { useEffect } from "react";
import styled from "styled-components";
import { useFormContext } from "./utils";
import { AddressInput, BlocksContent, Select, TextField } from "@global";
import { Container, GridContainer } from "@util/standard";
import { propertySaleTypes } from "@util/constants";

const InformationTab = () => {
  const { pageData, inputs, changeInput } = useFormContext();
  const selectedPropType = inputs.propertyCategory
    ? pageData?.propertyTypes?.find(el => el?._key === inputs.propertyCategory)
    : undefined;

  const handleChangePropCategory = (val: string) => {
    changeInput("propertyCategory", val);
    changeInput("propertyType", "");
  };

  useEffect(() => {
    console.log({ inputs });
  }, [inputs]);

  return (
    <Wrapper className="tab-wrapper">
      <BlocksContent data={pageData.propertyInfoHeading} />
      <GridContainer repeat={1} width="100%" margin="30px 0 0" gap="30px">
        <AddressInput
          showLabel
          inputs={inputs.address}
          onChange={data => changeInput("address", data)}
        />
        <Select
          id="propertyCategory"
          label="Property Category"
          value={inputs.propertyCategory}
          onChange={e => handleChangePropCategory(e.target.value)}
          options={
            pageData?.propertyTypes?.map(el => ({ title: el?.title, value: el?._key })) ?? []
          }
        />
        <Select
          disabled={!selectedPropType}
          id="propertyType"
          label="Property Type"
          value={inputs.propertyType}
          onChange={e => changeInput("propertyType", e.target.value)}
          options={
            selectedPropType?.propertyTypes?.map(el => ({ title: el?.title, value: el?._key })) ??
            []
          }
        />
      </GridContainer>
      <hr></hr>
      <BlocksContent data={pageData.featuresHeading} />
      <GridContainer repeat={2} mobileRepeat={2} gap="30px" margin="50px 0 0">
        <Select
          id="bed"
          label="Bedrooms"
          value={inputs.details.bed}
          onChange={e => changeInput("details.bed", e.target.value)}
          options={Array.from({ length: 10 }).map((_, i) => ({
            title: (i + 1).toString(),
            value: (i + 1).toString(),
          }))}
        />
        <Select
          id="bath"
          label="Bathrooms"
          value={inputs.details.bath}
          onChange={e => changeInput("details.bath", e.target.value)}
          options={Array.from({ length: 10 }).map((_, i) => ({
            title: (i + 1).toString(),
            value: (i + 1).toString(),
          }))}
        />
        <Select
          id="toilet"
          label="Seperate Toilet (optional)"
          value={inputs.details.toilet}
          onChange={e => changeInput("details.toilet", e.target.value)}
          options={Array.from({ length: 10 }).map((_, i) => ({
            title: (i + 1).toString(),
            value: (i + 1).toString(),
          }))}
        />
        <Select
          id="ensuite"
          label="Ensuite Bathrooms (optional)"
          value={inputs.details.ensuite}
          onChange={e => changeInput("details.ensuite", e.target.value)}
          options={Array.from({ length: 10 }).map((_, i) => ({
            title: (i + 1).toString(),
            value: (i + 1).toString(),
          }))}
        />
        <Select
          id="lounge"
          label="Living Areas / Lounges (optional)"
          value={inputs.details.lounge}
          onChange={e => changeInput("details.lounge", e.target.value)}
          options={Array.from({ length: 10 }).map((_, i) => ({
            title: (i + 1).toString(),
            value: (i + 1).toString(),
          }))}
        />
        <Select
          id="studies"
          label="Studies (optional)"
          value={inputs.details.studies}
          onChange={e => changeInput("details.studies", e.target.value)}
          options={Array.from({ length: 10 }).map((_, i) => ({
            title: (i + 1).toString(),
            value: (i + 1).toString(),
          }))}
        />
        <TextField
          type="number"
          id="floorArea"
          label="Floor area (m2) (optional)"
          min={10}
          value={inputs.details.floorArea > 0 ? inputs.details.floorArea : ""}
          placeholder="Enter your total floor area"
          onChange={e => changeInput("details.floorArea", e.target.value)}
        />
        <TextField
          type="number"
          id="landArea"
          label="Land Area (optional)"
          min={10}
          value={inputs.details.landArea > 0 ? inputs.details.landArea : ""}
          placeholder="Enter your total land area"
          onChange={e => changeInput("details.landArea", e.target.value)}
        />
        <Select
          id="floorAreaUnit"
          label="Floor Area measurement"
          value={inputs.details.floorAreaUnit}
          onChange={e => changeInput("details.floorAreaUnit", e.target.value)}
          options={[
            { title: "m2", value: "m2" },
            { value: "ha", title: "ha" },
          ]}
        />
        <Select
          id="landAreaUnit"
          label="Land Area measurement"
          value={inputs.details.landAreaUnit}
          onChange={e => changeInput("details.landAreaUnit", e.target.value)}
          options={[
            { title: "m2", value: "m2" },
            { value: "ha", title: "ha" },
          ]}
        />
        <Select
          id="incomePotential"
          label="Home & income potential (optional)"
          value={inputs.details.incomePotential}
          onChange={e => changeInput("details.incomePotential", e.target.value)}
          options={[
            { title: "Yes", value: "Yes" },
            { title: "No", value: "No" },
          ]}
          description="Does the property have two dwellings on one title that could provide the flexibility of home and income living?"
        />
      </GridContainer>
      <hr></hr>
      <BlocksContent data={pageData.parkingHeading} />
      <Container margin="50px 0 0" width="100%">
        <TextField
          type="number"
          id="parking"
          inputType="textarea"
          label="Parking"
          value={inputs.details.parking}
          placeholder="eg Double garage plus 2 off street parking spaces..."
          onChange={e => changeInput("details.parking", e.target.value)}
        />
      </Container>
      <hr></hr>
      <BlocksContent data={pageData.priceHeading} />
      <GridContainer repeat={2} gap="30px" margin="50px 0 0">
        <Select
          label="Type of sale"
          id="saleType"
          value={inputs.saleDetails.type}
          onChange={e => changeInput("saleDetails.type", e.target.value)}
          options={propertySaleTypes}
        />
        {["askingPrice", "enquiriesOver"].includes(inputs.saleDetails.type) && (
          <TextField
            inputType="currency"
            currencyProps={{ decimalsLimit: 2, prefix: "$" }}
            onValueChange={val => changeInput("saleDetails.askingPrice", val)}
            value={inputs.saleDetails.askingPrice}
            id="askingPrice"
            helperText="The price buyers will see"
            label={inputs.saleDetails.type === "askingPrice" ? "Asking Price" : "Enquiries Over"}
          />
        )}
        {["auction"].includes(inputs.saleDetails.type) && (
          <>
            <TextField
              value={inputs.saleDetails.saleDate}
              onChange={e => changeInput("saleDetails.saleDate", e.target.value)}
              id="saleDate"
              type="date"
              label="Auction Date"
            />
            <TextField
              value={inputs.saleDetails.auctionTime}
              onChange={e => changeInput("saleDetails.auctionTime", e.target.value)}
              id="auctionTime"
              type="time"
              label="Auction Time"
            />
          </>
        )}
        {["byTender", "deadlineSale"].includes(inputs.saleDetails.type) && (
          <TextField
            value={inputs.saleDetails.saleDate}
            onChange={e => changeInput("saleDetails.saleDate", e.target.value)}
            id="saleDate"
            type="date"
            label={inputs.saleDetails.type === "byTender" ? "Tender closing date" : "Deadline date"}
          />
        )}
        <TextField
          inputType="currency"
          currencyProps={{ decimalsLimit: 2, prefix: "$" }}
          onValueChange={val => changeInput("saleDetails.expectedSalePrice", val)}
          value={inputs.saleDetails.expectedSalePrice}
          id="expectedSalePrice"
          helperText="Used for searches - not shown publically"
          label="Expected Sale Price"
        />
        <TextField
          inputType="currency"
          currencyProps={{ decimalsLimit: 2, prefix: "$" }}
          onValueChange={val => changeInput("saleDetails.rateableValue", val)}
          value={inputs.saleDetails.rateableValue}
          id="rateableValue"
          label="Rateable value (RV) (optional)"
        />
      </GridContainer>
    </Wrapper>
  );
};

export default InformationTab;

const Wrapper = styled.div``;
