import { graphql, useStaticQuery } from "gatsby";
import { useEffect, useState } from "react";

export const useSavingsCalculator = () => {
  const [savingsOutput, setSavingsOutput] = useState(10500);
  const [priceInput, setPriceInput] = useState(1000000);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [goSavings, setGoSavings] = useState(0);
  const [proSavings, setProSavings] = useState(0);

  function calculateTotalCost(
    sellingPrice: number,
    basePercentage: number,
    basePercentThreshold: number,
    remainingPercentage: number,
    adminFees: number,
  ) {
    const adminFee = adminFees ? adminFees : 495;
    const marketingPackageCost = 2000;
    const gstRate = 0.15; // 15% GST
    const commissionRate1 = basePercentage ? basePercentage : 0.0395; // 3.95% on the first $400,000
    const commissionRate2 = remainingPercentage ? remainingPercentage : 0.02; // 2% on the balance
    const threshold = basePercentThreshold ? basePercentThreshold : 400000; // $400,000

    // Calculate commission on the first $400,000
    const commission1 = Math.min(sellingPrice, threshold) * commissionRate1;

    // Calculate commission on the balance (if any)
    const commission2 = Math.max(0, sellingPrice - threshold) * commissionRate2;

    // Total commission
    const totalCommission = commission1 + commission2;

    // Total cost including admin fee, marketing package, and GST
    const totalCost = totalCommission + adminFee + marketingPackageCost;

    // Calculate GST on the total cost
    const gst = totalCost * gstRate;

    // Final total cost including GST
    const finalTotalCost = totalCost + gst;

    return finalTotalCost;
  }

  const calculatorData = useStaticQuery(graphql`
    {
      sanitySavingsCalculator {
        _id
        _type
        secondTextInput
        firstTextInput
        thirdTextInput
        toolTip {
          ...sanityBlockContent
        }
        goBasePrice
        proBasePrice
        basePercentage
        basePercentThreshold
        remainingPercentage
        adminFees
      }
    }
  `);

  const calculate = () => {
    if (calculatorData == null) return;

    const {
      goBasePrice,
      proBasePrice,
      basePercentage,
      basePercentThreshold,
      remainingPercentage,
      adminFees,
    } = calculatorData.sanitySavingsCalculator;

    const inputPrice = priceInput;

    // Calculate savings for HomeSell Go
    // const calculatedGoSavings = goBasePrice - inputPrice * (basePercentage / 100);
    const calculatedGoSavings = calculateTotalCost(
      inputPrice,
      basePercentage,
      basePercentThreshold,
      remainingPercentage,
      adminFees,
    );
    console.log(calculatedGoSavings);

    setGoSavings(calculatedGoSavings - goBasePrice);

    // Calculate savings for HomeSell Pro
    const calculatedProSavings = calculateTotalCost(
      inputPrice,
      basePercentage,
      basePercentThreshold,
      remainingPercentage,
      adminFees,
    );
    setProSavings(calculatedProSavings - proBasePrice);
  };

  useEffect(() => {
    calculate();
  }, [priceInput]);

  return {
    savingsOutput,
    setSavingsOutput,
    priceInput,
    setPriceInput,
    tooltipVisible,
    setTooltipVisible,
    calculatorData,
    goSavings,
    proSavings,
  };
};

/*
How have we calculated your savings?
The savings indicated are based on HomeSell Go’s ‘Starter’ marketing package at $999 OR HomeSell Pro’s 
fixed success fee of $10,500, compared to average agency commission rates of 3.95% on the 
first $400,000 plus 2% on the balance plus $495 admin fee plus $2000 for a standard marketing package plus gst.

Learn more in our FAQ's
*/
