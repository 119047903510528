export const regions = [
  {
    LocalityId: 9,
    Name: "Northland",
    Districts: [
      {
        DistrictId: 1,
        Name: "Far North",
        Suburbs: [
          { SuburbId: 2129, Name: "Ahipara", AdjacentSuburbs: [2129] },
          { SuburbId: 1590, Name: "Awanui", AdjacentSuburbs: [1590] },
          { SuburbId: 3806, Name: "Awarua", AdjacentSuburbs: [3806] },
          { SuburbId: 1029, Name: "Broadwood", AdjacentSuburbs: [1029] },
          { SuburbId: 1923, Name: "Cable Bay", AdjacentSuburbs: [1922, 1923, 3308, 1133, 1950] },
          { SuburbId: 1499, Name: "Cape Reinga", AdjacentSuburbs: [1499] },
          { SuburbId: 3178, Name: "Cavalli Islands", AdjacentSuburbs: [3178] },
          { SuburbId: 1950, Name: "Coopers Beach", AdjacentSuburbs: [1950, 1923, 1133] },
          { SuburbId: 3309, Name: "Diggers Valley", AdjacentSuburbs: [3309] },
          { SuburbId: 3310, Name: "Fairburn", AdjacentSuburbs: [3310] },
          { SuburbId: 1953, Name: "Haruru", AdjacentSuburbs: [1953] },
          { SuburbId: 3179, Name: "Henderson Bay", AdjacentSuburbs: [3179] },
          { SuburbId: 1216, Name: "Herekino", AdjacentSuburbs: [1216] },
          { SuburbId: 2493, Name: "Hihi", AdjacentSuburbs: [2493] },
          { SuburbId: 3180, Name: "Hokianga", AdjacentSuburbs: [3180] },
          { SuburbId: 3181, Name: "Hokianga Harbour", AdjacentSuburbs: [1529, 3181] },
          { SuburbId: 2126, Name: "Horeke", AdjacentSuburbs: [2126] },
          { SuburbId: 1918, Name: "Houhora", AdjacentSuburbs: [1918] },
          { SuburbId: 1855, Name: "Kaeo", AdjacentSuburbs: [1593, 1855] },
          { SuburbId: 1531, Name: "Kaikohe", AdjacentSuburbs: [1531] },
          { SuburbId: 3182, Name: "Kaimaumau", AdjacentSuburbs: [3182] },
          { SuburbId: 1589, Name: "Kaingaroa", AdjacentSuburbs: [1589] },
          { SuburbId: 1591, Name: "Kaitaia", AdjacentSuburbs: [1591] },
          { SuburbId: 3183, Name: "Karetu", AdjacentSuburbs: [3183] },
          { SuburbId: 2495, Name: "Karikari Peninsula", AdjacentSuburbs: [2494, 2495] },
          { SuburbId: 1526, Name: "Kawakawa", AdjacentSuburbs: [1526] },
          { SuburbId: 1736, Name: "Kerikeri", AdjacentSuburbs: [1736] },
          { SuburbId: 1960, Name: "Kohukohu", AdjacentSuburbs: [1960] },
          { SuburbId: 3313, Name: "Koutu ", AdjacentSuburbs: [3313] },
          { SuburbId: 3437, Name: "Lake Ohia", AdjacentSuburbs: [3437] },
          { SuburbId: 1959, Name: "Mangamuka", AdjacentSuburbs: [1959] },
          { SuburbId: 3412, Name: "Mangatoetoe", AdjacentSuburbs: [3412] },
          { SuburbId: 1133, Name: "Mangonui", AdjacentSuburbs: [1133, 1923, 1950] },
          { SuburbId: 3486, Name: "Maromaku", AdjacentSuburbs: [3486] },
          { SuburbId: 3184, Name: "Mitimiti", AdjacentSuburbs: [3184] },
          { SuburbId: 1957, Name: "Moerewa", AdjacentSuburbs: [1957] },
          { SuburbId: 3413, Name: "Motutangi", AdjacentSuburbs: [3413] },
          { SuburbId: 1917, Name: "Ngataki", AdjacentSuburbs: [1917] },
          { SuburbId: 1525, Name: "Ohaeawai", AdjacentSuburbs: [1525] },
          { SuburbId: 1523, Name: "Okaihau", AdjacentSuburbs: [1523] },
          { SuburbId: 3185, Name: "Omanaia", AdjacentSuburbs: [3185] },
          { SuburbId: 1530, Name: "Omapere", AdjacentSuburbs: [1530] },
          { SuburbId: 2496, Name: "Opononi", AdjacentSuburbs: [2496] },
          { SuburbId: 1952, Name: "Opua", AdjacentSuburbs: [1952] },
          { SuburbId: 3063, Name: "Pakaraka", AdjacentSuburbs: [3063] },
          { SuburbId: 3814, Name: "Oue", AdjacentSuburbs: [3814, 3312] },
          { SuburbId: 1951, Name: "Paihia", AdjacentSuburbs: [1951] },
          { SuburbId: 1961, Name: "Pamapuria", AdjacentSuburbs: [1961] },
          { SuburbId: 1958, Name: "Panguru", AdjacentSuburbs: [1958] },
          { SuburbId: 1135, Name: "Paranui", AdjacentSuburbs: [1135] },
          { SuburbId: 1552, Name: "Peria", AdjacentSuburbs: [1552] },
          { SuburbId: 1919, Name: "Pukenui", AdjacentSuburbs: [1919] },
          { SuburbId: 2125, Name: "Pukepoto", AdjacentSuburbs: [2125] },
          { SuburbId: 3186, Name: "Rangiahua", AdjacentSuburbs: [3186] },
          { SuburbId: 3239, Name: "Rangiputa", AdjacentSuburbs: [3239] },
          { SuburbId: 1529, Name: "Rawene", AdjacentSuburbs: [1529, 3181] },
          { SuburbId: 3639, Name: "Rawhiti", AdjacentSuburbs: [3639] },
          { SuburbId: 1527, Name: "Russell", AdjacentSuburbs: [1527] },
          { SuburbId: 1528, Name: "Taheke", AdjacentSuburbs: [1528] },
          { SuburbId: 1922, Name: "Taipa", AdjacentSuburbs: [1922, 1923, 3308] },
          { SuburbId: 1215, Name: "Takahue", AdjacentSuburbs: [1215] },
          { SuburbId: 1705, Name: "Taupo Bay", AdjacentSuburbs: [1705] },
          { SuburbId: 3240, Name: "Tauranga Bay", AdjacentSuburbs: [3240] },
          { SuburbId: 2494, Name: "Tokerau Beach", AdjacentSuburbs: [2494, 2495] },
          { SuburbId: 1592, Name: "Totara North", AdjacentSuburbs: [1592] },
          { SuburbId: 1192, Name: "Towai", AdjacentSuburbs: [1192] },
          { SuburbId: 3719, Name: "Umawera", AdjacentSuburbs: [3719] },
          { SuburbId: 1194, Name: "Victoria Valley", AdjacentSuburbs: [1194] },
          { SuburbId: 1921, Name: "Waiharara", AdjacentSuburbs: [1921] },
          { SuburbId: 3544, Name: "Waima", AdjacentSuburbs: [3544] },
          { SuburbId: 3187, Name: "Waimamaku", AdjacentSuburbs: [3187] },
          { SuburbId: 3259, Name: "Waimate North", AdjacentSuburbs: [3259] },
          { SuburbId: 3745, Name: "Waiomio", AdjacentSuburbs: [3745] },
          { SuburbId: 1735, Name: "Waipapa", AdjacentSuburbs: [1735] },
          { SuburbId: 3311, Name: "Waipapakauri", AdjacentSuburbs: [3311] },
          { SuburbId: 3188, Name: "Whangape Harbour", AdjacentSuburbs: [3188] },
          { SuburbId: 1593, Name: "Whangaroa", AdjacentSuburbs: [1593, 1855] },
          { SuburbId: 3436, Name: "Whatuwhiwhi", AdjacentSuburbs: [3436] },
          { SuburbId: 3312, Name: "Whirinaki ", AdjacentSuburbs: [3312, 3814] },
        ],
      },
      {
        DistrictId: 3,
        Name: "Kaipara",
        Suburbs: [
          { SuburbId: 1494, Name: "Aranga", AdjacentSuburbs: [1494] },
          { SuburbId: 3056, Name: "Arapohue", AdjacentSuburbs: [3056] },
          { SuburbId: 3778, Name: "Aratapu", AdjacentSuburbs: [3778, 1773] },
          { SuburbId: 1551, Name: "Baylys Beach", AdjacentSuburbs: [1551] },
          { SuburbId: 1862, Name: "Dargaville", AdjacentSuburbs: [1862] },
          { SuburbId: 3564, Name: "Hakaru", AdjacentSuburbs: [3564] },
          { SuburbId: 3822, Name: "Kaihu", AdjacentSuburbs: [3822] },
          { SuburbId: 1571, Name: "Kaiwaka", AdjacentSuburbs: [1571] },
          { SuburbId: 934, Name: "Kellys Bay", AdjacentSuburbs: [934] },
          { SuburbId: 1453, Name: "Mangawhai", AdjacentSuburbs: [1453, 1454, 1863] },
          { SuburbId: 1454, Name: "Mangawhai Heads", AdjacentSuburbs: [1453, 1454] },
          { SuburbId: 1863, Name: "Mangawhare", AdjacentSuburbs: [1453, 1863] },
          { SuburbId: 1866, Name: "Matakohe", AdjacentSuburbs: [1866] },
          { SuburbId: 1739, Name: "Maungaturoto", AdjacentSuburbs: [1739] },
          { SuburbId: 3241, Name: "Pahi", AdjacentSuburbs: [1867, 3241] },
          { SuburbId: 1835, Name: "Paparoa", AdjacentSuburbs: [1835] },
          { SuburbId: 1772, Name: "Pouto", AdjacentSuburbs: [1772] },
          { SuburbId: 1775, Name: "Ruawai", AdjacentSuburbs: [1775] },
          { SuburbId: 1706, Name: "Tangiteroria", AdjacentSuburbs: [1706] },
          { SuburbId: 1553, Name: "Tangowahine", AdjacentSuburbs: [1553] },
          { SuburbId: 1773, Name: "Te Kopuru", AdjacentSuburbs: [1773, 3778] },
          { SuburbId: 1774, Name: "Tinopai", AdjacentSuburbs: [1774] },
          { SuburbId: 3189, Name: "Waipoua", AdjacentSuburbs: [3189] },
          { SuburbId: 1867, Name: "Whakapirau", AdjacentSuburbs: [1867, 3241] },
        ],
      },
      {
        DistrictId: 2,
        Name: "Whangarei",
        Suburbs: [
          { SuburbId: 3627, Name: "Abbey Caves", AdjacentSuburbs: [3627, 1519, 1880] },
          {
            SuburbId: 3449,
            Name: "Avenues",
            AdjacentSuburbs: [1554, 1744, 1444, 1657, 1880, 1883, 3055, 3130, 3435, 3449, 3246],
          },
          { SuburbId: 1813, Name: "Bream Bay", AdjacentSuburbs: [1813] },
          {
            SuburbId: 3055,
            Name: "Central Whangarei",
            AdjacentSuburbs: [
              1554, 1744, 1444, 1519, 1657, 1880, 1882, 1883, 3055, 3130, 3449, 3246,
            ],
          },
          { SuburbId: 1420, Name: "Glenbervie", AdjacentSuburbs: [1442, 1420] },
          { SuburbId: 1213, Name: "Helena Bay", AdjacentSuburbs: [1213] },
          { SuburbId: 1966, Name: "Hikurangi", AdjacentSuburbs: [1966] },
          {
            SuburbId: 3435,
            Name: "Horahora",
            AdjacentSuburbs: [1744, 1429, 1657, 1881, 1883, 3435, 3449],
          },
          { SuburbId: 1963, Name: "Hukerenui", AdjacentSuburbs: [1963] },
          { SuburbId: 1424, Name: "Kamo", AdjacentSuburbs: [1424, 1441, 1443] },
          { SuburbId: 1422, Name: "Kauri", AdjacentSuburbs: [1879, 1422] },
          {
            SuburbId: 1444,
            Name: "Kensington",
            AdjacentSuburbs: [1441, 1443, 1444, 1880, 1882, 1883, 3055, 3130, 3449, 3246],
          },
          { SuburbId: 3190, Name: "Kokopu", AdjacentSuburbs: [3190] },
          { SuburbId: 3399, Name: "Langs Beach", AdjacentSuburbs: [3399] },
          {
            SuburbId: 1882,
            Name: "Mairtown",
            AdjacentSuburbs: [1441, 1442, 1443, 1444, 1880, 1882, 3055, 3130, 3246],
          },
          { SuburbId: 1789, Name: "Mangapai", AdjacentSuburbs: [1789, 3140] },
          { SuburbId: 1791, Name: "Marsden Point", AdjacentSuburbs: [1791, 1792] },
          { SuburbId: 3147, Name: "Marua", AdjacentSuburbs: [3147] },
          { SuburbId: 3669, Name: "Mata", AdjacentSuburbs: [3669] },
          { SuburbId: 3148, Name: "Matapouri", AdjacentSuburbs: [3148] },
          { SuburbId: 3772, Name: "Matarau", AdjacentSuburbs: [3772, 3108] },
          { SuburbId: 1811, Name: "Maungakaramea", AdjacentSuburbs: [1811] },
          { SuburbId: 1704, Name: "Maungatapere", AdjacentSuburbs: [1704] },
          { SuburbId: 1881, Name: "Maunu", AdjacentSuburbs: [1744, 1429, 1881, 3435] },
          { SuburbId: 3247, Name: "McLeod Bay", AdjacentSuburbs: [3247] },
          {
            SuburbId: 1554,
            Name: "Morningside",
            AdjacentSuburbs: [1554, 1744, 1429, 1451, 1657, 1880, 1883, 3055, 3449, 3246],
          },
          { SuburbId: 1003, Name: "Ngunguru", AdjacentSuburbs: [1003] },
          { SuburbId: 3127, Name: "Oakura Coast", AdjacentSuburbs: [3127] },
          { SuburbId: 3140, Name: "Oakleigh", AdjacentSuburbs: [3140, 1789] },
          { SuburbId: 3141, Name: "One Tree Point", AdjacentSuburbs: [3141] },
          { SuburbId: 1007, Name: "Onerahi", AdjacentSuburbs: [1546, 1555, 1007, 3315] },
          {
            SuburbId: 1744,
            Name: "Otaika",
            AdjacentSuburbs: [1554, 1744, 1429, 1451, 1657, 1881, 1883, 3055, 3435, 3449],
          },
          {
            SuburbId: 1443,
            Name: "Otangarei",
            AdjacentSuburbs: [1424, 1441, 1442, 1443, 1444, 1882, 3130],
          },
          { SuburbId: 3774, Name: "Pakotai", AdjacentSuburbs: [3774] },
          { SuburbId: 1519, Name: "Parahaki", AdjacentSuburbs: [1519, 1880, 3055, 3246, 3627] },
          { SuburbId: 1006, Name: "Parua Bay", AdjacentSuburbs: [1006] },
          { SuburbId: 1005, Name: "Pataua", AdjacentSuburbs: [1005] },
          { SuburbId: 3142, Name: "Pataua North", AdjacentSuburbs: [3142] },
          { SuburbId: 3143, Name: "Pataua South", AdjacentSuburbs: [3143, 3775] },
          { SuburbId: 3656, Name: "Pipiwai", AdjacentSuburbs: [3656] },
          { SuburbId: 1575, Name: "Poroti", AdjacentSuburbs: [1575] },
          { SuburbId: 1555, Name: "Port Whangarei", AdjacentSuburbs: [1546, 1555, 1007] },
          { SuburbId: 1641, Name: "Portland", AdjacentSuburbs: [1641] },
          {
            SuburbId: 3130,
            Name: "Regent",
            AdjacentSuburbs: [1441, 1443, 1444, 1657, 1880, 1882, 1883, 3055, 3130, 3449, 3246],
          },
          { SuburbId: 3597, Name: "Purua", AdjacentSuburbs: [3597] },
          { SuburbId: 3769, Name: "Puwera", AdjacentSuburbs: [3769] },
          {
            SuburbId: 1429,
            Name: "Raumanga",
            AdjacentSuburbs: [1554, 1744, 1429, 1451, 1657, 1881, 3435],
          },
          { SuburbId: 3472, Name: "Riponui", AdjacentSuburbs: [3472] },
          {
            SuburbId: 1880,
            Name: "Riverside",
            AdjacentSuburbs: [
              1554, 1444, 1519, 1657, 1880, 1882, 1883, 3055, 3130, 3449, 3246, 3627,
            ],
          },
          { SuburbId: 1792, Name: "Ruakaka", AdjacentSuburbs: [1791, 1792] },
          { SuburbId: 3108, Name: "Ruatangata", AdjacentSuburbs: [3108, 3772] },
          { SuburbId: 1546, Name: "Sherwood Rise", AdjacentSuburbs: [1546, 1555, 1007, 3315] },
          { SuburbId: 1879, Name: "Springs Flat", AdjacentSuburbs: [1879, 1422] },
          { SuburbId: 3775, Name: "Taiharuru", AdjacentSuburbs: [3775, 3143] },
          { SuburbId: 3767, Name: "Taipuha", AdjacentSuburbs: [3767] },
          { SuburbId: 3113, Name: "Tamaterau", AdjacentSuburbs: [3113] },
          { SuburbId: 1425, Name: "Three Mile Bush", AdjacentSuburbs: [1425] },
          { SuburbId: 1442, Name: "Tikipunga", AdjacentSuburbs: [1441, 1442, 1443, 1882, 1420] },
          { SuburbId: 3191, Name: "Titoki", AdjacentSuburbs: [3191] },
          { SuburbId: 1451, Name: "Toetoe", AdjacentSuburbs: [1554, 1744, 1429, 1451] },
          { SuburbId: 1967, Name: "Tutukaka", AdjacentSuburbs: [1967] },
          {
            SuburbId: 1883,
            Name: "Vinetown",
            AdjacentSuburbs: [1554, 1744, 1444, 1657, 1880, 1883, 3055, 3130, 3435, 3449, 3246],
          },
          { SuburbId: 3315, Name: "Waikaraka", AdjacentSuburbs: [1546, 1007, 3315] },
          { SuburbId: 3316, Name: "Waikiekie", AdjacentSuburbs: [3316] },
          { SuburbId: 1812, Name: "Waiotira", AdjacentSuburbs: [1812] },
          { SuburbId: 1814, Name: "Waipu", AdjacentSuburbs: [1814, 1815] },
          { SuburbId: 1815, Name: "Waipu Cove", AdjacentSuburbs: [1814, 1815] },
          { SuburbId: 1964, Name: "Whakapara", AdjacentSuburbs: [1964] },
          { SuburbId: 1002, Name: "Whananaki", AdjacentSuburbs: [1002] },
          {
            SuburbId: 3246,
            Name: "Whangarei",
            AdjacentSuburbs: [1554, 1444, 1519, 1657, 1880, 1882, 1883, 3055, 3130, 3449, 3246],
          },
          { SuburbId: 1642, Name: "Whangarei Heads", AdjacentSuburbs: [1642] },
          { SuburbId: 1954, Name: "Whangaruru", AdjacentSuburbs: [1954] },
          { SuburbId: 1419, Name: "Whareora", AdjacentSuburbs: [1419] },
          { SuburbId: 1640, Name: "Whatitiri", AdjacentSuburbs: [1640, 3441] },
          {
            SuburbId: 1441,
            Name: "Whau Valley",
            AdjacentSuburbs: [1424, 1441, 1442, 1443, 1444, 1882, 3130],
          },
          { SuburbId: 3441, Name: "Wheki Valley", AdjacentSuburbs: [1640, 3441] },
          {
            SuburbId: 1657,
            Name: "Woodhill",
            AdjacentSuburbs: [1554, 1744, 1429, 1657, 1880, 1883, 3055, 3130, 3435, 3449, 3246],
          },
        ],
      },
    ],
  },
  {
    LocalityId: 1,
    Name: "Auckland",
    Districts: [
      {
        DistrictId: 7,
        Name: "Auckland City",
        Suburbs: [
          {
            SuburbId: 3256,
            Name: "Arch Hill",
            AdjacentSuburbs: [82, 83, 86, 87, 88, 89, 91, 3256, 123, 124, 126, 135, 136],
          },
          { SuburbId: 149, Name: "Avondale", AdjacentSuburbs: [147, 149, 307, 3167, 142] },
          {
            SuburbId: 124,
            Name: "Balmoral",
            AdjacentSuburbs: [86, 90, 3256, 3258, 123, 124, 125, 126, 128, 135],
          },
          { SuburbId: 130, Name: "Blockhouse Bay", AdjacentSuburbs: [102, 130] },
          {
            SuburbId: 89,
            Name: "City Centre",
            AdjacentSuburbs: [41, 82, 83, 88, 89, 91, 99, 3256, 135, 136, 3566],
          },
          { SuburbId: 3229, Name: "Coxs Bay", AdjacentSuburbs: [83, 84, 85, 87, 109, 3229] },
          {
            SuburbId: 135,
            Name: "Eden Terrace",
            AdjacentSuburbs: [82, 83, 87, 88, 89, 90, 99, 3256, 123, 124, 126, 135, 136],
          },
          {
            SuburbId: 393,
            Name: "Ellerslie",
            AdjacentSuburbs: [63, 148, 122, 393, 3496, 290, 315],
          },
          { SuburbId: 125, Name: "Epsom", AdjacentSuburbs: [90, 99, 122, 124, 125, 131, 132] },
          {
            SuburbId: 88,
            Name: "Freemans Bay",
            AdjacentSuburbs: [82, 83, 84, 87, 88, 89, 91, 109, 3256, 123, 135, 136, 3566],
          },
          { SuburbId: 316, Name: "Glen Innes", AdjacentSuburbs: [16, 17, 42, 61, 316, 317, 337] },
          { SuburbId: 16, Name: "Glendowie", AdjacentSuburbs: [16, 42, 316, 317] },
          {
            SuburbId: 136,
            Name: "Grafton",
            AdjacentSuburbs: [41, 82, 88, 89, 90, 99, 3256, 123, 135, 136],
          },
          { SuburbId: 122, Name: "Greenlane", AdjacentSuburbs: [40, 148, 122, 125, 131, 393] },
          {
            SuburbId: 87,
            Name: "Grey Lynn",
            AdjacentSuburbs: [82, 83, 84, 86, 87, 88, 91, 109, 3256, 3229, 123, 126, 135],
          },
          { SuburbId: 84, Name: "Herne Bay", AdjacentSuburbs: [83, 84, 87, 88, 91, 109, 3229] },
          { SuburbId: 138, Name: "Hillsborough", AdjacentSuburbs: [146, 120, 121, 132, 138, 141] },
          {
            SuburbId: 123,
            Name: "Kingsland",
            AdjacentSuburbs: [82, 86, 87, 88, 90, 3256, 3258, 123, 124, 126, 135, 136, 128, 129],
          },
          { SuburbId: 17, Name: "Kohimarama", AdjacentSuburbs: [17, 39, 42, 61, 62, 316] },
          { SuburbId: 102, Name: "Lynfield", AdjacentSuburbs: [102, 146, 3167, 120, 127, 130] },
          {
            SuburbId: 39,
            Name: "Meadowbank",
            AdjacentSuburbs: [17, 39, 61, 62, 63, 322, 3496, 40],
          },
          { SuburbId: 62, Name: "Mission Bay", AdjacentSuburbs: [17, 39, 61, 62, 322] },
          {
            SuburbId: 126,
            Name: "Morningside",
            AdjacentSuburbs: [86, 87, 3256, 3258, 123, 124, 126, 128, 129, 135],
          },
          {
            SuburbId: 129,
            Name: "Mount Albert",
            AdjacentSuburbs: [85, 86, 147, 3167, 3258, 126, 128, 129, 142, 123],
          },
          {
            SuburbId: 90,
            Name: "Mount Eden",
            AdjacentSuburbs: [90, 99, 123, 124, 125, 131, 135, 136, 121],
          },
          {
            SuburbId: 120,
            Name: "Mount Roskill",
            AdjacentSuburbs: [102, 146, 120, 127, 138, 128, 121],
          },
          { SuburbId: 315, Name: "Mount Wellington", AdjacentSuburbs: [288, 315, 338, 393] },
          {
            SuburbId: 3167,
            Name: "New Windsor",
            AdjacentSuburbs: [102, 147, 149, 3167, 127, 128, 129],
          },
          {
            SuburbId: 99,
            Name: "Newmarket",
            AdjacentSuburbs: [40, 41, 82, 89, 90, 99, 125, 135, 136],
          },
          {
            SuburbId: 82,
            Name: "Newton",
            AdjacentSuburbs: [82, 83, 87, 88, 89, 91, 99, 3256, 123, 135, 136],
          },
          {
            SuburbId: 131,
            Name: "One Tree Hill",
            AdjacentSuburbs: [90, 121, 122, 125, 131, 132, 141],
          },
          { SuburbId: 141, Name: "Onehunga", AdjacentSuburbs: [148, 132, 141, 395, 338, 138, 131] },
          { SuburbId: 322, Name: "Orakei", AdjacentSuburbs: [39, 62, 322] },
          { SuburbId: 148, Name: "Oranga", AdjacentSuburbs: [148, 290, 122, 141, 393, 395] },
          { SuburbId: 338, Name: "Otahuhu", AdjacentSuburbs: [338, 315, 141] },
          {
            SuburbId: 147,
            Name: "Owairaka",
            AdjacentSuburbs: [147, 149, 3167, 3258, 127, 128, 129],
          },
          { SuburbId: 288, Name: "Panmure", AdjacentSuburbs: [288, 315, 336, 337, 394, 3496] },
          { SuburbId: 41, Name: "Parnell", AdjacentSuburbs: [41, 89, 99, 136] },
          { SuburbId: 290, Name: "Penrose", AdjacentSuburbs: [148, 290, 395, 393] },
          { SuburbId: 85, Name: "Point Chevalier", AdjacentSuburbs: [85, 86, 109, 3229, 129, 142] },
          {
            SuburbId: 337,
            Name: "Point England",
            AdjacentSuburbs: [61, 288, 316, 317, 337, 394, 3496],
          },
          {
            SuburbId: 83,
            Name: "Ponsonby",
            AdjacentSuburbs: [82, 83, 84, 87, 88, 89, 91, 109, 3256, 3229, 135],
          },
          { SuburbId: 40, Name: "Remuera", AdjacentSuburbs: [40, 99, 122, 39] },
          { SuburbId: 132, Name: "Royal Oak", AdjacentSuburbs: [121, 125, 131, 132, 138, 141] },
          { SuburbId: 42, Name: "Saint Heliers", AdjacentSuburbs: [16, 17, 42, 316] },
          {
            SuburbId: 61,
            Name: "Saint Johns",
            AdjacentSuburbs: [17, 39, 61, 62, 63, 316, 337, 3496],
          },
          { SuburbId: 63, Name: "Saint Johns Park", AdjacentSuburbs: [39, 61, 63, 393, 3496] },
          {
            SuburbId: 91,
            Name: "Saint Marys Bay",
            AdjacentSuburbs: [82, 83, 84, 87, 88, 89, 91, 3256, 3566],
          },
          {
            SuburbId: 128,
            Name: "Sandringham",
            AdjacentSuburbs: [86, 147, 3167, 3258, 121, 124, 126, 127, 128, 129, 123, 120],
          },
          {
            SuburbId: 3258,
            Name: "St Lukes",
            AdjacentSuburbs: [86, 147, 3258, 123, 124, 126, 128, 129],
          },
          {
            SuburbId: 3496,
            Name: "Stonefields",
            AdjacentSuburbs: [39, 61, 63, 288, 337, 393, 394, 3496],
          },
          { SuburbId: 394, Name: "Tamaki", AdjacentSuburbs: [286, 288, 336, 337, 394, 3496] },
          { SuburbId: 395, Name: "Te Papapa", AdjacentSuburbs: [148, 290, 141, 395] },
          {
            SuburbId: 121,
            Name: "Three Kings",
            AdjacentSuburbs: [121, 127, 128, 131, 132, 138, 90, 120],
          },
          { SuburbId: 317, Name: "Wai O Taiki Bay", AdjacentSuburbs: [16, 316, 317, 337] },
          { SuburbId: 146, Name: "Waikowhai", AdjacentSuburbs: [102, 146, 120, 138] },
          { SuburbId: 142, Name: "Waterview", AdjacentSuburbs: [85, 129, 142, 149] },
          { SuburbId: 127, Name: "Wesley", AdjacentSuburbs: [102, 147, 3167, 120, 121, 127, 128] },
          {
            SuburbId: 86,
            Name: "Western Springs",
            AdjacentSuburbs: [85, 86, 87, 109, 3256, 3258, 123, 124, 126, 128, 129],
          },
          { SuburbId: 109, Name: "Westmere", AdjacentSuburbs: [83, 84, 85, 86, 87, 88, 109, 3229] },
          { SuburbId: 3566, Name: "Wynyard Quarter", AdjacentSuburbs: [3566, 89, 88, 91] },
        ],
      },
      {
        DistrictId: 10,
        Name: "Franklin",
        Suburbs: [
          { SuburbId: 1188, Name: "Aka Aka", AdjacentSuburbs: [1188] },
          { SuburbId: 346, Name: "Ararimu", AdjacentSuburbs: [346] },
          { SuburbId: 293, Name: "Awhitu", AdjacentSuburbs: [293, 3599] },
          { SuburbId: 2851, Name: "Bombay", AdjacentSuburbs: [330, 2851] },
          { SuburbId: 865, Name: "Buckland", AdjacentSuburbs: [302, 2848, 865] },
          { SuburbId: 173, Name: "Clarks Beach", AdjacentSuburbs: [173, 181] },
          { SuburbId: 283, Name: "Glenbrook", AdjacentSuburbs: [283] },
          { SuburbId: 988, Name: "Harrisville", AdjacentSuburbs: [988] },
          { SuburbId: 345, Name: "Hunua", AdjacentSuburbs: [345] },
          { SuburbId: 1082, Name: "Kaiaua", AdjacentSuburbs: [1082] },
          { SuburbId: 66, Name: "Karaka", AdjacentSuburbs: [66] },
          { SuburbId: 3529, Name: "Karaka Harbourside", AdjacentSuburbs: [3529] },
          { SuburbId: 67, Name: "Kingseat", AdjacentSuburbs: [67] },
          { SuburbId: 1372, Name: "Mangatangi", AdjacentSuburbs: [1372] },
          { SuburbId: 1114, Name: "Mangatawhiri", AdjacentSuburbs: [1114] },
          { SuburbId: 3599, Name: "Manukau Heads", AdjacentSuburbs: [3599, 293] },
          { SuburbId: 1375, Name: "Maramarua", AdjacentSuburbs: [1375] },
          { SuburbId: 382, Name: "Mauku", AdjacentSuburbs: [382, 383] },
          { SuburbId: 867, Name: "Mercer", AdjacentSuburbs: [867] },
          { SuburbId: 659, Name: "Onewhero", AdjacentSuburbs: [659] },
          { SuburbId: 1209, Name: "Otaua", AdjacentSuburbs: [1209] },
          { SuburbId: 68, Name: "Paerata", AdjacentSuburbs: [68] },
          { SuburbId: 2853, Name: "Paparimu", AdjacentSuburbs: [2853] },
          { SuburbId: 383, Name: "Patumahoe", AdjacentSuburbs: [382, 383] },
          { SuburbId: 2849, Name: "Pokeno", AdjacentSuburbs: [2849] },
          { SuburbId: 134, Name: "Pollok", AdjacentSuburbs: [134] },
          { SuburbId: 1081, Name: "Port Waikato", AdjacentSuburbs: [1081] },
          { SuburbId: 660, Name: "Pukekawa", AdjacentSuburbs: [660] },
          { SuburbId: 302, Name: "Pukekohe", AdjacentSuburbs: [302, 2848, 865] },
          { SuburbId: 2848, Name: "Pukekohe East", AdjacentSuburbs: [302, 2848, 865] },
          { SuburbId: 871, Name: "Puni", AdjacentSuburbs: [871] },
          { SuburbId: 330, Name: "Ramarama", AdjacentSuburbs: [330, 2851] },
          { SuburbId: 658, Name: "Te Kohanga", AdjacentSuburbs: [658] },
          { SuburbId: 866, Name: "Tuakau", AdjacentSuburbs: [866] },
          { SuburbId: 181, Name: "Waiau Pa", AdjacentSuburbs: [173, 181] },
          { SuburbId: 3591, Name: "Waikaretu", AdjacentSuburbs: [3591] },
          { SuburbId: 175, Name: "Waiuku", AdjacentSuburbs: [175] },
          { SuburbId: 3555, Name: "Whakatiwai", AdjacentSuburbs: [3555, 348] },
          { SuburbId: 348, Name: "Wharekawa", AdjacentSuburbs: [348, 3555] },
        ],
      },
      {
        DistrictId: 81,
        Name: "Hauraki Gulf Islands",
        Suburbs: [
          { SuburbId: 381, Name: "Great Barrier Island", AdjacentSuburbs: [381] },
          { SuburbId: 3125, Name: "Kawau Island", AdjacentSuburbs: [3125] },
          { SuburbId: 3060, Name: "Rakino Island", AdjacentSuburbs: [3060] },
        ],
      },
      {
        DistrictId: 8,
        Name: "Manukau City",
        Suburbs: [
          { SuburbId: 390, Name: "Alfriston", AdjacentSuburbs: [390] },
          { SuburbId: 26, Name: "Beachlands", AdjacentSuburbs: [26] },
          {
            SuburbId: 15,
            Name: "Botany Downs",
            AdjacentSuburbs: [6, 13, 14, 15, 24, 27, 28, 29, 258, 3414, 3479, 3541],
          },
          { SuburbId: 10, Name: "Brookby", AdjacentSuburbs: [10, 9, 11] },
          { SuburbId: 18, Name: "Bucklands Beach", AdjacentSuburbs: [2, 3, 18, 252, 253] },
          { SuburbId: 258, Name: "Burswood", AdjacentSuburbs: [13, 14, 15, 29, 258, 3541] },
          { SuburbId: 335, Name: "Chapel Downs", AdjacentSuburbs: [273, 298, 299, 300, 335, 369] },
          {
            SuburbId: 368,
            Name: "City Centre",
            AdjacentSuburbs: [298, 300, 363, 364, 3146, 3205, 368, 369],
          },
          { SuburbId: 365, Name: "Clendon Park", AdjacentSuburbs: [365, 366] },
          { SuburbId: 11, Name: "Clevedon", AdjacentSuburbs: [11, 10] },
          {
            SuburbId: 369,
            Name: "Clover Park",
            AdjacentSuburbs: [273, 298, 299, 300, 335, 3146, 3205, 368, 369],
          },
          { SuburbId: 4, Name: "Cockle Bay", AdjacentSuburbs: [3, 4, 27, 28, 3414, 3488, 2] },
          {
            SuburbId: 29,
            Name: "Cumbria Downs",
            AdjacentSuburbs: [13, 14, 15, 24, 27, 28, 29, 258, 3414, 3479, 3490],
          },
          {
            SuburbId: 24,
            Name: "Dannemora",
            AdjacentSuburbs: [13, 14, 15, 24, 27, 28, 29, 3414, 3479, 3490],
          },
          { SuburbId: 5, Name: "East Tamaki", AdjacentSuburbs: [5, 254] },
          { SuburbId: 3490, Name: "East Tamaki Heights", AdjacentSuburbs: [14, 23, 24, 29, 3490] },
          { SuburbId: 253, Name: "Eastern Beach", AdjacentSuburbs: [2, 3, 18, 252, 253] },
          { SuburbId: 286, Name: "Farm Cove", AdjacentSuburbs: [25, 252, 286, 336, 394, 3541] },
          { SuburbId: 318, Name: "Favona", AdjacentSuburbs: [32, 269, 318] },
          { SuburbId: 273, Name: "Flat Bush", AdjacentSuburbs: [273, 298, 299, 335, 369] },
          {
            SuburbId: 13,
            Name: "Golflands",
            AdjacentSuburbs: [6, 13, 14, 15, 24, 29, 258, 3414, 3479, 3541],
          },
          {
            SuburbId: 298,
            Name: "Goodwood Heights",
            AdjacentSuburbs: [273, 298, 299, 300, 334, 335, 3146, 3205, 368, 369, 3586],
          },
          { SuburbId: 252, Name: "Half Moon Bay", AdjacentSuburbs: [2, 6, 18, 25, 252, 253, 286] },
          {
            SuburbId: 6,
            Name: "Highland Park",
            AdjacentSuburbs: [2, 6, 13, 15, 25, 252, 3414, 3541],
          },
          {
            SuburbId: 391,
            Name: "Hill Park",
            AdjacentSuburbs: [300, 301, 333, 334, 363, 391, 392],
          },
          { SuburbId: 2, Name: "Howick", AdjacentSuburbs: [2, 3, 6, 18, 252, 253, 3414, 4] },
          {
            SuburbId: 14,
            Name: "Huntington Park",
            AdjacentSuburbs: [13, 14, 15, 23, 24, 29, 258, 3490, 3541],
          },
          { SuburbId: 33, Name: "Kawakawa Bay", AdjacentSuburbs: [33] },
          {
            SuburbId: 3146,
            Name: "Mahia Park",
            AdjacentSuburbs: [298, 300, 363, 364, 3146, 3205, 368, 369],
          },
          { SuburbId: 32, Name: "Mangere", AdjacentSuburbs: [32, 269, 318] },
          { SuburbId: 30, Name: "Mangere Bridge", AdjacentSuburbs: [30] },
          { SuburbId: 269, Name: "Mangere East", AdjacentSuburbs: [32, 269, 318] },
          {
            SuburbId: 3205,
            Name: "Manukau",
            AdjacentSuburbs: [298, 300, 363, 364, 3146, 3205, 368, 369],
          },
          {
            SuburbId: 299,
            Name: "Manukau Heights",
            AdjacentSuburbs: [273, 298, 299, 300, 335, 369, 3586],
          },
          { SuburbId: 333, Name: "Manurewa", AdjacentSuburbs: [301, 333, 334, 391, 392] },
          { SuburbId: 301, Name: "Manurewa East", AdjacentSuburbs: [301, 333, 334, 391, 392] },
          { SuburbId: 8, Name: "Maraetai", AdjacentSuburbs: [8, 20] },
          { SuburbId: 36, Name: "Matingarahi", AdjacentSuburbs: [36] },
          {
            SuburbId: 3414,
            Name: "Meadowlands",
            AdjacentSuburbs: [2, 4, 6, 13, 15, 24, 27, 28, 29, 3414, 3479, 3488],
          },
          { SuburbId: 3, Name: "Mellons Bay", AdjacentSuburbs: [2, 3, 4, 18, 253] },
          { SuburbId: 3506, Name: "Mission Heights", AdjacentSuburbs: [23, 3506] },
          {
            SuburbId: 28,
            Name: "Northpark",
            AdjacentSuburbs: [4, 15, 24, 27, 28, 29, 3414, 3479, 3488],
          },
          { SuburbId: 20, Name: "Omana Beach", AdjacentSuburbs: [8, 20] },
          { SuburbId: 34, Name: "Orere", AdjacentSuburbs: [34, 35] },
          { SuburbId: 35, Name: "Orere Point", AdjacentSuburbs: [34, 35] },
          { SuburbId: 254, Name: "Otara", AdjacentSuburbs: [5, 254] },
          { SuburbId: 25, Name: "Pakuranga", AdjacentSuburbs: [6, 25, 252, 286, 336, 3541] },
          {
            SuburbId: 3541,
            Name: "Pakuranga Heights",
            AdjacentSuburbs: [3541, 15, 258, 286, 13, 6, 14, 25, 336],
          },
          { SuburbId: 240, Name: "Papatoetoe", AdjacentSuburbs: [240, 364] },
          { SuburbId: 23, Name: "Point View Park", AdjacentSuburbs: [14, 23, 3490, 3506] },
          { SuburbId: 364, Name: "Puhinui", AdjacentSuburbs: [240, 364, 3146, 3205, 368] },
          { SuburbId: 392, Name: "Randwick Park", AdjacentSuburbs: [301, 333, 334, 391, 392] },
          {
            SuburbId: 3479,
            Name: "Shamrock Park",
            AdjacentSuburbs: [13, 15, 24, 27, 28, 29, 3414, 3479, 3488],
          },
          { SuburbId: 3488, Name: "Shelly Park", AdjacentSuburbs: [4, 27, 28, 3414, 3479, 3488] },
          {
            SuburbId: 27,
            Name: "Somerville",
            AdjacentSuburbs: [4, 15, 24, 27, 28, 29, 3414, 3479, 3488],
          },
          { SuburbId: 336, Name: "Sunnyhills", AdjacentSuburbs: [25, 286, 288, 336, 394, 3541] },
          {
            SuburbId: 334,
            Name: "The Gardens",
            AdjacentSuburbs: [298, 300, 301, 333, 334, 391, 392, 3586],
          },
          {
            SuburbId: 300,
            Name: "Totara Heights",
            AdjacentSuburbs: [298, 299, 300, 334, 335, 363, 3146, 3205, 368, 369, 391, 3586],
          },
          { SuburbId: 3586, Name: "Totara Park", AdjacentSuburbs: [3586, 298, 299, 334, 300] },
          { SuburbId: 367, Name: "Wattle Downs", AdjacentSuburbs: [2636, 367] },
          { SuburbId: 366, Name: "Weymouth", AdjacentSuburbs: [365, 366] },
          { SuburbId: 9, Name: "Whitford", AdjacentSuburbs: [9, 10] },
          { SuburbId: 363, Name: "Wiri", AdjacentSuburbs: [300, 363, 3146, 3205, 368, 391] },
        ],
      },
      {
        DistrictId: 5,
        Name: "North Shore City",
        Suburbs: [
          { SuburbId: 139, Name: "Albany", AdjacentSuburbs: [3275, 139, 140, 3272, 92, 114] },
          { SuburbId: 2982, Name: "Bayswater", AdjacentSuburbs: [37, 49, 60, 74, 3262, 2982] },
          { SuburbId: 3233, Name: "Bayview", AdjacentSuburbs: [52, 156, 3003, 3233, 114, 116] },
          { SuburbId: 3005, Name: "Beach Haven", AdjacentSuburbs: [54, 3005] },
          { SuburbId: 37, Name: "Belmont", AdjacentSuburbs: [37, 49, 74, 3262, 2982] },
          { SuburbId: 54, Name: "Birkdale", AdjacentSuburbs: [54, 56, 104, 3005] },
          { SuburbId: 55, Name: "Birkenhead", AdjacentSuburbs: [55, 56, 104, 105, 3263] },
          {
            SuburbId: 93,
            Name: "Browns Bay",
            AdjacentSuburbs: [76, 81, 93, 94, 95, 111, 3275, 118, 3608],
          },
          {
            SuburbId: 97,
            Name: "Campbells Bay",
            AdjacentSuburbs: [95, 96, 97, 110, 154, 155, 118, 119, 3473],
          },
          {
            SuburbId: 154,
            Name: "Castor Bay",
            AdjacentSuburbs: [50, 53, 57, 96, 97, 110, 154, 155],
          },
          { SuburbId: 104, Name: "Chatswood", AdjacentSuburbs: [54, 55, 104] },
          {
            SuburbId: 116,
            Name: "Chester Park",
            AdjacentSuburbs: [51, 156, 3272, 3233, 114, 115, 116, 140],
          },
          {
            SuburbId: 155,
            Name: "Crown Hill",
            AdjacentSuburbs: [50, 53, 57, 97, 110, 154, 155, 3451],
          },
          { SuburbId: 60, Name: "Devonport", AdjacentSuburbs: [49, 60, 3262, 2982] },
          {
            SuburbId: 3674,
            Name: "Fairview Heights",
            AdjacentSuburbs: [3674, 3230, 111, 3275, 3265],
          },
          {
            SuburbId: 50,
            Name: "Forrest Hill",
            AdjacentSuburbs: [50, 53, 57, 110, 154, 155, 3451, 3235],
          },
          {
            SuburbId: 3003,
            Name: "Glenfield",
            AdjacentSuburbs: [52, 56, 105, 3451, 3003, 3233, 114],
          },
          { SuburbId: 3608, Name: "Glenvar", AdjacentSuburbs: [3608, 93, 77, 76, 81] },
          { SuburbId: 51, Name: "Greenhithe", AdjacentSuburbs: [51, 156, 3272, 116, 114] },
          { SuburbId: 74, Name: "Hauraki", AdjacentSuburbs: [37, 74, 404, 3262, 2982] },
          { SuburbId: 105, Name: "Hillcrest", AdjacentSuburbs: [52, 55, 56, 57, 105, 3003] },
          { SuburbId: 77, Name: "Long Bay", AdjacentSuburbs: [76, 77, 81, 3608] },
          { SuburbId: 3228, Name: "Lucas Heights", AdjacentSuburbs: [3265, 3228] },
          {
            SuburbId: 96,
            Name: "Mairangi Bay",
            AdjacentSuburbs: [94, 95, 96, 97, 110, 154, 118, 119, 3473],
          },
          { SuburbId: 52, Name: "Marlborough", AdjacentSuburbs: [52, 56, 105, 3451, 3003, 3233] },
          {
            SuburbId: 115,
            Name: "Meadowood",
            AdjacentSuburbs: [110, 3451, 3235, 114, 115, 116, 119, 140, 3473],
          },
          { SuburbId: 53, Name: "Milford", AdjacentSuburbs: [50, 53, 57, 154, 155, 404] },
          {
            SuburbId: 95,
            Name: "Murrays Bay",
            AdjacentSuburbs: [93, 94, 95, 96, 97, 111, 118, 119, 3473],
          },
          { SuburbId: 3262, Name: "Narrow Neck", AdjacentSuburbs: [37, 49, 60, 74, 3262, 2982] },
          {
            SuburbId: 140,
            Name: "North Harbour",
            AdjacentSuburbs: [3272, 114, 115, 116, 139, 140],
          },
          { SuburbId: 56, Name: "Northcote", AdjacentSuburbs: [52, 54, 55, 56, 105, 3003] },
          { SuburbId: 3263, Name: "Northcote Point", AdjacentSuburbs: [55, 3263] },
          {
            SuburbId: 111,
            Name: "Northcross",
            AdjacentSuburbs: [81, 93, 94, 95, 111, 3275, 3230, 118, 3674],
          },
          { SuburbId: 75, Name: "Okura", AdjacentSuburbs: [75, 226, 3648] },
          { SuburbId: 3275, Name: "Oteha", AdjacentSuburbs: [93, 94, 111, 3275, 3230, 139, 3674] },
          { SuburbId: 92, Name: "Paremoremo", AdjacentSuburbs: [92, 3272, 139] },
          {
            SuburbId: 118,
            Name: "Pinehill",
            AdjacentSuburbs: [93, 94, 95, 96, 97, 110, 111, 118, 119, 3473],
          },
          {
            SuburbId: 3473,
            Name: "Rosedale",
            AdjacentSuburbs: [95, 96, 97, 110, 3235, 115, 118, 119, 3473],
          },
          {
            SuburbId: 94,
            Name: "Rothesay Bay",
            AdjacentSuburbs: [81, 93, 94, 95, 96, 111, 3275, 118, 119],
          },
          {
            SuburbId: 3272,
            Name: "Schnapper Rock",
            AdjacentSuburbs: [51, 92, 156, 3272, 116, 140, 139],
          },
          { SuburbId: 49, Name: "Stanley Bay", AdjacentSuburbs: [37, 49, 60, 3262, 2982] },
          {
            SuburbId: 110,
            Name: "Sunnynook",
            AdjacentSuburbs: [50, 96, 97, 110, 154, 155, 3451, 3235, 115, 118, 119, 3473],
          },
          { SuburbId: 404, Name: "Takapuna", AdjacentSuburbs: [53, 57, 74, 404] },
          { SuburbId: 76, Name: "Torbay", AdjacentSuburbs: [76, 77, 81, 93, 3608] },
          {
            SuburbId: 3235,
            Name: "Totara Vale",
            AdjacentSuburbs: [50, 110, 3451, 3235, 114, 115, 119, 3473],
          },
          {
            SuburbId: 114,
            Name: "Unsworth Heights",
            AdjacentSuburbs: [3233, 3235, 114, 115, 116, 140, 3003, 139, 51],
          },
          { SuburbId: 81, Name: "Waiake", AdjacentSuburbs: [76, 77, 81, 93, 94, 111, 3608] },
          { SuburbId: 156, Name: "Wainoni", AdjacentSuburbs: [51, 156, 3272, 3233, 116] },
          {
            SuburbId: 3451,
            Name: "Wairau Valley",
            AdjacentSuburbs: [50, 52, 57, 110, 155, 3451, 3003, 3235, 115],
          },
          {
            SuburbId: 57,
            Name: "Westlake",
            AdjacentSuburbs: [50, 53, 57, 105, 154, 155, 404, 3451],
          },
          {
            SuburbId: 119,
            Name: "Windsor Park",
            AdjacentSuburbs: [94, 95, 96, 97, 110, 3235, 115, 118, 119, 3473],
          },
        ],
      },
      {
        DistrictId: 9,
        Name: "Papakura",
        Suburbs: [
          { SuburbId: 389, Name: "Ardmore", AdjacentSuburbs: [389] },
          { SuburbId: 2636, Name: "Conifer Grove", AdjacentSuburbs: [2510, 2636, 367] },
          { SuburbId: 2509, Name: "Drury", AdjacentSuburbs: [2509, 371, 3592] },
          { SuburbId: 371, Name: "Opaheke", AdjacentSuburbs: [2509, 371] },
          { SuburbId: 461, Name: "Pahurehure", AdjacentSuburbs: [2521, 461, 3545] },
          { SuburbId: 2521, Name: "Papakura", AdjacentSuburbs: [2521, 461, 372, 3545] },
          { SuburbId: 372, Name: "Red Hill", AdjacentSuburbs: [2521, 372] },
          { SuburbId: 3545, Name: "Rosehill", AdjacentSuburbs: [3545, 461, 2521] },
          { SuburbId: 3592, Name: "Runciman", AdjacentSuburbs: [3592, 2509] },
          { SuburbId: 2510, Name: "Takanini", AdjacentSuburbs: [2510, 2636] },
        ],
      },
      {
        DistrictId: 4,
        Name: "Rodney",
        Suburbs: [
          { SuburbId: 202, Name: "Ahuroa", AdjacentSuburbs: [202] },
          {
            SuburbId: 3265,
            Name: "Albany Heights",
            AdjacentSuburbs: [3265, 3228, 3230, 206, 247, 3674],
          },
          { SuburbId: 235, Name: "Algies Bay", AdjacentSuburbs: [280, 235] },
          { SuburbId: 320, Name: "Arkles Bay", AdjacentSuburbs: [320, 321, 3264, 454, 456, 402] },
          { SuburbId: 70, Name: "Army Bay", AdjacentSuburbs: [69, 70, 71, 72] },
          {
            SuburbId: 321,
            Name: "Big Manly",
            AdjacentSuburbs: [320, 321, 3264, 454, 455, 456, 402],
          },
          { SuburbId: 247, Name: "Coatesville", AdjacentSuburbs: [247, 206, 3265, 248] },
          { SuburbId: 206, Name: "Dairy Flat", AdjacentSuburbs: [206, 247, 3265, 226, 248] },
          { SuburbId: 197, Name: "Glorit", AdjacentSuburbs: [197] },
          { SuburbId: 71, Name: "Gulf Harbour", AdjacentSuburbs: [69, 70, 71, 72, 401] },
          { SuburbId: 43, Name: "Hatfields Beach", AdjacentSuburbs: [43, 274] },
          { SuburbId: 187, Name: "Helensville", AdjacentSuburbs: [187, 48] },
          { SuburbId: 69, Name: "Hobbs Bay", AdjacentSuburbs: [69, 70, 71, 72, 401] },
          { SuburbId: 195, Name: "Hoteo", AdjacentSuburbs: [195] },
          { SuburbId: 143, Name: "Huapai", AdjacentSuburbs: [143] },
          { SuburbId: 194, Name: "Kaipara Flats", AdjacentSuburbs: [194] },
          { SuburbId: 48, Name: "Kaukapakapa", AdjacentSuburbs: [48, 187] },
          { SuburbId: 144, Name: "Kumeu", AdjacentSuburbs: [144, 184] },
          { SuburbId: 199, Name: "Leigh", AdjacentSuburbs: [199] },
          {
            SuburbId: 456,
            Name: "Little Manly",
            AdjacentSuburbs: [320, 321, 3264, 454, 455, 456, 401, 402],
          },
          { SuburbId: 281, Name: "Mahurangi", AdjacentSuburbs: [281, 3452] },
          { SuburbId: 3452, Name: "Mahurangi East", AdjacentSuburbs: [281, 3452] },
          { SuburbId: 45, Name: "Mahurangi West", AdjacentSuburbs: [45] },
          { SuburbId: 58, Name: "Makarau", AdjacentSuburbs: [58] },
          { SuburbId: 3744, Name: "Mangakura", AdjacentSuburbs: [3744] },
          { SuburbId: 3264, Name: "Manly", AdjacentSuburbs: [320, 321, 3264, 454, 455, 456, 401] },
          { SuburbId: 208, Name: "Matakana", AdjacentSuburbs: [208, 214, 3487] },
          { SuburbId: 72, Name: "Matakatia Bay", AdjacentSuburbs: [69, 70, 71, 72, 455, 401] },
          { SuburbId: 224, Name: "Maygrove", AdjacentSuburbs: [274, 3484, 221, 224] },
          { SuburbId: 3603, Name: "Milldale", AdjacentSuburbs: [3603, 3484, 221, 220] },
          { SuburbId: 3484, Name: "Millwater", AdjacentSuburbs: [3484, 221, 224, 220, 3603, 274] },
          { SuburbId: 160, Name: "Muriwai Beach", AdjacentSuburbs: [160, 161] },
          { SuburbId: 161, Name: "Muriwai Valley", AdjacentSuburbs: [160, 161] },
          { SuburbId: 3648, Name: "Okura Bush", AdjacentSuburbs: [3648, 75, 226] },
          { SuburbId: 278, Name: "Omaha", AdjacentSuburbs: [241, 278] },
          { SuburbId: 214, Name: "Omaha Flats", AdjacentSuburbs: [208, 214] },
          { SuburbId: 274, Name: "Orewa", AdjacentSuburbs: [43, 274, 224, 3484] },
          { SuburbId: 198, Name: "Pakiri", AdjacentSuburbs: [198, 207] },
          { SuburbId: 215, Name: "Parakai", AdjacentSuburbs: [215] },
          { SuburbId: 2705, Name: "Parkhurst", AdjacentSuburbs: [555, 2705] },
          { SuburbId: 241, Name: "Point Wells", AdjacentSuburbs: [241, 278] },
          { SuburbId: 44, Name: "Puhoi", AdjacentSuburbs: [44] },
          { SuburbId: 221, Name: "Red Beach", AdjacentSuburbs: [3484, 221, 224, 3603, 402] },
          { SuburbId: 226, Name: "Redvale", AdjacentSuburbs: [75, 226, 206, 3648] },
          { SuburbId: 162, Name: "Riverhead", AdjacentSuburbs: [162] },
          { SuburbId: 242, Name: "Sandspit", AdjacentSuburbs: [242] },
          { SuburbId: 3447, Name: "Shelly Beach", AdjacentSuburbs: [3447] },
          { SuburbId: 220, Name: "Silverdale", AdjacentSuburbs: [220, 3484, 3603] },
          { SuburbId: 280, Name: "Snells Beach", AdjacentSuburbs: [280, 235] },
          { SuburbId: 2702, Name: "South Head", AdjacentSuburbs: [2702] },
          {
            SuburbId: 402,
            Name: "Stanmore Bay",
            AdjacentSuburbs: [320, 321, 454, 456, 402, 225, 221, 401],
          },
          { SuburbId: 225, Name: "Stillwater", AdjacentSuburbs: [454, 402, 225] },
          { SuburbId: 231, Name: "Streamlands", AdjacentSuburbs: [231] },
          { SuburbId: 3817, Name: "Tahekeroa", AdjacentSuburbs: [3817] },
          { SuburbId: 3114, Name: "Tapora", AdjacentSuburbs: [3114, 3515] },
          { SuburbId: 209, Name: "Tauhoa", AdjacentSuburbs: [209, 3515] },
          { SuburbId: 184, Name: "Taupaki", AdjacentSuburbs: [184, 144] },
          { SuburbId: 3487, Name: "Tawharanui Peninsula", AdjacentSuburbs: [3487, 208] },
          { SuburbId: 193, Name: "Te Arai", AdjacentSuburbs: [193, 207] },
          { SuburbId: 170, Name: "Te Hana", AdjacentSuburbs: [170, 3515] },
          { SuburbId: 236, Name: "The Grange", AdjacentSuburbs: [236] },
          {
            SuburbId: 455,
            Name: "Tindalls Beach",
            AdjacentSuburbs: [72, 321, 3264, 455, 456, 401],
          },
          { SuburbId: 207, Name: "Tomarata", AdjacentSuburbs: [207, 193, 198, 233] },
          { SuburbId: 1572, Name: "Topuni", AdjacentSuburbs: [1572] },
          {
            SuburbId: 454,
            Name: "Wade Heads",
            AdjacentSuburbs: [320, 321, 3264, 454, 456, 402, 225],
          },
          { SuburbId: 205, Name: "Waikoukou Valley", AdjacentSuburbs: [205] },
          { SuburbId: 185, Name: "Waimauku", AdjacentSuburbs: [185] },
          { SuburbId: 248, Name: "Wainui", AdjacentSuburbs: [248, 206, 247] },
          { SuburbId: 237, Name: "Waitoki", AdjacentSuburbs: [237] },
          { SuburbId: 73, Name: "Waiwera", AdjacentSuburbs: [73] },
          { SuburbId: 166, Name: "Warkworth", AdjacentSuburbs: [166] },
          { SuburbId: 210, Name: "Wellsford", AdjacentSuburbs: [210, 3515] },
          {
            SuburbId: 401,
            Name: "Whangaparaoa",
            AdjacentSuburbs: [69, 71, 72, 3264, 455, 456, 401, 402],
          },
          { SuburbId: 233, Name: "Whangaripo", AdjacentSuburbs: [233, 207] },
          { SuburbId: 3515, Name: "Wharehine", AdjacentSuburbs: [170, 209, 210, 3114, 3515] },
          { SuburbId: 201, Name: "Woodcocks", AdjacentSuburbs: [201] },
          { SuburbId: 217, Name: "Woodhill", AdjacentSuburbs: [217] },
        ],
      },
      {
        DistrictId: 77,
        Name: "Waiheke Island",
        Suburbs: [
          { SuburbId: 3199, Name: "Awaawaroa Bay", AdjacentSuburbs: [3199, 3209, 3450, 3751] },
          {
            SuburbId: 356,
            Name: "Blackpool",
            AdjacentSuburbs: [355, 356, 357, 3200, 3201, 3281, 3225, 376],
          },
          {
            SuburbId: 357,
            Name: "Church Bay",
            AdjacentSuburbs: [355, 356, 357, 3201, 3281, 3225, 376],
          },
          { SuburbId: 3245, Name: "Connells Bay", AdjacentSuburbs: [3232, 3245] },
          { SuburbId: 3232, Name: "Cowes Bay", AdjacentSuburbs: [3232, 3245] },
          {
            SuburbId: 3200,
            Name: "Enclosure Bay",
            AdjacentSuburbs: [355, 356, 3045, 3200, 3201, 3225, 376, 378],
          },
          {
            SuburbId: 3201,
            Name: "Hekerua Bay",
            AdjacentSuburbs: [355, 356, 357, 3045, 3200, 3201, 3225, 376, 378],
          },
          { SuburbId: 3202, Name: "Kennedy Point", AdjacentSuburbs: [3202, 376] },
          {
            SuburbId: 3225,
            Name: "Little Oneroa",
            AdjacentSuburbs: [355, 356, 357, 3200, 3201, 3281, 3225, 376],
          },
          { SuburbId: 3281, Name: "Matiatia Bay", AdjacentSuburbs: [355, 356, 357, 3281, 3225] },
          { SuburbId: 397, Name: "Omiha", AdjacentSuburbs: [3203, 3234, 397, 3667] },
          {
            SuburbId: 355,
            Name: "Oneroa",
            AdjacentSuburbs: [355, 356, 357, 3200, 3201, 3281, 3225, 376],
          },
          { SuburbId: 379, Name: "Onetangi", AdjacentSuburbs: [3450, 379] },
          { SuburbId: 398, Name: "Orapiu", AdjacentSuburbs: [3204, 398] },
          { SuburbId: 377, Name: "Ostend", AdjacentSuburbs: [3045, 3234, 376, 377, 378] },
          { SuburbId: 378, Name: "Palm Beach", AdjacentSuburbs: [3045, 3200, 3201, 377, 378] },
          {
            SuburbId: 3203,
            Name: "Rocky Bay",
            AdjacentSuburbs: [3203, 3209, 3210, 3234, 397, 3667],
          },
          { SuburbId: 3204, Name: "Sandy Bay", AdjacentSuburbs: [3204, 398] },
          {
            SuburbId: 376,
            Name: "Surfdale",
            AdjacentSuburbs: [355, 356, 357, 3045, 3200, 3201, 3202, 3225, 376, 377],
          },
          {
            SuburbId: 3209,
            Name: "Te Whau",
            AdjacentSuburbs: [3199, 3203, 3209, 3450, 3667, 3751],
          },
          { SuburbId: 3234, Name: "Te Whau Bay", AdjacentSuburbs: [3203, 3234, 377, 397] },
          { SuburbId: 3751, Name: "Waiheke Island", AdjacentSuburbs: [3751, 3199, 3209, 3450] },
          { SuburbId: 3450, Name: "Waikopou Bay", AdjacentSuburbs: [3199, 3209, 3450, 379, 3751] },
          { SuburbId: 3667, Name: "Whakanewha", AdjacentSuburbs: [3667, 397, 3203, 3209, 3210] },
          { SuburbId: 3210, Name: "Woodside Bay", AdjacentSuburbs: [3203, 3210, 3667] },
        ],
      },
      {
        DistrictId: 6,
        Name: "Waitakere City",
        Suburbs: [
          { SuburbId: 100, Name: "Anawhata", AdjacentSuburbs: [100] },
          { SuburbId: 145, Name: "Bethells Beach", AdjacentSuburbs: [145, 151, 158] },
          { SuburbId: 188, Name: "Cornwallis", AdjacentSuburbs: [188] },
          { SuburbId: 266, Name: "French Bay", AdjacentSuburbs: [261, 262, 266] },
          { SuburbId: 282, Name: "Glen Eden", AdjacentSuburbs: [264, 282, 306, 307, 313, 261] },
          { SuburbId: 265, Name: "Glendene", AdjacentSuburbs: [263, 264, 265, 2632, 2634, 243] },
          { SuburbId: 308, Name: "Green Bay", AdjacentSuburbs: [261, 262, 307, 308] },
          {
            SuburbId: 239,
            Name: "Harbour View",
            AdjacentSuburbs: [239, 1449, 1460, 1654, 2119, 2120, 2121, 2122, 2123, 2124, 3350],
          },
          {
            SuburbId: 2634,
            Name: "Henderson",
            AdjacentSuburbs: [260, 264, 265, 309, 310, 2632, 2634, 257],
          },
          {
            SuburbId: 2632,
            Name: "Henderson Valley",
            AdjacentSuburbs: [260, 264, 265, 309, 310, 2632, 2634, 151, 158, 313],
          },
          { SuburbId: 222, Name: "Herald Island", AdjacentSuburbs: [222, 256, 3528] },
          { SuburbId: 256, Name: "Hobsonville", AdjacentSuburbs: [222, 256, 3528, 405, 223] },
          { SuburbId: 189, Name: "Huia", AdjacentSuburbs: [189, 462] },
          { SuburbId: 463, Name: "Karekare", AdjacentSuburbs: [463] },
          { SuburbId: 263, Name: "Kelston", AdjacentSuburbs: [263, 265, 307] },
          { SuburbId: 306, Name: "Konini", AdjacentSuburbs: [261, 282, 306, 311] },
          { SuburbId: 150, Name: "Laingholm", AdjacentSuburbs: [150, 261, 267, 311, 190] },
          { SuburbId: 462, Name: "Little Huia", AdjacentSuburbs: [189, 462] },
          {
            SuburbId: 3255,
            Name: "Massey",
            AdjacentSuburbs: [245, 246, 255, 3255, 3257, 3392, 3242, 223, 257],
          },
          {
            SuburbId: 255,
            Name: "Massey East",
            AdjacentSuburbs: [245, 246, 255, 3255, 3257, 3392, 3242],
          },
          {
            SuburbId: 246,
            Name: "Massey North",
            AdjacentSuburbs: [245, 246, 255, 3255, 3257, 3392, 3242],
          },
          {
            SuburbId: 245,
            Name: "Massey West",
            AdjacentSuburbs: [245, 246, 255, 3255, 3257, 3392, 3242],
          },
          {
            SuburbId: 310,
            Name: "Mclaren Park",
            AdjacentSuburbs: [260, 264, 309, 310, 2632, 2634],
          },
          { SuburbId: 307, Name: "New Lynn", AdjacentSuburbs: [149, 263, 307, 308, 282] },
          { SuburbId: 2635, Name: "Oratia", AdjacentSuburbs: [2635, 261] },
          { SuburbId: 309, Name: "Palm Heights", AdjacentSuburbs: [260, 309, 310, 2632, 2634] },
          { SuburbId: 190, Name: "Parau", AdjacentSuburbs: [190, 267, 150] },
          { SuburbId: 101, Name: "Piha", AdjacentSuburbs: [101] },
          { SuburbId: 257, Name: "Ranui", AdjacentSuburbs: [257, 260, 151, 3255, 2634] },
          {
            SuburbId: 3257,
            Name: "Royal Heights",
            AdjacentSuburbs: [245, 246, 255, 3255, 3257, 3392, 3242, 223],
          },
          { SuburbId: 264, Name: "Sunnyvale", AdjacentSuburbs: [264, 265, 282, 310, 2632, 2634] },
          { SuburbId: 151, Name: "Swanson", AdjacentSuburbs: [151, 158, 257, 2632, 145] },
          { SuburbId: 268, Name: "Te Atatu Peninsula", AdjacentSuburbs: [238, 268] },
          { SuburbId: 243, Name: "Te Atatu South", AdjacentSuburbs: [238, 243, 265] },
          {
            SuburbId: 261,
            Name: "Titirangi",
            AdjacentSuburbs: [150, 261, 262, 266, 306, 308, 313, 282, 2635],
          },
          { SuburbId: 313, Name: "Waiatarua", AdjacentSuburbs: [313, 2632, 261, 282] },
          { SuburbId: 311, Name: "Waima", AdjacentSuburbs: [150, 306, 311] },
          { SuburbId: 238, Name: "Waimanu Bay", AdjacentSuburbs: [238, 243, 268] },
          { SuburbId: 158, Name: "Waitakere", AdjacentSuburbs: [158, 151, 2632, 145] },
          {
            SuburbId: 223,
            Name: "West Harbour",
            AdjacentSuburbs: [223, 3242, 3255, 3257, 3392, 3528, 256, 405],
          },
          {
            SuburbId: 260,
            Name: "Western Heights",
            AdjacentSuburbs: [257, 260, 309, 310, 2632, 2634],
          },
          {
            SuburbId: 3392,
            Name: "Westgate",
            AdjacentSuburbs: [245, 246, 255, 3255, 3257, 3392, 3242, 223],
          },
          { SuburbId: 396, Name: "Whatipu", AdjacentSuburbs: [396] },
          { SuburbId: 405, Name: "Whenuapai", AdjacentSuburbs: [405, 256, 223] },
          { SuburbId: 262, Name: "Wood Bay", AdjacentSuburbs: [261, 262, 266, 308] },
          { SuburbId: 267, Name: "Woodlands Park", AdjacentSuburbs: [150, 190, 267] },
        ],
      },
    ],
  },
  {
    LocalityId: 14,
    Name: "Waikato",
    Districts: [
      {
        DistrictId: 16,
        Name: "Hamilton",
        Suburbs: [
          {
            SuburbId: 3756,
            Name: "Avalon",
            AdjacentSuburbs: [3756, 3646, 1152, 1151, 1270, 1305, 1269],
          },
          {
            SuburbId: 3278,
            Name: "Bader",
            AdjacentSuburbs: [1024, 1025, 1366, 3278, 1239, 1243, 3491, 3677],
          },
          {
            SuburbId: 3646,
            Name: "Baverstock",
            AdjacentSuburbs: [3646, 3514, 1151, 3306, 1269, 3756],
          },
          {
            SuburbId: 1304,
            Name: "Beerescourt",
            AdjacentSuburbs: [1265, 1266, 1267, 1268, 1269, 975, 1152, 1153, 1304, 1305],
          },
          { SuburbId: 3789, Name: "Burbush", AdjacentSuburbs: [3789, 3306] },
          {
            SuburbId: 1268,
            Name: "Chartwell",
            AdjacentSuburbs: [1185, 1265, 1266, 1267, 1268, 1381, 975, 976, 1152, 1153, 1304, 1305],
          },
          {
            SuburbId: 976,
            Name: "Chedworth Park",
            AdjacentSuburbs: [1184, 1267, 1268, 3107, 975, 976, 977],
          },
          {
            SuburbId: 1266,
            Name: "Claudelands",
            AdjacentSuburbs: [1185, 1265, 1266, 1267, 1268, 1381, 3215, 3223, 3224, 1304, 1243],
          },
          { SuburbId: 1025, Name: "Deanwell", AdjacentSuburbs: [1024, 1025, 1366, 3278, 1239] },
          {
            SuburbId: 1380,
            Name: "Dinsdale",
            AdjacentSuburbs: [1380, 1382, 1150, 1151, 1244, 3514],
          },
          {
            SuburbId: 1185,
            Name: "Enderley",
            AdjacentSuburbs: [1184, 1185, 1265, 1266, 1267, 1268, 1381, 3215, 3223, 3224],
          },
          {
            SuburbId: 1267,
            Name: "Fairfield",
            AdjacentSuburbs: [1184, 1185, 1265, 1266, 1267, 1268, 975, 976, 977, 1304],
          },
          { SuburbId: 1184, Name: "Fairview Downs", AdjacentSuburbs: [1184, 1185, 1267, 976] },
          {
            SuburbId: 1239,
            Name: "Fitzroy",
            AdjacentSuburbs: [1024, 1025, 1366, 3278, 1239, 3677],
          },
          { SuburbId: 974, Name: "Flagstaff", AdjacentSuburbs: [1270, 3107, 974, 1305, 1240] },
          {
            SuburbId: 1152,
            Name: "Forest Lake",
            AdjacentSuburbs: [1265, 1268, 1269, 1151, 1152, 1153, 1304, 1305, 3756],
          },
          {
            SuburbId: 1244,
            Name: "Frankton",
            AdjacentSuburbs: [1265, 1380, 1381, 1382, 3215, 3223, 3224, 1153, 1243, 1244, 3491],
          },
          { SuburbId: 1366, Name: "Glenview", AdjacentSuburbs: [1024, 1025, 1366, 3278, 1239] },
          {
            SuburbId: 3514,
            Name: "Grandview Heights",
            AdjacentSuburbs: [1150, 1151, 1380, 3306, 3514, 3646],
          },
          {
            SuburbId: 3215,
            Name: "Hamilton City Central",
            AdjacentSuburbs: [1185, 1265, 1266, 1381, 3215, 3223, 3224, 1153, 1243, 1244, 3491],
          },
          { SuburbId: 1240, Name: "Hamilton East", AdjacentSuburbs: [1270, 974, 1240] },
          {
            SuburbId: 3491,
            Name: "Hamilton Lake",
            AdjacentSuburbs: [1024, 1381, 3215, 3223, 3278, 3224, 1243, 1244, 3491],
          },
          {
            SuburbId: 1381,
            Name: "Hamilton North",
            AdjacentSuburbs: [
              1185, 1265, 1266, 1268, 1381, 3215, 3223, 3224, 1153, 1243, 1244, 3491,
            ],
          },
          {
            SuburbId: 1243,
            Name: "Hamilton West",
            AdjacentSuburbs: [1265, 1266, 1381, 3215, 3223, 3278, 3224, 1243, 1244, 3491],
          },
          {
            SuburbId: 3223,
            Name: "Harrowfield",
            AdjacentSuburbs: [1185, 1265, 1266, 1381, 3215, 3223, 3224, 1153, 1243, 1244, 3491],
          },
          {
            SuburbId: 1241,
            Name: "Hillcrest",
            AdjacentSuburbs: [1186, 3446, 1303, 1241, 1242, 3677],
          },
          { SuburbId: 777, Name: "Horsham Downs", AdjacentSuburbs: [777] },
          { SuburbId: 3477, Name: "Huntington", AdjacentSuburbs: [3107, 975, 977, 3477] },
          {
            SuburbId: 1382,
            Name: "Livingstone",
            AdjacentSuburbs: [1265, 1380, 1382, 1150, 1151, 1153, 1244],
          },
          {
            SuburbId: 1153,
            Name: "Maeroa",
            AdjacentSuburbs: [
              1265, 1268, 1381, 1382, 3215, 3223, 3224, 1151, 1152, 1153, 1304, 1244,
            ],
          },
          {
            SuburbId: 1024,
            Name: "Melville",
            AdjacentSuburbs: [1024, 1025, 1366, 3278, 1239, 3491],
          },
          {
            SuburbId: 1151,
            Name: "Nawton",
            AdjacentSuburbs: [1380, 1382, 1150, 1151, 1152, 1153, 3514, 3646, 3756],
          },
          {
            SuburbId: 3677,
            Name: "Peacocke",
            AdjacentSuburbs: [3677, 3278, 1239, 1241, 3446, 1186],
          },
          { SuburbId: 3579, Name: "Pukemoremore", AdjacentSuburbs: [3579] },
          { SuburbId: 1270, Name: "Pukete", AdjacentSuburbs: [1269, 1270, 974, 1305, 1240, 3756] },
          {
            SuburbId: 975,
            Name: "Queenwood",
            AdjacentSuburbs: [1267, 1268, 3107, 975, 976, 977, 1304, 1305, 3477],
          },
          {
            SuburbId: 3446,
            Name: "Riverlea",
            AdjacentSuburbs: [1186, 3446, 1303, 1241, 1242, 3677],
          },
          { SuburbId: 3306, Name: "Rotokauri ", AdjacentSuburbs: [3306, 3514, 3646, 3789] },
          { SuburbId: 3107, Name: "Rototuna", AdjacentSuburbs: [3107, 974, 975, 976, 977, 3477] },
          { SuburbId: 3270, Name: "Ruakura", AdjacentSuburbs: [3270, 897] },
          { SuburbId: 3236, Name: "Rukuhia", AdjacentSuburbs: [3236, 3307] },
          {
            SuburbId: 1305,
            Name: "Saint Andrews",
            AdjacentSuburbs: [1268, 1269, 1270, 974, 975, 1152, 1304, 1305, 3756],
          },
          {
            SuburbId: 1186,
            Name: "Silverdale",
            AdjacentSuburbs: [1186, 3446, 1303, 1241, 1242, 3677],
          },
          {
            SuburbId: 1269,
            Name: "Te Rapa",
            AdjacentSuburbs: [1269, 1270, 1152, 1304, 1305, 3646, 3756],
          },
          { SuburbId: 2764, Name: "Temple View", AdjacentSuburbs: [2764, 3403] },
          {
            SuburbId: 3224,
            Name: "Thornton Estate",
            AdjacentSuburbs: [1185, 1265, 1266, 1381, 3215, 3223, 3224, 1153, 1243, 1244, 3491],
          },
          {
            SuburbId: 1150,
            Name: "Western Heights",
            AdjacentSuburbs: [1380, 1382, 1150, 1151, 3514],
          },
          {
            SuburbId: 1265,
            Name: "Whitiora",
            AdjacentSuburbs: [
              1185, 1265, 1266, 1267, 1268, 1381, 1382, 3215, 3223, 3224, 1152, 1153, 1304, 1243,
              1244,
            ],
          },
        ],
      },
      {
        DistrictId: 12,
        Name: "Hauraki",
        Suburbs: [
          { SuburbId: 3664, Name: "Awaiti", AdjacentSuburbs: [3664] },
          { SuburbId: 3662, Name: "Kaihere", AdjacentSuburbs: [3662] },
          { SuburbId: 3494, Name: "Karangahake", AdjacentSuburbs: [3494] },
          { SuburbId: 649, Name: "Kerepehi", AdjacentSuburbs: [649] },
          { SuburbId: 3553, Name: "Mangatarata", AdjacentSuburbs: [3553] },
          { SuburbId: 846, Name: "Netherton", AdjacentSuburbs: [846] },
          { SuburbId: 648, Name: "Ngatea", AdjacentSuburbs: [648] },
          { SuburbId: 3619, Name: "Orongo", AdjacentSuburbs: [3619] },
          { SuburbId: 847, Name: "Paeroa", AdjacentSuburbs: [847] },
          { SuburbId: 3663, Name: "Pipiroa", AdjacentSuburbs: [3663] },
          { SuburbId: 763, Name: "Turua", AdjacentSuburbs: [763] },
          { SuburbId: 460, Name: "Waihi", AdjacentSuburbs: [453, 460] },
          { SuburbId: 453, Name: "Waihi East", AdjacentSuburbs: [453, 460] },
          { SuburbId: 597, Name: "Waikino", AdjacentSuburbs: [597] },
          { SuburbId: 645, Name: "Waitakaruru", AdjacentSuburbs: [645, 728] },
          { SuburbId: 765, Name: "Whiritoa", AdjacentSuburbs: [765] },
        ],
      },
      {
        DistrictId: 15,
        Name: "Matamata-Piako",
        Suburbs: [
          { SuburbId: 824, Name: "Mangateparu", AdjacentSuburbs: [824] },
          { SuburbId: 859, Name: "Matamata", AdjacentSuburbs: [859] },
          { SuburbId: 538, Name: "Morrinsville", AdjacentSuburbs: [538] },
          { SuburbId: 722, Name: "Patetonga", AdjacentSuburbs: [722, 3604] },
          { SuburbId: 595, Name: "Springdale", AdjacentSuburbs: [595] },
          { SuburbId: 848, Name: "Tahuna", AdjacentSuburbs: [848] },
          { SuburbId: 3595, Name: "Tauhei", AdjacentSuburbs: [3595] },
          { SuburbId: 987, Name: "Te Aroha", AdjacentSuburbs: [987] },
          { SuburbId: 862, Name: "Te Poi", AdjacentSuburbs: [862] },
          { SuburbId: 723, Name: "Waharoa", AdjacentSuburbs: [723] },
          { SuburbId: 827, Name: "Waihou", AdjacentSuburbs: [827] },
          { SuburbId: 826, Name: "Waitoa", AdjacentSuburbs: [826] },
          { SuburbId: 1160, Name: "Walton", AdjacentSuburbs: [1160] },
        ],
      },
      {
        DistrictId: 18,
        Name: "Otorohanga",
        Suburbs: [
          { SuburbId: 3510, Name: "Aotea Harbour", AdjacentSuburbs: [3510] },
          { SuburbId: 674, Name: "Kawhia", AdjacentSuburbs: [674] },
          { SuburbId: 688, Name: "Otorohanga", AdjacentSuburbs: [688] },
        ],
      },
      {
        DistrictId: 19,
        Name: "South Waikato",
        Suburbs: [
          { SuburbId: 801, Name: "Arapuni", AdjacentSuburbs: [800, 801] },
          { SuburbId: 880, Name: "Hodderville", AdjacentSuburbs: [880] },
          { SuburbId: 3749, Name: "Kinleith", AdjacentSuburbs: [3749] },
          { SuburbId: 831, Name: "Lichfield", AdjacentSuburbs: [831] },
          { SuburbId: 832, Name: "Ngatira", AdjacentSuburbs: [832] },
          { SuburbId: 803, Name: "Putaruru", AdjacentSuburbs: [803] },
          { SuburbId: 863, Name: "Tirau", AdjacentSuburbs: [863] },
          { SuburbId: 834, Name: "Tokoroa", AdjacentSuburbs: [834] },
          { SuburbId: 833, Name: "Wiltsdown", AdjacentSuburbs: [833] },
        ],
      },
      {
        DistrictId: 21,
        Name: "Taupo",
        Suburbs: [
          { SuburbId: 1106, Name: "Acacia Bay", AdjacentSuburbs: [1106] },
          { SuburbId: 3617, Name: "Bonshaw Park", AdjacentSuburbs: [3617] },
          { SuburbId: 3499, Name: "Broadlands Forest", AdjacentSuburbs: [3499] },
          { SuburbId: 3540, Name: "Hatepe", AdjacentSuburbs: [3540] },
          {
            SuburbId: 1330,
            Name: "Hilltop",
            AdjacentSuburbs: [1023, 3196, 3221, 1300, 1301, 1330, 3542],
          },
          { SuburbId: 3196, Name: "Invergarry", AdjacentSuburbs: [1023, 3196, 1300, 1330] },
          { SuburbId: 1298, Name: "Kinloch", AdjacentSuburbs: [1298] },
          { SuburbId: 1103, Name: "Kuratau", AdjacentSuburbs: [1103, 3527] },
          { SuburbId: 1715, Name: "Lake Taupo", AdjacentSuburbs: [1715] },
          { SuburbId: 787, Name: "Mangakino", AdjacentSuburbs: [787] },
          { SuburbId: 3543, Name: "Marotiri", AdjacentSuburbs: [3543] },
          { SuburbId: 3618, Name: "Maunganamu", AdjacentSuburbs: [3618, 1299, 3221, 3542, 1108] },
          { SuburbId: 1102, Name: "Motuoapa", AdjacentSuburbs: [1102] },
          { SuburbId: 1107, Name: "Nukuhau", AdjacentSuburbs: [1107, 1301, 3512] },
          { SuburbId: 3737, Name: "Ohakuri", AdjacentSuburbs: [3737] },
          { SuburbId: 3266, Name: "Omori", AdjacentSuburbs: [3266, 3244] },
          { SuburbId: 1351, Name: "Oruanui", AdjacentSuburbs: [1351] },
          { SuburbId: 3405, Name: "Oruatua", AdjacentSuburbs: [1109, 3405, 3666] },
          { SuburbId: 3244, Name: "Pukawa", AdjacentSuburbs: [3266, 3244] },
          {
            SuburbId: 1299,
            Name: "Rainbow Point",
            AdjacentSuburbs: [1108, 3221, 1299, 3542, 3618],
          },
          { SuburbId: 3512, Name: "Rangatira Park", AdjacentSuburbs: [1107, 1301, 3512] },
          {
            SuburbId: 3221,
            Name: "Richmond Heights",
            AdjacentSuburbs: [3221, 1299, 1300, 1330, 3542, 3618],
          },
          { SuburbId: 1023, Name: "Tauhara", AdjacentSuburbs: [1023, 3196, 1301, 1330] },
          { SuburbId: 3666, Name: "Tauranga Taupo", AdjacentSuburbs: [3666, 3405, 1109] },
          { SuburbId: 1109, Name: "Te Rangiita", AdjacentSuburbs: [1109, 3404, 3405, 3666] },
          { SuburbId: 791, Name: "Tongariro", AdjacentSuburbs: [791] },
          { SuburbId: 1301, Name: "Town Centre", AdjacentSuburbs: [1023, 1107, 1301, 1330, 3512] },
          { SuburbId: 1101, Name: "Turangi", AdjacentSuburbs: [1101] },
          {
            SuburbId: 3542,
            Name: "Two Mile Bay",
            AdjacentSuburbs: [3542, 1330, 1299, 3221, 1300, 3618],
          },
          { SuburbId: 1300, Name: "Waipahihi", AdjacentSuburbs: [3196, 3221, 1300, 1330, 3542] },
          { SuburbId: 994, Name: "Wairakei", AdjacentSuburbs: [994] },
          { SuburbId: 1264, Name: "Waitahanui", AdjacentSuburbs: [1264] },
          { SuburbId: 3404, Name: "Waitetoko", AdjacentSuburbs: [1109, 3404] },
          { SuburbId: 3527, Name: "Whareroa", AdjacentSuburbs: [1103, 3527] },
          { SuburbId: 1108, Name: "Wharewaka", AdjacentSuburbs: [1108, 1299, 3618] },
        ],
      },
      {
        DistrictId: 11,
        Name: "Thames-Coromandel",
        Suburbs: [
          { SuburbId: 618, Name: "Cooks Beach", AdjacentSuburbs: [618, 3323] },
          { SuburbId: 624, Name: "Coroglen", AdjacentSuburbs: [624] },
          { SuburbId: 728, Name: "Coromandel", AdjacentSuburbs: [645, 728] },
          { SuburbId: 3712, Name: "Ferry Landing", AdjacentSuburbs: [3712, 3323, 2683] },
          { SuburbId: 3323, Name: "Flaxmill Bay", AdjacentSuburbs: [618, 2683, 3323, 3712] },
          { SuburbId: 625, Name: "Hahei", AdjacentSuburbs: [625] },
          { SuburbId: 621, Name: "Hikuai", AdjacentSuburbs: [621, 607, 619, 2723] },
          { SuburbId: 3283, Name: "Hikutaia ", AdjacentSuburbs: [3283] },
          { SuburbId: 623, Name: "Hot Water Beach", AdjacentSuburbs: [623] },
          { SuburbId: 1022, Name: "Kaimarama", AdjacentSuburbs: [1022] },
          { SuburbId: 3825, Name: "Kennedy Bay", AdjacentSuburbs: [3825] },
          { SuburbId: 610, Name: "Kopu", AdjacentSuburbs: [610, 3285] },
          { SuburbId: 3213, Name: "Kuaotunu Beach", AdjacentSuburbs: [3213] },
          { SuburbId: 629, Name: "Matarangi", AdjacentSuburbs: [629] },
          { SuburbId: 3285, Name: "Matatoki ", AdjacentSuburbs: [610, 3285] },
          { SuburbId: 761, Name: "Onemana", AdjacentSuburbs: [761] },
          { SuburbId: 627, Name: "Opito", AdjacentSuburbs: [627] },
          { SuburbId: 3826, Name: "Opoutere", AdjacentSuburbs: [3826] },
          { SuburbId: 1978, Name: "Otama", AdjacentSuburbs: [1978] },
          { SuburbId: 2723, Name: "Pauanui", AdjacentSuburbs: [619, 2723] },
          { SuburbId: 794, Name: "Port Charles", AdjacentSuburbs: [794] },
          { SuburbId: 3282, Name: "Puriri ", AdjacentSuburbs: [3282] },
          { SuburbId: 3784, Name: "Ruamahunga", AdjacentSuburbs: [3784] },
          { SuburbId: 3699, Name: "Slipper Island", AdjacentSuburbs: [3699] },
          { SuburbId: 619, Name: "Tairua", AdjacentSuburbs: [619, 2723] },
          { SuburbId: 606, Name: "Tapu", AdjacentSuburbs: [606] },
          { SuburbId: 3782, Name: "Tararu", AdjacentSuburbs: [3782] },
          { SuburbId: 3673, Name: "Te Mata", AdjacentSuburbs: [3673] },
          { SuburbId: 607, Name: "Te Puru", AdjacentSuburbs: [607, 3781] },
          { SuburbId: 608, Name: "Thames", AdjacentSuburbs: [608] },
          { SuburbId: 3781, Name: "Thornton Bay", AdjacentSuburbs: [3781, 607] },
          { SuburbId: 3530, Name: "Tuateawa", AdjacentSuburbs: [3530] },
          { SuburbId: 3593, Name: "Waiomu", AdjacentSuburbs: [3593] },
          { SuburbId: 3783, Name: "Whakatete Bay", AdjacentSuburbs: [3783] },
          { SuburbId: 762, Name: "Whangamata", AdjacentSuburbs: [762] },
          { SuburbId: 630, Name: "Whangapoua", AdjacentSuburbs: [630] },
          { SuburbId: 622, Name: "Whenuakite", AdjacentSuburbs: [622] },
          { SuburbId: 2683, Name: "Whitianga", AdjacentSuburbs: [2683, 3323, 3712] },
        ],
      },
      {
        DistrictId: 13,
        Name: "Waikato",
        Suburbs: [
          { SuburbId: 800, Name: "Arapuni", AdjacentSuburbs: [800, 801] },
          { SuburbId: 902, Name: "Eureka", AdjacentSuburbs: [902] },
          { SuburbId: 3761, Name: "Glen Afton", AdjacentSuburbs: [3761, 3812] },
          { SuburbId: 3750, Name: "Glen Massey", AdjacentSuburbs: [3750, 1060] },
          { SuburbId: 669, Name: "Glen Murray", AdjacentSuburbs: [669] },
          { SuburbId: 1089, Name: "Gordonton", AdjacentSuburbs: [1089] },
          { SuburbId: 3500, Name: "Hampton Downs", AdjacentSuburbs: [3500] },
          { SuburbId: 805, Name: "Horotiu", AdjacentSuburbs: [805] },
          { SuburbId: 592, Name: "Huntly", AdjacentSuburbs: [592] },
          { SuburbId: 1091, Name: "Kainui", AdjacentSuburbs: [1091] },
          { SuburbId: 896, Name: "Matangi", AdjacentSuburbs: [896] },
          { SuburbId: 662, Name: "Meremere", AdjacentSuburbs: [662] },
          { SuburbId: 1084, Name: "Miranda", AdjacentSuburbs: [1084] },
          { SuburbId: 897, Name: "Newstead", AdjacentSuburbs: [3270, 897] },
          { SuburbId: 1088, Name: "Ngaruawahia", AdjacentSuburbs: [1088] },
          { SuburbId: 715, Name: "Ohinewai", AdjacentSuburbs: [715] },
          { SuburbId: 675, Name: "Oparau", AdjacentSuburbs: [675] },
          { SuburbId: 718, Name: "Orini", AdjacentSuburbs: [718] },
          { SuburbId: 3812, Name: "Pukemiro", AdjacentSuburbs: [3812, 3761] },
          { SuburbId: 775, Name: "Puketaha", AdjacentSuburbs: [775] },
          { SuburbId: 850, Name: "Raglan", AdjacentSuburbs: [850, 3587] },
          { SuburbId: 714, Name: "Rangiriri", AdjacentSuburbs: [714] },
          { SuburbId: 3267, Name: "Rotongaro", AdjacentSuburbs: [3267] },
          { SuburbId: 3808, Name: "Ruawaro", AdjacentSuburbs: [3808] },
          { SuburbId: 890, Name: "Tamahere", AdjacentSuburbs: [890] },
          { SuburbId: 1092, Name: "Taupiri", AdjacentSuburbs: [1092] },
          { SuburbId: 1278, Name: "Tauwhare", AdjacentSuburbs: [1278] },
          { SuburbId: 704, Name: "Te Akau", AdjacentSuburbs: [704] },
          { SuburbId: 717, Name: "Te Hoe", AdjacentSuburbs: [717] },
          { SuburbId: 713, Name: "Te Kauwhata", AdjacentSuburbs: [713] },
          { SuburbId: 1060, Name: "Te Kowhai", AdjacentSuburbs: [1060, 3750] },
          { SuburbId: 3271, Name: "Te Uku", AdjacentSuburbs: [3271] },
          { SuburbId: 3548, Name: "Tirohanga", AdjacentSuburbs: [3548] },
          { SuburbId: 665, Name: "Waerenga", AdjacentSuburbs: [665] },
          { SuburbId: 3634, Name: "Waikaretu", AdjacentSuburbs: [3634] },
          { SuburbId: 3197, Name: "Waikato Surrounds", AdjacentSuburbs: [3197] },
          { SuburbId: 3511, Name: "Waiterimu", AdjacentSuburbs: [3511] },
          { SuburbId: 3587, Name: "Waitetuna", AdjacentSuburbs: [3587, 850] },
          { SuburbId: 1183, Name: "Whakamaru", AdjacentSuburbs: [1183] },
          { SuburbId: 768, Name: "Whatawhata", AdjacentSuburbs: [768] },
          { SuburbId: 820, Name: "Whitikahu", AdjacentSuburbs: [820] },
        ],
      },
      {
        DistrictId: 17,
        Name: "Waipa",
        Suburbs: [
          { SuburbId: 696, Name: "Cambridge", AdjacentSuburbs: [696] },
          { SuburbId: 864, Name: "Horahora", AdjacentSuburbs: [864, 3755] },
          { SuburbId: 638, Name: "Kaipaki", AdjacentSuburbs: [638, 756] },
          { SuburbId: 796, Name: "Karamu", AdjacentSuburbs: [796] },
          { SuburbId: 885, Name: "Karapiro", AdjacentSuburbs: [885] },
          { SuburbId: 1625, Name: "Kihikihi", AdjacentSuburbs: [1625] },
          { SuburbId: 3403, Name: "Koromatua", AdjacentSuburbs: [2764, 3403] },
          { SuburbId: 884, Name: "Leamington", AdjacentSuburbs: [884] },
          { SuburbId: 3755, Name: "Maungatautari", AdjacentSuburbs: [3755, 864] },
          { SuburbId: 797, Name: "Ngahinapouri", AdjacentSuburbs: [797] },
          { SuburbId: 756, Name: "Ohaupo", AdjacentSuburbs: [756] },
          { SuburbId: 753, Name: "Pirongia", AdjacentSuburbs: [753] },
          { SuburbId: 3726, Name: "Pukeatua", AdjacentSuburbs: [3726, 604] },
          { SuburbId: 695, Name: "Pukekura", AdjacentSuburbs: [695] },
          { SuburbId: 882, Name: "Rotoorangi", AdjacentSuburbs: [882] },
          { SuburbId: 3307, Name: "Rukuhia ", AdjacentSuburbs: [3236, 3307] },
          { SuburbId: 1624, Name: "Te Awamutu", AdjacentSuburbs: [1624] },
          { SuburbId: 3815, Name: "Te Miro", AdjacentSuburbs: [3815] },
          { SuburbId: 798, Name: "Te Pahu", AdjacentSuburbs: [798] },
          { SuburbId: 754, Name: "Te Rore", AdjacentSuburbs: [754] },
          { SuburbId: 3704, Name: "Wharepapa South", AdjacentSuburbs: [3704] },
        ],
      },
      {
        DistrictId: 20,
        Name: "Waitomo",
        Suburbs: [
          { SuburbId: 815, Name: "Awakino", AdjacentSuburbs: [815] },
          { SuburbId: 3567, Name: "Benneydale", AdjacentSuburbs: [3567] },
          { SuburbId: 3552, Name: "Marokopa", AdjacentSuburbs: [3552] },
          { SuburbId: 749, Name: "Piopio", AdjacentSuburbs: [749] },
          { SuburbId: 786, Name: "Te Kuiti", AdjacentSuburbs: [786] },
        ],
      },
    ],
  },
  {
    LocalityId: 2,
    Name: "Bay Of Plenty",
    Districts: [
      {
        DistrictId: 26,
        Name: "Kawerau",
        Suburbs: [{ SuburbId: 442, Name: "Kawerau", AdjacentSuburbs: [442] }],
      },
      {
        DistrictId: 27,
        Name: "Opotiki",
        Suburbs: [
          {
            SuburbId: 572,
            Name: "Hospital Hill",
            AdjacentSuburbs: [572, 3176, 1075, 1076, 1077, 1078, 1154, 3797, 3821],
          },
          { SuburbId: 653, Name: "Kutarere", AdjacentSuburbs: [653] },
          { SuburbId: 3614, Name: "Ohiwa", AdjacentSuburbs: [3614] },
          { SuburbId: 570, Name: "Opotiki", AdjacentSuburbs: [570] },
          { SuburbId: 457, Name: "Te Kaha", AdjacentSuburbs: [457] },
          { SuburbId: 3668, Name: "Tirohanga", AdjacentSuburbs: [3668] },
          { SuburbId: 3589, Name: "Waihau Bay", AdjacentSuburbs: [3589, 342] },
          { SuburbId: 568, Name: "Waiotahi", AdjacentSuburbs: [568] },
        ],
      },
      {
        DistrictId: 24,
        Name: "Rotorua",
        Suburbs: [
          { SuburbId: 3132, Name: "Atiamuri", AdjacentSuburbs: [3132] },
          { SuburbId: 553, Name: "Awahou", AdjacentSuburbs: [553] },
          { SuburbId: 3393, Name: "Brunswick", AdjacentSuburbs: [3393] },
          {
            SuburbId: 3168,
            Name: "Fairy Springs",
            AdjacentSuburbs: [3087, 3109, 3133, 3168, 3170],
          },
          {
            SuburbId: 3074,
            Name: "Fenton Park",
            AdjacentSuburbs: [556, 3074, 3077, 3083, 3095, 3118, 3570],
          },
          {
            SuburbId: 3075,
            Name: "Fordlands",
            AdjacentSuburbs: [
              556, 3075, 3083, 3089, 3102, 3116, 3133, 3169, 3170, 3172, 3206, 3208, 3513, 3570,
              3786,
            ],
          },
          {
            SuburbId: 3077,
            Name: "Glenholme",
            AdjacentSuburbs: [556, 3074, 3077, 3083, 3116, 3134, 3171, 3172, 3570],
          },
          { SuburbId: 554, Name: "Hamurana", AdjacentSuburbs: [554] },
          { SuburbId: 3080, Name: "Hannahs Bay", AdjacentSuburbs: [3080, 3207, 478] },
          {
            SuburbId: 3083,
            Name: "Hillcrest",
            AdjacentSuburbs: [
              556, 3074, 3075, 3077, 3083, 3102, 3116, 3134, 3169, 3171, 3172, 3206, 3208, 3570,
              3786,
            ],
          },
          { SuburbId: 3207, Name: "Holdens Bay", AdjacentSuburbs: [3080, 3207, 478] },
          { SuburbId: 2909, Name: "Horohoro", AdjacentSuburbs: [2909] },
          { SuburbId: 550, Name: "Kaharoa", AdjacentSuburbs: [550] },
          { SuburbId: 3087, Name: "Kawaha Point", AdjacentSuburbs: [3087, 3089, 3109, 3168] },
          {
            SuburbId: 3089,
            Name: "Koutu",
            AdjacentSuburbs: [556, 3075, 3087, 3089, 3109, 3133, 3169, 3513],
          },
          { SuburbId: 563, Name: "Lake Okareka", AdjacentSuburbs: [563, 3605] },
          { SuburbId: 3605, Name: "Lake Okataina", AdjacentSuburbs: [3605, 563] },
          { SuburbId: 3489, Name: "Lake Rotoehu", AdjacentSuburbs: [3489] },
          { SuburbId: 410, Name: "Lake Rotoma", AdjacentSuburbs: [410] },
          { SuburbId: 415, Name: "Lake Tarawera", AdjacentSuburbs: [415] },
          { SuburbId: 558, Name: "Lynmore", AdjacentSuburbs: [558, 3095, 478] },
          { SuburbId: 2911, Name: "Mamaku", AdjacentSuburbs: [2911] },
          {
            SuburbId: 3169,
            Name: "Mangakakahi",
            AdjacentSuburbs: [
              556, 3075, 3083, 3089, 3102, 3109, 3116, 3133, 3169, 3170, 3172, 3208, 3513,
            ],
          },
          {
            SuburbId: 3206,
            Name: "Matipo Heights",
            AdjacentSuburbs: [3075, 3083, 3102, 3116, 3134, 3171, 3172, 3206, 3208, 3786],
          },
          { SuburbId: 555, Name: "Mourea", AdjacentSuburbs: [555, 2705] },
          { SuburbId: 1227, Name: "Ngakuru", AdjacentSuburbs: [1227] },
          { SuburbId: 3095, Name: "Ngapuna", AdjacentSuburbs: [558, 3074, 3095, 478] },
          { SuburbId: 552, Name: "Ngongotaha", AdjacentSuburbs: [552] },
          { SuburbId: 549, Name: "Ngongotaha Valley", AdjacentSuburbs: [549] },
          {
            SuburbId: 3513,
            Name: "Ohinemutu",
            AdjacentSuburbs: [556, 3075, 3089, 3116, 3169, 3513, 3570],
          },
          { SuburbId: 475, Name: "Okere Falls", AdjacentSuburbs: [475] },
          { SuburbId: 478, Name: "Owhata", AdjacentSuburbs: [558, 3080, 3095, 3207, 478] },
          {
            SuburbId: 3170,
            Name: "Pleasant Heights",
            AdjacentSuburbs: [3075, 3102, 3109, 3133, 3168, 3169, 3170, 3172, 3208],
          },
          {
            SuburbId: 3786,
            Name: "Pomare",
            AdjacentSuburbs: [3786, 3075, 3083, 3206, 3102, 3134, 3208, 3171, 3116, 3172],
          },
          {
            SuburbId: 3102,
            Name: "Pukehangi",
            AdjacentSuburbs: [3075, 3083, 3102, 3116, 3133, 3169, 3170, 3172, 3206, 3208, 3786],
          },
          { SuburbId: 991, Name: "Reporoa", AdjacentSuburbs: [991] },
          { SuburbId: 2977, Name: "Rerewhakaaitu", AdjacentSuburbs: [2977] },
          { SuburbId: 561, Name: "Rotoiti", AdjacentSuburbs: [561] },
          {
            SuburbId: 556,
            Name: "Rotorua",
            AdjacentSuburbs: [
              556, 3074, 3075, 3077, 3083, 3089, 3116, 3169, 3172, 3208, 3513, 3570,
            ],
          },
          {
            SuburbId: 3109,
            Name: "Selwyn Heights",
            AdjacentSuburbs: [3087, 3089, 3109, 3133, 3168, 3169, 3170],
          },
          {
            SuburbId: 3134,
            Name: "Springfield",
            AdjacentSuburbs: [3077, 3083, 3116, 3134, 3171, 3172, 3206, 3208, 3786],
          },
          {
            SuburbId: 3208,
            Name: "Sunnybrook",
            AdjacentSuburbs: [
              556, 3075, 3083, 3102, 3116, 3133, 3134, 3169, 3170, 3171, 3172, 3206, 3208, 3786,
            ],
          },
          { SuburbId: 559, Name: "Te Ngae", AdjacentSuburbs: [559, 560] },
          {
            SuburbId: 3171,
            Name: "Tihiotonga",
            AdjacentSuburbs: [3077, 3083, 3116, 3118, 3134, 3171, 3172, 3206, 3208, 3786],
          },
          { SuburbId: 560, Name: "Tikitere", AdjacentSuburbs: [559, 560] },
          {
            SuburbId: 3116,
            Name: "Utuhina",
            AdjacentSuburbs: [
              556, 3075, 3077, 3083, 3102, 3116, 3133, 3134, 3169, 3171, 3172, 3206, 3208, 3513,
              3570, 3786,
            ],
          },
          {
            SuburbId: 3570,
            Name: "Victoria",
            AdjacentSuburbs: [3570, 3074, 3513, 3075, 556, 3083, 3116, 3077],
          },
          { SuburbId: 3165, Name: "Waikite", AdjacentSuburbs: [3165] },
          { SuburbId: 1228, Name: "Waikite Valley", AdjacentSuburbs: [1228] },
          { SuburbId: 3602, Name: "Waiotapu", AdjacentSuburbs: [3602] },
          {
            SuburbId: 3172,
            Name: "Westbrook",
            AdjacentSuburbs: [
              556, 3075, 3077, 3083, 3102, 3116, 3133, 3134, 3169, 3170, 3171, 3172, 3206, 3208,
              3786,
            ],
          },
          {
            SuburbId: 3133,
            Name: "Western Heights",
            AdjacentSuburbs: [3075, 3089, 3102, 3109, 3116, 3133, 3168, 3169, 3170, 3172, 3208],
          },
          { SuburbId: 3118, Name: "Whakarewarewa", AdjacentSuburbs: [3074, 3118, 3171] },
        ],
      },
      {
        DistrictId: 23,
        Name: "Tauranga",
        Suburbs: [
          { SuburbId: 503, Name: "Arataki", AdjacentSuburbs: [503, 504, 481] },
          { SuburbId: 3173, Name: "Avenues", AdjacentSuburbs: [498, 501, 3173] },
          { SuburbId: 504, Name: "Bayfair", AdjacentSuburbs: [431, 503, 504, 481] },
          {
            SuburbId: 511,
            Name: "Bellevue",
            AdjacentSuburbs: [429, 498, 510, 511, 512, 513, 3415],
          },
          { SuburbId: 480, Name: "Bethlehem", AdjacentSuburbs: [429, 497, 480, 581] },
          { SuburbId: 429, Name: "Brookfield", AdjacentSuburbs: [429, 497, 498, 511, 512, 480] },
          { SuburbId: 513, Name: "Bureta", AdjacentSuburbs: [501, 510, 511, 512, 513, 3415] },
          {
            SuburbId: 497,
            Name: "Cambridge Heights",
            AdjacentSuburbs: [429, 497, 498, 499, 500, 480],
          },
          { SuburbId: 3415, Name: "Cherrywood", AdjacentSuburbs: [510, 511, 512, 513, 3415] },
          { SuburbId: 501, Name: "City Centre", AdjacentSuburbs: [498, 501, 513, 3173, 482] },
          { SuburbId: 499, Name: "Gate Pa", AdjacentSuburbs: [430, 497, 499, 500, 502, 3390] },
          { SuburbId: 502, Name: "Greerton", AdjacentSuburbs: [427, 430, 499, 502, 523, 3390] },
          { SuburbId: 472, Name: "Hairini", AdjacentSuburbs: [427, 430, 472, 3799] },
          { SuburbId: 498, Name: "Judea", AdjacentSuburbs: [429, 497, 498, 501, 511, 3173] },
          { SuburbId: 426, Name: "Kairua", AdjacentSuburbs: [426] },
          { SuburbId: 482, Name: "Matapihi", AdjacentSuburbs: [428, 482, 501] },
          { SuburbId: 510, Name: "Matua", AdjacentSuburbs: [510, 511, 512, 513, 3415] },
          { SuburbId: 428, Name: "Maungatapu", AdjacentSuburbs: [428, 474, 482] },
          { SuburbId: 3390, Name: "Merivale", AdjacentSuburbs: [427, 430, 499, 500, 502, 3390] },
          { SuburbId: 3709, Name: "Minden", AdjacentSuburbs: [3709, 470] },
          { SuburbId: 431, Name: "Mount Maunganui", AdjacentSuburbs: [431, 432, 504, 481] },
          { SuburbId: 492, Name: "Ohauiti", AdjacentSuburbs: [474, 492] },
          { SuburbId: 432, Name: "Omanu", AdjacentSuburbs: [431, 432] },
          { SuburbId: 3559, Name: "Oropi", AdjacentSuburbs: [3559, 3135] },
          { SuburbId: 512, Name: "Otumoetai", AdjacentSuburbs: [429, 510, 511, 512, 513, 3415] },
          { SuburbId: 433, Name: "Papamoa", AdjacentSuburbs: [433, 435] },
          { SuburbId: 435, Name: "Papamoa Beach", AdjacentSuburbs: [433, 435] },
          { SuburbId: 500, Name: "Parkvale", AdjacentSuburbs: [430, 497, 499, 500, 3390] },
          { SuburbId: 430, Name: "Poike", AdjacentSuburbs: [427, 430, 499, 500, 502, 3390, 472] },
          { SuburbId: 524, Name: "Pyes Pa", AdjacentSuburbs: [523, 524] },
          {
            SuburbId: 427,
            Name: "Tauranga South",
            AdjacentSuburbs: [427, 430, 502, 3390, 472, 3799],
          },
          { SuburbId: 581, Name: "Tauriko", AdjacentSuburbs: [581, 480, 3710] },
          { SuburbId: 481, Name: "Te Maunga", AdjacentSuburbs: [431, 503, 504, 481] },
          { SuburbId: 3710, Name: "Wairoa", AdjacentSuburbs: [3710, 581] },
          { SuburbId: 474, Name: "Welcome Bay", AdjacentSuburbs: [428, 474, 492] },
        ],
      },
      {
        DistrictId: 22,
        Name: "Western Bay Of Plenty",
        Suburbs: [
          { SuburbId: 467, Name: "Aongatete", AdjacentSuburbs: [467] },
          { SuburbId: 3385, Name: "Apata", AdjacentSuburbs: [3385, 3470] },
          { SuburbId: 465, Name: "Athenree", AdjacentSuburbs: [465, 508] },
          { SuburbId: 466, Name: "Katikati", AdjacentSuburbs: [466] },
          { SuburbId: 488, Name: "Lower Kaimai", AdjacentSuburbs: [488] },
          { SuburbId: 598, Name: "Maketu", AdjacentSuburbs: [598] },
          { SuburbId: 2912, Name: "Matakana Island", AdjacentSuburbs: [2912] },
          { SuburbId: 3799, Name: "Ohauiti", AdjacentSuburbs: [3799, 472, 427] },
          { SuburbId: 3384, Name: "Omanawa", AdjacentSuburbs: [3384] },
          { SuburbId: 468, Name: "Omokoroa", AdjacentSuburbs: [468] },
          { SuburbId: 3135, Name: "Oropi", AdjacentSuburbs: [3135, 3559] },
          { SuburbId: 495, Name: "Paengaroa", AdjacentSuburbs: [495] },
          { SuburbId: 3470, Name: "Pahoia", AdjacentSuburbs: [3385, 3470] },
          { SuburbId: 601, Name: "Pongakawa", AdjacentSuburbs: [601] },
          { SuburbId: 525, Name: "Pukehina", AdjacentSuburbs: [525] },
          { SuburbId: 523, Name: "Pyes Pa", AdjacentSuburbs: [502, 523, 524] },
          { SuburbId: 600, Name: "Rangiuru", AdjacentSuburbs: [600] },
          { SuburbId: 3620, Name: "Tanners Point", AdjacentSuburbs: [3620] },
          { SuburbId: 494, Name: "Te Puke", AdjacentSuburbs: [494] },
          { SuburbId: 470, Name: "Te Puna", AdjacentSuburbs: [470, 3709] },
          { SuburbId: 508, Name: "Waihi Beach", AdjacentSuburbs: [508, 465] },
          { SuburbId: 469, Name: "Whakamarama", AdjacentSuburbs: [469] },
        ],
      },
      {
        DistrictId: 25,
        Name: "Whakatane",
        Suburbs: [
          { SuburbId: 542, Name: "Awakaponga", AdjacentSuburbs: [542] },
          { SuburbId: 516, Name: "Awakeri", AdjacentSuburbs: [516] },
          { SuburbId: 464, Name: "Coastlands", AdjacentSuburbs: [464] },
          { SuburbId: 544, Name: "Edgecumbe", AdjacentSuburbs: [544] },
          { SuburbId: 417, Name: "Galatea", AdjacentSuburbs: [417] },
          { SuburbId: 3734, Name: "Manawahe", AdjacentSuburbs: [3734] },
          { SuburbId: 541, Name: "Matata", AdjacentSuburbs: [541] },
          { SuburbId: 418, Name: "Murupara", AdjacentSuburbs: [418] },
          { SuburbId: 520, Name: "Ohope", AdjacentSuburbs: [520, 522] },
          { SuburbId: 440, Name: "Onepu", AdjacentSuburbs: [440] },
          { SuburbId: 543, Name: "Otakiri", AdjacentSuburbs: [543] },
          { SuburbId: 518, Name: "Pahou", AdjacentSuburbs: [518] },
          { SuburbId: 515, Name: "Paroa", AdjacentSuburbs: [515] },
          { SuburbId: 528, Name: "Pikowai", AdjacentSuburbs: [528] },
          { SuburbId: 517, Name: "Poroporo", AdjacentSuburbs: [517] },
          { SuburbId: 522, Name: "Port Ohope", AdjacentSuburbs: [520, 522] },
          { SuburbId: 450, Name: "Ruatoki North", AdjacentSuburbs: [450] },
          { SuburbId: 521, Name: "Taneatua", AdjacentSuburbs: [521] },
          { SuburbId: 441, Name: "Te Teko", AdjacentSuburbs: [441] },
          { SuburbId: 514, Name: "Thornton", AdjacentSuburbs: [514] },
          { SuburbId: 449, Name: "Waimana", AdjacentSuburbs: [449] },
          { SuburbId: 3675, Name: "Wainui", AdjacentSuburbs: [3675] },
          { SuburbId: 519, Name: "Whakatane", AdjacentSuburbs: [519] },
        ],
      },
    ],
  },
  {
    LocalityId: 4,
    Name: "Gisborne",
    Districts: [
      {
        DistrictId: 28,
        Name: "Gisborne",
        Suburbs: [
          { SuburbId: 1189, Name: "Awapuni", AdjacentSuburbs: [1756, 1189, 1516, 1608] },
          {
            SuburbId: 1056,
            Name: "City Centre",
            AdjacentSuburbs: [1026, 1027, 1028, 1191, 3320, 1056, 1057],
          },
          { SuburbId: 1058, Name: "Elgin", AdjacentSuburbs: [1190, 1057, 1058] },
          { SuburbId: 1068, Name: "Hangaroa", AdjacentSuburbs: [1068] },
          { SuburbId: 1285, Name: "Hexton", AdjacentSuburbs: [1285, 1287] },
          { SuburbId: 1162, Name: "Hicks Bay", AdjacentSuburbs: [1162] },
          {
            SuburbId: 3320,
            Name: "Inner Kaiti",
            AdjacentSuburbs: [1026, 1027, 1028, 1168, 3320, 1056],
          },
          { SuburbId: 1028, Name: "Kaiti", AdjacentSuburbs: [1026, 1027, 1028, 1168, 3320, 1056] },
          { SuburbId: 3698, Name: "Lytton West", AdjacentSuburbs: [3698, 1191, 1190, 1057] },
          { SuburbId: 3317, Name: "Makaraka ", AdjacentSuburbs: [3317] },
          { SuburbId: 1287, Name: "Makauri", AdjacentSuburbs: [1285, 1287] },
          { SuburbId: 3632, Name: "Makorori", AdjacentSuburbs: [3632] },
          {
            SuburbId: 1191,
            Name: "Mangapapa",
            AdjacentSuburbs: [1026, 1190, 1191, 1056, 1057, 3698],
          },
          { SuburbId: 1155, Name: "Manutuke", AdjacentSuburbs: [1155] },
          { SuburbId: 586, Name: "Matawai", AdjacentSuburbs: [586] },
          { SuburbId: 1288, Name: "Matawhero", AdjacentSuburbs: [1288, 3715, 3731] },
          { SuburbId: 3495, Name: "Matokitoki", AdjacentSuburbs: [3495] },
          { SuburbId: 374, Name: "Motu", AdjacentSuburbs: [374] },
          { SuburbId: 1156, Name: "Muriwai", AdjacentSuburbs: [1156] },
          { SuburbId: 1293, Name: "Ngatapa", AdjacentSuburbs: [1293] },
          { SuburbId: 1309, Name: "Okitu", AdjacentSuburbs: [1309, 1336] },
          { SuburbId: 1196, Name: "Ormond", AdjacentSuburbs: [1196] },
          { SuburbId: 1158, Name: "Otoko", AdjacentSuburbs: [1158] },
          {
            SuburbId: 1027,
            Name: "Outer Kaiti",
            AdjacentSuburbs: [1026, 1027, 1028, 1168, 3320, 1056],
          },
          { SuburbId: 1286, Name: "Patutahi", AdjacentSuburbs: [1286] },
          { SuburbId: 3693, Name: "Pehiri", AdjacentSuburbs: [3693] },
          { SuburbId: 1166, Name: "Pouawa", AdjacentSuburbs: [1166] },
          { SuburbId: 3695, Name: "Raukumara", AdjacentSuburbs: [3695] },
          { SuburbId: 1065, Name: "Rere", AdjacentSuburbs: [1065] },
          { SuburbId: 1190, Name: "Riverdale", AdjacentSuburbs: [1190, 1191, 1057, 1058, 3698] },
          { SuburbId: 1169, Name: "Ruatoria", AdjacentSuburbs: [1169] },
          { SuburbId: 3725, Name: "Tahunga", AdjacentSuburbs: [3725] },
          { SuburbId: 1168, Name: "Tamarau", AdjacentSuburbs: [1027, 1028, 1168, 3320, 1336] },
          { SuburbId: 3804, Name: "Te Araroa", AdjacentSuburbs: [3804] },
          {
            SuburbId: 1057,
            Name: "Te Hapara",
            AdjacentSuburbs: [1190, 1191, 1056, 1057, 1058, 3698],
          },
          { SuburbId: 1290, Name: "Te Karaka", AdjacentSuburbs: [1290] },
          { SuburbId: 1097, Name: "Tiniroto", AdjacentSuburbs: [1097] },
          { SuburbId: 2663, Name: "Tokomaru Bay", AdjacentSuburbs: [2663] },
          { SuburbId: 2643, Name: "Tolaga Bay", AdjacentSuburbs: [2643] },
          { SuburbId: 3715, Name: "Waerengaahika", AdjacentSuburbs: [3715, 1288, 3731] },
          { SuburbId: 1069, Name: "Waerengaokuri", AdjacentSuburbs: [1069] },
          { SuburbId: 342, Name: "Waihau Bay", AdjacentSuburbs: [342, 3589] },
          { SuburbId: 734, Name: "Waimata", AdjacentSuburbs: [734] },
          { SuburbId: 1098, Name: "Waingake", AdjacentSuburbs: [1098] },
          { SuburbId: 1336, Name: "Wainui", AdjacentSuburbs: [1168, 1309, 1336] },
          { SuburbId: 1271, Name: "Waipaoa", AdjacentSuburbs: [1271] },
          { SuburbId: 3731, Name: "Waituhi", AdjacentSuburbs: [3731, 1288, 3715] },
          { SuburbId: 1167, Name: "Whangara", AdjacentSuburbs: [1167] },
          { SuburbId: 1036, Name: "Wharekopae", AdjacentSuburbs: [1036] },
          { SuburbId: 3600, Name: "Wharerata", AdjacentSuburbs: [3600] },
          { SuburbId: 1292, Name: "Whatatutu", AdjacentSuburbs: [1292] },
          {
            SuburbId: 1026,
            Name: "Whataupoko",
            AdjacentSuburbs: [1026, 1027, 1028, 1191, 3320, 1056],
          },
        ],
      },
    ],
  },
  {
    LocalityId: 5,
    Name: "Hawke's Bay",
    Districts: [
      {
        DistrictId: 32,
        Name: "Central Hawke's Bay",
        Suburbs: [
          { SuburbId: 3820, Name: "Aramoana", AdjacentSuburbs: [3820] },
          { SuburbId: 3505, Name: "Central Hawke's Bay Country", AdjacentSuburbs: [3505] },
          { SuburbId: 3072, Name: "Elsthorpe", AdjacentSuburbs: [3072] },
          { SuburbId: 3612, Name: "Flemington", AdjacentSuburbs: [3612] },
          { SuburbId: 3649, Name: "Hatuma", AdjacentSuburbs: [3649] },
          { SuburbId: 3718, Name: "Maraetotara", AdjacentSuburbs: [3718] },
          { SuburbId: 3740, Name: "Omakere", AdjacentSuburbs: [3740] },
          { SuburbId: 1335, Name: "Ongaonga", AdjacentSuburbs: [1335] },
          { SuburbId: 3098, Name: "Otane", AdjacentSuburbs: [3098] },
          { SuburbId: 1277, Name: "Patangata", AdjacentSuburbs: [1263, 1277] },
          { SuburbId: 3175, Name: "Porangahau", AdjacentSuburbs: [3175] },
          { SuburbId: 3758, Name: "Pourerere", AdjacentSuburbs: [3758] },
          { SuburbId: 1310, Name: "Takapau", AdjacentSuburbs: [1310] },
          { SuburbId: 1275, Name: "Tikokino", AdjacentSuburbs: [1275] },
          { SuburbId: 1282, Name: "Waipawa", AdjacentSuburbs: [1282] },
          { SuburbId: 1274, Name: "Waipukurau", AdjacentSuburbs: [1274] },
          { SuburbId: 1313, Name: "Wanstead", AdjacentSuburbs: [1313] },
        ],
      },
      {
        DistrictId: 30,
        Name: "Hastings",
        Suburbs: [
          {
            SuburbId: 1208,
            Name: "Akina",
            AdjacentSuburbs: [1202, 1203, 1204, 3058, 3092, 1207, 1208],
          },
          { SuburbId: 1072, Name: "Bridge Pa", AdjacentSuburbs: [1072, 1232, 3596] },
          {
            SuburbId: 1229,
            Name: "Camberley",
            AdjacentSuburbs: [1202, 1203, 3374, 1206, 1229, 3807],
          },
          { SuburbId: 958, Name: "Clive", AdjacentSuburbs: [958] },
          { SuburbId: 3070, Name: "Crownthorpe", AdjacentSuburbs: [3070] },
          { SuburbId: 3073, Name: "Eskdale", AdjacentSuburbs: [3073] },
          { SuburbId: 3062, Name: "Fernhill", AdjacentSuburbs: [3062, 3096] },
          { SuburbId: 1232, Name: "Flaxmere", AdjacentSuburbs: [1072, 1232, 3807] },
          {
            SuburbId: 1206,
            Name: "Frimley",
            AdjacentSuburbs: [1201, 1202, 1203, 3374, 1206, 1229, 3807],
          },
          {
            SuburbId: 3058,
            Name: "Hastings Central",
            AdjacentSuburbs: [1201, 1202, 1203, 1204, 3058, 3374, 1207, 1208],
          },
          { SuburbId: 1137, Name: "Haumoana", AdjacentSuburbs: [1137] },
          { SuburbId: 959, Name: "Havelock North", AdjacentSuburbs: [959] },
          { SuburbId: 3346, Name: "Kahuranaki", AdjacentSuburbs: [3346] },
          { SuburbId: 1231, Name: "Karamu", AdjacentSuburbs: [1204, 1205, 3334, 1230, 1231] },
          { SuburbId: 1250, Name: "Kereru", AdjacentSuburbs: [1250] },
          { SuburbId: 3092, Name: "Longlands", AdjacentSuburbs: [1203, 3092, 1208] },
          {
            SuburbId: 1201,
            Name: "Mahora",
            AdjacentSuburbs: [1201, 1202, 1204, 1205, 3058, 3374, 1206],
          },
          { SuburbId: 3333, Name: "Mangatahi", AdjacentSuburbs: [3333] },
          { SuburbId: 3334, Name: "Mangateretere", AdjacentSuburbs: [3131, 3334, 1230, 1231] },
          { SuburbId: 1140, Name: "Maraekakaho", AdjacentSuburbs: [1140] },
          {
            SuburbId: 1204,
            Name: "Mayfair",
            AdjacentSuburbs: [1201, 1202, 1204, 1205, 3058, 1207, 1208, 1230, 1231],
          },
          { SuburbId: 1251, Name: "Moteo", AdjacentSuburbs: [1251] },
          { SuburbId: 3096, Name: "Omahu", AdjacentSuburbs: [3062, 3096] },
          { SuburbId: 3335, Name: "Otamauri", AdjacentSuburbs: [3335] },
          { SuburbId: 1139, Name: "Pakipaki", AdjacentSuburbs: [1139] },
          { SuburbId: 3337, Name: "Pakowhai", AdjacentSuburbs: [3337] },
          { SuburbId: 1207, Name: "Parkvale", AdjacentSuburbs: [1204, 3058, 1207, 1208] },
          { SuburbId: 1263, Name: "Patangata", AdjacentSuburbs: [1263, 1277] },
          { SuburbId: 1009, Name: "Patoka", AdjacentSuburbs: [1009] },
          { SuburbId: 1142, Name: "Poukawa", AdjacentSuburbs: [1142] },
          { SuburbId: 3220, Name: "Puketapu", AdjacentSuburbs: [3220] },
          { SuburbId: 3103, Name: "Puketitiri", AdjacentSuburbs: [3103] },
          { SuburbId: 3336, Name: "Putorino", AdjacentSuburbs: [3336] },
          { SuburbId: 3222, Name: "Raukawa", AdjacentSuburbs: [3222] },
          {
            SuburbId: 1203,
            Name: "Raureka",
            AdjacentSuburbs: [1202, 1203, 3058, 3092, 3374, 1206, 1208, 1229],
          },
          { SuburbId: 1008, Name: "Rissington", AdjacentSuburbs: [1008] },
          { SuburbId: 3596, Name: "Roys Hill", AdjacentSuburbs: [3596, 1072] },
          {
            SuburbId: 1202,
            Name: "Saint Leonards",
            AdjacentSuburbs: [1201, 1202, 1203, 1204, 3058, 3374, 1206, 1208, 1229],
          },
          { SuburbId: 1112, Name: "Sherenden", AdjacentSuburbs: [1112] },
          {
            SuburbId: 3374,
            Name: "Stortford Lodge",
            AdjacentSuburbs: [1201, 1202, 1203, 3058, 3374, 1206, 1229, 3807],
          },
          { SuburbId: 3338, Name: "Tangoio", AdjacentSuburbs: [3338] },
          { SuburbId: 3115, Name: "Te Awanga", AdjacentSuburbs: [3115] },
          { SuburbId: 3339, Name: "Te Hauke", AdjacentSuburbs: [3339] },
          { SuburbId: 983, Name: "Te Pohue", AdjacentSuburbs: [983] },
          { SuburbId: 1205, Name: "Tomoana", AdjacentSuburbs: [1201, 1204, 1205, 1230, 1231] },
          { SuburbId: 3792, Name: "Tuki Tuki", AdjacentSuburbs: [3792] },
          { SuburbId: 982, Name: "Tutira", AdjacentSuburbs: [982] },
          { SuburbId: 1073, Name: "Twyford", AdjacentSuburbs: [1073] },
          { SuburbId: 1141, Name: "Waimarama", AdjacentSuburbs: [1141] },
          { SuburbId: 3720, Name: "Waiohiki", AdjacentSuburbs: [3720] },
          { SuburbId: 1230, Name: "Waipatu", AdjacentSuburbs: [1204, 1205, 3334, 1230, 1231] },
          { SuburbId: 3366, Name: "Waiwhare", AdjacentSuburbs: [3366] },
          { SuburbId: 3131, Name: "Whakatu", AdjacentSuburbs: [3131, 3334] },
          { SuburbId: 3557, Name: "Whanawhana", AdjacentSuburbs: [3557] },
          { SuburbId: 984, Name: "Whirinaki", AdjacentSuburbs: [984] },
          { SuburbId: 3807, Name: "Woolwich", AdjacentSuburbs: [3807, 1229, 1232, 1206, 3374] },
        ],
      },
      {
        DistrictId: 31,
        Name: "Napier",
        Suburbs: [
          {
            SuburbId: 1076,
            Name: "Ahuriri",
            AdjacentSuburbs: [572, 1053, 1075, 1076, 1077, 3797, 3821],
          },
          { SuburbId: 1562, Name: "Awatoto", AdjacentSuburbs: [1562] },
          { SuburbId: 962, Name: "Bay View", AdjacentSuburbs: [962] },
          {
            SuburbId: 1075,
            Name: "Bluff Hill",
            AdjacentSuburbs: [572, 3176, 1075, 1076, 1077, 3797, 3821],
          },
          {
            SuburbId: 1154,
            Name: "City Centre",
            AdjacentSuburbs: [1561, 572, 3100, 3176, 3443, 1077, 1078, 1149, 1154, 3821],
          },
          {
            SuburbId: 1129,
            Name: "Greenmeadows",
            AdjacentSuburbs: [945, 3100, 1128, 1129, 1130, 3507],
          },
          {
            SuburbId: 1077,
            Name: "Hospital Hill",
            AdjacentSuburbs: [572, 3176, 1075, 1076, 1077, 1078, 1154, 3797, 3821],
          },
          { SuburbId: 3507, Name: "Jervoistown", AdjacentSuburbs: [1563, 945, 1129, 3507] },
          {
            SuburbId: 1561,
            Name: "Maraenui",
            AdjacentSuburbs: [1561, 3100, 3176, 3443, 1078, 1149, 1154],
          },
          {
            SuburbId: 1078,
            Name: "Marewa",
            AdjacentSuburbs: [1561, 572, 3176, 3443, 1077, 1078, 1149, 1154, 3797],
          },
          { SuburbId: 1563, Name: "Meeanee", AdjacentSuburbs: [1563, 3507] },
          {
            SuburbId: 1130,
            Name: "Mission View",
            AdjacentSuburbs: [945, 3101, 1128, 1129, 1130, 3796],
          },
          {
            SuburbId: 3176,
            Name: "Napier South",
            AdjacentSuburbs: [1561, 572, 3176, 3443, 1075, 1077, 1078, 1149, 1154, 3797],
          },
          {
            SuburbId: 1149,
            Name: "Onekawa",
            AdjacentSuburbs: [1561, 3100, 3176, 3443, 1078, 1128, 1149, 1154],
          },
          {
            SuburbId: 3821,
            Name: "Pandora",
            AdjacentSuburbs: [3821, 572, 1076, 1075, 1154, 1077, 1053],
          },
          {
            SuburbId: 3100,
            Name: "Pirimai",
            AdjacentSuburbs: [1561, 3100, 1128, 1129, 1149, 1154],
          },
          { SuburbId: 3101, Name: "Poraiti", AdjacentSuburbs: [3101, 1128, 1130] },
          { SuburbId: 3563, Name: "Pukehamoamoa", AdjacentSuburbs: [3563] },
          {
            SuburbId: 1128,
            Name: "Tamatea",
            AdjacentSuburbs: [3100, 3101, 1128, 1129, 1130, 1149],
          },
          { SuburbId: 945, Name: "Taradale", AdjacentSuburbs: [945, 1129, 1130, 3507, 3796] },
          { SuburbId: 3443, Name: "Te Awa", AdjacentSuburbs: [1561, 3176, 3443, 1078, 1149, 1154] },
          { SuburbId: 1053, Name: "Westshore", AdjacentSuburbs: [1053, 1076, 3821] },
        ],
      },
      {
        DistrictId: 29,
        Name: "Wairoa",
        Suburbs: [
          { SuburbId: 904, Name: "Frasertown", AdjacentSuburbs: [904, 3481] },
          { SuburbId: 3332, Name: "Kotemaori", AdjacentSuburbs: [3332] },
          { SuburbId: 3397, Name: "Mahanga", AdjacentSuburbs: [3397] },
          { SuburbId: 3367, Name: "Mahia", AdjacentSuburbs: [3367, 1339] },
          { SuburbId: 1339, Name: "Mahia Beach", AdjacentSuburbs: [1339, 3367] },
          { SuburbId: 3481, Name: "Marumaru", AdjacentSuburbs: [904, 3481] },
          { SuburbId: 3355, Name: "Mohaka", AdjacentSuburbs: [3355] },
          { SuburbId: 3252, Name: "Morere", AdjacentSuburbs: [3252] },
          { SuburbId: 968, Name: "Nuhaka", AdjacentSuburbs: [968] },
          { SuburbId: 1119, Name: "Ruakituri", AdjacentSuburbs: [1119] },
          { SuburbId: 3480, Name: "Te Reinga", AdjacentSuburbs: [3480] },
          { SuburbId: 1338, Name: "Tuai", AdjacentSuburbs: [1338] },
          { SuburbId: 1296, Name: "Wairoa", AdjacentSuburbs: [1296] },
          { SuburbId: 903, Name: "Whakaki", AdjacentSuburbs: [903] },
        ],
      },
    ],
  },
  {
    LocalityId: 12,
    Name: "Taranaki",
    Districts: [
      {
        DistrictId: 33,
        Name: "New Plymouth",
        Suburbs: [
          { SuburbId: 926, Name: "Bell Block", AdjacentSuburbs: [926] },
          {
            SuburbId: 3290,
            Name: "Blagdon",
            AdjacentSuburbs: [1041, 3084, 3212, 3261, 1042, 1144, 1224, 3290],
          },
          { SuburbId: 1395, Name: "Brixton", AdjacentSuburbs: [917, 1395] },
          {
            SuburbId: 1175,
            Name: "Brooklands",
            AdjacentSuburbs: [1173, 1174, 1175, 1248, 2026, 3117, 3194, 3382, 1043, 1144, 1225],
          },
          {
            SuburbId: 1225,
            Name: "City Centre",
            AdjacentSuburbs: [1174, 1175, 1248, 3117, 3382, 1144, 1225, 1245, 1246],
          },
          { SuburbId: 1121, Name: "Egmont Village", AdjacentSuburbs: [1121] },
          {
            SuburbId: 2026,
            Name: "Ferndale",
            AdjacentSuburbs: [1041, 1174, 1175, 2026, 3084, 3212, 1043, 1144, 3729],
          },
          {
            SuburbId: 1246,
            Name: "Fitzroy",
            AdjacentSuburbs: [1247, 1248, 3117, 1225, 1245, 1246, 3625],
          },
          {
            SuburbId: 1043,
            Name: "Frankleigh Park",
            AdjacentSuburbs: [1174, 1175, 2026, 3084, 3212, 1043, 1144, 3729],
          },
          { SuburbId: 1247, Name: "Glen Avon", AdjacentSuburbs: [1247, 1248, 3117, 1246, 3625] },
          {
            SuburbId: 1173,
            Name: "Highlands Park",
            AdjacentSuburbs: [1173, 1174, 1175, 1248, 3117, 3194],
          },
          { SuburbId: 918, Name: "Hillsborough", AdjacentSuburbs: [918] },
          {
            SuburbId: 3084,
            Name: "Hurdon",
            AdjacentSuburbs: [1041, 2026, 3084, 3212, 1042, 1043, 1144, 3290],
          },
          { SuburbId: 919, Name: "Hurworth", AdjacentSuburbs: [919, 3729] },
          { SuburbId: 1122, Name: "Inglewood", AdjacentSuburbs: [1122] },
          { SuburbId: 3802, Name: "Korito", AdjacentSuburbs: [3802] },
          { SuburbId: 3533, Name: "Koru", AdjacentSuburbs: [3533] },
          { SuburbId: 917, Name: "Lepperton", AdjacentSuburbs: [917, 1395] },
          {
            SuburbId: 3261,
            Name: "Lynmouth",
            AdjacentSuburbs: [3212, 3261, 3382, 1042, 1144, 1224, 3290],
          },
          { SuburbId: 3194, Name: "Mangorei", AdjacentSuburbs: [1173, 1175, 3194] },
          {
            SuburbId: 3212,
            Name: "Marfell",
            AdjacentSuburbs: [1041, 2026, 3084, 3212, 3261, 1042, 1043, 1144, 1224, 3290],
          },
          {
            SuburbId: 1248,
            Name: "Merrilands",
            AdjacentSuburbs: [1173, 1175, 1247, 1248, 3117, 3382, 1225, 1245, 1246, 3625],
          },
          { SuburbId: 1315, Name: "Mokau", AdjacentSuburbs: [1315] },
          {
            SuburbId: 1224,
            Name: "Moturoa",
            AdjacentSuburbs: [1041, 3212, 3261, 1042, 1224, 3290],
          },
          {
            SuburbId: 3382,
            Name: "New Plymouth",
            AdjacentSuburbs: [1174, 1175, 1248, 3117, 3261, 3382, 1144, 1225, 1245],
          },
          { SuburbId: 3631, Name: "Norfolk", AdjacentSuburbs: [3631] },
          { SuburbId: 1325, Name: "Oakura", AdjacentSuburbs: [1325] },
          { SuburbId: 1074, Name: "Okato", AdjacentSuburbs: [1074] },
          { SuburbId: 1537, Name: "Omata", AdjacentSuburbs: [1537] },
          { SuburbId: 1392, Name: "Onaero", AdjacentSuburbs: [1392] },
          { SuburbId: 3729, Name: "Ridgewood", AdjacentSuburbs: [3729, 2026, 1043, 919] },
          {
            SuburbId: 3242,
            Name: "Royal Heights",
            AdjacentSuburbs: [245, 246, 255, 3255, 3257, 3392, 3242, 223],
          },
          {
            SuburbId: 1042,
            Name: "Spotswood",
            AdjacentSuburbs: [1041, 3084, 3212, 3261, 1042, 1224, 3290],
          },
          {
            SuburbId: 1245,
            Name: "Strandon",
            AdjacentSuburbs: [1248, 3117, 3382, 1225, 1245, 1246],
          },
          { SuburbId: 1145, Name: "Tariki", AdjacentSuburbs: [1145] },
          { SuburbId: 3195, Name: "Tikorangi", AdjacentSuburbs: [3195] },
          { SuburbId: 3660, Name: "Tongaporutu", AdjacentSuburbs: [3660] },
          { SuburbId: 954, Name: "Urenui", AdjacentSuburbs: [954] },
          { SuburbId: 3723, Name: "Uruti", AdjacentSuburbs: [3723] },
          {
            SuburbId: 1174,
            Name: "Vogeltown",
            AdjacentSuburbs: [1173, 1174, 1175, 2026, 3117, 3382, 1043, 1144, 1225],
          },
          { SuburbId: 1394, Name: "Waitara", AdjacentSuburbs: [1394] },
          { SuburbId: 3625, Name: "Waiwhakaiho", AdjacentSuburbs: [3625, 1246, 1247, 1248] },
          {
            SuburbId: 3117,
            Name: "Welbourn",
            AdjacentSuburbs: [1173, 1174, 1175, 1247, 1248, 3117, 3382, 1225, 1245, 1246],
          },
          {
            SuburbId: 1144,
            Name: "Westown",
            AdjacentSuburbs: [
              1041, 1174, 1175, 2026, 3084, 3212, 3261, 3382, 1043, 1144, 1225, 3290,
            ],
          },
          {
            SuburbId: 1041,
            Name: "Whalers Gate",
            AdjacentSuburbs: [1041, 2026, 3084, 3212, 1042, 1144, 1224, 3290],
          },
        ],
      },
      {
        DistrictId: 35,
        Name: "South Taranaki",
        Suburbs: [
          { SuburbId: 1032, Name: "Alton", AdjacentSuburbs: [1032] },
          { SuburbId: 3785, Name: "Auroa", AdjacentSuburbs: [3785] },
          { SuburbId: 3582, Name: "Awatuna", AdjacentSuburbs: [3582, 3581, 772] },
          { SuburbId: 1177, Name: "Eltham", AdjacentSuburbs: [1177] },
          { SuburbId: 1125, Name: "Hawera", AdjacentSuburbs: [1125] },
          { SuburbId: 772, Name: "Kaponga", AdjacentSuburbs: [772, 3582] },
          { SuburbId: 2522, Name: "Manaia", AdjacentSuburbs: [2522] },
          { SuburbId: 3694, Name: "Mokoia", AdjacentSuburbs: [3694] },
          { SuburbId: 1238, Name: "Normanby", AdjacentSuburbs: [1238] },
          { SuburbId: 3773, Name: "Ohawe", AdjacentSuburbs: [3773, 1503] },
          { SuburbId: 2523, Name: "Okaiawa", AdjacentSuburbs: [2523] },
          { SuburbId: 1358, Name: "Opunake", AdjacentSuburbs: [1358] },
          { SuburbId: 1033, Name: "Patea", AdjacentSuburbs: [1033] },
          { SuburbId: 2561, Name: "Pungarehu", AdjacentSuburbs: [2561] },
          { SuburbId: 3581, Name: "Te Kiri", AdjacentSuburbs: [3581, 3582] },
          { SuburbId: 1503, Name: "Tokaora", AdjacentSuburbs: [1503, 3773] },
          { SuburbId: 1171, Name: "Waitotara", AdjacentSuburbs: [1171] },
          { SuburbId: 1253, Name: "Warea", AdjacentSuburbs: [1253] },
          { SuburbId: 1034, Name: "Waverley", AdjacentSuburbs: [1034] },
        ],
      },
      {
        DistrictId: 34,
        Name: "Stratford",
        Suburbs: [
          { SuburbId: 1146, Name: "Midhurst", AdjacentSuburbs: [1146] },
          { SuburbId: 3368, Name: "Stratford", AdjacentSuburbs: [1254, 1255, 3368] },
          { SuburbId: 1254, Name: "Stratford East", AdjacentSuburbs: [1254, 1255, 3368] },
          { SuburbId: 1255, Name: "Stratford West", AdjacentSuburbs: [1254, 1255, 3368] },
          { SuburbId: 1147, Name: "Toko", AdjacentSuburbs: [1147] },
        ],
      },
    ],
  },
  {
    LocalityId: 6,
    Name: "Manawatu / Whanganui",
    Districts: [
      {
        DistrictId: 42,
        Name: "Horowhenua",
        Suburbs: [
          { SuburbId: 1390, Name: "Foxton", AdjacentSuburbs: [1390] },
          { SuburbId: 1391, Name: "Foxton Beach", AdjacentSuburbs: [1391] },
          { SuburbId: 1598, Name: "Heatherlea", AdjacentSuburbs: [1598] },
          { SuburbId: 948, Name: "Hokio Beach", AdjacentSuburbs: [948] },
          { SuburbId: 1361, Name: "Ihakara", AdjacentSuburbs: [1361, 1050] },
          { SuburbId: 3798, Name: "Koputaroa", AdjacentSuburbs: [3798] },
          { SuburbId: 2639, Name: "Kuku", AdjacentSuburbs: [2639] },
          { SuburbId: 1051, Name: "Levin", AdjacentSuburbs: [1051] },
          { SuburbId: 3061, Name: "Manakau", AdjacentSuburbs: [3061] },
          { SuburbId: 1050, Name: "Muhunoa East", AdjacentSuburbs: [1361, 1050] },
          { SuburbId: 1049, Name: "Ohau", AdjacentSuburbs: [1049] },
          { SuburbId: 3343, Name: "Opiki", AdjacentSuburbs: [3343] },
          { SuburbId: 1362, Name: "Shannon", AdjacentSuburbs: [1362] },
          { SuburbId: 1364, Name: "Tokomaru", AdjacentSuburbs: [1364] },
          { SuburbId: 3440, Name: "Waikawa Beach", AdjacentSuburbs: [3440] },
          { SuburbId: 1597, Name: "Waitarere", AdjacentSuburbs: [1597, 3569] },
          { SuburbId: 3569, Name: "Waitarere Beach", AdjacentSuburbs: [3569, 1597] },
        ],
      },
      {
        DistrictId: 39,
        Name: "Manawatu",
        Suburbs: [
          { SuburbId: 3791, Name: "Aorangi", AdjacentSuburbs: [3791] },
          { SuburbId: 3321, Name: "Apiti", AdjacentSuburbs: [3321] },
          { SuburbId: 3288, Name: "Awahuri", AdjacentSuburbs: [3288] },
          { SuburbId: 107, Name: "Cheltenham", AdjacentSuburbs: [107] },
          { SuburbId: 3287, Name: "Colyton", AdjacentSuburbs: [3287] },
          { SuburbId: 1411, Name: "Feilding", AdjacentSuburbs: [1411] },
          { SuburbId: 1409, Name: "Halcombe", AdjacentSuburbs: [1409] },
          { SuburbId: 1638, Name: "Highbury", AdjacentSuburbs: [1638] },
          { SuburbId: 1816, Name: "Himatangi", AdjacentSuburbs: [1816, 3577] },
          { SuburbId: 1692, Name: "Hiwinui", AdjacentSuburbs: [1692] },
          { SuburbId: 1660, Name: "Kelvin Grove", AdjacentSuburbs: [1660, 1661] },
          { SuburbId: 1694, Name: "Kimbolton", AdjacentSuburbs: [1694] },
          { SuburbId: 3289, Name: "Mt Biggs", AdjacentSuburbs: [3289] },
          { SuburbId: 1646, Name: "Newbury", AdjacentSuburbs: [1646] },
          { SuburbId: 3406, Name: "Ohakea", AdjacentSuburbs: [3406] },
          { SuburbId: 3757, Name: "Parewanui", AdjacentSuburbs: [3757] },
          { SuburbId: 1695, Name: "Pohangina", AdjacentSuburbs: [1695] },
          { SuburbId: 3577, Name: "Rangiotu", AdjacentSuburbs: [3577, 1816] },
          { SuburbId: 3471, Name: "Rangiwahia", AdjacentSuburbs: [3471] },
          { SuburbId: 1648, Name: "Rongotea", AdjacentSuburbs: [1648] },
          { SuburbId: 1408, Name: "Sanson", AdjacentSuburbs: [1408] },
          { SuburbId: 1407, Name: "Tangimoana", AdjacentSuburbs: [1407] },
          { SuburbId: 3457, Name: "Waituna West", AdjacentSuburbs: [3457] },
          { SuburbId: 3615, Name: "Whakarongo", AdjacentSuburbs: [3615, 1661, 3568] },
        ],
      },
      {
        DistrictId: 40,
        Name: "Palmerston North",
        Suburbs: [
          { SuburbId: 1617, Name: "Aokautere", AdjacentSuburbs: [1517, 1617, 3174] },
          { SuburbId: 1684, Name: "Ashhurst", AdjacentSuburbs: [1684] },
          { SuburbId: 1516, Name: "Awapuni", AdjacentSuburbs: [1756, 1189, 1516, 1608] },
          { SuburbId: 1410, Name: "Bunnythorpe", AdjacentSuburbs: [1410] },
          {
            SuburbId: 1468,
            Name: "City Centre",
            AdjacentSuburbs: [1605, 1606, 1607, 1608, 1637, 1910, 1468],
          },
          { SuburbId: 1518, Name: "Cloverlea", AdjacentSuburbs: [1756, 1518, 1637] },
          { SuburbId: 1517, Name: "Fitzherbert", AdjacentSuburbs: [1517, 1617, 3174] },
          {
            SuburbId: 1756,
            Name: "Highbury",
            AdjacentSuburbs: [1756, 1189, 1516, 1518, 1608, 1637],
          },
          { SuburbId: 1607, Name: "Hokowhitu", AdjacentSuburbs: [1605, 1606, 1607, 1910, 1468] },
          { SuburbId: 3456, Name: "Kairanga", AdjacentSuburbs: [3456] },
          { SuburbId: 1661, Name: "Kelvin Grove", AdjacentSuburbs: [1660, 1661, 3568, 3615] },
          { SuburbId: 1612, Name: "Linton", AdjacentSuburbs: [1612] },
          { SuburbId: 1644, Name: "Longburn", AdjacentSuburbs: [1644, 1691] },
          { SuburbId: 1467, Name: "Milson", AdjacentSuburbs: [1605, 1606, 1910, 1467] },
          {
            SuburbId: 1910,
            Name: "Papaioea",
            AdjacentSuburbs: [1605, 1606, 1607, 1910, 1467, 1468],
          },
          { SuburbId: 1605, Name: "Roslyn", AdjacentSuburbs: [1605, 1606, 1607, 1910, 1467, 1468] },
          { SuburbId: 1637, Name: "Takaro", AdjacentSuburbs: [1756, 1518, 1608, 1637, 1468] },
          {
            SuburbId: 1606,
            Name: "Terrace End",
            AdjacentSuburbs: [1605, 1606, 1607, 1910, 1467, 1468],
          },
          { SuburbId: 3813, Name: "Tiakitahuna", AdjacentSuburbs: [3813] },
          { SuburbId: 1691, Name: "Turitea", AdjacentSuburbs: [1644, 1691] },
          { SuburbId: 3044, Name: "Westbrook", AdjacentSuburbs: [3044] },
          {
            SuburbId: 1608,
            Name: "West End",
            AdjacentSuburbs: [1756, 1189, 1516, 1608, 1637, 1468],
          },
          { SuburbId: 3568, Name: "Whakarongo", AdjacentSuburbs: [3568, 1661, 3615] },
        ],
      },
      {
        DistrictId: 38,
        Name: "Rangitikei",
        Suburbs: [
          { SuburbId: 1857, Name: "Bulls", AdjacentSuburbs: [1857] },
          { SuburbId: 1928, Name: "Hunterville", AdjacentSuburbs: [1928] },
          { SuburbId: 1925, Name: "Mangaweka", AdjacentSuburbs: [1925] },
          { SuburbId: 2153, Name: "Marton", AdjacentSuburbs: [2153] },
          { SuburbId: 3558, Name: "Ohingaiti", AdjacentSuburbs: [3558] },
          { SuburbId: 3764, Name: "Parewanui", AdjacentSuburbs: [3764] },
          { SuburbId: 1702, Name: "Taihape", AdjacentSuburbs: [1702] },
          { SuburbId: 1047, Name: "Turakina", AdjacentSuburbs: [1047] },
          { SuburbId: 3810, Name: "Utiku", AdjacentSuburbs: [3810] },
        ],
      },
      {
        DistrictId: 36,
        Name: "Ruapehu",
        Suburbs: [
          { SuburbId: 3816, Name: "Kaitieke", AdjacentSuburbs: [3816] },
          { SuburbId: 3733, Name: "Kirikau", AdjacentSuburbs: [3733] },
          { SuburbId: 1403, Name: "National Park", AdjacentSuburbs: [1403] },
          { SuburbId: 1430, Name: "Ohakune", AdjacentSuburbs: [1430] },
          { SuburbId: 1346, Name: "Ohura", AdjacentSuburbs: [1346] },
          { SuburbId: 1402, Name: "Owhango", AdjacentSuburbs: [1402] },
          { SuburbId: 1512, Name: "Raetihi", AdjacentSuburbs: [1512] },
          { SuburbId: 3370, Name: "Raurimu", AdjacentSuburbs: [3370] },
          { SuburbId: 1385, Name: "Taumarunui", AdjacentSuburbs: [1385] },
          { SuburbId: 1431, Name: "Waiouru", AdjacentSuburbs: [1431] },
        ],
      },
      {
        DistrictId: 41,
        Name: "Tararua",
        Suburbs: [
          { SuburbId: 3389, Name: "Akitio", AdjacentSuburbs: [3389] },
          { SuburbId: 3770, Name: "Alfredton", AdjacentSuburbs: [3770] },
          { SuburbId: 1824, Name: "Dannevirke", AdjacentSuburbs: [3251, 1824] },
          { SuburbId: 1322, Name: "Eketahuna", AdjacentSuburbs: [1322] },
          { SuburbId: 3621, Name: "Herbertville", AdjacentSuburbs: [3621] },
          { SuburbId: 3771, Name: "Ihuraua", AdjacentSuburbs: [3771] },
          { SuburbId: 3670, Name: "Makotuku", AdjacentSuburbs: [3670] },
          { SuburbId: 3606, Name: "Norsewood", AdjacentSuburbs: [3606] },
          { SuburbId: 3671, Name: "Ormondville", AdjacentSuburbs: [3671] },
          { SuburbId: 1823, Name: "Pahiatua", AdjacentSuburbs: [1823] },
          { SuburbId: 1794, Name: "Pongaroa", AdjacentSuburbs: [1794] },
          { SuburbId: 1822, Name: "Woodville", AdjacentSuburbs: [1822] },
        ],
      },
      {
        DistrictId: 37,
        Name: "Whanganui",
        Suburbs: [
          { SuburbId: 3705, Name: "Aberfeldy", AdjacentSuburbs: [3705] },
          { SuburbId: 1613, Name: "Aramoho", AdjacentSuburbs: [1613] },
          { SuburbId: 1665, Name: "Bastia Hill", AdjacentSuburbs: [1664, 1665, 1666, 1667, 1672] },
          { SuburbId: 1396, Name: "Brunswick", AdjacentSuburbs: [1396] },
          { SuburbId: 1671, Name: "Castlecliff", AdjacentSuburbs: [1670, 1671] },
          {
            SuburbId: 1672,
            Name: "City Centre",
            AdjacentSuburbs: [1741, 1665, 1666, 1667, 1672, 3177, 2077, 3707],
          },
          {
            SuburbId: 3177,
            Name: "College Estate",
            AdjacentSuburbs: [1741, 1669, 1672, 3177, 2077, 3707],
          },
          { SuburbId: 1666, Name: "Durie Hill", AdjacentSuburbs: [1665, 1666, 1667, 1672] },
          { SuburbId: 3250, Name: "Fordell", AdjacentSuburbs: [3250] },
          { SuburbId: 1669, Name: "Gonville", AdjacentSuburbs: [1669, 1670, 3177, 2077, 3707] },
          { SuburbId: 3085, Name: "Kai Iwi", AdjacentSuburbs: [3085] },
          { SuburbId: 964, Name: "Kaitoke", AdjacentSuburbs: [964] },
          { SuburbId: 3762, Name: "Kaiwhaiki", AdjacentSuburbs: [3762] },
          { SuburbId: 3805, Name: "Koitiata", AdjacentSuburbs: [3805] },
          { SuburbId: 3251, Name: "Makirikiri ", AdjacentSuburbs: [3251, 1824] },
          { SuburbId: 3269, Name: "Mangamahu ", AdjacentSuburbs: [3269] },
          { SuburbId: 1668, Name: "Marybank", AdjacentSuburbs: [1667, 1668] },
          { SuburbId: 1777, Name: "Maxwell", AdjacentSuburbs: [1777] },
          { SuburbId: 3214, Name: "Mosston", AdjacentSuburbs: [1670, 3214, 2077, 3707] },
          { SuburbId: 1778, Name: "Okoia", AdjacentSuburbs: [1778] },
          { SuburbId: 1742, Name: "Otamatea", AdjacentSuburbs: [1741, 1742] },
          { SuburbId: 3732, Name: "Papaiti", AdjacentSuburbs: [3732, 1673] },
          { SuburbId: 3697, Name: "Paparangi", AdjacentSuburbs: [3697] },
          { SuburbId: 1699, Name: "Parikino", AdjacentSuburbs: [1699] },
          { SuburbId: 1667, Name: "Putiki", AdjacentSuburbs: [1665, 1666, 1667, 1668, 1672] },
          { SuburbId: 1398, Name: "Rapanui", AdjacentSuburbs: [1398] },
          {
            SuburbId: 1741,
            Name: "Saint Johns Hill",
            AdjacentSuburbs: [1741, 1742, 1672, 3177, 2077, 3707],
          },
          {
            SuburbId: 2077,
            Name: "Springvale",
            AdjacentSuburbs: [1741, 1669, 1670, 1672, 3177, 3214, 2077, 3707],
          },
          {
            SuburbId: 1670,
            Name: "Tawhero",
            AdjacentSuburbs: [1669, 1670, 1671, 3214, 2077, 3707],
          },
          { SuburbId: 1673, Name: "Upokongaro", AdjacentSuburbs: [1673, 3732] },
          { SuburbId: 1743, Name: "Westmere", AdjacentSuburbs: [1743] },
          { SuburbId: 3408, Name: "Whangaehu", AdjacentSuburbs: [3408] },
          {
            SuburbId: 3707,
            Name: "Whanganui Central",
            AdjacentSuburbs: [3707, 1672, 3177, 1669, 3214, 1741, 2077, 1670],
          },
          { SuburbId: 1664, Name: "Whanganui East", AdjacentSuburbs: [1664, 1665] },
        ],
      },
    ],
  },
  {
    LocalityId: 15,
    Name: "Wellington",
    Districts: [
      {
        DistrictId: 49,
        Name: "Carterton",
        Suburbs: [{ SuburbId: 1656, Name: "Carterton", AdjacentSuburbs: [1656] }],
      },
      {
        DistrictId: 43,
        Name: "Kapiti Coast",
        Suburbs: [
          { SuburbId: 999, Name: "Otaihanga", AdjacentSuburbs: [1446, 1762, 999] },
          { SuburbId: 2640, Name: "Otaki", AdjacentSuburbs: [2640] },
          { SuburbId: 1211, Name: "Otaki Beach", AdjacentSuburbs: [1211] },
          { SuburbId: 1650, Name: "Paekakariki", AdjacentSuburbs: [1650] },
          { SuburbId: 1762, Name: "Paraparaumu", AdjacentSuburbs: [1446, 1762, 999] },
          { SuburbId: 1446, Name: "Paraparaumu Beach", AdjacentSuburbs: [1446, 1762, 999] },
          { SuburbId: 1652, Name: "Raumati Beach", AdjacentSuburbs: [1651, 1652] },
          { SuburbId: 1651, Name: "Raumati South", AdjacentSuburbs: [1651, 1652] },
          { SuburbId: 1802, Name: "Te Horo", AdjacentSuburbs: [1802] },
          { SuburbId: 1445, Name: "Waikanae", AdjacentSuburbs: [1445, 998] },
          { SuburbId: 998, Name: "Waikanae Beach", AdjacentSuburbs: [1445, 998] },
          { SuburbId: 3099, Name: "Peka Peka", AdjacentSuburbs: [3099] },
        ],
      },
      {
        DistrictId: 46,
        Name: "Lower Hutt",
        Suburbs: [
          {
            SuburbId: 2124,
            Name: "Alicetown",
            AdjacentSuburbs: [
              239, 1435, 1448, 1449, 1460, 1654, 1766, 1871, 2119, 2120, 2121, 2124, 3350, 1765,
            ],
          },
          {
            SuburbId: 1618,
            Name: "Avalon",
            AdjacentSuburbs: [1618, 1619, 1620, 1767, 2118, 2122, 2123, 3351, 3364, 1415, 1765],
          },
          {
            SuburbId: 2122,
            Name: "Belmont",
            AdjacentSuburbs: [
              239, 1618, 1620, 2118, 2120, 2121, 2122, 2123, 3350, 3351, 3364, 1765,
            ],
          },
          {
            SuburbId: 2123,
            Name: "Boulcott",
            AdjacentSuburbs: [
              239, 924, 1449, 1618, 1654, 1767, 2118, 2120, 2121, 2122, 2123, 3350, 3351, 3364,
              1765,
            ],
          },
          {
            SuburbId: 1428,
            Name: "Days Bay",
            AdjacentSuburbs: [1745, 1428, 1763, 1847, 2116, 2117, 1765],
          },
          {
            SuburbId: 2116,
            Name: "Eastbourne",
            AdjacentSuburbs: [1428, 1847, 2116, 2117, 2079, 3364, 1448, 1765],
          },
          {
            SuburbId: 2118,
            Name: "Epuni",
            AdjacentSuburbs: [
              924, 1618, 1619, 1654, 1767, 2118, 2122, 2123, 3350, 3351, 3364, 1765,
            ],
          },
          {
            SuburbId: 1767,
            Name: "Fairfield",
            AdjacentSuburbs: [924, 1618, 1619, 1654, 1767, 2118, 2123, 3351, 3364, 1765],
          },
          {
            SuburbId: 1872,
            Name: "Gracefield",
            AdjacentSuburbs: [1449, 1766, 1871, 1872, 1873, 3657, 1765],
          },
          {
            SuburbId: 2120,
            Name: "Harbour View",
            AdjacentSuburbs: [
              239, 1449, 1460, 1654, 2119, 2120, 2121, 2122, 2123, 2124, 3350, 1765,
            ],
          },
          { SuburbId: 1389, Name: "Haywards", AdjacentSuburbs: [1388, 1389, 1765] },
          { SuburbId: 1416, Name: "Holborn", AdjacentSuburbs: [1841, 1388, 1415, 1416, 1765] },
          {
            SuburbId: 1620,
            Name: "Kelson",
            AdjacentSuburbs: [1618, 1620, 2122, 3351, 3364, 1415, 1765],
          },
          {
            SuburbId: 1435,
            Name: "Korokoro",
            AdjacentSuburbs: [1435, 1448, 1460, 2124, 877, 1765],
          },
          {
            SuburbId: 1654,
            Name: "Lower Hutt",
            AdjacentSuburbs: [
              239, 924, 1449, 1654, 1766, 1767, 1871, 2118, 2119, 2120, 2121, 2123, 2124, 3350,
              1765,
            ],
          },
          {
            SuburbId: 3331,
            Name: "Lowry Bay",
            AdjacentSuburbs: [1745, 1763, 1847, 1873, 3331, 3657, 1765],
          },
          {
            SuburbId: 1745,
            Name: "Mahina Bay",
            AdjacentSuburbs: [1745, 1428, 1763, 1847, 3331, 3657, 1765],
          },
          { SuburbId: 1388, Name: "Manor Park", AdjacentSuburbs: [1841, 1388, 1389, 1416, 1765] },
          {
            SuburbId: 1460,
            Name: "Maungaraki",
            AdjacentSuburbs: [239, 1435, 1448, 1460, 2119, 2120, 2121, 2124, 3350, 1765],
          },
          {
            SuburbId: 3350,
            Name: "Melling",
            AdjacentSuburbs: [
              239, 924, 1449, 1460, 1654, 2118, 2119, 2120, 2121, 2122, 2123, 2124, 3350, 1765,
            ],
          },
          {
            SuburbId: 1871,
            Name: "Moera",
            AdjacentSuburbs: [924, 1448, 1449, 1654, 1766, 1871, 1872, 1873, 2124, 1765],
          },
          { SuburbId: 2079, Name: "Muritai", AdjacentSuburbs: [2116, 2117, 2079, 1765] },
          {
            SuburbId: 1619,
            Name: "Naenae",
            AdjacentSuburbs: [1618, 1619, 1767, 2118, 3351, 3364, 1765],
          },
          {
            SuburbId: 2119,
            Name: "Normandale",
            AdjacentSuburbs: [239, 1460, 1654, 2119, 2120, 2121, 2124, 3350, 1765],
          },
          {
            SuburbId: 3351,
            Name: "Park Avenue",
            AdjacentSuburbs: [924, 1618, 1619, 1620, 1767, 2118, 2122, 2123, 3351, 3364, 1765],
          },
          {
            SuburbId: 1448,
            Name: "Petone",
            AdjacentSuburbs: [1435, 1448, 1449, 1460, 1871, 2124, 2116, 1765],
          },
          {
            SuburbId: 3657,
            Name: "Point Howard",
            AdjacentSuburbs: [3657, 1872, 3331, 1745, 1873, 1763, 1765],
          },
          {
            SuburbId: 2117,
            Name: "Rona Bay",
            AdjacentSuburbs: [1428, 1847, 2116, 2117, 2079, 1765],
          },
          {
            SuburbId: 1873,
            Name: "Seaview",
            AdjacentSuburbs: [1871, 1872, 1873, 3331, 3657, 1765],
          },
          {
            SuburbId: 1841,
            Name: "Stokes Valley",
            AdjacentSuburbs: [1841, 1388, 1415, 1416, 1765],
          },
          {
            SuburbId: 3364,
            Name: "Sunshine Bay",
            AdjacentSuburbs: [
              924, 1618, 1619, 1620, 1767, 2118, 2122, 2123, 3351, 3364, 2116, 1765,
            ],
          },
          { SuburbId: 1415, Name: "Taita", AdjacentSuburbs: [1618, 1620, 1841, 1415, 1416, 1765] },
          {
            SuburbId: 2121,
            Name: "Tirohanga",
            AdjacentSuburbs: [
              239, 1449, 1460, 1654, 2119, 2120, 2121, 2122, 2123, 2124, 3350, 1765,
            ],
          },
          {
            SuburbId: 1765,
            Name: "Wainuiomata",
            AdjacentSuburbs: [
              1765, 1654, 2124, 1618, 2122, 2123, 1428, 2116, 2118, 1767, 1872, 2120, 1389, 1416,
              1620,
            ],
          },
          {
            SuburbId: 1766,
            Name: "Waiwhetu",
            AdjacentSuburbs: [924, 1449, 1654, 1766, 1871, 1872, 2124, 1765],
          },
          {
            SuburbId: 924,
            Name: "Waterloo",
            AdjacentSuburbs: [
              924, 1449, 1654, 1766, 1767, 1871, 2118, 2123, 3350, 3351, 3364, 1765,
            ],
          },
          {
            SuburbId: 1449,
            Name: "Woburn",
            AdjacentSuburbs: [
              239, 924, 1448, 1449, 1654, 1766, 1871, 1872, 2120, 2121, 2123, 2124, 3350, 1765,
            ],
          },
          {
            SuburbId: 1763,
            Name: "York Bay",
            AdjacentSuburbs: [1745, 1428, 1763, 1847, 3331, 3657, 1765],
          },
        ],
      },
      {
        DistrictId: 48,
        Name: "Masterton",
        Suburbs: [
          { SuburbId: 3349, Name: "Castlepoint", AdjacentSuburbs: [3349, 3576] },
          { SuburbId: 3434, Name: "Flat Point", AdjacentSuburbs: [3434] },
          { SuburbId: 2579, Name: "Masterton", AdjacentSuburbs: [2579] },
          { SuburbId: 3576, Name: "Mataikona", AdjacentSuburbs: [3576, 3349] },
          { SuburbId: 3560, Name: "Mauriceville", AdjacentSuburbs: [3560] },
          { SuburbId: 3106, Name: "Riversdale Beach", AdjacentSuburbs: [3106] },
          { SuburbId: 3759, Name: "Tauweru", AdjacentSuburbs: [3759] },
          { SuburbId: 3788, Name: "Whareama", AdjacentSuburbs: [3788, 3787] },
        ],
      },
      {
        DistrictId: 44,
        Name: "Porirua",
        Suburbs: [
          { SuburbId: 3198, Name: "Aotea", AdjacentSuburbs: [1520, 3198, 2172] },
          { SuburbId: 1520, Name: "Ascot Park", AdjacentSuburbs: [1520, 3198, 1132] },
          {
            SuburbId: 985,
            Name: "Camborne",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1948, 2783, 2785, 973, 985, 1334, 3610,
            ],
          },
          { SuburbId: 2172, Name: "Cannons Creek", AdjacentSuburbs: [1447, 3198, 2172] },
          {
            SuburbId: 1929,
            Name: "Elsdon",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1948, 2783, 2785, 973, 985, 1334, 3610,
            ],
          },
          { SuburbId: 3613, Name: "Judgeford", AdjacentSuburbs: [3613] },
          { SuburbId: 1491, Name: "Karehana Bay", AdjacentSuburbs: [1491] },
          {
            SuburbId: 3610,
            Name: "Kenepuru",
            AdjacentSuburbs: [
              3610, 985, 1929, 973, 2783, 1948, 2785, 1110, 1573, 1433, 1842, 1334, 1434, 1930,
            ],
          },
          {
            SuburbId: 973,
            Name: "Linden",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1948, 2783, 2785, 973, 985, 1334, 3610,
            ],
          },
          {
            SuburbId: 2783,
            Name: "Mana",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1948, 2783, 2785, 973, 985, 1334, 3610,
            ],
          },
          {
            SuburbId: 1948,
            Name: "Papakowhai",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1948, 2783, 2785, 973, 985, 1334, 3610,
            ],
          },
          {
            SuburbId: 2785,
            Name: "Paremata",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1948, 2783, 2785, 973, 985, 1334, 3610,
            ],
          },
          {
            SuburbId: 1110,
            Name: "Pauatahanui",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1948, 2783, 2785, 973, 985, 1334, 3610,
            ],
          },
          {
            SuburbId: 1573,
            Name: "Plimmerton",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1948, 2783, 2785, 973, 985, 1334, 3610,
            ],
          },
          {
            SuburbId: 1433,
            Name: "Porirua",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1930, 1948, 2783, 2785, 973, 985, 1334,
              3610,
            ],
          },
          {
            SuburbId: 1842,
            Name: "Porirua East",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1948, 2783, 2785, 973, 985, 1334, 3610,
            ],
          },
          { SuburbId: 1853, Name: "Pukerua Bay", AdjacentSuburbs: [1853] },
          {
            SuburbId: 1447,
            Name: "Ranui Heights",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1930, 1948, 2783, 2785, 2172, 973, 985,
              1334,
            ],
          },
          { SuburbId: 1755, Name: "Takapu Valley", AdjacentSuburbs: [1755, 2175] },
          {
            SuburbId: 1334,
            Name: "Takapuwahia",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1948, 2783, 2785, 973, 985, 1334, 3610,
            ],
          },
          { SuburbId: 1515, Name: "Titahi Bay", AdjacentSuburbs: [1515] },
          {
            SuburbId: 1434,
            Name: "Waitangirua",
            AdjacentSuburbs: [
              1573, 1110, 1433, 1434, 1447, 1842, 1929, 1948, 2783, 2785, 973, 985, 1334, 3610,
            ],
          },
          { SuburbId: 1132, Name: "Whitby", AdjacentSuburbs: [1520, 1132] },
        ],
      },
      {
        DistrictId: 50,
        Name: "South Wairarapa",
        Suburbs: [
          { SuburbId: 3347, Name: "Cape Palliser", AdjacentSuburbs: [3347] },
          { SuburbId: 1803, Name: "Featherston", AdjacentSuburbs: [1803, 1655] },
          { SuburbId: 3375, Name: "Gladstone", AdjacentSuburbs: [3375] },
          { SuburbId: 1655, Name: "Greytown", AdjacentSuburbs: [1655, 1803, 875] },
          { SuburbId: 3534, Name: "Lake Ferry", AdjacentSuburbs: [3534] },
          { SuburbId: 875, Name: "Martinborough", AdjacentSuburbs: [875, 1655] },
          { SuburbId: 3348, Name: "Ngawi", AdjacentSuburbs: [3348] },
          { SuburbId: 3626, Name: "Ocean Beach", AdjacentSuburbs: [3626] },
          { SuburbId: 3551, Name: "Tora", AdjacentSuburbs: [3551] },
        ],
      },
      {
        DistrictId: 45,
        Name: "Upper Hutt",
        Suburbs: [
          {
            SuburbId: 1758,
            Name: "Akatarawa",
            AdjacentSuburbs: [1565, 1758, 923, 3067, 3071, 3352, 2078],
          },
          {
            SuburbId: 1212,
            Name: "Birchville",
            AdjacentSuburbs: [1703, 1768, 3411, 2078, 1212, 1400],
          },
          { SuburbId: 1799, Name: "Blue Mountains", AdjacentSuburbs: [920, 1799] },
          {
            SuburbId: 1400,
            Name: "Brown Owl",
            AdjacentSuburbs: [1768, 3067, 3094, 3411, 2078, 1212, 1400],
          },
          {
            SuburbId: 3067,
            Name: "Clouston Park",
            AdjacentSuburbs: [1565, 1758, 1768, 3067, 3094, 3352, 2078, 1400],
          },
          {
            SuburbId: 3352,
            Name: "Ebdentown",
            AdjacentSuburbs: [1565, 1758, 923, 3067, 3071, 3352, 2078],
          },
          { SuburbId: 925, Name: "Heretaunga", AdjacentSuburbs: [1547, 920, 921, 922, 925] },
          { SuburbId: 3166, Name: "Kaitoke", AdjacentSuburbs: [3166] },
          {
            SuburbId: 1565,
            Name: "Kingsley Heights",
            AdjacentSuburbs: [1565, 1758, 923, 3067, 3094, 3352, 2078],
          },
          {
            SuburbId: 1768,
            Name: "Maoribank",
            AdjacentSuburbs: [1768, 3067, 3094, 3411, 2078, 1212, 1399, 1400],
          },
          { SuburbId: 1399, Name: "Maymorn", AdjacentSuburbs: [1768, 3094, 3411, 1399] },
          { SuburbId: 3503, Name: "Moonshine Valley", AdjacentSuburbs: [3503] },
          {
            SuburbId: 3411,
            Name: "Mount Marua",
            AdjacentSuburbs: [1703, 1768, 1770, 3094, 3411, 1212, 1399, 1400],
          },
          { SuburbId: 3353, Name: "Pakuratahi", AdjacentSuburbs: [3353] },
          { SuburbId: 920, Name: "Pinehaven", AdjacentSuburbs: [920, 921, 925, 1799] },
          { SuburbId: 1333, Name: "Riverstone Terraces", AdjacentSuburbs: [922, 923, 3071, 1333] },
          { SuburbId: 921, Name: "Silverstream", AdjacentSuburbs: [1547, 920, 921, 925] },
          { SuburbId: 1703, Name: "Te Marua", AdjacentSuburbs: [1703, 1770, 3411, 1212] },
          { SuburbId: 1770, Name: "The Plateau", AdjacentSuburbs: [1703, 1770, 3411] },
          { SuburbId: 1769, Name: "Timberlea", AdjacentSuburbs: [1769] },
          {
            SuburbId: 2078,
            Name: "Totara Park",
            AdjacentSuburbs: [1565, 1758, 1768, 3067, 3094, 3352, 2078, 1212, 1400],
          },
          {
            SuburbId: 3094,
            Name: "Mangaroa",
            AdjacentSuburbs: [1565, 1768, 3067, 3094, 3411, 2078, 1399, 1400],
          },
          { SuburbId: 922, Name: "Trentham", AdjacentSuburbs: [1547, 922, 923, 925, 3071, 1333] },
          { SuburbId: 1547, Name: "Upper Hutt", AdjacentSuburbs: [1547, 921, 922, 925] },
          {
            SuburbId: 923,
            Name: "Wallaceville",
            AdjacentSuburbs: [1565, 1758, 922, 923, 3071, 3352, 1333],
          },
          { SuburbId: 1840, Name: "Whitemans Valley", AdjacentSuburbs: [1840] },
          {
            SuburbId: 3071,
            Name: "Elderslea",
            AdjacentSuburbs: [1758, 922, 923, 3071, 3352, 1333],
          },
        ],
      },
      {
        DistrictId: 47,
        Name: "Wellington",
        Suburbs: [
          {
            SuburbId: 3047,
            Name: "Aro Valley",
            AdjacentSuburbs: [
              1017, 1018, 1094, 1343, 1344, 1345, 1450, 1622, 1783, 3047, 3054, 1486, 1326,
            ],
          },
          {
            SuburbId: 1342,
            Name: "Berhampore",
            AdjacentSuburbs: [
              1567, 1568, 1018, 1124, 1342, 1343, 1344, 1450, 1622, 1784, 1785, 3164, 1294, 1226,
              1795,
            ],
          },
          {
            SuburbId: 3048,
            Name: "Breaker Bay",
            AdjacentSuburbs: [930, 1341, 1621, 3048, 1487, 3226, 1796],
          },
          {
            SuburbId: 951,
            Name: "Broadmeadows",
            AdjacentSuburbs: [950, 951, 1761, 966, 967, 1797],
          },
          {
            SuburbId: 1018,
            Name: "Brooklyn",
            AdjacentSuburbs: [
              1568, 1018, 1124, 1342, 1343, 1344, 1345, 1450, 1622, 1783, 3047, 3054, 1486, 1326,
              1759,
            ],
          },
          {
            SuburbId: 1798,
            Name: "Chartwell",
            AdjacentSuburbs: [1559, 1019, 1115, 1653, 1761, 3053, 1798],
          },
          {
            SuburbId: 1199,
            Name: "Churton Park",
            AdjacentSuburbs: [1560, 1199, 2638, 3049, 3330, 967],
          },
          {
            SuburbId: 1487,
            Name: "Crawford",
            AdjacentSuburbs: [
              1567, 930, 1341, 1621, 3042, 3048, 3051, 3164, 1487, 3226, 1294, 1226, 1795, 1796,
            ],
          },
          {
            SuburbId: 1653,
            Name: "Crofton Downs",
            AdjacentSuburbs: [1559, 1019, 1115, 1653, 1761, 1798],
          },
          {
            SuburbId: 3164,
            Name: "Evans Bay",
            AdjacentSuburbs: [
              1566, 1567, 1124, 1341, 1342, 1450, 3042, 3164, 1487, 874, 1294, 1326, 1226, 1795,
            ],
          },
          { SuburbId: 3049, Name: "Glenside", AdjacentSuburbs: [1560, 1199, 2638, 3049, 3330] },
          { SuburbId: 978, Name: "Grenada", AdjacentSuburbs: [1852, 965, 978, 980] },
          { SuburbId: 980, Name: "Grenada North", AdjacentSuburbs: [1852, 965, 978, 980] },
          {
            SuburbId: 3330,
            Name: "Grenada Village",
            AdjacentSuburbs: [1560, 1199, 1538, 2638, 3049, 3330, 967],
          },
          {
            SuburbId: 874,
            Name: "Hataitai",
            AdjacentSuburbs: [1566, 1124, 1450, 3040, 3042, 3054, 3164, 1486, 874, 1294, 1326],
          },
          {
            SuburbId: 1783,
            Name: "Highbury",
            AdjacentSuburbs: [1017, 1018, 1019, 1094, 1117, 1345, 1450, 1783, 3047, 3054, 1486],
          },
          { SuburbId: 877, Name: "Horokiwi", AdjacentSuburbs: [1435, 2638, 877] },
          {
            SuburbId: 1784,
            Name: "Houghton Bay",
            AdjacentSuburbs: [1567, 1342, 1784, 1785, 3051, 1226, 1795, 979],
          },
          {
            SuburbId: 1785,
            Name: "Island Bay",
            AdjacentSuburbs: [1567, 1568, 1342, 1759, 1784, 1785, 1795, 979],
          },
          {
            SuburbId: 967,
            Name: "Johnsonville",
            AdjacentSuburbs: [1560, 950, 951, 1199, 1538, 3330, 967],
          },
          {
            SuburbId: 1760,
            Name: "Kaiwharawhara",
            AdjacentSuburbs: [1559, 1115, 1760, 1761, 3053, 1797],
          },
          {
            SuburbId: 3050,
            Name: "Karaka Bays",
            AdjacentSuburbs: [1341, 1621, 3042, 3050, 3226, 1796],
          },
          { SuburbId: 1117, Name: "Karori", AdjacentSuburbs: [1017, 1117, 1345, 1783] },
          {
            SuburbId: 1345,
            Name: "Kelburn",
            AdjacentSuburbs: [
              1559, 1017, 1018, 1019, 1094, 1117, 1345, 1450, 1783, 3047, 3053, 3054, 1486, 1326,
            ],
          },
          {
            SuburbId: 1797,
            Name: "Khandallah",
            AdjacentSuburbs: [950, 951, 1115, 1760, 1761, 966, 1797],
          },
          {
            SuburbId: 1294,
            Name: "Kilbirnie",
            AdjacentSuburbs: [
              1567, 1124, 1341, 1342, 1450, 3042, 3164, 1487, 874, 1294, 1326, 1226, 1795,
            ],
          },
          {
            SuburbId: 1568,
            Name: "Kingston",
            AdjacentSuburbs: [
              1567, 1568, 1018, 1124, 1342, 1343, 1344, 1450, 1622, 1785, 979, 1759,
            ],
          },
          {
            SuburbId: 1622,
            Name: "Kowhai Park",
            AdjacentSuburbs: [1568, 1018, 1124, 1342, 1343, 1344, 1450, 1622, 3047, 1759],
          },
          {
            SuburbId: 1094,
            Name: "Lambton",
            AdjacentSuburbs: [
              1559, 1017, 1019, 1094, 1345, 1450, 1783, 3040, 3047, 3053, 3054, 1486, 1326,
            ],
          },
          { SuburbId: 1930, Name: "Linden", AdjacentSuburbs: [1433, 1447, 1930, 965, 3610] },
          {
            SuburbId: 1795,
            Name: "Lyall Bay",
            AdjacentSuburbs: [
              1567, 1124, 1342, 1784, 1785, 3051, 3164, 1487, 1294, 1226, 1795, 979, 930,
            ],
          },
          { SuburbId: 3043, Name: "Makara-Ohariu", AdjacentSuburbs: [3043] },
          {
            SuburbId: 3042,
            Name: "Maupuia",
            AdjacentSuburbs: [1566, 1341, 1621, 3042, 3050, 3164, 1487, 3226, 874, 1294, 1796],
          },
          {
            SuburbId: 1567,
            Name: "Melrose",
            AdjacentSuburbs: [
              1567, 1568, 1124, 1342, 1784, 1785, 3051, 3164, 1487, 1294, 1226, 1795, 979,
            ],
          },
          {
            SuburbId: 1341,
            Name: "Miramar",
            AdjacentSuburbs: [
              930, 1341, 1621, 3042, 3048, 3050, 3164, 1487, 3226, 1294, 1226, 1796,
            ],
          },
          {
            SuburbId: 3051,
            Name: "Moa Point",
            AdjacentSuburbs: [1567, 930, 1784, 3051, 1487, 1226, 1795, 979],
          },
          {
            SuburbId: 1343,
            Name: "Mornington",
            AdjacentSuburbs: [1568, 1018, 1124, 1342, 1343, 1344, 1450, 1622, 3047, 1759],
          },
          {
            SuburbId: 1450,
            Name: "Mount Cook",
            AdjacentSuburbs: [
              1018, 1124, 1342, 1343, 1344, 1450, 1622, 3047, 3164, 1486, 874, 1326, 3054,
            ],
          },
          {
            SuburbId: 1326,
            Name: "Mount Victoria",
            AdjacentSuburbs: [
              1566, 1018, 1094, 1124, 1345, 1450, 3040, 3047, 3054, 3164, 1486, 874, 1294, 1326,
            ],
          },
          {
            SuburbId: 1538,
            Name: "Newlands",
            AdjacentSuburbs: [1560, 950, 1538, 2638, 3330, 966, 967],
          },
          {
            SuburbId: 1124,
            Name: "Newtown",
            AdjacentSuburbs: [
              1567, 1568, 1018, 1124, 1342, 1343, 1344, 1450, 1622, 3164, 874, 1294, 1326, 1226,
              1795,
            ],
          },
          {
            SuburbId: 1761,
            Name: "Ngaio",
            AdjacentSuburbs: [951, 1115, 1653, 1760, 1761, 1797, 1798],
          },
          { SuburbId: 966, Name: "Ngauranga", AdjacentSuburbs: [950, 951, 1538, 966, 1797] },
          {
            SuburbId: 1017,
            Name: "Northland",
            AdjacentSuburbs: [1559, 1017, 1019, 1094, 1117, 1345, 1783, 3047, 3053, 3054, 1486],
          },
          { SuburbId: 993, Name: "Ohariu", AdjacentSuburbs: [993] },
          {
            SuburbId: 3040,
            Name: "Oriental Bay",
            AdjacentSuburbs: [1566, 1094, 1450, 3040, 3054, 1486, 874, 1326],
          },
          {
            SuburbId: 1759,
            Name: "Owhiro Bay",
            AdjacentSuburbs: [1759, 1785, 979, 1018, 1568, 1343, 1622],
          },
          {
            SuburbId: 1560,
            Name: "Paparangi",
            AdjacentSuburbs: [1560, 950, 1199, 1538, 2638, 3049, 3330, 967],
          },
          {
            SuburbId: 3053,
            Name: "Pipitea",
            AdjacentSuburbs: [1559, 1017, 1019, 1094, 1115, 1345, 1760, 3053, 3054, 1486, 1798],
          },
          { SuburbId: 950, Name: "Raroa", AdjacentSuburbs: [1560, 950, 951, 1538, 966, 967, 1797] },
          { SuburbId: 1852, Name: "Redwood", AdjacentSuburbs: [1852, 965, 978, 980] },
          {
            SuburbId: 1226,
            Name: "Rongotai",
            AdjacentSuburbs: [
              1567, 930, 1124, 1341, 1342, 1784, 3051, 3164, 1487, 3226, 1294, 1226, 1795, 979,
            ],
          },
          {
            SuburbId: 1566,
            Name: "Roseneath",
            AdjacentSuburbs: [1566, 3040, 3042, 3054, 3164, 1486, 874, 1326],
          },
          {
            SuburbId: 1796,
            Name: "Seatoun",
            AdjacentSuburbs: [930, 1341, 1621, 3042, 3048, 3050, 1487, 3226, 1796],
          },
          {
            SuburbId: 1621,
            Name: "Seatoun Bays",
            AdjacentSuburbs: [930, 1341, 1621, 3042, 3048, 3050, 1487, 3226, 1796],
          },
          {
            SuburbId: 3226,
            Name: "Seatoun Heights",
            AdjacentSuburbs: [930, 1341, 1621, 3042, 3048, 3050, 1487, 3226, 1226, 1796],
          },
          {
            SuburbId: 979,
            Name: "Southgate",
            AdjacentSuburbs: [979, 1226, 1342, 1567, 1568, 1759, 1784, 1785, 1795, 3051],
          },
          {
            SuburbId: 930,
            Name: "Strathmore Park",
            AdjacentSuburbs: [930, 1341, 1621, 3048, 3051, 1487, 3226, 1226, 1796, 1795],
          },
          { SuburbId: 965, Name: "Tawa", AdjacentSuburbs: [1852, 1930, 965, 978, 980] },
          {
            SuburbId: 1486,
            Name: "Te Aro",
            AdjacentSuburbs: [
              1559, 1566, 1017, 1018, 1094, 1345, 1450, 1783, 3040, 3047, 3053, 3054, 1486, 874,
              1326,
            ],
          },
          {
            SuburbId: 1559,
            Name: "Thorndon",
            AdjacentSuburbs: [
              1559, 1017, 1019, 1094, 1115, 1345, 1653, 1760, 3053, 3054, 1486, 1798,
            ],
          },
          {
            SuburbId: 1344,
            Name: "Vogeltown",
            AdjacentSuburbs: [1568, 1018, 1124, 1342, 1343, 1344, 1450, 1622, 3047],
          },
          {
            SuburbId: 1115,
            Name: "Wadestown",
            AdjacentSuburbs: [1559, 1019, 1115, 1653, 1760, 1761, 3053, 1797, 1798],
          },
          {
            SuburbId: 3054,
            Name: "Wellington Central",
            AdjacentSuburbs: [
              1559, 1566, 1017, 1018, 1094, 1345, 1450, 1783, 3040, 3047, 3053, 3054, 1486, 874,
              1326,
            ],
          },
          {
            SuburbId: 1019,
            Name: "Wilton",
            AdjacentSuburbs: [1559, 1017, 1019, 1094, 1115, 1345, 1653, 1783, 3053, 1798],
          },
          {
            SuburbId: 2638,
            Name: "Woodridge",
            AdjacentSuburbs: [1560, 1199, 1538, 2638, 3049, 3330, 877],
          },
        ],
      },
    ],
  },
  {
    LocalityId: 8,
    Name: "Nelson / Tasman",
    Districts: [
      {
        DistrictId: 52,
        Name: "Nelson",
        Suburbs: [
          {
            SuburbId: 2401,
            Name: "Annesbrook",
            AdjacentSuburbs: [2231, 2232, 2316, 2400, 2401, 2402, 3280, 3292, 3532],
          },
          { SuburbId: 2318, Name: "Atawhai", AdjacentSuburbs: [2317, 2318] },
          {
            SuburbId: 3601,
            Name: "Beachville",
            AdjacentSuburbs: [
              3601, 2269, 2397, 2396, 3532, 3260, 2395, 2398, 3492, 2270, 2375, 3398, 3254,
            ],
          },
          {
            SuburbId: 2316,
            Name: "Bishopdale",
            AdjacentSuburbs: [2316, 3280, 2231, 2232, 3292, 2401, 2402],
          },
          {
            SuburbId: 2269,
            Name: "Britannia Heights",
            AdjacentSuburbs: [
              2269, 2270, 2375, 2397, 2398, 3254, 3260, 3280, 3398, 3492, 3532, 3601,
            ],
          },
          { SuburbId: 3308, Name: "Cable Bay ", AdjacentSuburbs: [1922, 1923, 3308] },
          {
            SuburbId: 2397,
            Name: "City Centre",
            AdjacentSuburbs: [
              2269, 2270, 2375, 3254, 3260, 3398, 3492, 2394, 2395, 2396, 2397, 2398, 3601,
            ],
          },
          {
            SuburbId: 3292,
            Name: "Enner Glynn",
            AdjacentSuburbs: [2316, 2231, 2232, 3292, 2401, 2402],
          },
          { SuburbId: 2527, Name: "Hira", AdjacentSuburbs: [2527] },
          { SuburbId: 3580, Name: "Kikiwa", AdjacentSuburbs: [3580] },
          {
            SuburbId: 2396,
            Name: "Maitai",
            AdjacentSuburbs: [2317, 2375, 3254, 3260, 2394, 2395, 2396, 2397, 2398, 3601],
          },
          {
            SuburbId: 2231,
            Name: "Maitlands",
            AdjacentSuburbs: [2315, 2316, 2231, 2232, 3292, 3299, 2401, 2402],
          },
          { SuburbId: 2319, Name: "Marybank", AdjacentSuburbs: [2319, 3483] },
          {
            SuburbId: 3532,
            Name: "Moana",
            AdjacentSuburbs: [2269, 2270, 2398, 2400, 2401, 3254, 3280, 3398, 3492, 3532, 3601],
          },
          { SuburbId: 3297, Name: "Monaco", AdjacentSuburbs: [2315, 3297, 2402] },
          {
            SuburbId: 2402,
            Name: "Nayland",
            AdjacentSuburbs: [2315, 2316, 3280, 2231, 2232, 3292, 3297, 2400, 2401, 2402],
          },
          {
            SuburbId: 3260,
            Name: "Nelson",
            AdjacentSuburbs: [
              2317, 2269, 2270, 2375, 3254, 3260, 3398, 3492, 2394, 2395, 2396, 2397, 2398, 3601,
            ],
          },
          {
            SuburbId: 2395,
            Name: "Nelson East",
            AdjacentSuburbs: [2317, 2375, 3260, 2394, 2395, 2396, 2397, 2398, 3601],
          },
          {
            SuburbId: 2398,
            Name: "Nelson South",
            AdjacentSuburbs: [
              2269, 2270, 2375, 2394, 2395, 2396, 2397, 2398, 3254, 3260, 3280, 3398, 3492, 3532,
              3601,
            ],
          },
          { SuburbId: 3299, Name: "Ngawhatu", AdjacentSuburbs: [2315, 2231, 3299] },
          {
            SuburbId: 3492,
            Name: "Port Hills",
            AdjacentSuburbs: [2269, 2270, 2375, 2397, 2398, 3254, 3260, 3398, 3492, 3532, 3601],
          },
          {
            SuburbId: 2270,
            Name: "Stepneyville",
            AdjacentSuburbs: [2269, 2270, 2375, 2397, 2398, 3254, 3260, 3398, 3492, 3532, 3601],
          },
          { SuburbId: 2315, Name: "Stoke", AdjacentSuburbs: [2315, 2231, 3297, 3299, 2402] },
          {
            SuburbId: 2400,
            Name: "Tahunanui",
            AdjacentSuburbs: [2232, 2400, 2401, 2402, 3280, 3398, 3532],
          },
          {
            SuburbId: 3280,
            Name: "Tahunanui Hills",
            AdjacentSuburbs: [2232, 2269, 2316, 2398, 2400, 2401, 2402, 3280, 3398, 3532],
          },
          {
            SuburbId: 2394,
            Name: "The Brook",
            AdjacentSuburbs: [2375, 3260, 3398, 2394, 2395, 2396, 2397, 2398],
          },
          { SuburbId: 3304, Name: "The Glen", AdjacentSuburbs: [3304] },
          {
            SuburbId: 2375,
            Name: "The Wood",
            AdjacentSuburbs: [
              2317, 2269, 2270, 2375, 3254, 3260, 3492, 2394, 2395, 2396, 2397, 2398, 3601,
            ],
          },
          { SuburbId: 3483, Name: "Todds Valley", AdjacentSuburbs: [2319, 2244, 3483] },
          {
            SuburbId: 3398,
            Name: "Toi Toi",
            AdjacentSuburbs: [
              2269, 2270, 2394, 2397, 2398, 2400, 3254, 3260, 3280, 3398, 3492, 3532, 3601,
            ],
          },
          { SuburbId: 2244, Name: "Wakapuaka", AdjacentSuburbs: [2244, 3483] },
          {
            SuburbId: 2232,
            Name: "Wakatu",
            AdjacentSuburbs: [2316, 3280, 2231, 2232, 3292, 2400, 2401, 2402],
          },
          {
            SuburbId: 3254,
            Name: "Washington Valley",
            AdjacentSuburbs: [
              2269, 2270, 2375, 2396, 2397, 2398, 3254, 3260, 3398, 3492, 3532, 3601,
            ],
          },
          { SuburbId: 2601, Name: "Whangamoa", AdjacentSuburbs: [2601] },
        ],
      },
      {
        DistrictId: 51,
        Name: "Tasman",
        Suburbs: [
          { SuburbId: 3291, Name: "Aniseed Valley", AdjacentSuburbs: [3291, 2562] },
          { SuburbId: 3407, Name: "Appleby", AdjacentSuburbs: [2133, 3407, 2562] },
          { SuburbId: 3460, Name: "Best Island", AdjacentSuburbs: [2133, 3460] },
          { SuburbId: 2612, Name: "Brightwater", AdjacentSuburbs: [2612, 2562] },
          { SuburbId: 3722, Name: "Brooklyn", AdjacentSuburbs: [3722] },
          { SuburbId: 3716, Name: "Clifton", AdjacentSuburbs: [3716, 2570] },
          { SuburbId: 2085, Name: "Collingwood", AdjacentSuburbs: [2085] },
          { SuburbId: 3046, Name: "Golden Bay", AdjacentSuburbs: [2569, 3046] },
          { SuburbId: 2135, Name: "Dovedale", AdjacentSuburbs: [2135] },
          { SuburbId: 2562, Name: "Hope", AdjacentSuburbs: [2562, 3407, 2563, 3291, 2612] },
          { SuburbId: 3439, Name: "Kaiteriteri", AdjacentSuburbs: [3438, 3439] },
          { SuburbId: 3294, Name: "Kohatu", AdjacentSuburbs: [3294, 3298] },
          { SuburbId: 2566, Name: "Korere", AdjacentSuburbs: [2566] },
          { SuburbId: 3295, Name: "Lower Moutere", AdjacentSuburbs: [3295, 2089] },
          { SuburbId: 3296, Name: "Mahana", AdjacentSuburbs: [3296] },
          { SuburbId: 2133, Name: "Mapua", AdjacentSuburbs: [2133, 3407, 3460, 3303] },
          { SuburbId: 3438, Name: "Marahau", AdjacentSuburbs: [3438, 3439] },
          { SuburbId: 2089, Name: "Motueka", AdjacentSuburbs: [2089, 2803, 3295, 2087] },
          { SuburbId: 3298, Name: "Motupiko", AdjacentSuburbs: [3294, 3298] },
          { SuburbId: 2565, Name: "Murchison", AdjacentSuburbs: [2565] },
          { SuburbId: 2087, Name: "Ngatimoti", AdjacentSuburbs: [2087, 2089] },
          { SuburbId: 3537, Name: "Onekaka", AdjacentSuburbs: [3537] },
          { SuburbId: 3300, Name: "Owen River", AdjacentSuburbs: [3300] },
          { SuburbId: 3554, Name: "Pakawau", AdjacentSuburbs: [3554, 3588] },
          { SuburbId: 3538, Name: "Parapara", AdjacentSuburbs: [3538] },
          { SuburbId: 3301, Name: "Patons Rock", AdjacentSuburbs: [3301] },
          { SuburbId: 2570, Name: "Pohara", AdjacentSuburbs: [2570, 3716] },
          { SuburbId: 3588, Name: "Puponga", AdjacentSuburbs: [3588, 3554] },
          { SuburbId: 2578, Name: "Rabbit Island", AdjacentSuburbs: [2578] },
          { SuburbId: 3302, Name: "Redwood Valley", AdjacentSuburbs: [3302] },
          { SuburbId: 2563, Name: "Richmond", AdjacentSuburbs: [2563, 2562] },
          { SuburbId: 2803, Name: "Riwaka", AdjacentSuburbs: [2803, 2089] },
          { SuburbId: 3303, Name: "Ruby Bay", AdjacentSuburbs: [3303, 2133] },
          { SuburbId: 2157, Name: "Saint Arnaud", AdjacentSuburbs: [2157] },
          { SuburbId: 3743, Name: "Stanley Brook", AdjacentSuburbs: [3743] },
          { SuburbId: 2569, Name: "Takaka", AdjacentSuburbs: [2569, 3046] },
          { SuburbId: 2564, Name: "Tapawera", AdjacentSuburbs: [2564] },
          { SuburbId: 2131, Name: "Tasman", AdjacentSuburbs: [2131] },
          { SuburbId: 3536, Name: "Tata Beach", AdjacentSuburbs: [3536] },
          { SuburbId: 2132, Name: "Upper Moutere", AdjacentSuburbs: [2132] },
          { SuburbId: 2611, Name: "Wakefield", AdjacentSuburbs: [2611] },
        ],
      },
    ],
  },
  {
    LocalityId: 7,
    Name: "Marlborough",
    Districts: [
      {
        DistrictId: 82,
        Name: "Blenheim",
        Suburbs: [
          {
            SuburbId: 3655,
            Name: "Blenheim",
            AdjacentSuburbs: [3655, 2226, 3640, 3469, 3104, 2241, 3119],
          },
          {
            SuburbId: 2226,
            Name: "Blenheim Central",
            AdjacentSuburbs: [3104, 3111, 2226, 3231, 3469, 3628, 3640, 3655],
          },
          { SuburbId: 3571, Name: "Burleigh", AdjacentSuburbs: [3571, 3104, 3119, 3628] },
          {
            SuburbId: 3640,
            Name: "Islington",
            AdjacentSuburbs: [3640, 2226, 3469, 3105, 3111, 3655],
          },
          { SuburbId: 3469, Name: "Mayfield", AdjacentSuburbs: [3111, 2226, 3469, 3640, 3655] },
          { SuburbId: 3572, Name: "Omaka", AdjacentSuburbs: [3572] },
          {
            SuburbId: 3104,
            Name: "Redwoodtown",
            AdjacentSuburbs: [3104, 3119, 2226, 3231, 3571, 3655],
          },
          { SuburbId: 2241, Name: "Riverlands", AdjacentSuburbs: [2241, 3655] },
          { SuburbId: 3105, Name: "Riversdale", AdjacentSuburbs: [3105, 2242, 3640] },
          { SuburbId: 3111, Name: "Springlands", AdjacentSuburbs: [3111, 2226, 3469, 3628, 3640] },
          { SuburbId: 3119, Name: "Witherlea", AdjacentSuburbs: [3104, 3119, 3231, 3571, 3655] },
          { SuburbId: 3628, Name: "Yelverton", AdjacentSuburbs: [3628, 2226, 3571, 3111] },
        ],
      },
      {
        DistrictId: 54,
        Name: "Kaikoura",
        Suburbs: [
          { SuburbId: 3753, Name: "Goose Bay", AdjacentSuburbs: [3753] },
          { SuburbId: 2111, Name: "Kaikoura", AdjacentSuburbs: [2111] },
          { SuburbId: 2204, Name: "Kekerengu", AdjacentSuburbs: [2204] },
        ],
      },
      {
        DistrictId: 53,
        Name: "Marlborough",
        Suburbs: [
          { SuburbId: 3319, Name: "Anakiwa ", AdjacentSuburbs: [3319] },
          { SuburbId: 3248, Name: "Awatere Valley", AdjacentSuburbs: [3248] },
          { SuburbId: 3607, Name: "Blind River", AdjacentSuburbs: [3607] },
          { SuburbId: 3124, Name: "Dashwood", AdjacentSuburbs: [3124] },
          { SuburbId: 2224, Name: "Fairhall", AdjacentSuburbs: [2224] },
          { SuburbId: 2242, Name: "Grovetown", AdjacentSuburbs: [2200, 3105, 2242] },
          { SuburbId: 2195, Name: "Havelock", AdjacentSuburbs: [2195] },
          { SuburbId: 3624, Name: "Hawkesbury", AdjacentSuburbs: [3624, 2225, 2605] },
          { SuburbId: 3126, Name: "Kenepuru Sounds", AdjacentSuburbs: [3126] },
          { SuburbId: 3819, Name: "Kaituna", AdjacentSuburbs: [3819] },
          { SuburbId: 3691, Name: "Kaiuma Bay", AdjacentSuburbs: [3691] },
          { SuburbId: 3041, Name: "Marlborough Sounds", AdjacentSuburbs: [3041] },
          { SuburbId: 3249, Name: "Koromiko", AdjacentSuburbs: [3249] },
          { SuburbId: 2346, Name: "Linkwater", AdjacentSuburbs: [2346] },
          { SuburbId: 3498, Name: "Mahau Sound", AdjacentSuburbs: [3498] },
          { SuburbId: 3561, Name: "Ngakuta Bay", AdjacentSuburbs: [3561] },
          { SuburbId: 3128, Name: "Pelorus Sounds", AdjacentSuburbs: [3128] },
          { SuburbId: 3459, Name: "North Bank", AdjacentSuburbs: [3459] },
          { SuburbId: 3594, Name: "North West Bay", AdjacentSuburbs: [3594] },
          { SuburbId: 2193, Name: "Okaramio", AdjacentSuburbs: [2193] },
          { SuburbId: 3388, Name: "Okiwi Bay", AdjacentSuburbs: [3388] },
          { SuburbId: 3458, Name: "Onamalutu Valley", AdjacentSuburbs: [3458] },
          { SuburbId: 3129, Name: "Queen Charlotte Sounds", AdjacentSuburbs: [3129] },
          { SuburbId: 2198, Name: "Picton", AdjacentSuburbs: [2198] },
          { SuburbId: 3696, Name: "Port Underwood", AdjacentSuburbs: [3696] },
          { SuburbId: 2284, Name: "Portage", AdjacentSuburbs: [2284] },
          { SuburbId: 3735, Name: "Punga Cove", AdjacentSuburbs: [3735] },
          { SuburbId: 2281, Name: "Rai Valley", AdjacentSuburbs: [2281] },
          { SuburbId: 2192, Name: "Rapaura", AdjacentSuburbs: [2192] },
          { SuburbId: 3268, Name: "Rarangi", AdjacentSuburbs: [3268] },
          { SuburbId: 2225, Name: "Renwick", AdjacentSuburbs: [2605, 2225, 3624] },
          { SuburbId: 2223, Name: "Seddon", AdjacentSuburbs: [2223] },
          { SuburbId: 2200, Name: "Spring Creek", AdjacentSuburbs: [2200, 2242] },
          { SuburbId: 2196, Name: "Tennyson Inlet", AdjacentSuburbs: [2196] },
          { SuburbId: 3763, Name: "Totaranui", AdjacentSuburbs: [3763] },
          { SuburbId: 2191, Name: "Tuamarina", AdjacentSuburbs: [2191] },
          { SuburbId: 2199, Name: "Waihopai Valley", AdjacentSuburbs: [2199] },
          { SuburbId: 2345, Name: "Waikawa", AdjacentSuburbs: [2345] },
          { SuburbId: 2605, Name: "Wairau Valley", AdjacentSuburbs: [2605, 2225, 3624] },
          { SuburbId: 2222, Name: "Ward", AdjacentSuburbs: [2222] },
          { SuburbId: 2518, Name: "Woodbourne", AdjacentSuburbs: [2518] },
        ],
      },
    ],
  },
  {
    LocalityId: 16,
    Name: "West Coast",
    Districts: [
      {
        DistrictId: 55,
        Name: "Buller",
        Suburbs: [
          { SuburbId: 3661, Name: "Cape Foulwind", AdjacentSuburbs: [3661] },
          { SuburbId: 3120, Name: "Carters Beach", AdjacentSuburbs: [3120] },
          { SuburbId: 2412, Name: "Charleston", AdjacentSuburbs: [2412] },
          { SuburbId: 3121, Name: "Fairdown", AdjacentSuburbs: [3121] },
          { SuburbId: 3122, Name: "Granity", AdjacentSuburbs: [3122, 3361] },
          { SuburbId: 2582, Name: "Hector", AdjacentSuburbs: [2582, 3377] },
          { SuburbId: 2466, Name: "Inangahua", AdjacentSuburbs: [2466] },
          { SuburbId: 2384, Name: "Karamea", AdjacentSuburbs: [2384] },
          { SuburbId: 3501, Name: "Little Wanganui", AdjacentSuburbs: [3501] },
          { SuburbId: 3556, Name: "Maruia", AdjacentSuburbs: [3556] },
          { SuburbId: 3361, Name: "Millerton", AdjacentSuburbs: [3122, 3361] },
          { SuburbId: 2385, Name: "Mokihinui", AdjacentSuburbs: [2385] },
          { SuburbId: 3377, Name: "Ngakawau", AdjacentSuburbs: [2582, 3377] },
          { SuburbId: 2824, Name: "Punakaiki", AdjacentSuburbs: [2824] },
          { SuburbId: 2464, Name: "Reefton", AdjacentSuburbs: [2464] },
          { SuburbId: 3123, Name: "Seddonville", AdjacentSuburbs: [3123] },
          { SuburbId: 2467, Name: "Springs Junction", AdjacentSuburbs: [2467] },
          { SuburbId: 2581, Name: "Waimangaroa", AdjacentSuburbs: [2581] },
          { SuburbId: 2460, Name: "Westport", AdjacentSuburbs: [2460] },
        ],
      },
      {
        DistrictId: 56,
        Name: "Grey",
        Suburbs: [
          { SuburbId: 2473, Name: "Ahaura", AdjacentSuburbs: [2473] },
          { SuburbId: 2825, Name: "Barrytown", AdjacentSuburbs: [2825] },
          { SuburbId: 2471, Name: "Blackball", AdjacentSuburbs: [2471] },
          { SuburbId: 3066, Name: "Blaketown", AdjacentSuburbs: [3066, 3068, 2481] },
          { SuburbId: 3157, Name: "Camerons", AdjacentSuburbs: [3157] },
          { SuburbId: 3068, Name: "Cobden", AdjacentSuburbs: [3066, 3068] },
          { SuburbId: 2475, Name: "Dobson", AdjacentSuburbs: [3163, 2475] },
          { SuburbId: 3158, Name: "Dunollie", AdjacentSuburbs: [3158, 2480] },
          { SuburbId: 3803, Name: "Gladstone", AdjacentSuburbs: [3803] },
          { SuburbId: 2481, Name: "Greymouth", AdjacentSuburbs: [3066, 3086, 2481] },
          { SuburbId: 2465, Name: "Ikamatua", AdjacentSuburbs: [2465] },
          { SuburbId: 3728, Name: "Inchbonnie", AdjacentSuburbs: [3728] },
          { SuburbId: 3086, Name: "Karoro", AdjacentSuburbs: [3086, 3110, 2481] },
          { SuburbId: 3227, Name: "Lake Brunner", AdjacentSuburbs: [3227] },
          { SuburbId: 3629, Name: "Marsden", AdjacentSuburbs: [3629] },
          { SuburbId: 2382, Name: "Moana", AdjacentSuburbs: [2382, 2399] },
          { SuburbId: 3160, Name: "Nelson Creek", AdjacentSuburbs: [3160] },
          { SuburbId: 2474, Name: "Ngahere", AdjacentSuburbs: [2474] },
          { SuburbId: 2482, Name: "Paroa", AdjacentSuburbs: [2482] },
          { SuburbId: 3161, Name: "Rapahoe", AdjacentSuburbs: [3161] },
          { SuburbId: 2480, Name: "Runanga", AdjacentSuburbs: [3158, 2480] },
          { SuburbId: 3162, Name: "Rutherglen", AdjacentSuburbs: [3162] },
          { SuburbId: 3110, Name: "South Beach", AdjacentSuburbs: [3086, 3110] },
          { SuburbId: 3163, Name: "Taylorville", AdjacentSuburbs: [3163, 2475] },
          { SuburbId: 3155, Name: "Totara Flat", AdjacentSuburbs: [3155] },
        ],
      },
      {
        DistrictId: 57,
        Name: "Westland",
        Suburbs: [
          { SuburbId: 3692, Name: "Arahura Valley", AdjacentSuburbs: [3692] },
          { SuburbId: 3482, Name: "Awatuna", AdjacentSuburbs: [3482] },
          { SuburbId: 3156, Name: "Bruce Bay", AdjacentSuburbs: [3156] },
          { SuburbId: 2363, Name: "Fox Glacier", AdjacentSuburbs: [2363] },
          { SuburbId: 2443, Name: "Franz Josef", AdjacentSuburbs: [2443] },
          { SuburbId: 3079, Name: "Haast", AdjacentSuburbs: [3079] },
          { SuburbId: 2361, Name: "Harihari", AdjacentSuburbs: [2361] },
          { SuburbId: 2489, Name: "Hokitika", AdjacentSuburbs: [2489] },
          { SuburbId: 3794, Name: "Jacksons", AdjacentSuburbs: [3794] },
          { SuburbId: 2386, Name: "Kaniere", AdjacentSuburbs: [2386] },
          { SuburbId: 3159, Name: "Kokatahi", AdjacentSuburbs: [3159] },
          { SuburbId: 2332, Name: "Kowhitirangi", AdjacentSuburbs: [2332] },
          { SuburbId: 2483, Name: "Kumara", AdjacentSuburbs: [2483] },
          { SuburbId: 3717, Name: "Kumara Junction", AdjacentSuburbs: [3717] },
          { SuburbId: 2399, Name: "Moana", AdjacentSuburbs: [2382, 2399] },
          { SuburbId: 3378, Name: "Okarito", AdjacentSuburbs: [3378] },
          { SuburbId: 2364, Name: "Okuru", AdjacentSuburbs: [2364] },
          { SuburbId: 3790, Name: "Otira", AdjacentSuburbs: [3790] },
          { SuburbId: 2360, Name: "Pukekura", AdjacentSuburbs: [2360] },
          { SuburbId: 2333, Name: "Ross", AdjacentSuburbs: [2333] },
          { SuburbId: 3314, Name: "Whataroa ", AdjacentSuburbs: [3314] },
        ],
      },
    ],
  },
  {
    LocalityId: 3,
    Name: "Canterbury",
    Districts: [
      {
        DistrictId: 63,
        Name: "Ashburton",
        Suburbs: [
          { SuburbId: 3574, Name: "Allenton", AdjacentSuburbs: [3574] },
          { SuburbId: 2337, Name: "Ashburton", AdjacentSuburbs: [2337, 2498, 3702] },
          { SuburbId: 1438, Name: "Chertsey", AdjacentSuburbs: [1438] },
          { SuburbId: 1437, Name: "Fairton", AdjacentSuburbs: [1437, 3703, 3818] },
          { SuburbId: 3702, Name: "Hampstead", AdjacentSuburbs: [3702, 2337] },
          { SuburbId: 2497, Name: "Hinds", AdjacentSuburbs: [2497] },
          { SuburbId: 3730, Name: "Huntingdon", AdjacentSuburbs: [3730] },
          { SuburbId: 2354, Name: "Mayfield", AdjacentSuburbs: [2354] },
          { SuburbId: 2358, Name: "Methven", AdjacentSuburbs: [2358] },
          { SuburbId: 2355, Name: "Montalto", AdjacentSuburbs: [2355] },
          { SuburbId: 3359, Name: "Mt Somers", AdjacentSuburbs: [3359] },
          { SuburbId: 3703, Name: "Netherby", AdjacentSuburbs: [3703, 1437, 3818] },
          { SuburbId: 3818, Name: "Newland", AdjacentSuburbs: [3818, 1437, 3703] },
          { SuburbId: 2137, Name: "Rakaia", AdjacentSuburbs: [2137] },
          { SuburbId: 2498, Name: "Tinwald", AdjacentSuburbs: [2337, 2498] },
          { SuburbId: 3584, Name: "Willowby", AdjacentSuburbs: [3584, 3583] },
        ],
      },
      {
        DistrictId: 61,
        Name: "Banks Peninsula",
        Suburbs: [
          { SuburbId: 2255, Name: "Akaroa", AdjacentSuburbs: [2255] },
          { SuburbId: 3504, Name: "Birdlings Flat", AdjacentSuburbs: [3504] },
          { SuburbId: 2262, Name: "Cass Bay", AdjacentSuburbs: [2262, 2265] },
          { SuburbId: 3432, Name: "Charteris Bay", AdjacentSuburbs: [2260, 3432] },
          { SuburbId: 2260, Name: "Diamond Harbour", AdjacentSuburbs: [2260, 3432, 2476] },
          { SuburbId: 2258, Name: "Duvauchelle", AdjacentSuburbs: [2258] },
          { SuburbId: 2341, Name: "Governors Bay", AdjacentSuburbs: [2341] },
          { SuburbId: 3497, Name: "Little Akaloa", AdjacentSuburbs: [3497] },
          { SuburbId: 2253, Name: "Little River", AdjacentSuburbs: [2253] },
          { SuburbId: 2265, Name: "Lyttelton", AdjacentSuburbs: [2262, 2265] },
          { SuburbId: 2150, Name: "Motukarara", AdjacentSuburbs: [2150] },
          { SuburbId: 3573, Name: "Okains Bay", AdjacentSuburbs: [3573] },
          { SuburbId: 3445, Name: "Pigeon Bay", AdjacentSuburbs: [3445] },
          { SuburbId: 2259, Name: "Port Levy", AdjacentSuburbs: [2259] },
          { SuburbId: 2476, Name: "Purau", AdjacentSuburbs: [2260, 2476] },
          { SuburbId: 3433, Name: "Teddington", AdjacentSuburbs: [3433] },
          { SuburbId: 2254, Name: "Wainui", AdjacentSuburbs: [2254] },
        ],
      },
      {
        DistrictId: 60,
        Name: "Christchurch City",
        Suburbs: [
          {
            SuburbId: 2189,
            Name: "Addington",
            AdjacentSuburbs: [2338, 2189, 1993, 2178, 2180, 3253],
          },
          { SuburbId: 3326, Name: "Aidanfield", AdjacentSuburbs: [2046, 3326, 2233, 2236, 2149] },
          {
            SuburbId: 2031,
            Name: "Aranui",
            AdjacentSuburbs: [2030, 2031, 2032, 2034, 2043, 2342, 2390],
          },
          {
            SuburbId: 2030,
            Name: "Avondale",
            AdjacentSuburbs: [2030, 2031, 2342, 1994, 1995, 2390],
          },
          {
            SuburbId: 2504,
            Name: "Avonhead",
            AdjacentSuburbs: [2504, 2505, 2512, 2058, 2500, 2165, 2501, 3305],
          },
          {
            SuburbId: 2388,
            Name: "Avonside",
            AdjacentSuburbs: [2213, 2216, 1994, 2238, 2388, 2389, 2390],
          },
          {
            SuburbId: 1969,
            Name: "Balmoral Hill",
            AdjacentSuburbs: [2044, 2266, 2434, 2435, 2436, 2437, 1968, 1969],
          },
          {
            SuburbId: 3253,
            Name: "Barrington",
            AdjacentSuburbs: [2338, 2189, 3379, 3391, 2235, 2178, 2179, 2180, 3253],
          },
          {
            SuburbId: 2338,
            Name: "Beckenham",
            AdjacentSuburbs: [2338, 2182, 2183, 2186, 2187, 2188, 2189, 2178, 2179, 2180, 3253],
          },
          { SuburbId: 2039, Name: "Belfast", AdjacentSuburbs: [2039, 3277] },
          {
            SuburbId: 2342,
            Name: "Bexley",
            AdjacentSuburbs: [2030, 2031, 2032, 2034, 2043, 2342, 2390],
          },
          {
            SuburbId: 2507,
            Name: "Bishopdale",
            AdjacentSuburbs: [2557, 2505, 2506, 2507, 3371, 2501, 2508],
          },
          {
            SuburbId: 2237,
            Name: "Bromley",
            AdjacentSuburbs: [2185, 2217, 2455, 3325, 2237, 2389, 2390],
          },
          {
            SuburbId: 3325,
            Name: "Brookhaven",
            AdjacentSuburbs: [2184, 2185, 2217, 2437, 2455, 3325, 2237],
          },
          {
            SuburbId: 2317,
            Name: "Brooklands",
            AdjacentSuburbs: [2317, 2318, 2375, 3260, 2395, 2396],
          },
          {
            SuburbId: 2500,
            Name: "Broomfield",
            AdjacentSuburbs: [2504, 2512, 2058, 2500, 2144, 2145, 2146, 2501],
          },
          {
            SuburbId: 2280,
            Name: "Bryndwr",
            AdjacentSuburbs: [2557, 2558, 2101, 2280, 2505, 3322, 3454],
          },
          {
            SuburbId: 2505,
            Name: "Burnside",
            AdjacentSuburbs: [2101, 2280, 2504, 2505, 2507, 2501, 2165],
          },
          { SuburbId: 1995, Name: "Burwood", AdjacentSuburbs: [2030, 1994, 1995, 2391] },
          { SuburbId: 2508, Name: "Casebrook", AdjacentSuburbs: [2506, 2508, 2511, 3371, 2507] },
          {
            SuburbId: 2181,
            Name: "Cashmere",
            AdjacentSuburbs: [2182, 2187, 3379, 3391, 2179, 2181],
          },
          { SuburbId: 2040, Name: "Chaneys", AdjacentSuburbs: [2040, 2041] },
          {
            SuburbId: 2212,
            Name: "City Centre",
            AdjacentSuburbs: [2212, 2213, 2214, 2216, 3345, 2238, 2180],
          },
          {
            SuburbId: 2435,
            Name: "Clifton",
            AdjacentSuburbs: [2434, 2435, 2436, 1968, 1969, 2065, 2067],
          },
          {
            SuburbId: 3379,
            Name: "Cracroft",
            AdjacentSuburbs: [3379, 3391, 2234, 2235, 2179, 2181, 3253],
          },
          {
            SuburbId: 1994,
            Name: "Dallington",
            AdjacentSuburbs: [2030, 2213, 1994, 1995, 2238, 2388, 2389, 2390, 2391],
          },
          {
            SuburbId: 3345,
            Name: "Edgeware",
            AdjacentSuburbs: [2558, 2212, 2213, 2214, 2215, 3322, 3345],
          },
          { SuburbId: 3394, Name: "Eyreton", AdjacentSuburbs: [3394] },
          {
            SuburbId: 2101,
            Name: "Fendalton",
            AdjacentSuburbs: [2101, 2280, 2505, 3454, 1993, 2165, 2177],
          },
          {
            SuburbId: 2217,
            Name: "Ferrymead",
            AdjacentSuburbs: [2184, 2185, 2217, 2266, 2437, 2455, 3325, 2237],
          },
          {
            SuburbId: 3273,
            Name: "Forest Park",
            AdjacentSuburbs: [2033, 2035, 3217, 3273, 3279, 3453, 3531],
          },
          { SuburbId: 2149, Name: "Halswell", AdjacentSuburbs: [3326, 2233, 2149] },
          {
            SuburbId: 2506,
            Name: "Harewood",
            AdjacentSuburbs: [2557, 2506, 2507, 2508, 3371, 2501, 3277],
          },
          {
            SuburbId: 2455,
            Name: "Heathcote",
            AdjacentSuburbs: [2183, 2184, 2185, 2186, 2188, 2217, 2455, 3325, 2237],
          },
          {
            SuburbId: 2144,
            Name: "Hei Hei",
            AdjacentSuburbs: [2339, 3139, 2500, 2144, 2145, 2146],
          },
          { SuburbId: 2236, Name: "Hillmorton", AdjacentSuburbs: [3326, 2235, 2236] },
          {
            SuburbId: 2184,
            Name: "Hillsborough",
            AdjacentSuburbs: [2182, 2183, 2184, 2185, 2186, 2187, 2217, 2455, 3325],
          },
          {
            SuburbId: 2235,
            Name: "Hoon Hay",
            AdjacentSuburbs: [3379, 3391, 2234, 2235, 2236, 2178, 3253],
          },
          { SuburbId: 2146, Name: "Hornby", AdjacentSuburbs: [2339, 3139, 2500, 2144, 2145, 2146] },
          {
            SuburbId: 2182,
            Name: "Huntsbury",
            AdjacentSuburbs: [2338, 2182, 2183, 2184, 2186, 2187, 2179, 2181],
          },
          { SuburbId: 2512, Name: "Hyde Park", AdjacentSuburbs: [2504, 2512, 2500, 2501, 2165] },
          {
            SuburbId: 2165,
            Name: "Ilam",
            AdjacentSuburbs: [2101, 2504, 2505, 2512, 2058, 2165, 2177],
          },
          { SuburbId: 2145, Name: "Islington", AdjacentSuburbs: [2500, 2144, 2145, 2146, 2453] },
          { SuburbId: 2308, Name: "Kainga", AdjacentSuburbs: [2308, 3376] },
          { SuburbId: 3508, Name: "Kennedy's Bush", AdjacentSuburbs: [3508] },
          {
            SuburbId: 2238,
            Name: "Linwood",
            AdjacentSuburbs: [2188, 2212, 2213, 2216, 1994, 2238, 2180, 2388, 2389],
          },
          {
            SuburbId: 2215,
            Name: "Mairehau",
            AdjacentSuburbs: [2213, 2214, 2215, 3322, 3345, 2391],
          },
          { SuburbId: 2042, Name: "Marshland", AdjacentSuburbs: [2042, 2511, 3531] },
          {
            SuburbId: 2558,
            Name: "Merivale",
            AdjacentSuburbs: [2558, 2214, 2280, 3322, 3345, 3454, 1993],
          },
          { SuburbId: 2057, Name: "Middleton", AdjacentSuburbs: [2339, 3139, 2057, 2058, 2177] },
          {
            SuburbId: 2436,
            Name: "Moncks Bay",
            AdjacentSuburbs: [2434, 2435, 2436, 1968, 1969, 2065, 2067],
          },
          {
            SuburbId: 2266,
            Name: "Mount Pleasant",
            AdjacentSuburbs: [2217, 2266, 2437, 1968, 1969],
          },
          {
            SuburbId: 2183,
            Name: "Murray Aynsley",
            AdjacentSuburbs: [2338, 2182, 2183, 2184, 2185, 2186, 2187, 2188, 2455],
          },
          { SuburbId: 2034, Name: "New Brighton", AdjacentSuburbs: [2031, 2032, 2034, 2043, 2342] },
          {
            SuburbId: 2389,
            Name: "North Linwood",
            AdjacentSuburbs: [2185, 2188, 2213, 2216, 1994, 2237, 2238, 2388, 2389, 2390],
          },
          {
            SuburbId: 2032,
            Name: "North New Brighton",
            AdjacentSuburbs: [2031, 2032, 2034, 2043, 2342],
          },
          {
            SuburbId: 3371,
            Name: "Northcote",
            AdjacentSuburbs: [2557, 2506, 2507, 2508, 2511, 3371],
          },
          {
            SuburbId: 3453,
            Name: "Northshore",
            AdjacentSuburbs: [2033, 2035, 3217, 3273, 3279, 3453],
          },
          { SuburbId: 3277, Name: "Northwood ", AdjacentSuburbs: [2039, 3277, 2506] },
          { SuburbId: 2233, Name: "Oaklands", AdjacentSuburbs: [2046, 3326, 2233, 2149] },
          {
            SuburbId: 2186,
            Name: "Opawa",
            AdjacentSuburbs: [2338, 2182, 2183, 2184, 2185, 2186, 2187, 2188, 2455],
          },
          {
            SuburbId: 2557,
            Name: "Papanui",
            AdjacentSuburbs: [2557, 2280, 2506, 2507, 3322, 3371, 3454],
          },
          {
            SuburbId: 2035,
            Name: "Parklands",
            AdjacentSuburbs: [2033, 2035, 3217, 3273, 3279, 3453, 3531],
          },
          {
            SuburbId: 2216,
            Name: "Phillipstown",
            AdjacentSuburbs: [2185, 2188, 2212, 2213, 2216, 2238, 2180, 2388, 2389],
          },
          {
            SuburbId: 3279,
            Name: "Queenspark",
            AdjacentSuburbs: [2033, 2035, 3217, 3273, 3279, 3453, 3531],
          },
          { SuburbId: 3701, Name: "Ravenswood", AdjacentSuburbs: [3701, 3410, 3700, 2514] },
          {
            SuburbId: 1968,
            Name: "Redcliffs",
            AdjacentSuburbs: [2044, 2266, 2434, 2435, 2436, 2437, 1968, 1969],
          },
          { SuburbId: 2511, Name: "Redwood", AdjacentSuburbs: [2042, 2508, 2511, 3371] },
          { SuburbId: 1993, Name: "Riccarton", AdjacentSuburbs: [2558, 2101, 2189, 1993, 2177] },
          {
            SuburbId: 2213,
            Name: "Richmond",
            AdjacentSuburbs: [2212, 2213, 2214, 2215, 2216, 3345, 1994, 2238, 2388, 2389, 2391],
          },
          {
            SuburbId: 2434,
            Name: "Richmond Hill",
            AdjacentSuburbs: [2434, 2435, 2436, 1968, 1969, 2065, 2067],
          },
          {
            SuburbId: 2501,
            Name: "Russley",
            AdjacentSuburbs: [2505, 2506, 2507, 2512, 2501, 2504, 2500, 3305],
          },
          { SuburbId: 2065, Name: "Scarborough", AdjacentSuburbs: [2434, 2435, 2436, 2065, 2067] },
          { SuburbId: 2391, Name: "Shirley", AdjacentSuburbs: [2213, 2215, 1994, 1995, 2391] },
          {
            SuburbId: 2058,
            Name: "Sockburn",
            AdjacentSuburbs: [2339, 2504, 3139, 2057, 2058, 2500, 2165, 2177],
          },
          {
            SuburbId: 2179,
            Name: "Somerfield",
            AdjacentSuburbs: [2338, 2182, 2187, 3379, 3391, 2178, 2179, 2180, 2181, 3253],
          },
          {
            SuburbId: 2043,
            Name: "South New Brighton",
            AdjacentSuburbs: [2031, 2032, 2034, 2043, 2342],
          },
          { SuburbId: 2044, Name: "Southshore", AdjacentSuburbs: [2044, 1968, 1969] },
          { SuburbId: 2309, Name: "Spencerville", AdjacentSuburbs: [2309, 3531] },
          {
            SuburbId: 2178,
            Name: "Spreydon",
            AdjacentSuburbs: [2338, 2189, 2235, 2178, 2179, 2180, 3253],
          },
          {
            SuburbId: 2214,
            Name: "St. Albans",
            AdjacentSuburbs: [2558, 2212, 2213, 2214, 2215, 3322, 3345, 3454],
          },
          {
            SuburbId: 2437,
            Name: "St. Andrews Hill",
            AdjacentSuburbs: [2217, 2266, 2437, 3325, 1968, 1969],
          },
          {
            SuburbId: 2187,
            Name: "St. Martins",
            AdjacentSuburbs: [2338, 2182, 2183, 2184, 2186, 2187, 2188, 2179, 2180, 2181],
          },
          { SuburbId: 3376, Name: "Stewarts Gully", AdjacentSuburbs: [2308, 3112, 3376] },
          {
            SuburbId: 3454,
            Name: "Strowan",
            AdjacentSuburbs: [2557, 2558, 2101, 2214, 2280, 3322, 3454],
          },
          { SuburbId: 3112, Name: "Styx", AdjacentSuburbs: [3112, 3376] },
          { SuburbId: 2067, Name: "Sumner", AdjacentSuburbs: [2434, 2435, 2436, 2065, 2067] },
          {
            SuburbId: 2180,
            Name: "Sydenham",
            AdjacentSuburbs: [2338, 2187, 2188, 2189, 2212, 2216, 2238, 2178, 2179, 2180, 3253],
          },
          { SuburbId: 2453, Name: "Templeton", AdjacentSuburbs: [2453, 2145, 3305] },
          {
            SuburbId: 2177,
            Name: "Upper Riccarton",
            AdjacentSuburbs: [2101, 1993, 2057, 2058, 2165, 2177],
          },
          {
            SuburbId: 2033,
            Name: "Waimairi Beach",
            AdjacentSuburbs: [2033, 2035, 3217, 3273, 3279, 3453],
          },
          {
            SuburbId: 2390,
            Name: "Wainoni",
            AdjacentSuburbs: [2030, 2031, 2342, 1994, 2237, 2388, 2389, 2390],
          },
          {
            SuburbId: 3531,
            Name: "Waitikiri",
            AdjacentSuburbs: [2035, 2309, 3273, 3279, 3531, 2042],
          },
          {
            SuburbId: 2188,
            Name: "Waltham",
            AdjacentSuburbs: [2338, 2183, 2185, 2186, 2187, 2188, 2216, 2455, 2238, 2180, 2389],
          },
          { SuburbId: 2046, Name: "Westlake", AdjacentSuburbs: [2339, 2046, 3139, 3326, 2233] },
          { SuburbId: 2234, Name: "Westmorland", AdjacentSuburbs: [3379, 3391, 2234, 2235] },
          {
            SuburbId: 3139,
            Name: "Wigram",
            AdjacentSuburbs: [2339, 2046, 3139, 2057, 2058, 2144, 2146],
          },
          {
            SuburbId: 2339,
            Name: "Wigram Park",
            AdjacentSuburbs: [2339, 2046, 3139, 2057, 2058, 2144, 2146],
          },
          {
            SuburbId: 2185,
            Name: "Woolston",
            AdjacentSuburbs: [2183, 2184, 2185, 2186, 2188, 2216, 2217, 2455, 3325, 2237, 2389],
          },
          { SuburbId: 3305, Name: "Yaldhurst ", AdjacentSuburbs: [3305, 2453, 2504, 2501] },
        ],
      },
      {
        DistrictId: 58,
        Name: "Hurunui",
        Suburbs: [
          { SuburbId: 2249, Name: "Amberley", AdjacentSuburbs: [2249, 3418] },
          { SuburbId: 3218, Name: "Amberley Beach", AdjacentSuburbs: [3218] },
          { SuburbId: 3418, Name: "Balcairn", AdjacentSuburbs: [3418, 2249] },
          { SuburbId: 1541, Name: "Cheviot", AdjacentSuburbs: [1544, 1541] },
          { SuburbId: 1542, Name: "Culverden", AdjacentSuburbs: [1542, 3360, 3516] },
          { SuburbId: 1544, Name: "Domett", AdjacentSuburbs: [1544, 1541] },
          { SuburbId: 3395, Name: "Gore Bay", AdjacentSuburbs: [3395] },
          { SuburbId: 3357, Name: "Greta Valley", AdjacentSuburbs: [3357] },
          { SuburbId: 1543, Name: "Hanmer Springs", AdjacentSuburbs: [1543, 3516] },
          { SuburbId: 2615, Name: "Hawarden", AdjacentSuburbs: [2615] },
          { SuburbId: 3219, Name: "Leithfield", AdjacentSuburbs: [3219, 2250] },
          { SuburbId: 2250, Name: "Leithfield Beach", AdjacentSuburbs: [3219, 2250] },
          { SuburbId: 3356, Name: "Lewis Pass", AdjacentSuburbs: [3356] },
          { SuburbId: 3516, Name: "Lyford", AdjacentSuburbs: [1539, 1542, 1543, 2276, 3425, 3516] },
          { SuburbId: 2273, Name: "Motunau", AdjacentSuburbs: [2273] },
          { SuburbId: 3425, Name: "Oaro", AdjacentSuburbs: [2276, 3425, 3516] },
          { SuburbId: 2276, Name: "Omihi", AdjacentSuburbs: [2276, 3425, 3516] },
          { SuburbId: 3360, Name: "Rotherham", AdjacentSuburbs: [1542, 3360] },
          { SuburbId: 3358, Name: "Scargill", AdjacentSuburbs: [3358] },
          { SuburbId: 1539, Name: "Waiau", AdjacentSuburbs: [1539, 3516] },
          { SuburbId: 2163, Name: "Waikari", AdjacentSuburbs: [2163] },
          { SuburbId: 2277, Name: "Waipara", AdjacentSuburbs: [2277] },
        ],
      },
      {
        DistrictId: 65,
        Name: "Mackenzie",
        Suburbs: [
          { SuburbId: 3485, Name: "Albury", AdjacentSuburbs: [3485] },
          { SuburbId: 3318, Name: "Burkes Pass ", AdjacentSuburbs: [3318] },
          { SuburbId: 2275, Name: "Cave", AdjacentSuburbs: [2275] },
          { SuburbId: 2407, Name: "Fairlie", AdjacentSuburbs: [2407] },
          { SuburbId: 2550, Name: "Lake Tekapo", AdjacentSuburbs: [2550] },
          { SuburbId: 3455, Name: "Mt Cook", AdjacentSuburbs: [3455] },
          { SuburbId: 2219, Name: "Twizel", AdjacentSuburbs: [2334, 2219] },
        ],
      },
      {
        DistrictId: 62,
        Name: "Selwyn",
        Suburbs: [
          { SuburbId: 3427, Name: "Annat", AdjacentSuburbs: [3427] },
          { SuburbId: 2614, Name: "Arthurs Pass", AdjacentSuburbs: [2614] },
          { SuburbId: 3396, Name: "Broadfield", AdjacentSuburbs: [3396] },
          { SuburbId: 2003, Name: "Burnham", AdjacentSuburbs: [2003] },
          { SuburbId: 3431, Name: "Castle Hill Village", AdjacentSuburbs: [3431] },
          { SuburbId: 3428, Name: "Charing Cross", AdjacentSuburbs: [3428] },
          { SuburbId: 2279, Name: "Coalgate", AdjacentSuburbs: [2279] },
          { SuburbId: 2278, Name: "Darfield", AdjacentSuburbs: [2278] },
          { SuburbId: 3286, Name: "Doyleston", AdjacentSuburbs: [3286] },
          { SuburbId: 2002, Name: "Dunsandel", AdjacentSuburbs: [2002] },
          { SuburbId: 3509, Name: "Glentunnel", AdjacentSuburbs: [3509] },
          { SuburbId: 2312, Name: "Greendale", AdjacentSuburbs: [2312] },
          { SuburbId: 2311, Name: "Hororata", AdjacentSuburbs: [2311] },
          { SuburbId: 3409, Name: "Irwell", AdjacentSuburbs: [3409] },
          { SuburbId: 2454, Name: "Kirwee", AdjacentSuburbs: [2454] },
          { SuburbId: 3430, Name: "Kowai Bush", AdjacentSuburbs: [3430] },
          { SuburbId: 2314, Name: "Lake Coleridge", AdjacentSuburbs: [2314] },
          { SuburbId: 2049, Name: "Lake Ellesmere", AdjacentSuburbs: [2049] },
          { SuburbId: 2001, Name: "Leeston", AdjacentSuburbs: [2001] },
          { SuburbId: 2005, Name: "Lincoln", AdjacentSuburbs: [2005] },
          { SuburbId: 2147, Name: "Prebbleton", AdjacentSuburbs: [2147] },
          { SuburbId: 2004, Name: "Rolleston", AdjacentSuburbs: [2004, 2406] },
          { SuburbId: 3429, Name: "Russells Flat", AdjacentSuburbs: [3429] },
          { SuburbId: 2419, Name: "Sheffield", AdjacentSuburbs: [2419] },
          { SuburbId: 1999, Name: "Southbridge", AdjacentSuburbs: [1999] },
          { SuburbId: 2418, Name: "Springfield", AdjacentSuburbs: [2418] },
          { SuburbId: 2006, Name: "Springston", AdjacentSuburbs: [2006] },
          { SuburbId: 2007, Name: "Tai Tapu", AdjacentSuburbs: [2007] },
          { SuburbId: 2406, Name: "Weedons", AdjacentSuburbs: [2004, 2406] },
          { SuburbId: 2404, Name: "West Melton", AdjacentSuburbs: [2404] },
          { SuburbId: 2359, Name: "Windwhistle", AdjacentSuburbs: [2359] },
        ],
      },
      {
        DistrictId: 64,
        Name: "Timaru",
        Suburbs: [
          { SuburbId: 2047, Name: "Arundel", AdjacentSuburbs: [2047, 3421] },
          { SuburbId: 3828, Name: "Claremont", AdjacentSuburbs: [3828] },
          { SuburbId: 2408, Name: "Geraldine", AdjacentSuburbs: [2408] },
          { SuburbId: 3679, Name: "Gleniti", AdjacentSuburbs: [3679, 2292, 3680, 3681] },
          {
            SuburbId: 3680,
            Name: "Glenwood",
            AdjacentSuburbs: [3680, 3679, 3676, 2292, 3681, 3690],
          },
          {
            SuburbId: 3681,
            Name: "Highfield",
            AdjacentSuburbs: [3681, 3679, 3680, 3676, 2292, 3685, 3688, 3689, 3690, 3721],
          },
          { SuburbId: 3708, Name: "Kensington", AdjacentSuburbs: [3708, 3683, 3684, 3685, 3689] },
          { SuburbId: 3547, Name: "Kerrytown", AdjacentSuburbs: [3547] },
          { SuburbId: 3546, Name: "Levels", AdjacentSuburbs: [3546] },
          {
            SuburbId: 3721,
            Name: "Maori Hill",
            AdjacentSuburbs: [
              3721, 3681, 3676, 3682, 3683, 3685, 3686, 2292, 3687, 3688, 3689, 3690,
            ],
          },
          {
            SuburbId: 3676,
            Name: "Marchwiel",
            AdjacentSuburbs: [3676, 2292, 2243, 3680, 3681, 3682, 3686, 3688, 3690, 3721],
          },
          {
            SuburbId: 3682,
            Name: "Oceanview",
            AdjacentSuburbs: [3682, 3676, 2292, 2243, 3686, 3688, 3721],
          },
          { SuburbId: 3562, Name: "Orari", AdjacentSuburbs: [3562] },
          { SuburbId: 2365, Name: "Pareora", AdjacentSuburbs: [2365] },
          {
            SuburbId: 3683,
            Name: "Parkside",
            AdjacentSuburbs: [3683, 3684, 3685, 3687, 3689, 3690, 3708, 3721],
          },
          { SuburbId: 2229, Name: "Pleasant Point", AdjacentSuburbs: [2229] },
          { SuburbId: 3823, Name: "Rangitata", AdjacentSuburbs: [3823] },
          { SuburbId: 3684, Name: "Redruth", AdjacentSuburbs: [3684, 3683, 3689, 3708] },
          { SuburbId: 3623, Name: "Rosewill", AdjacentSuburbs: [3623] },
          { SuburbId: 3824, Name: "Seadown", AdjacentSuburbs: [3824] },
          {
            SuburbId: 3685,
            Name: "Seaview",
            AdjacentSuburbs: [3685, 3681, 3683, 3687, 3688, 3689, 3690, 3708, 3721],
          },
          {
            SuburbId: 3686,
            Name: "Smithfield",
            AdjacentSuburbs: [3686, 3676, 3682, 2243, 3688, 3721],
          },
          { SuburbId: 3324, Name: "St Andrews", AdjacentSuburbs: [2291, 3324] },
          { SuburbId: 2228, Name: "Temuka", AdjacentSuburbs: [2228] },
          {
            SuburbId: 2292,
            Name: "Timaru",
            AdjacentSuburbs: [2292, 3676, 3679, 3680, 3681, 3682, 3688, 3690, 3721],
          },
          {
            SuburbId: 3687,
            Name: "Timaru Port",
            AdjacentSuburbs: [3687, 3683, 3685, 3688, 3689, 3690, 3721],
          },
          {
            SuburbId: 3688,
            Name: "Waimataitai",
            AdjacentSuburbs: [3688, 3681, 3676, 3682, 3685, 3686, 2292, 3687, 3690, 3721],
          },
          { SuburbId: 2243, Name: "Washdyke", AdjacentSuburbs: [2243, 3676, 3682, 3686] },
          {
            SuburbId: 3689,
            Name: "Watlington",
            AdjacentSuburbs: [3689, 3681, 3683, 3684, 3685, 3687, 3690, 3708, 3721],
          },
          {
            SuburbId: 3690,
            Name: "West End",
            AdjacentSuburbs: [3690, 3680, 3681, 3676, 3683, 3685, 2292, 3687, 3688, 3689, 3721],
          },
          { SuburbId: 2208, Name: "Winchester", AdjacentSuburbs: [2208] },
        ],
      },
      {
        DistrictId: 59,
        Name: "Waimakariri",
        Suburbs: [
          { SuburbId: 2545, Name: "Ashley", AdjacentSuburbs: [2545, 2516] },
          { SuburbId: 2336, Name: "Ashley Gorge", AdjacentSuburbs: [2336] },
          { SuburbId: 3419, Name: "Bennetts", AdjacentSuburbs: [3419] },
          { SuburbId: 2417, Name: "Bexley", AdjacentSuburbs: [2417] },
          { SuburbId: 3420, Name: "Burnt Hill", AdjacentSuburbs: [3420] },
          { SuburbId: 2306, Name: "Carleton", AdjacentSuburbs: [2306] },
          { SuburbId: 2218, Name: "Clarkville", AdjacentSuburbs: [2218] },
          { SuburbId: 2516, Name: "Coldstream", AdjacentSuburbs: [2545, 2516] },
          { SuburbId: 3421, Name: "Coopers Creek", AdjacentSuburbs: [2047, 3421] },
          { SuburbId: 2041, Name: "Coutts Island", AdjacentSuburbs: [2040, 2041] },
          { SuburbId: 2307, Name: "Cust", AdjacentSuburbs: [2307, 2313, 3216] },
          { SuburbId: 3422, Name: "Eyrewell", AdjacentSuburbs: [3422] },
          { SuburbId: 2544, Name: "Fernside", AdjacentSuburbs: [2544] },
          { SuburbId: 2542, Name: "Flaxton", AdjacentSuburbs: [2542] },
          { SuburbId: 3423, Name: "Gammans Creek", AdjacentSuburbs: [2305, 3423] },
          { SuburbId: 3424, Name: "Glentui", AdjacentSuburbs: [3424] },
          { SuburbId: 2313, Name: "Horrellville", AdjacentSuburbs: [2307, 2313, 3216] },
          { SuburbId: 2543, Name: "Kaiapoi", AdjacentSuburbs: [2543, 2190] },
          { SuburbId: 3736, Name: "Kairaki", AdjacentSuburbs: [3736, 3365] },
          { SuburbId: 3328, Name: "Loburn", AdjacentSuburbs: [3328] },
          { SuburbId: 2352, Name: "Loburn North", AdjacentSuburbs: [2352] },
          { SuburbId: 2190, Name: "Ohapuku", AdjacentSuburbs: [2543, 2190] },
          { SuburbId: 2549, Name: "Ohoka", AdjacentSuburbs: [2549] },
          { SuburbId: 3327, Name: "Okuku", AdjacentSuburbs: [3327] },
          { SuburbId: 2305, Name: "Oxford", AdjacentSuburbs: [2305, 3423] },
          { SuburbId: 3410, Name: "Pegasus", AdjacentSuburbs: [3410, 3700, 3701] },
          {
            SuburbId: 3217,
            Name: "Pegasus Bay",
            AdjacentSuburbs: [2033, 2035, 3217, 3273, 3279, 3453],
          },
          { SuburbId: 3365, Name: "Pines Beach", AdjacentSuburbs: [3365, 3736] },
          { SuburbId: 2515, Name: "Rangiora", AdjacentSuburbs: [2541, 2515] },
          { SuburbId: 3700, Name: "Ravenswood", AdjacentSuburbs: [3700, 3410, 2514, 3701] },
          { SuburbId: 2350, Name: "Sefton", AdjacentSuburbs: [2350] },
          { SuburbId: 2541, Name: "Southbrook", AdjacentSuburbs: [2541, 2515] },
          { SuburbId: 2546, Name: "Springbank", AdjacentSuburbs: [2546] },
          { SuburbId: 3174, Name: "Summerhill", AdjacentSuburbs: [1517, 1617, 3174] },
          { SuburbId: 2548, Name: "Swannanoa", AdjacentSuburbs: [2548] },
          { SuburbId: 3329, Name: "Tuahiwi", AdjacentSuburbs: [2514, 3329] },
          { SuburbId: 3426, Name: "View Hill", AdjacentSuburbs: [3426] },
          { SuburbId: 2513, Name: "Waikuku", AdjacentSuburbs: [2513, 3444] },
          { SuburbId: 3444, Name: "Waikuku Beach", AdjacentSuburbs: [2513, 3444] },
          { SuburbId: 3216, Name: "West Eyreton", AdjacentSuburbs: [2307, 2313, 3216] },
          { SuburbId: 2423, Name: "White Rock", AdjacentSuburbs: [2423] },
          { SuburbId: 2514, Name: "Woodend", AdjacentSuburbs: [2514, 3329, 3700, 3701] },
          { SuburbId: 3633, Name: "Woodend Beach", AdjacentSuburbs: [3633] },
        ],
      },
      {
        DistrictId: 66,
        Name: "Waimate",
        Suburbs: [
          { SuburbId: 2169, Name: "Glenavy", AdjacentSuburbs: [2169] },
          { SuburbId: 2479, Name: "Makikihi", AdjacentSuburbs: [2479] },
          { SuburbId: 2291, Name: "Saint Andrews", AdjacentSuburbs: [2291, 3324] },
          { SuburbId: 2168, Name: "Waimate", AdjacentSuburbs: [2168] },
        ],
      },
    ],
  },
  {
    LocalityId: 10,
    Name: "Otago",
    Districts: [
      {
        DistrictId: 69,
        Name: "Central Otago",
        Suburbs: [
          { SuburbId: 2296, Name: "Alexandra", AdjacentSuburbs: [2296] },
          { SuburbId: 2297, Name: "Clyde", AdjacentSuburbs: [2297] },
          { SuburbId: 2298, Name: "Cromwell", AdjacentSuburbs: [2298] },
          { SuburbId: 1975, Name: "Ettrick", AdjacentSuburbs: [3462, 1975] },
          { SuburbId: 3381, Name: "Ida Valley", AdjacentSuburbs: [3381] },
          { SuburbId: 3658, Name: "Kokonga", AdjacentSuburbs: [3658] },
          { SuburbId: 3766, Name: "Kyeburn", AdjacentSuburbs: [3766] },
          { SuburbId: 3461, Name: "Lauder", AdjacentSuburbs: [3461] },
          { SuburbId: 3462, Name: "Millers Flat", AdjacentSuburbs: [3462, 1975] },
          { SuburbId: 3653, Name: "Moa Flat", AdjacentSuburbs: [3653] },
          { SuburbId: 3738, Name: "Mount Pisa", AdjacentSuburbs: [3738] },
          { SuburbId: 1729, Name: "Naseby", AdjacentSuburbs: [1729] },
          { SuburbId: 1676, Name: "Omakau", AdjacentSuburbs: [1676] },
          { SuburbId: 3448, Name: "Ophir", AdjacentSuburbs: [3448] },
          { SuburbId: 1679, Name: "Oturehua", AdjacentSuburbs: [1679] },
          { SuburbId: 1730, Name: "Ranfurly", AdjacentSuburbs: [1730] },
          { SuburbId: 1976, Name: "Roxburgh", AdjacentSuburbs: [1976] },
          { SuburbId: 3706, Name: "Springvale", AdjacentSuburbs: [3706] },
          { SuburbId: 3502, Name: "St Bathans", AdjacentSuburbs: [3502] },
          { SuburbId: 1675, Name: "Tarras", AdjacentSuburbs: [1675] },
          { SuburbId: 1680, Name: "Wedderburn", AdjacentSuburbs: [1680] },
        ],
      },
      {
        DistrictId: 72,
        Name: "Clutha",
        Suburbs: [
          { SuburbId: 2106, Name: "Balclutha", AdjacentSuburbs: [2106] },
          { SuburbId: 1587, Name: "Clinton", AdjacentSuburbs: [1587] },
          { SuburbId: 3611, Name: "Clydevale", AdjacentSuburbs: [3611] },
          { SuburbId: 3747, Name: "Heriot", AdjacentSuburbs: [3747] },
          { SuburbId: 2415, Name: "Kaitangata", AdjacentSuburbs: [2415] },
          { SuburbId: 1900, Name: "Kaka Point", AdjacentSuburbs: [1900] },
          { SuburbId: 1632, Name: "Lawrence", AdjacentSuburbs: [1632] },
          { SuburbId: 3724, Name: "Leithen Bush", AdjacentSuburbs: [3724] },
          { SuburbId: 3777, Name: "Maclennan", AdjacentSuburbs: [3777] },
          { SuburbId: 1897, Name: "Milton", AdjacentSuburbs: [1897] },
          { SuburbId: 1489, Name: "Owaka", AdjacentSuburbs: [1489] },
          { SuburbId: 3387, Name: "Pounawea", AdjacentSuburbs: [3387] },
          { SuburbId: 3344, Name: "Taieri Mouth", AdjacentSuburbs: [3344] },
          { SuburbId: 1634, Name: "Tapanui", AdjacentSuburbs: [1634] },
          { SuburbId: 1463, Name: "Taumata", AdjacentSuburbs: [1463] },
          { SuburbId: 3800, Name: "Tuapeka Flat", AdjacentSuburbs: [3800] },
          { SuburbId: 1631, Name: "Tuapeka Mouth", AdjacentSuburbs: [1631] },
          { SuburbId: 2559, Name: "Waihola", AdjacentSuburbs: [2559] },
          { SuburbId: 3650, Name: "Waipahi", AdjacentSuburbs: [3650] },
          { SuburbId: 1588, Name: "Wairuna", AdjacentSuburbs: [1588] },
          { SuburbId: 3638, Name: "Waitahuna", AdjacentSuburbs: [3638] },
          { SuburbId: 1462, Name: "Waiwera South", AdjacentSuburbs: [1462] },
        ],
      },
      {
        DistrictId: 71,
        Name: "Dunedin",
        Suburbs: [
          { SuburbId: 2012, Name: "Abbotsford", AdjacentSuburbs: [1903, 3211, 2012] },
          { SuburbId: 2288, Name: "Allanton", AdjacentSuburbs: [2288] },
          {
            SuburbId: 3031,
            Name: "Andersons Bay",
            AdjacentSuburbs: [1747, 106, 1839, 3027, 3028, 3031, 1826, 1418],
          },
          { SuburbId: 1470, Name: "Aramoana", AdjacentSuburbs: [1470] },
          {
            SuburbId: 1808,
            Name: "Balaclava",
            AdjacentSuburbs: [
              2378, 3038, 3211, 2011, 1805, 1806, 1807, 1808, 1809, 1810, 3478, 1904, 1905, 1906,
              3535,
            ],
          },
          {
            SuburbId: 1805,
            Name: "Belleknowes",
            AdjacentSuburbs: [
              1941, 1944, 1945, 1946, 1947, 2013, 3038, 1805, 1806, 1807, 1808, 1904, 1905, 1906,
              1907,
            ],
          },
          { SuburbId: 1972, Name: "Berwick", AdjacentSuburbs: [1972] },
          { SuburbId: 1426, Name: "Blackhead", AdjacentSuburbs: [1426, 1903, 2378, 3274] },
          {
            SuburbId: 1806,
            Name: "Bradford",
            AdjacentSuburbs: [
              1904, 1905, 1906, 1944, 1945, 1946, 1947, 2013, 3038, 3211, 1805, 1806, 1807, 1808,
              3578,
            ],
          },
          { SuburbId: 2056, Name: "Brighton", AdjacentSuburbs: [2056, 2068] },
          { SuburbId: 3022, Name: "Broad Bay", AdjacentSuburbs: [1887, 3022] },
          {
            SuburbId: 2013,
            Name: "Brockville",
            AdjacentSuburbs: [
              1904, 1908, 1941, 1945, 1946, 1947, 2013, 2014, 3038, 3211, 1805, 1806, 3598,
            ],
          },
          {
            SuburbId: 1810,
            Name: "Calton Hill",
            AdjacentSuburbs: [
              1949, 2378, 3274, 1469, 2011, 1807, 1808, 1809, 1810, 1832, 3478, 1904, 1905, 1906,
              1907,
            ],
          },
          { SuburbId: 3565, Name: "Careys Bay", AdjacentSuburbs: [3565, 2176, 2209] },
          {
            SuburbId: 1809,
            Name: "Caversham",
            AdjacentSuburbs: [
              1949, 3039, 1469, 2011, 1807, 1808, 1809, 1810, 1831, 1832, 3478, 1904, 1905, 1906,
              1907,
            ],
          },
          {
            SuburbId: 3038,
            Name: "City Centre",
            AdjacentSuburbs: [
              1944, 1945, 1946, 1947, 2013, 3038, 1805, 1806, 1807, 1808, 3478, 1904, 1905, 1906,
              1907,
            ],
          },
          {
            SuburbId: 1807,
            Name: "Clyde Hill",
            AdjacentSuburbs: [
              3038, 3039, 2011, 1805, 1806, 1807, 1808, 1809, 1810, 1832, 3478, 1904, 1905, 1906,
              1907,
            ],
          },
          { SuburbId: 3023, Name: "Company Bay", AdjacentSuburbs: [3035, 3023, 3024] },
          {
            SuburbId: 2378,
            Name: "Concord",
            AdjacentSuburbs: [
              1426, 1904, 1949, 2378, 3274, 1469, 2011, 1808, 1809, 1810, 1832, 3478, 3535,
            ],
          },
          {
            SuburbId: 2011,
            Name: "Corstorphine",
            AdjacentSuburbs: [
              1949, 2378, 3274, 1469, 2011, 1807, 1808, 1809, 1810, 1832, 3478, 1904, 1905, 1906,
              3535,
            ],
          },
          { SuburbId: 1988, Name: "Dalmore", AdjacentSuburbs: [1720, 2343, 1987, 1988, 2457] },
          { SuburbId: 3057, Name: "East Taieri", AdjacentSuburbs: [2102, 3057, 2103] },
          { SuburbId: 3463, Name: "Doctors Point", AdjacentSuburbs: [2430, 3463] },
          { SuburbId: 3713, Name: "Evansdale", AdjacentSuburbs: [3713] },
          { SuburbId: 2070, Name: "Fairfield", AdjacentSuburbs: [3380, 2070] },
          {
            SuburbId: 3535,
            Name: "Forbury",
            AdjacentSuburbs: [
              3535, 1808, 1810, 1809, 1807, 2378, 2011, 1904, 1907, 1832, 3478, 1906, 1905, 1469,
              1949,
            ],
          },
          {
            SuburbId: 2015,
            Name: "Glenleith",
            AdjacentSuburbs: [1908, 1941, 1942, 2015, 2016, 2344, 1987],
          },
          {
            SuburbId: 1947,
            Name: "Glenross",
            AdjacentSuburbs: [1908, 1941, 1944, 1945, 1946, 1947, 2013, 2014, 3038, 1805, 1806],
          },
          { SuburbId: 1903, Name: "Green Island", AdjacentSuburbs: [1426, 1903, 2012] },
          {
            SuburbId: 2014,
            Name: "Halfway Bush",
            AdjacentSuburbs: [1908, 1941, 1945, 1946, 1947, 2013, 2014],
          },
          { SuburbId: 971, Name: "Harington Point", AdjacentSuburbs: [969, 971] },
          { SuburbId: 1889, Name: "Harwood", AdjacentSuburbs: [1889] },
          {
            SuburbId: 1908,
            Name: "Helensburgh",
            AdjacentSuburbs: [
              1908, 1941, 1942, 1944, 1945, 1946, 1947, 2013, 2014, 2015, 2016, 2344,
            ],
          },
          { SuburbId: 2528, Name: "Henley", AdjacentSuburbs: [2528] },
          { SuburbId: 2017, Name: "Heyward Point", AdjacentSuburbs: [2017] },
          { SuburbId: 1825, Name: "Highcliff", AdjacentSuburbs: [3027, 1825] },
          { SuburbId: 1877, Name: "Hindon", AdjacentSuburbs: [1877] },
          {
            SuburbId: 1946,
            Name: "Kaikorai",
            AdjacentSuburbs: [
              1904, 1905, 1906, 1908, 1941, 1944, 1945, 1946, 1947, 2013, 2014, 3038, 1805, 1806,
            ],
          },
          {
            SuburbId: 3211,
            Name: "Kaikorai Valley",
            AdjacentSuburbs: [1904, 2013, 3211, 2012, 1806, 1808, 3598],
          },
          { SuburbId: 459, Name: "Karitane", AdjacentSuburbs: [459] },
          {
            SuburbId: 1904,
            Name: "Kenmure",
            AdjacentSuburbs: [
              1946, 3038, 3211, 2011, 1805, 1806, 1807, 1808, 1809, 1810, 3478, 1904, 1905, 1906,
              1907,
            ],
          },
          {
            SuburbId: 1907,
            Name: "Kensington",
            AdjacentSuburbs: [
              1839, 3038, 3039, 1469, 1807, 1809, 1810, 1831, 1832, 3478, 1904, 1905, 1906, 1907,
              3535,
            ],
          },
          {
            SuburbId: 1832,
            Name: "Kew",
            AdjacentSuburbs: [
              1949, 2378, 3039, 3274, 1469, 2011, 1807, 1809, 1810, 1831, 1832, 3478, 1906, 1907,
              3535,
            ],
          },
          { SuburbId: 2102, Name: "Kinmont Park", AdjacentSuburbs: [2102, 2103, 3057, 3380] },
          {
            SuburbId: 2016,
            Name: "Leith Valley",
            AdjacentSuburbs: [1908, 1942, 2015, 2016, 2344, 2429, 1987],
          },
          {
            SuburbId: 1987,
            Name: "Liberton",
            AdjacentSuburbs: [1942, 2015, 2016, 2344, 2429, 3037, 1987, 1988, 1989, 2457],
          },
          {
            SuburbId: 1944,
            Name: "Littlebourne",
            AdjacentSuburbs: [
              1908, 1941, 1942, 1944, 1945, 1946, 1947, 2344, 3037, 3038, 1805, 1806,
            ],
          },
          { SuburbId: 3362, Name: "Long Beach", AdjacentSuburbs: [3362, 3363, 1490] },
          {
            SuburbId: 3478,
            Name: "Lookout Point",
            AdjacentSuburbs: [
              1949, 3038, 3039, 1469, 2011, 1807, 1808, 1809, 1810, 1832, 3478, 1904, 1905, 1906,
              1907,
            ],
          },
          { SuburbId: 3024, Name: "Macandrew Bay", AdjacentSuburbs: [3023, 3024] },
          { SuburbId: 3464, Name: "Maia", AdjacentSuburbs: [3032, 3035, 3464] },
          {
            SuburbId: 1942,
            Name: "Maori Hill",
            AdjacentSuburbs: [
              1908, 1941, 1942, 1944, 1945, 2015, 2016, 2344, 2429, 3037, 1987, 1989,
            ],
          },
          {
            SuburbId: 1906,
            Name: "Maryhill",
            AdjacentSuburbs: [
              3038, 3039, 2011, 1805, 1806, 1807, 1808, 1809, 1810, 1832, 3478, 1904, 1905, 1906,
              1907,
            ],
          },
          { SuburbId: 1582, Name: "Maungatua", AdjacentSuburbs: [1582] },
          { SuburbId: 1844, Name: "Middlemarch", AdjacentSuburbs: [1844] },
          { SuburbId: 3622, Name: "Momona", AdjacentSuburbs: [3622, 2433] },
          {
            SuburbId: 1905,
            Name: "Mornington",
            AdjacentSuburbs: [
              3038, 3039, 2011, 1805, 1806, 1807, 1808, 1809, 1810, 3478, 1904, 1905, 1906, 1907,
              3535,
            ],
          },
          { SuburbId: 2103, Name: "Mosgiel", AdjacentSuburbs: [2102, 2103, 3057] },
          { SuburbId: 3598, Name: "Mount Grand", AdjacentSuburbs: [3598, 1806, 2013, 3211] },
          {
            SuburbId: 1720,
            Name: "Mount Mera",
            AdjacentSuburbs: [1720, 2343, 2429, 3032, 1988, 1989, 2457],
          },
          { SuburbId: 3386, Name: "Mt Cargill", AdjacentSuburbs: [3386] },
          {
            SuburbId: 1839,
            Name: "Musselburgh",
            AdjacentSuburbs: [1747, 106, 1839, 1907, 3028, 3031, 3039, 1826, 1831, 1418],
          },
          {
            SuburbId: 2343,
            Name: "Normanby",
            AdjacentSuburbs: [1720, 2343, 1988, 1989, 2457, 2429],
          },
          {
            SuburbId: 3037,
            Name: "North Dunedin",
            AdjacentSuburbs: [1942, 1944, 1945, 2344, 2429, 3037, 1987, 1989],
          },
          {
            SuburbId: 2429,
            Name: "North East Valley",
            AdjacentSuburbs: [1720, 1942, 2016, 2344, 2429, 3037, 1987, 1989, 2343],
          },
          { SuburbId: 2104, Name: "North Taieri", AdjacentSuburbs: [2104] },
          { SuburbId: 1826, Name: "Ocean Grove", AdjacentSuburbs: [1747, 1839, 3028, 3031, 1826] },
          { SuburbId: 2068, Name: "Ocean View", AdjacentSuburbs: [2056, 2068] },
          {
            SuburbId: 1989,
            Name: "Opoho",
            AdjacentSuburbs: [1720, 1942, 2343, 2344, 2429, 3032, 3037, 1987, 1989],
          },
          { SuburbId: 3363, Name: "Osborne", AdjacentSuburbs: [3362, 3363, 1490] },
          { SuburbId: 3192, Name: "Otago Peninsula", AdjacentSuburbs: [3192] },
          { SuburbId: 969, Name: "Otakou", AdjacentSuburbs: [969, 971] },
          { SuburbId: 2433, Name: "Otokia", AdjacentSuburbs: [2433, 3622] },
          { SuburbId: 1585, Name: "Outram", AdjacentSuburbs: [1585] },
          { SuburbId: 2457, Name: "Pine Hill", AdjacentSuburbs: [1720, 2343, 1987, 1988, 2457] },
          { SuburbId: 2176, Name: "Port Chalmers", AdjacentSuburbs: [2209, 3036, 2176, 3565] },
          { SuburbId: 1887, Name: "Portobello", AdjacentSuburbs: [1887, 3022] },
          { SuburbId: 1490, Name: "Purakaunui", AdjacentSuburbs: [3362, 3363, 1490] },
          { SuburbId: 3032, Name: "Ravensbourne", AdjacentSuburbs: [1720, 3027, 3032, 3464, 1989] },
          { SuburbId: 3036, Name: "Roseneath", AdjacentSuburbs: [2209, 3036, 2176] },
          {
            SuburbId: 1945,
            Name: "Roslyn",
            AdjacentSuburbs: [
              1908, 1941, 1942, 1944, 1945, 1946, 1947, 2013, 2014, 2344, 3037, 3038, 1805, 1806,
            ],
          },
          { SuburbId: 3380, Name: "Saddle Hill", AdjacentSuburbs: [2102, 3380, 2070] },
          {
            SuburbId: 1469,
            Name: "Saint Clair",
            AdjacentSuburbs: [
              1907, 1949, 2378, 3039, 3274, 1469, 2011, 1807, 1809, 1810, 1831, 1832, 3478, 3535,
              3578,
            ],
          },
          {
            SuburbId: 1949,
            Name: "Saint Clair Park",
            AdjacentSuburbs: [1949, 2378, 3274, 1469, 2011, 1809, 1810, 1831, 1832, 3478, 3535],
          },
          {
            SuburbId: 1831,
            Name: "Saint Kilda",
            AdjacentSuburbs: [
              1839, 1907, 1949, 3039, 1469, 1807, 1809, 1831, 1832, 3478, 1418, 3535, 3578,
            ],
          },
          { SuburbId: 3035, Name: "Saint Leonards", AdjacentSuburbs: [3035, 3464, 3023] },
          { SuburbId: 2209, Name: "Sawyers Bay", AdjacentSuburbs: [2209, 3036, 2176, 3565] },
          { SuburbId: 2289, Name: "Scroggs Hill", AdjacentSuburbs: [2289] },
          {
            SuburbId: 1747,
            Name: "Shiel Hill",
            AdjacentSuburbs: [1747, 106, 1839, 3027, 3028, 3031, 1826, 1418],
          },
          {
            SuburbId: 3039,
            Name: "South Dunedin",
            AdjacentSuburbs: [
              1839, 1905, 1906, 1907, 3038, 3039, 1469, 2011, 1807, 1809, 1810, 1831, 1832, 3478,
              1418,
            ],
          },
          {
            SuburbId: 1418,
            Name: "Tainui",
            AdjacentSuburbs: [1747, 106, 1839, 1907, 3028, 3031, 3039, 1831, 1418],
          },
          {
            SuburbId: 3027,
            Name: "The Cove",
            AdjacentSuburbs: [1747, 106, 3027, 3028, 3031, 3032, 1825],
          },
          {
            SuburbId: 3578,
            Name: "The Glen",
            AdjacentSuburbs: [
              3578, 1808, 1805, 1806, 1810, 1809, 3038, 1807, 2011, 3535, 1904, 1907, 1832, 3478,
              1906,
            ],
          },
          { SuburbId: 3809, Name: "Upper Waitati", AdjacentSuburbs: [3809] },
          {
            SuburbId: 106,
            Name: "Vauxhall",
            AdjacentSuburbs: [1747, 106, 1839, 3027, 3028, 3031, 1418],
          },
          { SuburbId: 2432, Name: "Waikouaiti", AdjacentSuburbs: [2432] },
          { SuburbId: 1973, Name: "Waipori", AdjacentSuburbs: [1973] },
          { SuburbId: 2430, Name: "Waitati", AdjacentSuburbs: [2430, 3463] },
          {
            SuburbId: 1941,
            Name: "Wakari",
            AdjacentSuburbs: [
              1908, 1941, 1942, 1944, 1945, 1946, 1947, 2013, 2014, 2015, 2344, 1805,
            ],
          },
          { SuburbId: 1427, Name: "Waldronville", AdjacentSuburbs: [1427] },
          { SuburbId: 458, Name: "Warrington", AdjacentSuburbs: [458] },
          {
            SuburbId: 3028,
            Name: "Waverley",
            AdjacentSuburbs: [1747, 106, 1839, 3027, 3028, 3031, 1826, 1418],
          },
          {
            SuburbId: 3274,
            Name: "Westgate",
            AdjacentSuburbs: [
              1426, 1949, 2378, 3274, 1469, 2011, 1808, 1809, 1810, 1832, 3478, 3535,
            ],
          },
          { SuburbId: 2069, Name: "Westwood", AdjacentSuburbs: [2069] },
          { SuburbId: 2071, Name: "Wingatui", AdjacentSuburbs: [2071] },
          {
            SuburbId: 2344,
            Name: "Woodhaugh",
            AdjacentSuburbs: [
              1908, 1941, 1942, 1944, 1945, 2015, 2016, 2344, 2429, 3037, 1987, 1989,
            ],
          },
        ],
      },
      {
        DistrictId: 70,
        Name: "Queenstown-Lakes",
        Suburbs: [
          { SuburbId: 2330, Name: "Arrow Junction", AdjacentSuburbs: [2330, 2327] },
          { SuburbId: 2329, Name: "Arrowtown", AdjacentSuburbs: [2329, 2327] },
          { SuburbId: 1746, Name: "Arthurs Point", AdjacentSuburbs: [1746] },
          { SuburbId: 3585, Name: "Ben Lomond", AdjacentSuburbs: [3585, 1848, 955] },
          { SuburbId: 1849, Name: "Boydtown", AdjacentSuburbs: [955, 1674, 1849, 3145] },
          { SuburbId: 3149, Name: "Closeburn", AdjacentSuburbs: [3149, 2141] },
          { SuburbId: 2328, Name: "Crown Terrace", AdjacentSuburbs: [2328, 2327] },
          { SuburbId: 3150, Name: "Dalefield", AdjacentSuburbs: [3150, 3754] },
          { SuburbId: 1848, Name: "Fernhill", AdjacentSuburbs: [955, 1848, 3585] },
          { SuburbId: 953, Name: "Frankton", AdjacentSuburbs: [953, 3151] },
          { SuburbId: 1508, Name: "Gibbston", AdjacentSuburbs: [1508, 2327] },
          { SuburbId: 2301, Name: "Glenorchy", AdjacentSuburbs: [2301] },
          {
            SuburbId: 3151,
            Name: "Goldfield Heights",
            AdjacentSuburbs: [952, 953, 1674, 3145, 3151],
          },
          { SuburbId: 3276, Name: "Jacks Point", AdjacentSuburbs: [3276] },
          { SuburbId: 3827, Name: "Kawarau Falls", AdjacentSuburbs: [3827] },
          { SuburbId: 3795, Name: "Kelvin Heights", AdjacentSuburbs: [3795, 952, 1674] },
          { SuburbId: 952, Name: "Kelvin Peninsula", AdjacentSuburbs: [952, 1674, 3151, 3795] },
          { SuburbId: 1510, Name: "Kingston", AdjacentSuburbs: [1510] },
          { SuburbId: 3090, Name: "Lake Hayes", AdjacentSuburbs: [1505, 3090] },
          { SuburbId: 2141, Name: "Lake Wakatipu", AdjacentSuburbs: [3149, 2141] },
          { SuburbId: 1505, Name: "Lower Shotover", AdjacentSuburbs: [1505, 3090] },
          { SuburbId: 3665, Name: "Mount Creighton", AdjacentSuburbs: [3665] },
          {
            SuburbId: 1674,
            Name: "Queenstown East",
            AdjacentSuburbs: [952, 1674, 1849, 3145, 3151, 3795],
          },
          { SuburbId: 3145, Name: "Queenstown Hill", AdjacentSuburbs: [1674, 1849, 3145, 3151] },
          { SuburbId: 3754, Name: "Speargrass Flat", AdjacentSuburbs: [3754, 3150] },
          {
            SuburbId: 1847,
            Name: "Sunshine Bay",
            AdjacentSuburbs: [1745, 1428, 1763, 1847, 2116, 2117, 3331],
          },
          { SuburbId: 955, Name: "Town Centre", AdjacentSuburbs: [955, 1848, 1849, 3585] },
        ],
      },
      {
        DistrictId: 79,
        Name: "South Otago",
        Suburbs: [{ SuburbId: 3354, Name: "Stirling", AdjacentSuburbs: [3354] }],
      },
      {
        DistrictId: 68,
        Name: "Waitaki",
        Suburbs: [
          { SuburbId: 3402, Name: "Alma", AdjacentSuburbs: [3402] },
          { SuburbId: 3091, Name: "Lake Ohau", AdjacentSuburbs: [3091] },
          { SuburbId: 1931, Name: "Awamoko", AdjacentSuburbs: [1931] },
          { SuburbId: 1734, Name: "Dunback", AdjacentSuburbs: [1734] },
          { SuburbId: 2862, Name: "Duntroon", AdjacentSuburbs: [2862] },
          { SuburbId: 1754, Name: "Enfield", AdjacentSuburbs: [1754] },
          { SuburbId: 3442, Name: "Glenavy", AdjacentSuburbs: [3442] },
          { SuburbId: 2554, Name: "Hampden", AdjacentSuburbs: [2554] },
          { SuburbId: 2556, Name: "Herbert", AdjacentSuburbs: [2556] },
          { SuburbId: 47, Name: "Kakanui", AdjacentSuburbs: [47, 2366] },
          { SuburbId: 2167, Name: "Kurow", AdjacentSuburbs: [2167] },
          { SuburbId: 2368, Name: "Maheno", AdjacentSuburbs: [2368] },
          { SuburbId: 2553, Name: "Moeraki", AdjacentSuburbs: [2553] },
          { SuburbId: 1688, Name: "Ngapara", AdjacentSuburbs: [1688] },
          { SuburbId: 2372, Name: "Oamaru", AdjacentSuburbs: [2371, 2372] },
          { SuburbId: 3059, Name: "Otematata", AdjacentSuburbs: [3059] },
          { SuburbId: 2519, Name: "Palmerston", AdjacentSuburbs: [2519] },
          { SuburbId: 2038, Name: "Pukeuri", AdjacentSuburbs: [2038] },
          { SuburbId: 2036, Name: "Sailors Cutting", AdjacentSuburbs: [2036] },
          { SuburbId: 2371, Name: "South Oamaru", AdjacentSuburbs: [2371, 2372] },
          { SuburbId: 2366, Name: "Taranui", AdjacentSuburbs: [47, 2366] },
          { SuburbId: 3401, Name: "Totara", AdjacentSuburbs: [3401] },
          { SuburbId: 2373, Name: "Waiareka", AdjacentSuburbs: [1727, 2373] },
          { SuburbId: 3465, Name: "Waihemo", AdjacentSuburbs: [3465] },
          { SuburbId: 1727, Name: "Weston", AdjacentSuburbs: [1727, 2373] },
          { SuburbId: 3400, Name: "Windsor", AdjacentSuburbs: [3400] },
          { SuburbId: 3097, Name: "Omarama", AdjacentSuburbs: [3097] },
        ],
      },
      {
        DistrictId: 83,
        Name: "Wanaka",
        Suburbs: [
          { SuburbId: 3193, Name: "Albert Town", AdjacentSuburbs: [2303, 3093, 3193, 3519, 3523] },
          { SuburbId: 2327, Name: "Cardrona", AdjacentSuburbs: [1508, 2327, 2328, 2329, 2330] },
          { SuburbId: 3526, Name: "Cattle flat", AdjacentSuburbs: [2100, 2303, 3520, 3524, 3526] },
          { SuburbId: 3524, Name: "Glendhu Bay", AdjacentSuburbs: [2303, 3193, 3520, 3524, 3526] },
          { SuburbId: 3519, Name: "Hawea Flat", AdjacentSuburbs: [2100, 3093, 3193, 3519, 3523] },
          { SuburbId: 3521, Name: "Hunter Valley", AdjacentSuburbs: [2100, 3521, 3525] },
          { SuburbId: 2100, Name: "Lake Hawea", AdjacentSuburbs: [2100, 3519, 3521, 3525, 3526] },
          { SuburbId: 3493, Name: "Queensberry", AdjacentSuburbs: [3493, 3093] },
          { SuburbId: 1725, Name: "Makarora", AdjacentSuburbs: [1725] },
          { SuburbId: 3522, Name: "Mount Aspiring", AdjacentSuburbs: [3520, 3522] },
          { SuburbId: 3523, Name: "Mount Barker", AdjacentSuburbs: [2303, 3093, 3193, 3519, 3523] },
          { SuburbId: 3525, Name: "The Neck", AdjacentSuburbs: [2100, 3521, 3525, 3526] },
          { SuburbId: 3520, Name: "Treble cone", AdjacentSuburbs: [2303, 3520, 3522, 3524, 3526] },
          { SuburbId: 2303, Name: "Wanaka", AdjacentSuburbs: [2303, 3093, 3193, 3523, 3524] },
          { SuburbId: 3093, Name: "Luggate", AdjacentSuburbs: [3093, 3193, 3493, 3519, 3523] },
        ],
      },
    ],
  },
  {
    LocalityId: 11,
    Name: "Southland",
    Districts: [
      {
        DistrictId: 78,
        Name: "Catlins",
        Suburbs: [
          { SuburbId: 3741, Name: "Curio Bay", AdjacentSuburbs: [3741] },
          { SuburbId: 3645, Name: "Progress Valley", AdjacentSuburbs: [3645] },
          { SuburbId: 3742, Name: "Tokanui", AdjacentSuburbs: [3742, 2438] },
          { SuburbId: 3237, Name: "Waikawa Valley", AdjacentSuburbs: [3237] },
        ],
      },
      {
        DistrictId: 74,
        Name: "Gore",
        Suburbs: [
          { SuburbId: 3651, Name: "Conical Hill", AdjacentSuburbs: [3651] },
          { SuburbId: 1985, Name: "Gore", AdjacentSuburbs: [1985] },
          { SuburbId: 2023, Name: "Mataura", AdjacentSuburbs: [2023] },
          { SuburbId: 3711, Name: "Te Tipua", AdjacentSuburbs: [3711] },
          { SuburbId: 3637, Name: "Waimumu", AdjacentSuburbs: [3637] },
        ],
      },
      {
        DistrictId: 75,
        Name: "Invercargill",
        Suburbs: [
          {
            SuburbId: 3064,
            Name: "Appleby",
            AdjacentSuburbs: [2537, 3064, 3076, 3082, 3137, 3153, 3373, 2052, 2080, 3609],
          },
          {
            SuburbId: 3467,
            Name: "Ascot",
            AdjacentSuburbs: [2533, 3081, 3136, 3138, 3154, 3467, 3468, 3475],
          },
          {
            SuburbId: 3065,
            Name: "Avenal",
            AdjacentSuburbs: [2532, 2534, 3065, 3136, 3153, 3154, 3373, 2052, 3748],
          },
          { SuburbId: 2450, Name: "Bluff", AdjacentSuburbs: [2450] },
          {
            SuburbId: 2052,
            Name: "City Centre",
            AdjacentSuburbs: [2532, 3064, 3065, 3076, 3153, 3154, 3373, 2052, 3609],
          },
          { SuburbId: 2536, Name: "Clifton", AdjacentSuburbs: [2536, 3088] },
          {
            SuburbId: 3076,
            Name: "Georgetown",
            AdjacentSuburbs: [
              2537, 3064, 3076, 3081, 3082, 3137, 3153, 3154, 3373, 2052, 2080, 3609, 3727,
            ],
          },
          {
            SuburbId: 2532,
            Name: "Gladstone",
            AdjacentSuburbs: [2532, 2533, 2534, 3065, 3136, 3138, 3154, 3468, 2052, 3475, 3748],
          },
          {
            SuburbId: 2533,
            Name: "Glengarry",
            AdjacentSuburbs: [
              2532, 2533, 2537, 3081, 3136, 3138, 3153, 3154, 3467, 3468, 3475, 3609,
            ],
          },
          { SuburbId: 3078, Name: "Grasmere", AdjacentSuburbs: [3078, 3372, 3748] },
          { SuburbId: 3678, Name: "Greenhills", AdjacentSuburbs: [3678] },
          {
            SuburbId: 3468,
            Name: "Hargest",
            AdjacentSuburbs: [2532, 2533, 3081, 3136, 3138, 3154, 3467, 3468, 3475],
          },
          {
            SuburbId: 3081,
            Name: "Hawthorndale",
            AdjacentSuburbs: [
              2533, 2537, 3076, 3081, 3138, 3153, 3154, 3467, 3468, 3475, 3609, 3727,
            ],
          },
          {
            SuburbId: 3082,
            Name: "Heidelberg",
            AdjacentSuburbs: [2537, 3064, 3076, 3082, 3088, 3137, 2080, 3609, 3727],
          },
          {
            SuburbId: 3373,
            Name: "Invercargill",
            AdjacentSuburbs: [3064, 3065, 3076, 3137, 3153, 3154, 3373, 2052, 3609],
          },
          { SuburbId: 2140, Name: "Kennington", AdjacentSuburbs: [2140] },
          { SuburbId: 2080, Name: "Kew", AdjacentSuburbs: [3064, 3076, 3082, 3088, 3137, 2080] },
          { SuburbId: 3088, Name: "Kingswell", AdjacentSuburbs: [2536, 3082, 3088, 3137, 2080] },
          { SuburbId: 3654, Name: "Longbush", AdjacentSuburbs: [3654, 2114] },
          { SuburbId: 3340, Name: "Lorneville", AdjacentSuburbs: [3340, 1479] },
          { SuburbId: 3416, Name: "Mabel Bush", AdjacentSuburbs: [3416] },
          { SuburbId: 1479, Name: "Makarewa", AdjacentSuburbs: [3340, 1479] },
          { SuburbId: 2413, Name: "Mataura Island", AdjacentSuburbs: [2413] },
          { SuburbId: 3779, Name: "Mokotua", AdjacentSuburbs: [3779] },
          { SuburbId: 3152, Name: "Myross Bush", AdjacentSuburbs: [3152] },
          { SuburbId: 3793, Name: "New River Ferry", AdjacentSuburbs: [3793] },
          {
            SuburbId: 2537,
            Name: "Newfield",
            AdjacentSuburbs: [2533, 2537, 3064, 3076, 3081, 3082, 3137, 3153, 3609, 3727],
          },
          { SuburbId: 3647, Name: "Omaui", AdjacentSuburbs: [3647] },
          { SuburbId: 3739, Name: "Otahuti", AdjacentSuburbs: [3739] },
          { SuburbId: 1480, Name: "Otatara", AdjacentSuburbs: [1480] },
          {
            SuburbId: 3748,
            Name: "Prestonville",
            AdjacentSuburbs: [3748, 3065, 2532, 3078, 3136, 2534],
          },
          {
            SuburbId: 3153,
            Name: "Richmond",
            AdjacentSuburbs: [
              2533, 2537, 3064, 3065, 3076, 3081, 3137, 3138, 3153, 3154, 3373, 2052, 3475, 3609,
            ],
          },
          {
            SuburbId: 3727,
            Name: "Rockdale",
            AdjacentSuburbs: [3727, 3076, 3081, 3082, 2537, 3652, 3609],
          },
          {
            SuburbId: 3136,
            Name: "Rosedale",
            AdjacentSuburbs: [2532, 2533, 2534, 3065, 3136, 3138, 3154, 3467, 3468, 3475, 3748],
          },
          { SuburbId: 3238, Name: "Roslyn Bush", AdjacentSuburbs: [3238] },
          { SuburbId: 3780, Name: "Ryal Bush", AdjacentSuburbs: [3780, 1484] },
          { SuburbId: 3652, Name: "Seaward Bush", AdjacentSuburbs: [3652, 3727] },
          {
            SuburbId: 3137,
            Name: "Strathern",
            AdjacentSuburbs: [2537, 3064, 3076, 3082, 3088, 3137, 3153, 3373, 2080, 3609],
          },
          { SuburbId: 2535, Name: "Tisbury", AdjacentSuburbs: [2452, 2535] },
          {
            SuburbId: 3609,
            Name: "Turnbull Thomson Park",
            AdjacentSuburbs: [
              3609, 3081, 2052, 2533, 3373, 3076, 2537, 3064, 3153, 3137, 3475, 3154, 3082, 3727,
            ],
          },
          { SuburbId: 3539, Name: "Tussock Creek", AdjacentSuburbs: [3539] },
          { SuburbId: 2534, Name: "Waikiwi", AdjacentSuburbs: [2532, 2534, 3065, 3136, 3748] },
          { SuburbId: 3474, Name: "Wallacetown", AdjacentSuburbs: [1478, 3474] },
          {
            SuburbId: 3475,
            Name: "Waverley",
            AdjacentSuburbs: [2532, 2533, 3081, 3136, 3138, 3153, 3154, 3467, 3468, 3475, 3609],
          },
          { SuburbId: 3372, Name: "West Plains", AdjacentSuburbs: [3078, 3372] },
          {
            SuburbId: 3154,
            Name: "Windsor",
            AdjacentSuburbs: [
              2532, 2533, 3065, 3076, 3081, 3136, 3138, 3153, 3154, 3373, 3467, 3468, 2052, 3475,
              3609,
            ],
          },
          { SuburbId: 2452, Name: "Woodend", AdjacentSuburbs: [2452, 2535] },
        ],
      },
      {
        DistrictId: 73,
        Name: "Southland",
        Suburbs: [
          { SuburbId: 3672, Name: "Ashers", AdjacentSuburbs: [3672] },
          { SuburbId: 2081, Name: "Athol", AdjacentSuburbs: [2081] },
          { SuburbId: 3369, Name: "Balfour", AdjacentSuburbs: [3369] },
          { SuburbId: 3776, Name: "Branxholme", AdjacentSuburbs: [3776] },
          { SuburbId: 2445, Name: "Browns", AdjacentSuburbs: [2445] },
          { SuburbId: 3342, Name: "Centre Bush", AdjacentSuburbs: [2446, 3342] },
          { SuburbId: 3069, Name: "Colac Bay", AdjacentSuburbs: [3069] },
          { SuburbId: 3641, Name: "Dacre", AdjacentSuburbs: [3641] },
          { SuburbId: 3383, Name: "Dipton", AdjacentSuburbs: [3383] },
          { SuburbId: 2421, Name: "Dipton West", AdjacentSuburbs: [2421] },
          { SuburbId: 2420, Name: "Drummond", AdjacentSuburbs: [2420] },
          { SuburbId: 3801, Name: "Eastern Bush", AdjacentSuburbs: [3801] },
          { SuburbId: 2428, Name: "Edendale", AdjacentSuburbs: [2428] },
          { SuburbId: 3746, Name: "Feldwick", AdjacentSuburbs: [3746] },
          { SuburbId: 3616, Name: "Fortrose", AdjacentSuburbs: [3616] },
          { SuburbId: 3417, Name: "Garston", AdjacentSuburbs: [3417] },
          { SuburbId: 3635, Name: "Heddon Bush", AdjacentSuburbs: [3635] },
          { SuburbId: 3642, Name: "Hedgehope", AdjacentSuburbs: [3642] },
          { SuburbId: 3341, Name: "Isla Bank", AdjacentSuburbs: [3341] },
          { SuburbId: 2446, Name: "Limehills", AdjacentSuburbs: [2446, 3342] },
          { SuburbId: 2447, Name: "Lumsden", AdjacentSuburbs: [2447] },
          { SuburbId: 2540, Name: "Manapouri", AdjacentSuburbs: [2540] },
          { SuburbId: 2440, Name: "Mossburn", AdjacentSuburbs: [2440] },
          { SuburbId: 1473, Name: "Nightcaps", AdjacentSuburbs: [1473] },
          { SuburbId: 2084, Name: "Oban", AdjacentSuburbs: [2084] },
          { SuburbId: 1472, Name: "Ohai", AdjacentSuburbs: [1472] },
          { SuburbId: 1476, Name: "Orepuki", AdjacentSuburbs: [1476] },
          { SuburbId: 3765, Name: "Otama", AdjacentSuburbs: [3765] },
          { SuburbId: 3643, Name: "Otapiri", AdjacentSuburbs: [3643] },
          { SuburbId: 1474, Name: "Otautau", AdjacentSuburbs: [1474] },
          { SuburbId: 3644, Name: "Pine Bush", AdjacentSuburbs: [3644] },
          { SuburbId: 2448, Name: "Riversdale", AdjacentSuburbs: [2448] },
          { SuburbId: 1477, Name: "Riverton", AdjacentSuburbs: [1477] },
          { SuburbId: 1484, Name: "Ryal Bush", AdjacentSuburbs: [1484, 3780] },
          { SuburbId: 3636, Name: "South Hillend", AdjacentSuburbs: [3636] },
          { SuburbId: 2028, Name: "Stewart Island", AdjacentSuburbs: [2028] },
          { SuburbId: 2539, Name: "Te Anau", AdjacentSuburbs: [2539] },
          { SuburbId: 3714, Name: "Te Tipua", AdjacentSuburbs: [3714] },
          { SuburbId: 1482, Name: "Thornbury", AdjacentSuburbs: [1482] },
          { SuburbId: 2438, Name: "Tokanui", AdjacentSuburbs: [2438, 3742] },
          { SuburbId: 1475, Name: "Tuatapere", AdjacentSuburbs: [1475] },
          { SuburbId: 3466, Name: "Waianiwa", AdjacentSuburbs: [3466] },
          { SuburbId: 2449, Name: "Waikaia", AdjacentSuburbs: [2449] },
          { SuburbId: 3760, Name: "Waikouro", AdjacentSuburbs: [3760] },
          { SuburbId: 3630, Name: "Waimahaka", AdjacentSuburbs: [3630] },
          { SuburbId: 3659, Name: "Waimatuku", AdjacentSuburbs: [3659] },
          { SuburbId: 2426, Name: "Waituna", AdjacentSuburbs: [2426] },
          { SuburbId: 1478, Name: "Wallacetown", AdjacentSuburbs: [1478, 3474] },
          {
            SuburbId: 3138,
            Name: "Waverley",
            AdjacentSuburbs: [2532, 2533, 3081, 3136, 3138, 3153, 3154, 3467, 3468, 3475],
          },
          { SuburbId: 1485, Name: "Winton", AdjacentSuburbs: [1485] },
          { SuburbId: 2114, Name: "Woodlands", AdjacentSuburbs: [2114, 3654] },
          { SuburbId: 3752, Name: "Woodlaw", AdjacentSuburbs: [3752] },
          { SuburbId: 2112, Name: "Wyndham", AdjacentSuburbs: [2112] },
        ],
      },
    ],
  },
  { LocalityId: 100, Name: "All", Districts: [] },
];
