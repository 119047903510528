exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-orders-tsx": () => import("./../../../src/pages/account/orders.tsx" /* webpackChunkName: "component---src-pages-account-orders-tsx" */),
  "component---src-pages-account-pro-orders-tsx": () => import("./../../../src/pages/account/pro-orders.tsx" /* webpackChunkName: "component---src-pages-account-pro-orders-tsx" */),
  "component---src-pages-account-property-tsx": () => import("./../../../src/pages/account/property.tsx" /* webpackChunkName: "component---src-pages-account-property-tsx" */),
  "component---src-pages-account-qr-code-tsx": () => import("./../../../src/pages/account/qrCode.tsx" /* webpackChunkName: "component---src-pages-account-qr-code-tsx" */),
  "component---src-pages-admin-order-log-tsx": () => import("./../../../src/pages/admin/order-log.tsx" /* webpackChunkName: "component---src-pages-admin-order-log-tsx" */),
  "component---src-pages-code-tsx": () => import("./../../../src/pages/code.tsx" /* webpackChunkName: "component---src-pages-code-tsx" */),
  "component---src-pages-payment-successful-tsx": () => import("./../../../src/pages/paymentSuccessful.tsx" /* webpackChunkName: "component---src-pages-payment-successful-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-sell-tsx": () => import("./../../../src/pages/sell.tsx" /* webpackChunkName: "component---src-pages-sell-tsx" */),
  "component---src-templates-blog-inner-template-tsx": () => import("./../../../src/templates/blogInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-inner-template-tsx" */),
  "component---src-templates-blog-landing-template-tsx": () => import("./../../../src/templates/blogLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-landing-template-tsx" */),
  "component---src-templates-browse-properties-template-tsx": () => import("./../../../src/templates/browsePropertiesTemplate.tsx" /* webpackChunkName: "component---src-templates-browse-properties-template-tsx" */),
  "component---src-templates-careers-template-tsx": () => import("./../../../src/templates/careersTemplate.tsx" /* webpackChunkName: "component---src-templates-careers-template-tsx" */),
  "component---src-templates-contact-template-tsx": () => import("./../../../src/templates/contactTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/homeTemplate.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-kiosk-template-tsx": () => import("./../../../src/templates/kioskTemplate.tsx" /* webpackChunkName: "component---src-templates-kiosk-template-tsx" */),
  "component---src-templates-package-customise-template-tsx": () => import("./../../../src/templates/packageCustomiseTemplate.tsx" /* webpackChunkName: "component---src-templates-package-customise-template-tsx" */),
  "component---src-templates-package-template-tsx": () => import("./../../../src/templates/packageTemplate.tsx" /* webpackChunkName: "component---src-templates-package-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-privacy-policy-template-tsx": () => import("./../../../src/templates/privacyPolicyTemplate.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-template-tsx" */),
  "component---src-templates-property-inner-template-tsx": () => import("./../../../src/templates/propertyInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-property-inner-template-tsx" */),
  "component---src-templates-service-providers-template-tsx": () => import("./../../../src/templates/serviceProvidersTemplate.tsx" /* webpackChunkName: "component---src-templates-service-providers-template-tsx" */),
  "component---src-templates-team-inner-template-tsx": () => import("./../../../src/templates/teamInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-team-inner-template-tsx" */),
  "component---src-templates-team-landing-template-tsx": () => import("./../../../src/templates/teamLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-team-landing-template-tsx" */),
  "component---src-templates-testimonial-landing-template-tsx": () => import("./../../../src/templates/testimonialLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-testimonial-landing-template-tsx" */)
}

