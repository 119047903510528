import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT, colorsRGB } from "@util/constants";
import { Maybe, SanityBlockContent } from "@graphql-types";
import React, { CSSProperties, createElement } from "react";

import { Button } from "@global";
import { ColorRGB } from "@util/types";
import { P } from "@util/standard";
import PortableText from "@sanity/block-content-to-react";
import styled from "styled-components";
import { urlForImageRef } from "@lib/sanityClient";

const PortableTextStyled = styled.div<{
  maxWidth?: number;
  margin?: string;
  tabletMargin?: string;
  mobileMargin?: string;
  textAlign?: CSSProperties["textAlign"];
  type?: ColorRGB; //Go, Pro, Know, White
}>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 20px;
    margin-bottom: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin-bottom: 5px;
    list-style: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    margin: 0 0 10px 20px;

    &.list--inline {
      margin-left: 0;
    }
  }

  ul {
    list-style: disc outside;

    ul {
      list-style: circle outside;

      ul {
        list-style: square outside;
      }
    }
  }

  a:not(.buttonLink) {
    border-bottom: 1px solid currentColor;

    &:hover,
    &:active,
    &:focus {
      opacity: 0.8;
    }
  }

  img {
    height: auto;
    width: auto;
    max-width: 100%;
    margin: 25px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .highlight {
    display: inline;
    border-radius: 100px;
    padding: 0 0.4em;
    line-height: 1.6;

    ${({ type }) =>
      type
        ? `
      background: ${colorsRGB[type](0.4)};
    `
        : `
      background: ${colorsRGB.main(0.15)};
    `}
  }

  ${({ tabletMargin }) =>
    tabletMargin &&
    `
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      margin: ${tabletMargin};
    }
  `}

  ${({ mobileMargin }) =>
    mobileMargin &&
    `
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      margin: ${mobileMargin};
    }
  `}
`;

const IframeWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.2%;

  iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

interface Props {
  data?: Maybe<SanityBlockContent> | undefined;
  maxWidth?: number;
  isHeading?: boolean;
  margin?: string;
  tabletMargin?: string;
  mobileMargin?: string;
  textAlign?: CSSProperties["textAlign"];
  type?: ColorRGB;
  groqData?: any;
}

const highlight = (props: any) => {
  return <span className="highlight">{props.children}</span>;
};

const iframe = (props: any) => {
  if (!props?.node?.iframe) return null;

  const propStr = [props.node.iframe.split("<iframe")[1].split(">")[0]];

  if (!propStr) return null;

  const iframeStr = `<iframe ${propStr}></iframe>`;

  return <IframeWrapper dangerouslySetInnerHTML={{ __html: iframeStr }} />;
};

const link = (props: any) => {
  if (!props?.node) return null;

  return <Button {...props.node} />;
};

const BlocksContent = ({
  data,
  maxWidth,
  isHeading,
  margin,
  mobileMargin,
  tabletMargin,
  textAlign,
  type,
  groqData,
}: Props) => {
  if (data?._rawContent == null && groqData == null && data?.content == null) {
    return null;
  }

  const _rawContent = groqData ? groqData : data?._rawContent ?? data?.content;

  const block = (props: any) => {
    const { style = "normal" } = props.node;
    const tag = style;
    if (/^h\d/.test(tag)) {
      if (!isHeading && tag.indexOf("h1") > -1) {
        return <h2 className={tag}>{props.children}</h2>;
      }
      return createElement(tag, {}, props.children);
    }

    if (!props.children[0]) return null;

    return <P>{props.children}</P>;
  };

  const ImageRenderer = (props: any) => {
    return <img src={urlForImageRef(props.node.asset).url() as string} />;
  };

  return (
    <PortableTextStyled
      maxWidth={maxWidth}
      margin={margin}
      mobileMargin={mobileMargin}
      tabletMargin={tabletMargin}
      textAlign={textAlign}
      type={type}
      className="block-content"
    >
      <PortableText
        blocks={_rawContent}
        serializers={{
          marks: { highlight },
          types: { block, imageWithMeta: ImageRenderer, iframe, link },
        }}
        imageOptions={{ auto: "format", fit: "fill" }}
        projectId={process.env.SANITY_PROJECT_ID}
        dataset={process.env.GATSBY_SANITY_DATASET ?? "development"}
      />
    </PortableTextStyled>
  );
};

export default BlocksContent;
