import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const FeaturedPostsQuery = () => {
  const posts: Query = useStaticQuery(graphql`
    {
      allSanityBlogInner(limit: 3, sort: { fields: _updatedAt, order: DESC }) {
        nodes {
          ...sanityFeaturedBlogInner
        }
      }
      allSanityProperty(
        limit: 3
        sort: { order: DESC, fields: _createdAt }
        filter: { status: { ne: "prospect" } }
      ) {
        nodes {
          ...sanityFeaturedProperty
        }
      }
    }
  `);

  return posts;
};

export default FeaturedPostsQuery;
