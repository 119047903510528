import React from "react";
import styled from "styled-components";

import { Maybe } from "@graphql-types";
import { PropertySvg } from "@util/svg";

interface Props {
  bed?: Maybe<string | number>;
  bath?: Maybe<string | number>;
  scale?: number;
}

const Span = styled.span<{ scale?: number }>`
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;

  span {
    display: inline-block;
    margin-right: 6px;
    vertical-align: middle;
  }
  svg {
    vertical-align: middle;
    ${({ scale }) =>
      scale &&
      `
      transform: scale(${scale});
    `}
  }
`;

const PropertyIcons = ({ bed, bath, scale }: Props) => {
  const icons = [
    {
      number: bed ?? "",
      name: "bed",
    },
    {
      number: bath ?? "",
      name: "bath",
    },
  ].filter(obj => obj.number !== "");

  return (
    <>
      {icons.map(icon => {
        if (icon.number === "" || icon.number === "0") return null;
        return (
          <Span key={icon.name} scale={scale}>
            <span>{icon.number}</span>
            <PropertySvg color="current" type={icon.name} />
          </Span>
        );
      })}
    </>
  );
};

export default PropertyIcons;
