import { Container, GridContainer } from "@util/standard";
import React, { useState } from "react";
import { emailValidator } from "@util/helper";
import AddressInput from "./addressInput";
import { Button, Select, TextField, Toggle } from "@global";
import { LogoType } from "@util/types";
import { toast } from "react-toastify";
import styled from "styled-components";
import { defProps } from "@util/inputs";
import { createDocument } from "@util/firebase";
import { useStore } from "@state/store";

interface Props {
  type?: LogoType;
  tierArr?: string[];
}

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: {
    unit: "",
    street: "",
    suburb: "",
    city: "",
    region: "",
  },
  time: "",
  packageTier: "",
  message: "",
  leadSource: "",
  newsletter: true,
};

const EnquireForm = ({ type, tierArr }: Props) => {
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const user = useStore(s => s.user);

  const handleChangeInput = (key: string, value: unknown) => {
    setInputs(p => ({
      ...p,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    if (
      !emailValidator(inputs.email) ||
      !inputs.firstName ||
      !inputs.lastName ||
      !inputs.message ||
      !inputs.phone
    ) {
      toast.error("Please make sure all fields are entered and try again!");
      return;
    }

    const formattedData = {
      ...inputs,
      uid: user?.id ?? "",
      createdAt: new Date().toISOString(),
    };

    setLoading(true);
    const docId = await createDocument("enquiry", formattedData);
    setLoading(false);

    if (!docId) {
      toast.error("Error on send Enquiry, please try again");
      return;
    }
    setClickedSubmit(true);
  };

  if (clickedSubmit)
    return (
      <>
        <h3>
          Thank you for your message.
          <br />
          We will be in contact.
        </h3>
        <Button linkText="View Selling Advice" internalSlug={`/homesell-know/selling`} />
      </>
    );
  return (
    <Wrapper>
      <h4>Fill out your details and someone will be in touch. </h4>
      <div className="inputs">
        <GridContainer repeat={2} gap="15px" width="100%">
          <TextField
            {...defProps.firstName}
            onChange={e => handleChangeInput("firstName", e.target.value)}
            value={inputs.firstName}
            required
          />
          <TextField
            {...defProps.lastName}
            onChange={e => handleChangeInput("lastName", e.target.value)}
            value={inputs.lastName}
            required
          />
        </GridContainer>
        <TextField
          {...defProps.email}
          onChange={e => handleChangeInput("email", e.target.value)}
          value={inputs.email}
          required
        />
        <TextField
          {...defProps.phone}
          onChange={e => handleChangeInput("phone", e.target.value)}
          value={inputs.phone}
          required
        />
        <GridContainer repeat={1} width="100%">
          <p style={{ margin: "0 0 5px" }}>Where is your property located?</p>
          <AddressInput
            theme="filled"
            inputs={inputs.address}
            onChange={val => handleChangeInput("address", val)}
          />
        </GridContainer>
        <Toggle
          label="When are you looking at selling?"
          id="time"
          value={inputs.time}
          onChange={val => handleChangeInput("time", val)}
          options={[
            { title: "ASAP", value: "asap" },
            { title: "Next few weeks", value: "weeks" },
            { title: "Next few months", value: "months" },
            { title: "6 - 12 months", value: "years" },
            { title: "Just gathering info", value: "other" },
          ]}
        />
        {tierArr && (
          <Select
            label={`What HomeSell ${type} marketing package are you interested in?`}
            theme="filled"
            hideEmptyOption
            value={inputs.packageTier}
            onChange={e => handleChangeInput("packageTier", e.target.value)}
            id="packageTier"
            options={[
              ...tierArr.map(tier => ({
                title: `Homesell ${type} ${tier}`,
                value: `${type}-${tier}`,
              })),
              { title: "Unsure", value: `${type}-unsure` },
            ]}
          />
        )}
        <TextField
          label="Provide any further information:"
          theme="filled"
          id="message"
          placeholder="Message"
          onChange={e => handleChangeInput("message", e.target.value)}
          value={inputs.message}
          rows={4}
          inputType="textarea"
        />
        <Select
          label="How did you hear about HomeSell?"
          theme="filled"
          id="leadSource"
          hideEmptyOption
          placeholder="Please select"
          onChange={e => handleChangeInput("leadSource", e.target.value)}
          value={inputs.leadSource}
          options={[
            { title: "Used HomeSell before", value: "0" },
            { title: "Word of mouth (friends, colleagues or family)", value: "1" },
            { title: "Saw a HomeSell street sign", value: "2" },
            { title: "Viewed a HomeSell listing for sale", value: "3" },
            { title: "Web search (eg google, bing)", value: "4" },
            { title: "Trade Me", value: "5" },
            { title: "Realestate.co.nz", value: "6" },
            { title: "Oneroof", value: "7" },
            { title: "Homes.co.nz", value: "8" },
            { title: "Link from another website", value: "9" },
            { title: "Home show", value: "10" },
            { title: "Talked to a HomeSell Pro agent", value: "11" },
            { title: "Saw a HomeSell vehicle", value: "12" },
            { title: "Heard about it in the media (radio, TV, newspaper)", value: "13" },
            { title: "Radio or TV advertising", value: "14" },
            { title: "Saw a post on Facebook/Instagram", value: "15" },
            { title: "Other", value: "16" },
          ]}
        />
        <Container>
          <input
            type="checkbox"
            checked={inputs.newsletter}
            onChange={() => handleChangeInput("newsletter", !inputs.newsletter)}
            id="newsletter"
          />
          <label htmlFor="newsletter">Sign-up to our monthly newsletter</label>
        </Container>
        <Button loading={loading} onClick={handleSubmit} theme="base" linkText="Submit" />
      </div>
    </Wrapper>
  );
};

export default EnquireForm;

const Wrapper = styled.div`
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;
