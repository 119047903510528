import { SanityOnboarding } from "@graphql-types";
import { sanityClient } from "@lib/sanityClient";
import { User } from "@state/types";
import { updateDocument } from "@util/firebase";
import { createContext, useContext } from "react";
import { toast } from "react-toastify";
import firebase from "gatsby-plugin-firebase";

export type PropertyOnboardingState = typeof initialState;

interface Context {
  inputs: PropertyOnboardingState;
  openTab: number;
  changeInput: (field: string, value: any) => void;
  changeTab: (dir: "next" | "prev" | number) => void;
  pageData: SanityOnboarding;
}

export const TABS = [
  { title: "Property Information", id: "info" },
  { title: "Property Description", id: "description" },
  { title: "Seller Information", id: "seller" },
];

export const initialState = {
  address: {
    suburb: "",
    city: "",
    district: "",
    region: "",
    unit: "",
    streetNumber: "",
    street: "",
  },
  propertyCategory: "",
  propertyType: "",
  details: {
    bed: 0,
    bath: 0,
    toilet: 0,
    ensuite: 0,
    floorArea: 0,
    floorAreaUnit: "m2",
    landArea: 0,
    landAreaUnit: "m2",
    parking: "",
    lounge: 0,
    studies: 0,
    incomePotential: false,
  },
  saleDetails: {
    type: "askingPrice",
    saleDate: "",
    auctionTime: "",
    askingPrice: "",
    expectedSalePrice: "",
    rateableValue: "",
  },
  mainSeller: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    privatePhone: "",
  },
  secondSeller: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    privatePhone: "",
  },
  postalAddress: {
    suburb: "",
    city: "",
    region: "",
    unit: "",
    street: "",
  },
  description: {
    heading: "",
    userInput: "",
    chatGPTResponse: "",
    brochureText: "",
    type: "myself",
  },
};

export const FormContext = createContext<Context>({
  inputs: initialState,
  openTab: 0,
  changeTab: () => {},
  changeInput: () => {},
  pageData: null,
} as unknown as Context);

export const useFormContext = () => useContext(FormContext);

export const submitToSanity = async (
  inputs: PropertyOnboardingState,
  userData: User | null | undefined,
  data: SanityOnboarding,
) => {
  const foundPropType = data.propertyTypes?.find(el => el?._key === inputs.propertyCategory);
  const { description, details, saleDetails, postalAddress, mainSeller, secondSeller } = inputs;

  if (!userData) {
    toast.error("You need to be authenticated to submit the form");
    return null;
  }

  const propertyData = {
    _type: "property",
    status: "prospect",
    vendor: "private",
    address: {
      ...inputs.address,
    },
    propertyCategory: foundPropType?.title ?? null,
    propertyType:
      foundPropType?.propertyTypes?.find(el => el?._key === inputs.propertyType)?.title ?? null,
    details: {
      ...details,
      bed: Number(details.bed),
      bath: Number(details.bath),
      toilet: Number(details.toilet),
      ensuite: Number(details.ensuite),
      floorArea: Number(details.floorArea),
      landArea: Number(details.landArea),
    },
    saleDetails: {
      type: saleDetails.type,
      date: saleDetails.saleDate,
      time: saleDetails.auctionTime,
      price: Number(saleDetails.askingPrice),
      expectedSalePrice: Number(saleDetails.expectedSalePrice),
    },
    heading: description.heading,
    description:
      description.type === "chatGPT" ? description.chatGPTResponse : description.userInput,
    brochureDescription: description.brochureText,
    sellerDetails: {
      mainFirstName: mainSeller.firstName,
      mainLastName: mainSeller.lastName,
      mainEmail: mainSeller.email,
      mainPhone: mainSeller.phone,
      mainPrivatePhone: mainSeller.privatePhone,
      secondFirstName: secondSeller.firstName,
      secondLastName: secondSeller.lastName,
      secondEmail: secondSeller.email,
      secondPhone: secondSeller.phone,
      secondPrivatePhone: secondSeller.privatePhone,
      postalAddress: {
        ...postalAddress,
      },
    },
  };
  try {
    // Save the property to Sanity
    const docRes = await sanityClient.create(propertyData);
    await sanityClient
      .patch(docRes._id)
      .set({ propertyId: docRes._id, userId: userData.id })
      .commit();
    await updateDocument("users", userData.id, {
      propertyIds: firebase.firestore.FieldValue.arrayUnion(docRes._id),
    });

    toast.success(
      "Your onboarding form has been submitted! You will be redirected to your account details",
    );
    return docRes._id as string;
  } catch (error) {
    console.error("Error saving property to Sanity:", error);
    toast.error("Failed to save the property. Please try again.");
    return null;
  }
};
