import React, { CSSProperties } from "react";
import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { NavigationOptions, PaginationOptions, Swiper as SwiperType } from "swiper/types";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { colors, colorsRGB, MOBILE_BREAKPOINT, SMALL_LAPTOP_BREAKPOINT } from "@util/constants";

export const SwiperStyle = styled(Swiper)<{
  alignItems?: CSSProperties["alignItems"];
  isCustomNav?: boolean;
  isBottomNav?: boolean;
}>`
  ${({ alignItems }) =>
    alignItems &&
    `
    .swiper-wrapper{
      align-items: ${alignItems};
    }
  `}

  .swiper-button-next, 
  .swiper-button-prev {
    width: 40px;
    height: 40px;

    &:after {
      width: 40px;
      height: 40px;
      content: "";
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20C38 29.9411 29.9411 38 20 38ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1782C17.04 12.7539 17.6705 12.6966 18.0948 13.0502L23.7184 17.7365C24.6779 18.5361 24.6779 20.0098 23.7184 20.8094L18.0948 25.4957C17.6705 25.8493 17.04 25.7919 16.6864 25.3677C16.3328 24.9434 16.3902 24.3128 16.8144 23.9593L22.438 19.2729L16.8144 14.5866C16.3902 14.233 16.3328 13.6025 16.6864 13.1782Z' fill='%23133456'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transition: background-image 0.3s ease;
    }

    &:hover:after {
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.95431 0 0 8.9543 0 20Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38.5C9.78273 38.5 1.5 30.2173 1.5 20C1.5 9.78273 9.78273 1.5 20 1.5C30.2173 1.5 38.5 9.78273 38.5 20C38.5 30.2173 30.2173 38.5 20 38.5ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1782C17.04 12.7539 17.6705 12.6966 18.0948 13.0502L23.7184 17.7365C24.6779 18.5361 24.6779 20.0098 23.7184 20.8094L18.0948 25.4957C17.6705 25.8493 17.04 25.7919 16.6864 25.3677C16.3328 24.9434 16.3902 24.3128 16.8144 23.9593L22.438 19.2729L16.8144 14.5866C16.3902 14.233 16.3328 13.6025 16.6864 13.1782Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  .swiper-button-prev {
    left: 8%;
    &:after {
      transform: rotate(180deg);
    }
  }

  .swiper-button-next {
    right: 8%;
  }

  .swiper-pagination-bullet {
    background: ${colors.main};
    opacity: 0.2;

    &.swiper-pagination-bullet-active {
      background: ${colors.main};
      opacity: 1;
    }
  }

  ${({ isBottomNav, isCustomNav }) =>
    isBottomNav
      ? `
    && {
      padding: 0 0 80px;
    }

    .swiper-button-next, 
    .swiper-button-prev{
      top: initial;
      bottom: 0;
    }

    .swiper-button-prev{
      left: 0;
    }
    .swiper-button-next{
      right: auto;
      left: 50px;

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.95431 0 0 8.9543 0 20Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38.5C9.78273 38.5 1.5 30.2173 1.5 20C1.5 9.78273 9.78273 1.5 20 1.5C30.2173 1.5 38.5 9.78273 38.5 20C38.5 30.2173 30.2173 38.5 20 38.5ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1782C17.04 12.7539 17.6705 12.6966 18.0948 13.0502L23.7184 17.7365C24.6779 18.5361 24.6779 20.0098 23.7184 20.8094L18.0948 25.4957C17.6705 25.8493 17.04 25.7919 16.6864 25.3677C16.3328 24.9434 16.3902 24.3128 16.8144 23.9593L22.438 19.2729L16.8144 14.5866C16.3902 14.233 16.3328 13.6025 16.6864 13.1782Z' fill='white'/%3E%3C/svg%3E%0A");
      }
  
      &:hover:after{
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20C38 29.9411 29.9411 38 20 38ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1782C17.04 12.7539 17.6705 12.6966 18.0948 13.0502L23.7184 17.7365C24.6779 18.5361 24.6779 20.0098 23.7184 20.8094L18.0948 25.4957C17.6705 25.8493 17.04 25.7919 16.6864 25.3677C16.3328 24.9434 16.3902 24.3128 16.8144 23.9593L22.438 19.2729L16.8144 14.5866C16.3902 14.233 16.3328 13.6025 16.6864 13.1782Z' fill='%23133456'/%3E%3C/svg%3E%0A");
      }
    }
  `
      : `
    @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
      && {
        padding: ${isCustomNav ? `0` : `0 0 80px`};
      }

      .swiper-button-next, 
      .swiper-button-prev{
        top: initial;
        bottom: 0;
      }

      .swiper-button-prev{
        left: 0;
      }
      .swiper-button-next{
        right: auto;
        left: 50px;

        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.95431 0 0 8.9543 0 20Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38.5C9.78273 38.5 1.5 30.2173 1.5 20C1.5 9.78273 9.78273 1.5 20 1.5C30.2173 1.5 38.5 9.78273 38.5 20C38.5 30.2173 30.2173 38.5 20 38.5ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1782C17.04 12.7539 17.6705 12.6966 18.0948 13.0502L23.7184 17.7365C24.6779 18.5361 24.6779 20.0098 23.7184 20.8094L18.0948 25.4957C17.6705 25.8493 17.04 25.7919 16.6864 25.3677C16.3328 24.9434 16.3902 24.3128 16.8144 23.9593L22.438 19.2729L16.8144 14.5866C16.3902 14.233 16.3328 13.6025 16.6864 13.1782Z' fill='white'/%3E%3C/svg%3E%0A");
        }
    
        &:hover:after{
          background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20C38 29.9411 29.9411 38 20 38ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1782C17.04 12.7539 17.6705 12.6966 18.0948 13.0502L23.7184 17.7365C24.6779 18.5361 24.6779 20.0098 23.7184 20.8094L18.0948 25.4957C17.6705 25.8493 17.04 25.7919 16.6864 25.3677C16.3328 24.9434 16.3902 24.3128 16.8144 23.9593L22.438 19.2729L16.8144 14.5866C16.3902 14.233 16.3328 13.6025 16.6864 13.1782Z' fill='%23133456'/%3E%3C/svg%3E%0A");
        }
      }
    }

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      && {
        padding: 0 0 80px;
      }
    }
  `}
`;

interface Props {
  slides?: Array<JSX.Element | null> | null;
  slideId?: string | null;
  slidesPerView?: number | "auto";
  loop?: boolean;
  navigation?: boolean | NavigationOptions;
  autoHeight?: boolean;
  alignItems?: CSSProperties["alignItems"];
  spaceBetween?: number;
  onInit?: (swiper: SwiperType) => any;
  isCustomNav?: boolean;
  isBottomNav?: boolean;
  allowTouchMove?: boolean;
  pagination?: boolean | PaginationOptions;
}

export default ({
  slides,
  slideId,
  slidesPerView = 3,
  loop = false,
  navigation = false,
  autoHeight = false,
  alignItems,
  spaceBetween = 50,
  onInit,
  isCustomNav,
  isBottomNav,
  allowTouchMove = true,
  pagination = false,
}: Props) => {
  if (!slides || slides.length < 1) return null;

  return (
    <SwiperStyle
      onInit={onInit}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      modules={[Navigation, Pagination]}
      pagination={pagination}
      navigation={navigation}
      autoHeight={autoHeight}
      loop={loop}
      alignItems={alignItems}
      isCustomNav={isCustomNav}
      isBottomNav={isBottomNav}
      allowTouchMove={allowTouchMove}
    >
      {slides.map((slide, i) => {
        return <SwiperSlide key={`${slideId}-${i}`}>{slide}</SwiperSlide>;
      })}
    </SwiperStyle>
  );
};
