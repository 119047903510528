import React, { useState } from "react";
import styled from "styled-components";
import { AddressInput, BlocksContent, TextField } from "@global";
import { useFormContext } from "./utils";
import { Container, GridContainer } from "@util/standard";

const SellerTab = () => {
  const { pageData, inputs, changeInput } = useFormContext();
  const [addSecondContact, setAddSecondContact] = useState(false);

  return (
    <Wrapper className="tab-wrapper">
      <BlocksContent data={pageData.personalInformationHeading} />

      <GridContainer repeat={2} width="100%" gap="30px" margin="20px 0 0 0 ">
        <TextField
          id="firstName"
          label="First Name"
          value={inputs.mainSeller.firstName}
          onChange={e => changeInput("mainSeller.firstName", e.target.value)}
        />
        <TextField
          id="lastName"
          label="Last Name"
          value={inputs.mainSeller.lastName}
          onChange={e => changeInput("mainSeller.lastName", e.target.value)}
        />
      </GridContainer>
      <GridContainer repeat={1} gap="30px" margin="30px 0 0 0 ">
        <TextField
          id="email"
          label="Email"
          type="email"
          value={inputs.mainSeller.email}
          onChange={e => changeInput("mainSeller.email", e.target.value)}
          helperText={"We will use this email for prospective buyers to get in touch"}
        />
        <TextField
          id="phone"
          label="Primary Phone Number"
          helperText="We will use this number for prospective buyers to get in touch"
          type="tel"
          value={inputs.mainSeller.phone}
          onChange={e => changeInput("mainSeller.phone", e.target.value)}
        />
        <TextField
          id="privatePhone"
          label="Secondary Phone Number"
          helperText="Private number"
          type="tel"
          value={inputs.mainSeller.privatePhone}
          onChange={e => changeInput("mainSeller.privatePhone", e.target.value)}
        />
      </GridContainer>
      <button className="add-second" onClick={() => setAddSecondContact(p => !p)}>
        {addSecondContact ? "Remove second contact" : "Add second contact?"}
      </button>
      {addSecondContact && (
        <>
          <p className="subtitle">Secondary Contact</p>
          <GridContainer repeat={2} width="100%" gap="30px">
            <TextField
              id="firstName2"
              label="First Name"
              value={inputs.secondSeller.firstName}
              onChange={e => changeInput("secondSeller.firstName", e.target.value)}
            />
            <TextField
              id="lastName2"
              label="Last Name"
              value={inputs.secondSeller.lastName}
              onChange={e => changeInput("secondSeller.lastName", e.target.value)}
            />
          </GridContainer>

          <GridContainer repeat={1} gap="30px" margin="30px 0 0 0 ">
            <TextField
              id="email2"
              label="Email"
              type="email"
              value={inputs.secondSeller.email}
              onChange={e => changeInput("secondSeller.email", e.target.value)}
            />
            <TextField
              id="phone2"
              label="Primary Phone Number"
              helperText="We will use this number for prospective buyers to get in touch"
              type="tel"
              value={inputs.secondSeller.phone}
              onChange={e => changeInput("secondSeller.phone", e.target.value)}
            />
            <TextField
              id="privatePhone2"
              label="Secondary Phone Number"
              helperText="Private number"
              type="tel"
              value={inputs.secondSeller.privatePhone}
              onChange={e => changeInput("secondSeller.privatePhone", e.target.value)}
            />
          </GridContainer>
        </>
      )}
      <hr></hr>
      <Container margin="0 0 30px">
        <BlocksContent data={pageData.addressHeading} />
      </Container>
      <AddressInput
        showLabel
        inputs={inputs.postalAddress}
        onChange={data => changeInput("postalAddress", data)}
      />
    </Wrapper>
  );
};

export default SellerTab;

const Wrapper = styled.div`
  .add-second {
    margin: 50px 0 0;
    text-decoration: underline;
    font-weight: 700;
  }

  .subtitle {
    font-size: 1.25em;
    font-style: italic;
    margin: 35px 0;
  }
`;
