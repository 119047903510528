import React, { useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "@util/constants";
import { Close } from "@util/svg";
import { useEventListener, useOnClickOutside } from "@util/hooks";
import { usePackageCustomizeTiers } from "@api";
import PackageCustomiser from "./packageCustomiser";
import { capitalize } from "@util/helper";
import ProductDetails from "@global/productDetails";
import { SanityProduct } from "@graphql-types";
import { CartItem } from "@util/types";
import { createOrder, usePropertyContext } from "../utils";

interface Props {
  visible: boolean;
  onClose: () => void;
}

const SelectPackageDialog = ({ visible, onClose }: Props) => {
  const refWrapper = useRef<HTMLDivElement>(null);
  const tiersData = usePackageCustomizeTiers();
  const [selectedTier, setSelectedTier] = useState(tiersData?.tiers?.[0]?.tierList ?? "");
  const tierDetails = tiersData?.tiers?.find(el => el?.tierList === selectedTier);
  const [productDetails, setProductDetails] = useState<SanityProduct>();
  const [loading, setLoading] = useState(false);
  const { property, setProperty } = usePropertyContext();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") handleClose();
  };

  const handleClose = () => {
    if (productDetails || loading) return;
    if (onClose) onClose();
  };

  const handlePurchase = async (basePrice: number, items: CartItem[]) => {
    setLoading(true);
    const data = await createOrder(selectedTier, basePrice, items, property);
    setLoading(false);

    if (data) {
      setProperty(p => ({
        ...p,
        order: data,
      }));

      onClose();
    }
  };

  useOnClickOutside(refWrapper, handleClose);
  useEventListener("keydown", handleKeyDown);

  return (
    <>
      <Wrapper className={visible ? "visible" : "hidden"}>
        <div className="dialog-wrapper" ref={refWrapper}>
          <div className="title-wrapper">
            <h4>Customize HomeSell Go {capitalize(selectedTier)}</h4>
            <Close color="black" className="close-icon" onClick={onClose} />
          </div>
          <PackageCustomiser
            loading={loading}
            onPurchase={handlePurchase}
            tierDetails={tierDetails}
            setCurrentTier={setSelectedTier}
            setProduct={setProductDetails}
            currentTier={selectedTier}
          />
        </div>
      </Wrapper>
      <ProductDetails onClose={() => setProductDetails(undefined)} product={productDetails} />
    </>
  );
};

export default SelectPackageDialog;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  transition: all 300ms;
  z-index: 100;
  padding: 30px;
  overflow-y: auto;
  overflow-x: hidden;

  &.visible {
    pointer-events: all;
    opacity: 1;

    .dialog-wrapper {
      transform: scale(1);
    }
  }

  &.hidden {
    pointer-events: none;
    opacity: 0;

    .dialog-wrapper {
      transform: scale(0);
    }
  }

  .dialog-wrapper {
    width: 70%;
    min-height: 50%;
    height: fit-content;
    margin: auto;
    padding: 40px;
    background-color: ${colors.white};
    border-radius: 20px;
    transform-origin: top;

    .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px;

      h4 {
        margin: 0;
      }

      .close-icon {
        cursor: pointer;
      }
    }
  }
`;
