import { LAPTOP_BREAKPOINT, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "./constants";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";

import { ScreenWidth } from "./types";
import firebase from "gatsby-plugin-firebase";
import { getDocsCollection } from "./firebase";
import { isBrowser } from "./helper";
import { sanityClient } from "@lib/sanityClient";
import { useStore } from "@state/store";

const defaultScreenWidth = {
  isTabletWidth: false,
  isMobileWidth: false,
  isLaptopWidth: false,
};

// get previous const for comparsion
export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

// similar to use previous but will do an comparsion between prev & next prop
export function usePreviousCompare<T>(next: T, compare: (prev: T, next: T) => T) {
  const previousRef = useRef<T>(next);
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (previousRef.current == null) {
      return;
    }

    if (!isEqual && previousRef.current) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
}

// check if component has been mounted
export function useHasMounted() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  if (!mounted) {
    return false;
  }

  return mounted;
}

// the same as useState but will store the value in localStorage
function useStorage<T>(key: string, defaultValue: T | (() => T), storage: Storage) {
  const [value, setValue] = useState(() => {
    const jsonValue = storage.getItem(key);
    if (jsonValue != null) return JSON.parse(jsonValue);

    if (defaultValue instanceof Function) {
      return defaultValue();
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (value === undefined) return storage.removeItem(key);
    storage.setItem(key, JSON.stringify(value));
  }, [key, value, storage]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove];
}

export function useLocalStorage<T>(key: string, defaultValue: T | (() => T)) {
  if (!isBrowser()) return;
  return useStorage(key, defaultValue, window.localStorage);
}

export function useSessionStorage<T>(key: string, defaultValue: T | (() => T)) {
  if (!isBrowser()) return;
  return useStorage(key, defaultValue, window.sessionStorage);
}

// event listener hook
export function useEventListener(
  eventName: string,
  handler: (args: any) => void,
  elementToListen?: any,
) {
  if (!isBrowser()) return;
  const element = elementToListen ?? window;

  const savedHandler = useRef<typeof handler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    //@ts-ignore
    const eventListener = (event: any) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);

    return () => element.removeEventListener(eventName, eventListener);
  }, [eventName, element]);
}

// set dark dark mode
export function useDarkMode(refObject?: RefObject<any>) {
  if (!isBrowser()) {
    return;
  }
  const [enabled, setEnabled] = useState(false);
  const element = refObject?.current ?? window.document.body;

  useEffect(() => {
    const className = "dark-mode";

    if (element) {
      if (enabled) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    }
  }, [enabled]);

  return [enabled, setEnabled];
}

// console logs the state when it gets updated
export function useUpdateLogger(value: any) {
  useEffect(() => {
    console.log(value);
  }, [value]);
}

// changes the boolean value to it's opposite value
export function useToggle(initialState = false): [boolean, () => void] {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(state => !state), []);

  return [state, toggle];
}

// timeout hook, returns reset and clear function
export function useTimeout(callback: (args?: any) => void, delay: number) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  useEffect(() => {
    return () => clear();
  }, []);

  return { reset, clear };
}

// debounce hook - run a callback after a certain delay
export function useDebounce(callback: (args?: any) => void, delay: number, dependencies: any[]) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, []);
}

export function useArray(defaultValue: any[]) {
  const [array, setArray] = useState(defaultValue);

  const push = (element: any) => {
    setArray(a => [...a, element]);
  };

  const filter = (callback: (args?: any) => void) => {
    setArray(a => a.filter(callback));
  };

  const update = (index: number, newElement: any) => {
    setArray(a => [...a.slice(0, index), newElement, ...a.slice(index + 1, a.length)]);
  };

  const remove = (index: number) => {
    setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  };

  const clear = () => {
    setArray([]);
  };

  return { array, set: setArray, push, filter, update, remove, clear };
}

// counts the number of re-renders
export function useRenderCount() {
  const count = useRef(1);
  useEffect(() => {
    count.current++;
  }, []);
  return count.current;
}

// media query hook
export default function useMediaQuery(mediaQuery: string) {
  const [isMatch, setIsMatch] = useState(false);
  const [mediaQueryList, setMediaQueryList] = useState<MediaQueryList | null>(null);

  useEffect(() => {
    if (!isBrowser()) return;
    const list = window.matchMedia(mediaQuery);
    setMediaQueryList(list);
    setIsMatch(list.matches);
  }, [mediaQuery]);

  useEventListener("change", e => setIsMatch(e.matches), mediaQueryList);

  return isMatch;
}

// checks screen width
export function useCheckScreenWidth(): ScreenWidth {
  if (!isBrowser()) {
    return defaultScreenWidth;
  }
  const [screenWidth, setScreenWidth] = useState(defaultScreenWidth);
  const hasMounted = useHasMounted();

  const checkScreenWidth = () => {
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
        isTabletWidth: true,
        isMobileWidth: true,
      });
      return;
    }
    if (window.innerWidth <= TABLET_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
        isTabletWidth: true,
      });
      return;
    }
    if (window.innerWidth <= LAPTOP_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
      });
      return;
    }
    if (window.innerWidth > LAPTOP_BREAKPOINT) {
      setScreenWidth(defaultScreenWidth);
      return;
    }
  };

  useEventListener("resize", checkScreenWidth);

  useEffect(() => {
    checkScreenWidth();
  }, []);

  useEffect(() => {
    if (hasMounted) {
      checkScreenWidth();
    }
  }, [hasMounted]);

  return screenWidth;
}

export function useOnClickOutside(ref: RefObject<any>, handler: () => void) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

//Account hooks

export const useGetOrderLog = () => {
  const { orderLog, setOrderLog, shouldUpdateOrders, setShouldUpdateOrders } = useStore();

  const getOrderLog = async () => {
    const firestore = firebase.firestore();
    const orders = await getDocsCollection(firestore.collection("orders"));
    if (orders) {
      setOrderLog(orders);
      setShouldUpdateOrders(false);
    }
  };

  useEffect(() => {
    if (orderLog != null && orderLog.length > 0) {
      return;
    }
    if (shouldUpdateOrders) {
      getOrderLog();
    }
  }, [shouldUpdateOrders]);
};

export const useGetChangeRequests = () => {
  console.log("in here");

  const { setChangeRequests, shouldUpdateFirebaseData, setShouldUpdateFirebaseData } = useStore();

  const getChangeRequests = async () => {
    const firestore = firebase.firestore();
    const orders = await getDocsCollection(firestore.collection("changeRequest"));
    if (orders) {
      setChangeRequests(orders);
      setShouldUpdateFirebaseData(false);
    }
  };

  useEffect(() => {
    console.log("loading");
    getChangeRequests();
  }, [shouldUpdateFirebaseData]);
};

export const useGetUserChangeRequests = () => {
  const { setUserChangeRequests, user, setShouldUpdateFirebaseData, shouldUpdateFirebaseData } =
    useStore();

  const getUserChangeRequests = async () => {
    console.log("getting change requests");

    const firestore = firebase.firestore();
    const orders = await getDocsCollection(
      firestore.collection("changeRequest").where("userID", "==", user?.id),
    );
    if (orders) {
      setUserChangeRequests(orders);
      setShouldUpdateFirebaseData(false);
    }
  };

  useEffect(() => {
    if (!user) return;
    getUserChangeRequests();
  }, [shouldUpdateFirebaseData, user]);
};
