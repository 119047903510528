import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { Maybe, SanityProduct, SanityTier } from "@graphql-types";
import { CartItem } from "@util/types";
import { em } from "@util/helper";
import { colors, fontWeights } from "@util/constants";
import { Button, ProductDetails, QuantityInput } from "@global";
import { generateCartItem } from "../utils";

interface ProductLinesProps {
  tierDetails: SanityTier;
  product?: Maybe<SanityProduct>;
  index: number;
  setLocalCartItems: Dispatch<SetStateAction<CartItem[]>>;
  setProduct: React.Dispatch<React.SetStateAction<SanityProduct | undefined>>;
  isDefault?: boolean;
  defaultQuantity?: Maybe<number>;
  defaultSku?: Maybe<string>;
  included?: boolean;
}

const handleLineUpdate = (
  product: SanityProduct,
  setCurrentCartItem: Dispatch<SetStateAction<CartItem | null>>,
  index: number,
) => {
  const quantityInput = document.querySelector<HTMLInputElement>(`#grid-${index} .quantity-input`);
  const variantInput = document.querySelector<HTMLSelectElement>(`#grid-${index} .variant-input`);

  if (!product) return;

  const selectedVariant =
    product?.variants &&
    product.variants.length > 0 &&
    (product.variants.find(v => v?.sku === variantInput?.value) ?? product.variants[0]);
  const quantityValue = quantityInput?.value ? parseInt(quantityInput?.value) : 1;

  setCurrentCartItem({
    title: product.title ?? "",
    quantity: quantityValue > 1 ? quantityValue : 1,
    sku: product.sku ?? "",
    price: product.price ?? 0,
    variant: selectedVariant
      ? {
          sku: selectedVariant.sku ?? "",
          title: selectedVariant.title ?? "",
          price: selectedVariant.price ?? 0,
        }
      : null,
  });
};

const ProductLines = ({
  tierDetails,
  product,
  index,
  isDefault,
  setLocalCartItems,
  defaultQuantity,
  setProduct,
  defaultSku,
  included,
}: ProductLinesProps) => {
  if (!product) return null;

  const { title, quantity, sku, variants } = product;
  const [currentCartItem, setCurrentCartItem] = useState(
    isDefault ? generateCartItem(product, defaultQuantity, defaultSku) : null,
  );
  const isAdded = Boolean(currentCartItem && currentCartItem.quantity > 0);

  useEffect(() => {
    setCurrentCartItem(isDefault ? generateCartItem(product, defaultQuantity, defaultSku) : null);
  }, [tierDetails]);

  useEffect(() => {
    setLocalCartItems(prev => {
      const existInCurrent = Boolean(prev.find(item => item?.sku === sku));

      if (!currentCartItem && existInCurrent) {
        return prev.filter(item => item?.sku !== sku);
      }

      if (currentCartItem && existInCurrent) {
        return prev.map(item => (item?.sku === currentCartItem.sku ? currentCartItem : item));
      }

      if (currentCartItem && !existInCurrent) {
        return [...prev, currentCartItem];
      }

      return prev;
    });
  }, [currentCartItem]);

  return (
    <Wrapper key={`grid-${index}`} id={`grid-${index}`}>
      <div className="product-title">
        {title && <p>{title}</p>}
        <button onClick={() => setProduct(product)}>Details</button>
      </div>
      <div className="qnt-variant">
        {isAdded && quantity ? (
          <QuantityInput
            defaultQuantity={currentCartItem?.quantity ?? 0}
            onChange={() => handleLineUpdate(product, setCurrentCartItem, index)}
          />
        ) : (
          <>
            {Boolean(isAdded && variants?.length) ? (
              <select
                name="variant"
                className="variant-input"
                value={currentCartItem?.variant?.sku}
                onChange={() => handleLineUpdate(product, setCurrentCartItem, index)}
              >
                {variants!.map(variant => {
                  if (!variant || !variant.sku || !variant.title) return;

                  return (
                    <option key={`variant-option-${variant.sku}`} value={variant.sku}>
                      {variant.title}
                    </option>
                  );
                })}
              </select>
            ) : null}
          </>
        )}
      </div>
      <div className="add-item">
        {included ? (
          <>
            {index > 1 && (
              <ButtonStyled
                isAdd={!isAdded}
                theme={isAdded ? "proOutline" : "knowOutline"}
                onClick={() =>
                  isAdded
                    ? setCurrentCartItem(null)
                    : handleLineUpdate(product, setCurrentCartItem, index)
                }
                linkText={isAdded ? "Remove" : "Add"}
              />
            )}
          </>
        ) : (
          <>
            <ButtonStyled
              isAdd={!isAdded}
              theme={isAdded ? "proOutline" : "knowOutline"}
              onClick={() =>
                isAdded
                  ? setCurrentCartItem(null)
                  : handleLineUpdate(product, setCurrentCartItem, index)
              }
              linkText={isAdded ? "Remove" : "Add"}
            />
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default ProductLines;

const Wrapper = styled.div`
  width: 100%;
  grid-template-columns: 3fr 1fr 1fr;
  grid-gap: 0 20px;
  border-bottom: 2px solid ${colors.gridBorder};
  padding: 35px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .product-title {
    flex: 1;
    p {
      margin-bottom: 10px;
      font-size: ${em(20, 16)};
    }

    button {
      font-weight: ${fontWeights.bold};
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }

  .variant-input {
    width: 250px;
  }
`;

const ButtonStyled = styled(Button)<{ isAdd?: boolean }>`
  span {
    display: flex;
    align-items: center;

    &:before {
      content: "";
      display: inline-block;
      margin: 0 10px 0 0;
      width: 14px;
      height: 14px;
      ${({ isAdd }) =>
        isAdd
          ? `
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.814 0L5.644 0.17V5.644H0.17L0 5.814V7.446L0.17 7.616H5.644V13.09L5.814 13.26H7.48L7.65 13.09V7.616H13.09L13.26 7.446V5.814L13.09 5.644H7.65V0.17L7.48 0H5.814Z' fill='%23149B9E'/%3E%3C/svg%3E%0A");
        background-size: contain;
      `
          : `
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.48758 0.111119L9.24717 0.111119L5.37647 3.98182L1.50576 0.111118L1.26535 0.111118L0.111348 1.26512L0.111348 1.50553L3.98205 5.37624L0.111348 9.24694V9.48735L1.28939 10.6654L1.5298 10.6654L5.40051 6.79469L9.24717 10.6414L9.48759 10.6414L10.6416 9.48735L10.6416 9.24694L6.79492 5.40028L10.6656 1.52957L10.6656 1.28916L9.48758 0.111119Z' fill='%23F15B5B'/%3E%3C/svg%3E%0A");
        background-size: 11px;
      `}
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &:hover {
    span {
      &:before {
        ${({ isAdd }) =>
          isAdd
            ? `
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.814 0L5.644 0.17V5.644H0.17L0 5.814V7.446L0.17 7.616H5.644V13.09L5.814 13.26H7.48L7.65 13.09V7.616H13.09L13.26 7.446V5.814L13.09 5.644H7.65V0.17L7.48 0H5.814Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
        `
            : `
          background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.48758 0.111119L9.24717 0.111119L5.37647 3.98182L1.50576 0.111118L1.26535 0.111118L0.111348 1.26512L0.111348 1.50553L3.98205 5.37624L0.111348 9.24694V9.48735L1.28939 10.6654L1.5298 10.6654L5.40051 6.79469L9.24717 10.6414L9.48759 10.6414L10.6416 9.48735L10.6416 9.24694L6.79492 5.40028L10.6656 1.52957L10.6656 1.28916L9.48758 0.111119Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
        `}
      }
    }
  }
`;
