import { Button, Drawer, Swiper } from "@global";
import { Close, SearchIcon } from "@util/svg";
import { Container, DesktopContainer, MobileContainer } from "@util/standard";
import React, {
  ChangeEvent,
  Dispatch,
  FormEvent,
  Fragment,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  SMALL_LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
  colors,
  colorsRGB,
  fontWeights,
} from "@util/constants";
import { defaultSearchParams, useStore } from "@state/store";
import { em, handleize, isBrowser } from "@util/helper";
import { useHandleQuery, useRegionQuery } from "@api";

import { Color } from "@util/types";
import { SearchParams } from "@state/types";
import styled from "styled-components";
import { useOnClickOutside } from "@util/hooks";
import { navigate } from "gatsby";

interface Props {
  isWhite?: boolean;
}

const Form = styled.form`
  width: 100%;
`;

const Wrapper = styled.div<{
  color: Color;
}>`
  color: ${({ color }) => colors[color]};
  position: relative;
  padding: 15px 105px 15px 15px;
  border: 2px solid currentColor;
  border-radius: 100px;
  width: 100%;
`;

const Controls = styled.div<{
  activeIndex: number;
}>`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    border-radius: 100px;
    background: currentColor;
    top: -15px;
    bottom: -15px;
    transition: left 0.3s ease;
    width: calc(20% + 30px);

    ${({ activeIndex }) =>
      activeIndex === -1
        ? `
      opacity: 0;
    `
        : `
      left: calc(${activeIndex * 20}% - 15px);
      opacity: 1;
    `}
  }

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    &:before {
      border-radius: 50px;
    }
  }
`;

const TitleButton = styled.button<{
  isActive: boolean;
  colorInvert: Color;
}>`
  border-right: 2px solid currentColor;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  padding: 10px 20px 10px 35px;
  text-align: left;
  line-height: 1.2;
  width: 20%;
  position: relative;
  z-index: 2;
  transition: none;
  transition: color 0.3s ease;
  min-height: 61px;

  &:last-of-type {
    border-right: 0;
  }

  span {
    display: inline-block;
    ${({ isActive, colorInvert }) =>
      isActive &&
      `
      color: ${colors[colorInvert]};
    `}
  }

  .title {
    font-size: ${em(17)};
    font-weight: ${fontWeights.semibold};
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: ${em(13)};
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .title {
      font-size: ${em(15)};
    }
    .subtitle {
      font-size: ${em(13)};
    }
  }

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    padding: 10px 20px;
  }
`;

const FloatWrapper = styled.div<{
  isVisible: boolean;
  index: number;
}>`
  position: absolute;
  z-index: 3;
  width: 450px;
  ${({ index }) =>
    index !== 4
      ? `
    left: ${index * 20}%;
  `
      : `
    right: -105px;
  `}
  top: calc( 100% + 25px);

  background: ${colors.white};
  color: ${colors.main};
  border: 1.5px solid currentColor;
  box-shadow: 6px 7px 20px 0px rgba(0, 0, 0, 0.18);
  border-radius: 20px;
  opacity: 1;
  padding: 35px 40px;

  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(0);

  ${({ isVisible }) =>
    !isVisible &&
    `
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
  `}

  input, select, textarea {
    width: 100%;
  }

  select + select {
    margin-top: 15px;
  }

  input[type="text"] {
    background: ${colors.inputBg};
    border: none;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 20px;
    width: 350px;
  }
`;

const Row = styled(Container)`
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1.5px solid ${colorsRGB.main(0.4)};

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const Label = styled.label`
  input[type="checkbox"] + &:before,
  input[type="checkbox"]:checked + &&:after {
    display: none;
  }

  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.main};
  border-radius: 100px;
  margin-right: 13px;
  transition: background 0.3s ease;
  padding-top: 0.1em;

  &:last-of-type {
    margin-right: 0;
  }

  input[type="checkbox"]:checked + & {
    background: ${colors.main};
    color: ${colors.white};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-right: 5px;
  }
`;

const Submit = styled.button<{
  activeIndex: number;
}>`
  background: currentColor;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: calc(100% - 95px);
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ activeIndex }) =>
    activeIndex !== -1 &&
    `
    top: 10px;
    right: 13px;
    bottom: 10px;
    left: calc(100% - 85px);
  `}

  svg {
    width: 24px;
  }
`;

//////////////////////////////
// Mobile Style
//////////////////////////////
const WrapperMobile = styled(Wrapper)`
  padding: 0;

  button {
    padding: 17px 38px;
    width: 100%;
    text-align: left;
  }
`;

const MobileIcon = styled.div`
  background: currentColor;
  position: absolute;
  top: 7px;
  right: 10px;
  bottom: 7px;
  left: calc(100% - 54px);
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
  padding: 15px 20px 35px;

  .swiper-slide {
    padding: 1px;
  }

  .swiper-button-prev {
    right: 70px;
    left: auto;
  }

  .swiper-button-next {
    right: 20px;
    left: auto;
  }

  && .swiper-pagination {
    display: flex;
    flex-flow: row wrap;
    top: 0;
    bottom: auto;
  }

  .swiper-pagination-bullet {
    flex-grow: 1;
    border-radius: 10px;
    height: 5px;
  }

  svg {
    z-index: 3;
    top: 40px;
  }

  select + select {
    margin-top: 24px;
  }
`;

const SkipBtn = styled.button`
  position: relative;
  top: -27px;
  text-decoration: underline;
  text-underline-offset: 3px;
  z-index: 2;
`;

const handleUpdate = (
  e: ChangeEvent<any>,
  setlocalSearchParams: Dispatch<SetStateAction<SearchParams>>,
) => {
  const { bathrooms, bedrooms, maxPrice, minPrice, region, searchTerm, types } =
    e.currentTarget.closest("form").elements;

  setlocalSearchParams({
    searched: true,
    bathrooms: Array.from(bathrooms)
      .filter(bathroom => bathroom?.checked === true)
      .map(bathroom => bathroom.value),
    bedrooms: Array.from(bedrooms)
      .filter(bedroom => bedroom?.checked === true)
      .map(bedroom => bedroom.value),
    maxPrice: maxPrice.value
      ? {
          value: parseInt(maxPrice.value),
          name: maxPrice.querySelector(`option[value="${maxPrice.value}"]`).innerHTML,
        }
      : null,
    minPrice: minPrice.value
      ? {
          value: parseInt(minPrice.value),
          name: minPrice.querySelector(`option[value="${minPrice.value}"]`).innerHTML,
        }
      : null,
    region: region.value
      ? {
          id: parseInt(region.value),
          name: region.querySelector(`option[value="${region.value}"]`).innerHTML,
        }
      : null,
    searchTerm: searchTerm.value ? searchTerm.value : null,
    types: Array.from(types)
      .filter(type => type?.checked === true)
      .map(type => type.value),
  });
};

const handleClearSearch = (setlocalSearchParams: Dispatch<SetStateAction<SearchParams>>) => {
  const clearParams = {
    searched: false,
    bathrooms: [],
    bedrooms: [],
    maxPrice: null,
    minPrice: null,
    region: null,
    searchTerm: null,
    types: [],
  };

  setlocalSearchParams(clearParams);

  useStore.setState({
    searchParams: clearParams,
  });
};

const handleSubmit = (
  e: FormEvent<HTMLFormElement>,
  localSearchParams: SearchParams,
  propertyHandle: string,
) => {
  e.preventDefault();

  useStore.setState({
    searchParams: localSearchParams,
  });

  const relocateURL = `/${propertyHandle}`;
  // localSearchParams.region
  //   ? `/${propertyHandle}/${handleize(localSearchParams.region.name)}`
  //   : `/${propertyHandle}`;
  if (window.location.pathname === relocateURL || window.location.pathname === `${relocateURL}/`)
    return;
  // location.href = relocateURL;
  navigate(relocateURL);
};

const buttonArr = (
  id: string,
  searchParams: SearchParams,
  setlocalSearchParams: Dispatch<SetStateAction<SearchParams>>,
) => {
  const regions = useRegionQuery();
  const propertyTypes = [
    "House",
    "Section Res",
    "Unit",
    "Townhouse",
    "Apartment",
    "Lifestyle Property",
    "Studio",
    "Retirement Living",
  ];
  const prices = [
    { value: 100000, name: "$100k" },
    { value: 150000, name: "$150k" },
    { value: 200000, name: "$200k" },
    { value: 250000, name: "$250k" },
    { value: 300000, name: "$300k" },
    { value: 350000, name: "$350k" },
    { value: 400000, name: "$400k" },
    { value: 450000, name: "$450k" },
    { value: 500000, name: "$500k" },
    { value: 550000, name: "$550k" },
    { value: 600000, name: "$600k" },
    { value: 650000, name: "$650k" },
    { value: 700000, name: "$700k" },
    { value: 750000, name: "$750k" },
    { value: 800000, name: "$800k" },
    { value: 850000, name: "$850k" },
    { value: 900000, name: "$900k" },
    { value: 950000, name: "$950k" },
    { value: 1000000, name: "$1M" },
    { value: 1100000, name: "$1.1M" },
    { value: 1200000, name: "$1.2M" },
    { value: 1300000, name: "$1.3M" },
    { value: 1400000, name: "$1.4M" },
    { value: 1500000, name: "$1.5M" },
    { value: 1600000, name: "$1.6M" },
    { value: 1700000, name: "$1.7M" },
    { value: 1800000, name: "$1.8M" },
    { value: 1900000, name: "$1.9M" },
    { value: 2000000, name: "$2M" },
    { value: 2250000, name: "$2.25M" },
    { value: 2500000, name: "$2.5M" },
    { value: 2750000, name: "$2.75M" },
    { value: 3000000, name: "$3M" },
    { value: 3500000, name: "$3.5M" },
    { value: 4000000, name: "$4M" },
    { value: 5000000, name: "$5M" },
    { value: 6000000, name: "$6M" },
    { value: 7000000, name: "$7M" },
    { value: 8000000, name: "$8M" },
    { value: 9000000, name: "$9M" },
    { value: 10000000, name: "$10M" },
  ];

  const minPriceVal = searchParams.minPrice?.value;
  const maxPriceVal = searchParams.maxPrice?.value;

  return [
    {
      title: "Location",
      subtitle: "Select location",
      node: () => (
        <>
          <select name="region" onChange={e => handleUpdate(e, setlocalSearchParams)}>
            <option value="" selected>
              Region (Any)
            </option>
            {regions.map(region => (
              <option
                selected={searchParams.region?.name === region.regionName}
                key={region.regionName}
                value={region.regionName}
              >
                {region.regionName}
              </option>
            ))}
          </select>
        </>
      ),
    },
    {
      title: "Property",
      subtitle: "Property types",
      node: () => (
        <>
          {propertyTypes.map((type, i) => {
            const typeName = "types";
            const divisable = Math.floor(i / 2) * 2;
            const nextType = propertyTypes[i + 1];

            if (i !== divisable) return;

            return (
              <Row key={`search-${i}`}>
                <Container width="50%">
                  <input
                    type="checkbox"
                    name={typeName}
                    value={type}
                    id={`${id}${typeName}-${type}`}
                    onChange={e => handleUpdate(e, setlocalSearchParams)}
                    checked={searchParams.types.includes(type)}
                  />
                  <label htmlFor={`${id}${typeName}-${type}`}>{type}</label>
                </Container>
                <Container width="50%">
                  <input
                    type="checkbox"
                    name={typeName}
                    value={nextType}
                    id={`${id}${typeName}-${nextType}`}
                    onChange={e => handleUpdate(e, setlocalSearchParams)}
                    checked={searchParams.types.includes(nextType)}
                  />
                  <label htmlFor={`${id}${typeName}-${nextType}`}>{nextType}</label>
                </Container>
              </Row>
            );
          })}
        </>
      ),
    },
    {
      title: "Rooms",
      subtitle: "Add room types",
      node: () => {
        const bedName = "bedrooms";
        const bathName = "bathrooms";

        return (
          <>
            <Row>
              <Container width="calc(100% - 242px)" tabletWidth="calc(100% - 210px)">
                Bedrooms
              </Container>
              <Container width="242px" tabletWidth="210px">
                {[...Array(6).keys()].map(i => (
                  <Fragment key={`bedName-${i}`}>
                    <input
                      id={`${id}-${bedName}-${i}`}
                      type="checkbox"
                      name={bedName}
                      value={`${i + 1}${i === 5 ? `+` : ``}`}
                      onChange={e => handleUpdate(e, setlocalSearchParams)}
                      checked={searchParams.bedrooms.includes(`${i + 1}${i === 5 ? `+` : ``}`)}
                    />
                    <Label htmlFor={`${id}-${bedName}-${i}`}>
                      {i + 1}
                      {i === 5 && `+`}
                    </Label>
                  </Fragment>
                ))}
              </Container>
            </Row>
            <Row>
              <Container width="calc(100% - 242px)" tabletWidth="calc(100% - 210px)">
                Bathrooms
              </Container>
              <Container width="242px" tabletWidth="210px">
                {[...Array(4).keys()].map(i => (
                  <Fragment key={`bathName-${i}`}>
                    <input
                      id={`${id}-${bathName}-${i}`}
                      type="checkbox"
                      name={bathName}
                      value={`${i + 1}${i === 3 ? `+` : ``}`}
                      onChange={e => handleUpdate(e, setlocalSearchParams)}
                      checked={searchParams.bathrooms.includes(`${i + 1}${i === 3 ? `+` : ``}`)}
                    />
                    <Label htmlFor={`${id}-${bathName}-${i}`}>
                      {i + 1}
                      {i === 3 && `+`}
                    </Label>
                  </Fragment>
                ))}
              </Container>
            </Row>
          </>
        );
      },
    },
    {
      title: "Price",
      subtitle: "Add a price",
      node: () => (
        <>
          <select name="minPrice" onChange={e => handleUpdate(e, setlocalSearchParams)}>
            <option value="" selected>
              Min Price (Any)
            </option>
            {prices.map(price => (
              <option
                key={`min-${price.value}`}
                disabled={maxPriceVal && price.value >= maxPriceVal ? true : false}
                value={price.value}
                selected={searchParams.minPrice?.value === price.value}
              >
                {price.name}
              </option>
            ))}
          </select>
          <select name="maxPrice" onChange={e => handleUpdate(e, setlocalSearchParams)}>
            <option value="" selected>
              Max Price (Any)
            </option>
            {prices.map(price => (
              <option
                key={`max-${price.value}`}
                disabled={minPriceVal && price.value <= minPriceVal ? true : false}
                value={price.value}
                selected={searchParams.maxPrice?.value === price.value}
              >
                {price.name}
              </option>
            ))}
          </select>
        </>
      ),
    },
    {
      title: "Property ID",
      subtitle: "Enter ID or keyword",
      node: () => (
        <>
          <input
            type="text"
            name="searchTerm"
            placeholder="Key words e.g. pool, views, property ID"
            onChange={e => handleUpdate(e, setlocalSearchParams)}
            value={searchParams.searchTerm ?? ""}
          />
          {id === "mobile" && <Button type="submit" linkText="Submit" margin="20px 0 0" />}
        </>
      ),
    },
  ];
};

const mobileSearchMenuSlides = (
  resultsArr: string[],
  localSearchParams: SearchParams,
  setlocalSearchParams: Dispatch<SetStateAction<SearchParams>>,
) => {
  return buttonArr("mobile", localSearchParams, setlocalSearchParams).map((button, i) => {
    return (
      <Container
        margin="55px 0 0"
        padding="0 20px"
        flexDirection="column"
        width="100%"
        key={`btn-${button.title}`}
      >
        <h4>{button.title}</h4>
        <p>{resultsArr[i] ? resultsArr[i] : button.subtitle}</p>
        <Container width="100%" flexDirection="column">
          {button.node()}
        </Container>
      </Container>
    );
  });
};

const SearchMenu = ({ isWhite = false }: Props) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [localSearchParams, setlocalSearchParams] = useState<SearchParams>(defaultSearchParams);
  const controlsEl = useRef<HTMLDivElement>(null);
  const color = isWhite ? "white" : "main";
  const colorInvert = isWhite ? "main" : "white";
  const swiperEl = useRef<HTMLDivElement>(null);
  const { property } = useHandleQuery();
  const propertyHandle = property?.slug?.current ?? "";
  const regionArray = useRegionQuery();

  const { searchParams } = useStore();
  const bedroomsText =
    localSearchParams.bedrooms.length > 0
      ? `${localSearchParams.bedrooms.join(", ")}  Bedroom(s)`
      : "";
  const bathroomsText =
    localSearchParams.bathrooms.length > 0
      ? `${localSearchParams.bathrooms.join(", ")}  Bathroom(s)`
      : "";
  const resultsArr = [
    `${localSearchParams.region ? localSearchParams.region?.name : ``}`,
    `${localSearchParams.types.join(", ")}`,
    `${
      bedroomsText && bathroomsText
        ? `${bedroomsText}, ${bathroomsText}`
        : bedroomsText
        ? bedroomsText
        : bathroomsText
        ? bathroomsText
        : ``
    }`,
    `${
      localSearchParams.minPrice && localSearchParams.maxPrice
        ? `${localSearchParams.minPrice.name} - ${localSearchParams.maxPrice.name}`
        : localSearchParams.minPrice
        ? `Min Price: ${localSearchParams.minPrice.name}`
        : localSearchParams.maxPrice
        ? `Max Price: ${localSearchParams.maxPrice.name}`
        : ``
    }`,
    `${localSearchParams.searchTerm ? localSearchParams.searchTerm : ``}`,
  ];

  useEffect(() => {
    setlocalSearchParams(searchParams);

    if (!isBrowser() || window.location.pathname.indexOf(propertyHandle) < 0) return;

    const region = window.location.pathname.split(propertyHandle)[1].split("/")[1];
    const currentRegion = regionArray.find(obj => handleize(obj.regionName) === region);

    if (!currentRegion || searchParams.region?.name === currentRegion?.regionName) return;

    const newSearchParams = {
      ...searchParams,
      region: {
        name: currentRegion.regionName,
      },
    };

    useStore.setState({
      searchParams: newSearchParams,
    });
    setlocalSearchParams(newSearchParams);
  }, []);

  const handleSkipClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const nextBtn = swiperEl?.current?.querySelector(".swiper-button-next") as any;

    if (!nextBtn) return;

    nextBtn.click();
  };

  useOnClickOutside(controlsEl, () => setActiveIndex(-1));

  return (
    <>
      <DesktopContainer width="100%" isMobile flexDirection="column">
        <Form onSubmit={e => handleSubmit(e, localSearchParams, propertyHandle)}>
          <Wrapper color={color}>
            <Controls ref={controlsEl} activeIndex={activeIndex}>
              {buttonArr("desktop", localSearchParams, setlocalSearchParams).map((button, i) => {
                const isActive = activeIndex === i;

                return (
                  <Fragment key={`btn2-${button.title}`}>
                    <TitleButton
                      isActive={isActive}
                      colorInvert={colorInvert}
                      onClick={e => {
                        e.preventDefault();
                        setActiveIndex(i);
                      }}
                    >
                      <span className="title">{button.title}</span>
                      <span className="subtitle" title={button.subtitle}>
                        {resultsArr[i] ? resultsArr[i] : button.subtitle}
                      </span>
                    </TitleButton>
                    <FloatWrapper isVisible={isActive} index={i}>
                      {button.node()}
                    </FloatWrapper>
                  </Fragment>
                );
              })}
            </Controls>

            <Submit type="submit" activeIndex={activeIndex}>
              <SearchIcon color={colorInvert} />
            </Submit>
          </Wrapper>
        </Form>
        <Button
          width="fit-content"
          margin="20px 0 0 auto"
          linkText="Clear"
          onClick={() => handleClearSearch(setlocalSearchParams)}
        />
      </DesktopContainer>
      <MobileContainer isMobile width="100%">
        <WrapperMobile color={color}>
          <button onClick={() => setIsOpen(true)}>
            Search for a property
            <MobileIcon>
              <SearchIcon color={colorInvert} />
            </MobileIcon>
          </button>
        </WrapperMobile>
        <Drawer
          alignItems="flex-start"
          isCustom
          visible={isOpen}
          height="500px"
          onClose={() => setIsOpen(false)}
        >
          <SliderWrapper ref={swiperEl}>
            <Close className="close-button" color="main" onClick={() => setIsOpen(false)} />
            <Form
              onSubmit={e => {
                handleSubmit(e, localSearchParams, propertyHandle);
                setIsOpen(false);
              }}
            >
              <Swiper
                slides={mobileSearchMenuSlides(resultsArr, localSearchParams, setlocalSearchParams)}
                slideId={"mobileSearchMenu"}
                slidesPerView={1}
                alignItems="flex-start"
                spaceBetween={0}
                navigation
                pagination={{ clickable: true }}
                isCustomNav
                allowTouchMove={false}
              />
            </Form>
            <SkipBtn onClick={e => handleSkipClick(e)}>Skip</SkipBtn>
          </SliderWrapper>
        </Drawer>
      </MobileContainer>
    </>
  );
};

export default SearchMenu;
