import React, { useState, useEffect } from "react";
import { GridContainer } from "@util/standard";
import { Address } from "@state/types";
import TextField from "../textField";

// Import the regions data from the JSON file
import { regions } from "../../util/regions"; // Adjust this import path based on your project structure

interface Props {
  onChange: (data: Address) => void;
  inputs: Address;
  theme?: "outlined" | "filled";
  showLabel?: boolean;
}

const AddressInput = ({ inputs, showLabel, theme, onChange }: Props) => {
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [availableDistricts, setAvailableDistricts] = useState([]);
  const [availableSuburbs, setAvailableSuburbs] = useState([]);

  const handleChange = (input: string, value: string) => {
    if (!onChange) return;
    onChange({ ...inputs, [input]: value });
  };

  // Handle region change and populate districts based on selected region
  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const region = e.target.value;
    setSelectedRegion(region);

    const regionData = regions.find(r => r.Name === region);
    setAvailableDistricts(regionData ? regionData.Districts : []);
    setSelectedDistrict(""); // Reset district when region changes
    setAvailableSuburbs([]); // Reset suburbs
    handleChange("region", region);
  };

  // Handle district change and populate suburbs based on selected district
  const handleDistrictChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const district = e.target.value;
    setSelectedDistrict(district);

    const districtData = availableDistricts.find(d => d.Name === district);
    setAvailableSuburbs(districtData ? districtData.Suburbs : []);
    handleChange("district", district);
  };

  // Handle suburb change
  const handleSuburbChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange("suburb", e.target.value);
  };

  return (
    <>
      <GridContainer repeat={3} mobileRepeat={2} gap="15px" width="100%">
        {/* Region Select */}
        <div>
          {showLabel && <label htmlFor="region">Region</label>}
          <select id="region" value={inputs.region || selectedRegion} onChange={handleRegionChange}>
            <option value="" disabled>
              Select Region
            </option>
            {regions.map(region => (
              <option key={region.LocalityId} value={region.Name}>
                {region.Name}
              </option>
            ))}
          </select>
        </div>

        {/* District Select */}
        <div>
          {showLabel && <label htmlFor="district">District</label>}
          <select
            id="district"
            value={inputs.district || selectedDistrict}
            onChange={handleDistrictChange}
            disabled={!availableDistricts.length}
          >
            <option value="" disabled>
              Select District
            </option>
            {availableDistricts.map(district => (
              <option key={district.DistrictId} value={district.Name}>
                {district.Name}
              </option>
            ))}
          </select>
        </div>

        {/* Suburb Select */}
        <div>
          {showLabel && <label htmlFor="suburb">Suburb</label>}
          <select
            id="suburb"
            value={inputs.suburb}
            onChange={handleSuburbChange}
            disabled={!availableSuburbs.length}
          >
            <option value="" disabled>
              Select Suburb
            </option>
            {availableSuburbs.map(suburb => (
              <option key={suburb.SuburbId} value={suburb.Name}>
                {suburb.Name}
              </option>
            ))}
          </select>
        </div>
      </GridContainer>

      <GridContainer repeat={2} mobileRepeat={2} gap="15px" width="100%">
        {/* Other Address Fields */}
        <TextField
          theme={theme}
          label={showLabel ? "Street #" : undefined}
          id="street"
          placeholder="Street #"
          // autoComplete="street-address"
          value={inputs.streetNumber}
          onChange={e => handleChange("streetNumber", e.target.value)}
        />
        <TextField
          theme={theme}
          label={showLabel ? "Unit, Apartment..." : ""}
          id="unit"
          placeholder="Unit, Apartment..."
          value={inputs.unit}
          onChange={e => handleChange("unit", e.target.value)}
        />
      </GridContainer>
      <GridContainer repeat={1} mobileRepeat={1} gap="15px" width="100%">
        <TextField
          theme={theme}
          label={showLabel ? "Street Name" : undefined}
          id="street"
          placeholder="Street Name"
          // autoComplete="street-address"
          value={inputs.street}
          onChange={e => handleChange("street", e.target.value)}
        />
      </GridContainer>
    </>
  );
};

export default AddressInput;
