import React, { useState } from "react";

import LoginForm from "./loginForm";
import RegisterForm from "./registerForm";
import { Wrapper } from "./cartDrawer";
import { useStore } from "@state/store";

interface Props {
  isCart?: boolean;
  onLoggedIn?: () => void;
}

const Account = ({ isCart, onLoggedIn }: Props) => {
  const { checkoutInitiated } = useStore();
  const [isCreateAccount, setIsCreateAccount] = useState(true);

  return (
    <>
      <Wrapper
        isInvert
        isVisible={isCart ? checkoutInitiated && !isCreateAccount : !isCreateAccount}
      >
        <LoginForm
          isCart={isCart}
          setIsCreateAccount={setIsCreateAccount}
          onLoggedIn={onLoggedIn}
        />
      </Wrapper>
      <Wrapper isInvert isVisible={isCart ? checkoutInitiated && isCreateAccount : isCreateAccount}>
        <RegisterForm
          isCart={isCart}
          setIsCreateAccount={setIsCreateAccount}
          onLoggedIn={onLoggedIn}
        />
      </Wrapper>
    </>
  );
};

export default Account;
