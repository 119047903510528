import { Dispatch, SetStateAction } from "react";
import {
  SanityCta,
  SanityCtaWithImage,
  SanityFeatureSlider,
  SanityFeaturedArticles,
  SanityFeaturedProperties,
  SanityFullWidthImage,
  SanityHomePage,
  SanityImage,
  SanityInfoAccordion,
  SanityInfoCards,
  SanityLeftRightContent,
  SanityLink,
  SanityLinkList,
  SanityMenuItem,
  SanityPageHero,
  SanityProcess,
  SanitySavingsCalculatorSlice,
  SanityTabbedContent,
  SanityTestimonialSlider,
  SanityTextSlider,
  SanityTierGrid,
} from "@graphql-types";
import { assets, buttonStyle, colors, colorsRGB, fontWeights } from "./constants";

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ColorRGB = keyof typeof colorsRGB;
export type ButtonTheme = keyof typeof buttonStyle;
export type ButtonType = typeof buttonStyle;
export type FontWeights = keyof typeof fontWeights;
export type AssetType = typeof assets;

export type BackgroundColor = "white" | "lightGrey" | "lightBlue" | "navy";
export type PackageType = "pro" | "go";
export type LogoType = PackageType | "know";
export type PackageTier = "basic" | "advanced" | "premium";
export type FirebaseExternalProviderType = "facebook" | "google";
export type PropertyDrawType = "change" | "update";
export type AccountHandles =
  | "orders"
  | "properties"
  | "pro-orders"
  | "order-log"
  | "change-requests";

export type PropertyDataType = "description" | "photos" | "title" | "short" | "price" | "heading";

export type PageContext = {
  pagePath: string;
  template: string;
  headerIsOverlay: boolean;
  headerIsDark?: boolean;
  slug?: string;
  pageId?: string;
  filter?: any;
  regionId?: string;
  type?: PackageType;
};

export type UserData = {
  firstName: string;
  lastName: string;
  createdAt: string;
};

export type SetRegion = Dispatch<
  SetStateAction<{
    iban: string;
    langCode: string;
  }>
>;

export type AnimationDirection = {
  to: {
    transform?: string;
    opacity?: number;
  };
  from: {
    transform?: string;
    opacity?: number;
  };
};
export interface ScreenWidth {
  isTabletWidth: boolean;
  isMobileWidth: boolean;
  isLaptopWidth: boolean;
}

export interface Package {
  type: PackageType;
  tier: PackageTier;
  price: number;
}

export interface CartItem {
  title: string;
  quantity: number;
  sku?: string | null;
  price?: number | null;
  variant?: {
    sku: string;
    title: string;
    price: number;
  } | null;
}

export interface LoginProps {
  email: string;
  password: string;
}

// -------------- Page Schema --------------- //
export function isSanityHomePage(data: any): data is SanityHomePage {
  return data._type === "homePage";
}

// -------------- Objects Schema --------------- //
export function isSanityImage(data: any): data is SanityImage {
  return data._type === "image";
}

export function isSanityMenuItem(data: any): data is SanityMenuItem {
  return data._type === "menuItem";
}

export function isSanityLink(data: any): data is SanityLink {
  return data._type === "link";
}

export function isSanityCta(data: any): data is SanityCta {
  return data._type === "cta";
}

export function isSanityCtaWithImage(data: any): data is SanityCtaWithImage {
  return data._type === "ctaWithImage";
}

// -------------- Sections Schema --------------- //
// Sections to check for type:
export function isSanityPageHero(data: any): data is SanityPageHero {
  return data._type === "pageHero";
}
export function isSanityFullWidthImage(data: any): data is SanityFullWidthImage {
  return data._type === "fullWidthImage";
}
export function isSanityInfoAccordion(data: any): data is SanityInfoAccordion {
  return data._type === "infoAccordion";
}
export function isSanityFeaturedArticles(data: any): data is SanityFeaturedArticles {
  return data._type === "featuredArticles";
}
export function isSanityFeaturedProperties(data: any): data is SanityFeaturedProperties {
  return data._type === "featuredProperties";
}
export function isSanityInfoCards(data: any): data is SanityInfoCards {
  return data._type === "infoCards";
}
// priceInfo
export function isSanityLeftRightContent(data: any): data is SanityLeftRightContent {
  return data._type === "leftRightContent";
}
export function isSanityFeatureSlider(data: any): data is SanityFeatureSlider {
  return data._type === "featureSlider";
}
export function isSanityProcess(data: any): data is SanityProcess {
  return data._type === "process";
}
export function isSanityTestimonialSlider(data: any): data is SanityTestimonialSlider {
  return data._type === "testimonialSlider";
}
export function isSanityTextSlider(data: any): data is SanityTextSlider {
  return data._type === "textSlider";
}
export function isSanityTabbedContent(data: any): data is SanityTabbedContent {
  return data._type === "tabbedContent";
}
export function isSanityLinkList(data: any): data is SanityLinkList {
  return data._type === "linkList";
}
export function isSanityTierGrid(data: any): data is SanityTierGrid {
  return data._type === "tierGrid";
}

export function isSanitySavingsCalculatorSlice(data: any): data is SanitySavingsCalculatorSlice {
  return data._type === "savingsCalculatorSlice";
}

export function isSanityCtaSection(data: any): data is SanityCta {
  return data._type === "ctaSection";
}
