import { Button, Drawer, Link } from "@global";
import { Cart as CartSvg, Logo } from "@util/svg";
import { Container, DesktopContainer, MobileContainer } from "@util/standard";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT, colors, pages } from "@util/constants";
import React, { useEffect, useRef, useState } from "react";

import Account from "@shared/drawer/account";
import { Cart } from "@state/types";
import DesktopMenu from "./desktopMenu";
import MobileMenu from "./mobileMenu";
import { navigate } from "gatsby";
import styled from "styled-components";
import { useEventListener } from "@util/hooks";
import { useHeaderQuery } from "@api";
import { useStore } from "@state/store";

interface Props {
  headerIsOverlay: boolean;
  headerIsDark: boolean;
}
interface CartProps {
  margin: string;
  isInverted: boolean;
  cart: Cart;
}

const HeaderWrapper = styled.div<{
  isHovered: boolean;
  isScrolled: boolean;
  isMenuOpen: boolean;
  headerIsOverlay: boolean;
}>`
  width: 100%;
  padding: 0 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s ease top;
  position: relative;
  z-index: 99;
  top; 0;

  @keyframes animateScrollHeader {
    from {transform: translateY(-100%);}
    to {transform: translateY(0);}
  }

  ${({ headerIsOverlay }) =>
    headerIsOverlay
      ? `
    position: absolute;
  `
      : `
    position: relative;
  `}

  ${({ isScrolled }) =>
    isScrolled &&
    `
    animation-name: animateScrollHeader;
    animation-duration: 0.6s;
    position: fixed;
    background: ${colors.white};
  `}

  @media only screen and (min-width: ${TABLET_BREAKPOINT + 1}px) {
    ${({ isHovered }) => isHovered && `background: ${colors.white};`}
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isMenuOpen }) =>
      isMenuOpen &&
      `
      position: fixed;
      top: 0;
      background: ${colors.white};
      transition: none;
    `}
    padding: 0 20px;
  }
`;

const LogoWrapper = styled.div`
  padding: 25px 0 20px;
  min-width: 200px;

  svg {
    width: 166px;
    path {
      transition: 0.5s ease fill;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 23px 0 17px;
    svg {
      width: 145px;
    }
  }
`;

const NavWrapper = styled(DesktopContainer)`
  align-self: stretch;
  align-items: center;
`;

const MainUl = styled.ul<{ isInverted: boolean; headerIsDark: boolean }>`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  ${({ isInverted, headerIsDark }) => isInverted && !headerIsDark && `color: ${colors.white};`}
`;

const CtaWrapper = styled(DesktopContainer)`
  justify-content: flex-end;
  min-width: 200px;
`;

const MobileIconsWrapper = styled(MobileContainer)<{ isInverted: boolean }>`
  button {
    ${({ isInverted }) => isInverted && `color: ${colors.white};`}
    width: 44px;
    height: 44px;
    position: relative;

    &:hover {
      opacity: 1;
    }

    &.mob-menu-btn {
      width: 24px;
    }
  }
`;

const MobileIcon = styled.span`
  display: block;
  height: 2px;
  width: 24px;
  background: currentColor;
  border-radius: 1px;
  position: absolute;
  transition: transform 0.3s ease;
  top: 50%;
`;

const MobileIconTop = styled(MobileIcon)<{ isMenuOpen: boolean }>`
  transform: translateY(-7px) rotate(0);

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    `
    transform: translateY(-50%) rotate(-45deg);
  `}
`;

const MobileIconBottom = styled(MobileIcon)<{ isMenuOpen: boolean }>`
  transform: translateY(7px) rotate(0);

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    `
    transform: translateY(-50%) rotate(45deg);
  `}
`;

const CartLink = styled(Link)<{
  isInvert: boolean;
}>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid ${props => (props.isInvert ? colors.white : colors.main)};
  && {
    position: relative;
    padding: 0;
    display: flex;
  }

  svg {
    margin: auto;
    @media only screen and (min-width: ${TABLET_BREAKPOINT + 1}px) {
      width: 40px;
    }
  }

  .bubble {
    position: absolute;
    background: ${({ isInvert }) => (isInvert ? colors.white : colors.main)};
    color: ${({ isInvert }) => (isInvert ? colors.main : colors.white)};
    right: -3px;
    top: -3px;
    border-radius: 100%;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding-top: 3px;
  }
`;

const CartButton = ({ margin, isInverted, cart }: CartProps) => (
  <CartLink
    onClick={() => useStore.setState({ cartOpen: true })}
    margin={margin}
    isInvert={isInverted}
  >
    <CartSvg color={isInverted ? "white" : "main"} />
    {cart.totalItems > 0 && <div className="bubble">{cart.totalItems}</div>}
  </CartLink>
);

const Header = ({ headerIsOverlay, headerIsDark }: Props) => {
  const headerData = useHeaderQuery();
  const [isInverted, setIsInverted] = useState(headerIsOverlay);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [isScrolled, setIsScrolled] = useState(false);
  const isHovered = hoverIndex !== -1;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const headerWrapperEl = useRef<HTMLDivElement>(null);
  const { cart, user, logout } = useStore();
  const [isAdmin, setIsAdmin] = useState(user?.isAdmin);

  //Language
  const language = {
    account: "My HomeSell",
    admin: "Admin",
  };

  useEffect(() => {
    setIsInverted(headerIsOverlay);
  }, [headerIsOverlay]);

  useEffect(() => {
    if (isHovered || isScrolled || isMenuOpen) {
      setIsInverted(false);
      return;
    }

    setIsInverted(headerIsOverlay);
  }, [isHovered, isScrolled, isMenuOpen]);

  const handleScroll = () => {
    const scrollDistance = document.body.scrollTop + document.documentElement.scrollTop;

    if (scrollDistance > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleAccountClick = () => {
    console.log(user?.id);

    if (user) {
      navigate(pages.account);
      return;
    }
    // if (user?.id && isAdmin) {
    //   navigate(pages.orderLog);
    //   return;
    // }
    setMenuOpen(true);
  };

  useEventListener("scroll", handleScroll);

  if (!headerData) return null;

  const { menuItems } = headerData;

  return (
    <header
      style={{ minHeight: `${isScrolled ? headerWrapperEl?.current?.offsetHeight ?? 0 : 0}px` }}
    >
      <HeaderWrapper
        ref={headerWrapperEl}
        isScrolled={isScrolled}
        isHovered={isHovered}
        isMenuOpen={isMenuOpen}
        headerIsOverlay={headerIsOverlay}
        onMouseLeave={() => setHoverIndex(-1)}
      >
        <LogoWrapper>
          <a href="/">
            <Logo color={isInverted && !headerIsDark ? "white" : "main"} />
          </a>
        </LogoWrapper>
        {menuItems && menuItems.length > 0 && (
          <NavWrapper>
            <nav>
              <MainUl isInverted={isInverted} headerIsDark={headerIsDark}>
                <DesktopMenu
                  menuItems={menuItems}
                  hoverIndex={hoverIndex}
                  setHoverIndex={setHoverIndex}
                />
              </MainUl>
            </nav>
          </NavWrapper>
        )}
        <CtaWrapper>
          <Container alignItems="center">
            <Button
              onClick={handleAccountClick}
              linkText={language.account}
              theme={isInverted && !headerIsDark ? "white" : "base"}
            />
          </Container>
          {/* <CartButton margin="0 0 0 20px" isInverted={isInverted && !headerIsDark} cart={cart} /> */}
        </CtaWrapper>
        <MobileIconsWrapper isInverted={isInverted}>
          {/* <CartButton margin="0 20px 0 0" isInverted={isInverted && !headerIsDark} cart={cart} /> */}
          <button
            className="mob-menu-btn"
            aria-label="Mobile Menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <MobileIconTop isMenuOpen={isMenuOpen}></MobileIconTop>
            <MobileIconBottom isMenuOpen={isMenuOpen}></MobileIconBottom>
          </button>
        </MobileIconsWrapper>
      </HeaderWrapper>
      {menuItems && menuItems.length > 0 && (
        <MobileMenu
          menuItems={menuItems}
          isMenuOpen={isMenuOpen}
          language={language}
          setIsMenuOpen={setIsMenuOpen}
          handleAccountClick={handleAccountClick}
        />
      )}
      <Drawer visible={menuOpen} onClose={() => setMenuOpen(false)}>
        <Account onLoggedIn={() => setMenuOpen(false)} />
      </Drawer>
    </header>
  );
};

export default Header;
