import React, { CSSProperties, ReactNode, useEffect, useRef } from "react";
import { MOBILE_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { Container } from "@util/standard";
import { Close } from "@util/svg";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import styled from "styled-components";

interface Props {
  width?: string;
  height?: string;
  children: ReactNode;
  placement?: "right" | "left" | "top" | "bottom";
  duration?: number;
  visible: boolean;
  onClose: () => void;
  isCustom?: boolean;
  alignItems?: CSSProperties["alignItems"];
  zIndex?: number;
  isCart?: boolean;
}

const DrawerStyled = styled(Drawer) <{
  alignItems?: CSSProperties["alignItems"];
  isCart?: boolean;
}>`
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: ${({ alignItems }) => alignItems ? alignItems : 'center'};
  justify-content: center;

  .close-button {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
    z-index: 5;
    transition: opacity 0.3s ease;

    &:hover{
      opacity: 0.5;
    }
  }

  ${({ isCart }) => !isCart && `
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      max-height: 80vh;
      bottom: 0;
      top: auto;
      border-radius: 20px 20px 0 0;
      max-width: 100%;

      .close-button {
        top: 25px;
        right: 25px;
        left: auto;
      }
    }
  `}

`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 50px 0;
`;

const DrawerComponent = ({ onClose, duration, width, height, placement, visible, children, isCustom, alignItems, zIndex, isCart }: Props) => {
  const { isMobileWidth } = useCheckScreenWidth();
  const drawerEl = useRef<HTMLDivElement>(null);

  //Bug with drawer plugin that causes overlay close popup to stop working 
  //because all popups share the same ID, causing the usage of the 
  //'for' attribute on label to target the first element of the ID.
  //The function below manually triggers the checkbox to be unchecked
  //to close the popup.
  useEffect(() => {
    const wrapper = drawerEl?.current;
    if (!wrapper) return;

    const overlay = wrapper.querySelector('#EZDrawer__overlay');
    const checkbox = wrapper.querySelector('#EZDrawer__checkbox');

    if (!overlay || !checkbox) return;

    overlay.addEventListener('click', () => {
      // @ts-ignore
      checkbox.checked = false;
    });

  }, [drawerEl]);

  return (
    <div ref={drawerEl}>
      <DrawerStyled
        open={visible}
        onClose={onClose}
        direction={placement ?? isCart ? "right" : isMobileWidth ? "bottom" : "right"}
        style={{
          width: "100%",
          maxWidth: width,
          height: height ?? "100%",
          backgroundSize: "100%",
        }}
        alignItems={alignItems}
        duration={duration}
        zIndex={zIndex}
        isCart={isCart}
      >
        {isCustom ?
          <>{children}</>
          :
          <>
            <Close className="close-button" color="main" onClick={onClose} />
            <Wrapper className="popupWrapper">
              <Container className="popupContainer" maxWidth="360px" width="100%" flexDirection="column" margin="auto">
                {children}
              </Container>
            </Wrapper>
          </>
        }
      </DrawerStyled>
    </div>
  );
};

export default DrawerComponent;
