import React, { MouseEvent, ReactNode } from "react";
import { Link as GatsbyLink } from "gatsby";
import styled, { CSSProperties } from "styled-components";

import { A } from "@util/standard";
import { Color, isSanityHomePage } from "@util/types";
import { Maybe, Scalars } from "@graphql-types";
import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  children?: ReactNode;
  linkText?: Maybe<Scalars["String"]>;
  internalSlug?: string;
  externalLink?: Maybe<Scalars["String"]>;
  internalLink?: Maybe<any>;
  internalLinkQuery?: Maybe<string>;
  className?: string;
  color?: Color;
  hoverColor?: Color;
  padding?: string;
  opacity?: number;
  hoverOpacity?: number;
  margin?: string;
  tabletMargin?: string;
  width?: string;
  mobileWidth?: string;
  fontSize?: string;
  fontFamily?: string;
  animatedSvg?: boolean;
  display?: CSSProperties["display"];
  state?: any;
  onMouseEnter?: () => void;
  onClick?: (e: any) => void;
}

interface LinkChildProps {
  children?: ReactNode;
  linkText?: Maybe<Scalars["String"]>;
}

const StyledGatsbyLink = styled(GatsbyLink)<{
  padding?: string;
  opacity?: number;
  margin: string | undefined;
  tabletMargin?: string;
  color?: Color;
  hoverColor?: Color;
  hoverOpacity?: number;
  width?: string;
  mobileWidth?: string;
  fontSize?: string;
  fontFamily?: string;
  animatedSvg?: boolean;
  display?: CSSProperties["display"];
}>`
  user-select: none;
  display: ${({ display }) => display ?? "inline-block"};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`}
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`}
  }

  ${({ hoverColor, hoverOpacity }) =>
    (hoverColor || hoverOpacity) &&
    `
    &:hover{
      ${hoverColor ? `color: ${colors[hoverColor]};` : ``}
      ${hoverOpacity ? `opacity: ${hoverOpacity};` : ``}
    }
  `};

  ${({ animatedSvg }) =>
    animatedSvg &&
    `
    svg{
      transition: ease transform 0.3s;
      vertical-align: middle;
    }
    &:hover{
      svg{
        transform: translateX(3px);
      }
    }
  `}
`;

const Button = styled.button<{
  padding?: string;
  opacity?: number;
  margin: string | undefined;
  tabletMargin?: string;
  color?: Color;
  hoverColor?: Color;
  hoverOpacity?: number;
  width?: string;
  mobileWidth?: string;
  fontSize?: string;
  fontFamily?: string;
  animatedSvg?: boolean;
  display?: CSSProperties["display"];
}>`
  user-select: none;
  display: ${({ display }) => display ?? "inline-block"};
  width: ${({ width }) => (width ? width : "fit-content")};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`}
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`}
  }

  ${({ hoverColor, hoverOpacity }) =>
    (hoverColor || hoverOpacity) &&
    `
    &:hover{
      ${hoverColor ? `color: ${colors[hoverColor]};` : ``}
      ${hoverOpacity ? `opacity: ${hoverOpacity};` : ``}
    }
  `};

  ${({ animatedSvg }) =>
    animatedSvg &&
    `
    svg{
      transition: ease transform 0.3s;
      vertical-align: middle;
    }
    &:hover{
      svg{
        transform: translateX(3px);
      }
    }
  `}
`;

export const getLink = (internalLink: Maybe<any>, internalLinkQuery?: Maybe<string>) => {
  if (!internalLink) return null;

  const slug =
    internalLink._type === "propertyTemplate"
      ? internalLink.slug?.current
      : internalLink.pageMeta?.slug?.current;

  if (slug && internalLinkQuery) return `/${slug}${internalLinkQuery}`;
  if (slug) return `/${slug}`;

  if (isSanityHomePage(internalLink)) return "/";

  return "/";
};

const LinkChild = ({ children, linkText }: LinkChildProps) => {
  if (children) {
    return <>{children}</>;
  }

  return <>{linkText ?? "Button"}</>;
};

const Link = ({
  linkText,
  internalLink,
  internalSlug,
  externalLink,
  className = "",
  color,
  hoverColor,
  padding,
  opacity,
  hoverOpacity,
  margin,
  tabletMargin,
  children,
  width,
  mobileWidth,
  fontSize,
  fontFamily,
  animatedSvg,
  display,
  state,
  onMouseEnter,
  onClick,
  internalLinkQuery,
}: Props) => {
  const to = internalSlug ?? getLink(internalLink, internalLinkQuery);

  if (to) {
    return (
      <StyledGatsbyLink
        activeClassName="active"
        className={className}
        to={to}
        padding={padding}
        opacity={opacity}
        hoverOpacity={hoverOpacity}
        margin={margin}
        tabletMargin={tabletMargin}
        color={color}
        hoverColor={hoverColor}
        width={width}
        mobileWidth={mobileWidth}
        fontFamily={fontFamily}
        fontSize={fontSize}
        animatedSvg={animatedSvg}
        display={display}
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        state={state}
      >
        <LinkChild children={children} linkText={linkText} />
      </StyledGatsbyLink>
    );
  }

  if (externalLink) {
    return (
      <A
        href={externalLink ?? "/"}
        className={className}
        target="_blank"
        rel="noreferrer"
        color={color}
        opacity={opacity}
        hoverOpacity={hoverOpacity}
        hoverColor={hoverColor}
        margin={margin}
        tabletMargin={tabletMargin}
        display={display}
        padding={padding}
        width={width}
        onClick={onClick}
        animatedSvg={animatedSvg}
      >
        <LinkChild children={children} linkText={linkText} />
      </A>
    );
  }

  return (
    <Button
      className={className}
      padding={padding}
      opacity={opacity}
      hoverOpacity={hoverOpacity}
      margin={margin}
      tabletMargin={tabletMargin}
      color={color}
      hoverColor={hoverColor}
      width={width}
      mobileWidth={mobileWidth}
      fontFamily={fontFamily}
      fontSize={fontSize}
      animatedSvg={animatedSvg}
      display={display}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <LinkChild children={children} linkText={linkText} />
    </Button>
  );
};

export default Link;
