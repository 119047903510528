import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SanityProduct, SanityTierDetails } from "@graphql-types";
import { Container } from "@util/standard";
import { colors, MOBILE_BREAKPOINT, tierArr } from "@util/constants";
import { BlocksContent, Drawer, Image } from "@global";

interface Props {
  product?: SanityProduct;
  onClose: () => void;
}

const ProductDetails = ({ onClose, product }: Props) => {
  const [data, setData] = useState<SanityProduct>();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (product) {
      setData(product);
      setTimeout(() => {
        setVisible(true);
      }, 100);
    } else {
      setVisible(false);
      setTimeout(() => {
        setData(undefined);
      }, 300);
    }
  }, [product]);

  if (!data) return null;

  const { title, tiers: productTiersOri, image, blockContent } = data;
  const productTiers = tierArr.map(
    str => productTiersOri?.find(tier => tier?.tierList === str) ?? { tierList: str },
  ) as SanityTierDetails[];

  return (
    <PopupWrapper>
      <Drawer duration={400} visible={visible} onClose={() => onClose()}>
        <Container flexDirection="column" alignItems="stretch">
          {title && <p className="h4">{title}</p>}
          {blockContent && <BlocksContent data={blockContent} />}

          <DetailsTable>
            {productTiers &&
              productTiers.map((tier, j) => {
                const { label, included, tierList } = tier;
                return (
                  <div key={`${title}-details-${j}`} className="row">
                    <div className="capitalize">{tierList}</div>
                    <div className="label">
                      {included ? label ? <>{label}</> : <>Included</> : <>Not Included</>}
                    </div>
                  </div>
                );
              })}
          </DetailsTable>
          {image && (
            <ImageWrapper>
              <Image data={image} />
            </ImageWrapper>
          )}
        </Container>
      </Drawer>
    </PopupWrapper>
  );
};

export default ProductDetails;

const PopupWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 500;

  .popupWrapper {
    padding: 80px 0;
  }

  .popupContainer {
    justify-content: space-between;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .popupWrapper {
      padding: 50px 0;
    }
  }
`;

const DetailsTable = styled.div`
  .row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.main};
    padding: 15px 0;

    &:last-child {
      border-bottom: none;
    }
  }
`;

const ImageWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
`;
