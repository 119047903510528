import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";

import { Image, Link, Button } from "@global";
import { colors, colorsRGB, fontWeights } from "@util/constants";
import { isSanityCtaWithImage, isSanityLink, isSanityMenuItem } from "@util/types";
import { Maybe, SanityLinkOrMenuItem } from "@graphql-types";
import { em } from "@util/helper";
import { Container } from "@util/standard";

interface Props {
  menuItems: Maybe<SanityLinkOrMenuItem>[];
  isMenuOpen: boolean;
  language: {
    account: string;
  };
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  handleAccountClick: () => void;
}

const MobileWrapper = styled.div<{ isMenuOpen: boolean }>`
  position: fixed;
  top: 0;
  z-index: 98;
  width: 100%;
  height: 100vh;
  background: ${colors.white};
  left: -100%;
  opacity: 0;
  transition: left 0.3s ease, opacity 0.1s ease;
  padding: 80px 0 0;
  overflow: hidden;

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    `
    left: 0;
    opacity: 1;
  `}
`;

const ParentWrapper = styled.div<{ isVisible: boolean }>`
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 0 20px 40px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  overflow: scroll;
  transition: transform 0.3s ease, opacity 0.3s ease;

  ${({ isVisible }) =>
    isVisible
      ? `
    opacity: 1;
    transform: translateX(0);
  `
      : `
    pointer-events: none;
    opacity: 0;
    transform: translateX(-100%);
  `}
`;

const NavList = styled.li<{ isBack?: boolean; hasArrow?: boolean }>`
  font-size: ${em(20, 14)};
  font-weight: ${fontWeights.semibold};
  border-bottom: 1.5px solid ${colorsRGB.main(0.1)};
  padding: 20px 10px;

  &:last-of-type {
    border-bottom: 0;
  }

  .link {
    padding: 20px;
    width: 100%;
    text-align: left;
    position: relative;
    display: flex;
  }
`;

const NavListArrow = styled(NavList)`
  .link {
    padding: 20px 60px 20px 20px;
  }

  > *:first-child {
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.3s ease;
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 13L7 7L0.999999 1' stroke='%23133456' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 10px;
      height: 20px;
    }
  }
`;

const NavListBack = styled(NavListArrow)`
  .link {
    padding: 20px 20px 20px 60px;
  }

  > *:first-child {
    &:after {
      left: 20px;
      transform: translateY(-50%) rotate(180deg);
    }
  }
`;

const ChildWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  overflow: scroll;
  transition: transform 0.3s ease, opacity 0.3s ease;

  ${({ isVisible }) =>
    isVisible
      ? `
    opacity: 1;
    transform: translateX(0);
    `
      : `
    pointer-events: none;
    opacity: 0;
    transform: translateX(100%);
  `}
`;

const MainLinksContainer = styled.div`
  padding: 0 20px;
`;

const SideLinksContainer = styled.div`
  background: ${colors.main}};
  color: ${colors.white}};
  width: calc(100% - 20px);
  border-radius: 0 100px 0 0;
  padding: 50px 50px 40px 40px;
  margin-top: 30px;
`;

const MobileMenu = ({ menuItems, isMenuOpen, setIsMenuOpen, handleAccountClick }: Props) => {
  const [hoverIndex, setHoverIndex] = useState(-1);

  const handleClick = () => {
    setIsMenuOpen(false);
    setHoverIndex(-1);
  };

  return (
    <MobileWrapper isMenuOpen={isMenuOpen}>
      <Container position="relative" height="100%">
        <ParentWrapper isVisible={hoverIndex === -1}>
          <ul>
            {menuItems.map((item, i) => {
              if (!item) return;

              if (isSanityLink(item)) {
                return (
                  <NavList key={item._key}>
                    <Link className="link" {...item} onClick={handleClick} />
                  </NavList>
                );
              }

              if (isSanityMenuItem(item)) {
                const { title } = item;

                return (
                  <NavListArrow key={item._key}>
                    <button className="link" onClick={() => setHoverIndex(i)}>
                      {title}
                    </button>
                  </NavListArrow>
                );
              }

              return;
            })}
            <Button onClick={handleAccountClick} linkText={"My Homesell"} theme={"base"} />
          </ul>
        </ParentWrapper>
        {menuItems.map((item, i) => {
          if (!item) return;

          if (isSanityMenuItem(item)) {
            const { title, links, ctasWithImage } = item;

            return (
              <ChildWrapper isVisible={hoverIndex === i} key={item._key}>
                {ctasWithImage && ctasWithImage.length > 0 && (
                  <MainLinksContainer>
                    <ul>
                      <NavListBack>
                        <button className="link" onClick={() => setHoverIndex(-1)}>
                          {title}
                        </button>
                      </NavListBack>
                      {ctasWithImage.map(child => {
                        if (!child) return;

                        const childImage = isSanityCtaWithImage(child) ? child.image : null;

                        return (
                          <NavList key={child._key}>
                            <Link className="link" {...child.link} onClick={handleClick}>
                              {childImage ? <Image data={childImage} /> : <>{child.title}</>}
                            </Link>
                          </NavList>
                        );
                      })}
                    </ul>
                  </MainLinksContainer>
                )}
                {links && links.length > 0 && (
                  <SideLinksContainer>
                    <ul>
                      {links.map(link => {
                        if (!link) return;

                        return (
                          <li key={link._key}>
                            <Link
                              className="animate"
                              padding="13px"
                              onClick={handleClick}
                              {...link}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </SideLinksContainer>
                )}
              </ChildWrapper>
            );
          }

          return;
        })}
      </Container>
    </MobileWrapper>
  );
};

export default MobileMenu;
