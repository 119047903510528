import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const RegionQuery = () => {
  const { allSanityRegion }: Query = useStaticQuery(graphql`
    {
      allSanityRegion {
        nodes {
          regionName
        }
      }
    }
  `);

  return allSanityRegion?.nodes ?? [];
};

export default RegionQuery;
