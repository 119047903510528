import React, { useState } from "react";
import { BlocksContent, Button, TextField } from "@global";
import styled from "styled-components";
import { GridContainer } from "@util/standard";
import { useFormContext } from "./utils";
import { toast } from "react-toastify";

const DescriptionTab = () => {
  const { pageData, inputs, changeInput } = useFormContext();

  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateDescription = async () => {
    try {
      const details = `Bedrooms: ${inputs.details.bed} Bathrooms: ${inputs.details.bath} Parking: ${inputs.details.parking}, ${inputs.description.userInput}`;

      setIsLoading(true);

      const res = await fetch(`/.netlify/functions/chatGPTEndpoint`, {
        method: "POST",
        body: JSON.stringify(details),
      });

      if (!res.ok) throw new Error(res.statusText);

      const json = await res.json();
      const messageResponse = json[0]?.message?.content;
      if (messageResponse) changeInput("description.chatGPTResponse", messageResponse);
      console.log("chatGPTResponse", json);
    } catch (err: any) {
      console.log("Error on generate: ", err);
      toast.error("Error on generate");
    }
    setIsLoading(false);
  };

  return (
    <Wrapper className="tab-wrapper">
      <BlocksContent margin="0 0 50px" data={pageData.propertyDescriptionHeading} />
      <TextField
        id="heading"
        helperText={pageData.propertyListingTitleHelpText}
        label="Listing title"
        placeholder="Make sure this stands out! Buyers see the listing title in search results"
        value={inputs.description.heading}
        onChange={e => changeInput("description.heading", e.target.value)}
        maxLength={50}
      />
      {pageData.chatGPTPromptText && (
        <BlocksContent margin="50px 0 0" data={pageData.chatGPTPromptText} />
      )}
      <GridContainer repeat={2} width="100%" margin="50px 0">
        <select
          onChange={e => changeInput("description.type", e.target.value)}
          value={inputs.description.type}
        >
          <option value="myself">Write it myself</option>
          <option value="chatGPT">Generate it</option>
          <option value="homesell">Let homesell create it</option>
        </select>
      </GridContainer>
      <GridContainer gap="30px" repeat={1}>
        {inputs.description.type === "myself" && (
          <div>
            <p>Enter your listing description below. There is a maximum character length of 1800</p>
            <textarea
              style={{ height: 300 }}
              name="userInput"
              maxLength={5200}
              value={inputs.description.userInput}
              onChange={e => changeInput("description.userInput", e.target.value)}
            />
            <p>{5200 - inputs.description.userInput.length} characters remaining</p>
          </div>
        )}
        {inputs.description.type === "chatGPT" && (
          <div>
            <p>
              Provide us with more information about your property such as a list of the rooms,
              interior features, exterior features, parking and location details.
            </p>
            <textarea
              style={{ height: 300 }}
              name="description"
              maxLength={5200}
              value={inputs.description.userInput}
              onChange={e => changeInput("description.userInput", e.target.value)}
            />

            <p>
              By clicking ‘Generate listing description’ above you: understand that the property
              information you have supplied us will be sent to a third party, OpenAI (note personal
              details such as name/contacts will NOT be sent to OpenAI) are agreeing to allow us to
              send that information to OpenAI confirming that you will check and edit the generated
              description to ensure it is accurate for use in advertising your property for sale on
              various listing portals and promotional materials
            </p>
            <Button
              linkText="Generate listing description"
              theme="base"
              width="fit-content"
              onClick={handleGenerateDescription}
              loading={isLoading}
            />
            <textarea
              style={{ height: 300, marginTop: "50px" }}
              name="chatGPTResponse"
              maxLength={5200}
              value={inputs.description.chatGPTResponse}
              onChange={e => changeInput("description.chatGPTResponse", e.target.value)}
            />
            <p>{5200 - inputs.description.chatGPTResponse.length} characters remaining</p>
          </div>
        )}
        {inputs.description.type === "homesell" && (
          <div>
            <p>
              Enter 4 - 5 bullet points on the things you love about your house to help us write the
              listing description
            </p>
            <textarea
              style={{ height: 300 }}
              name="description"
              maxLength={1800}
              value={inputs.description.userInput}
              onChange={e => changeInput("description.userInput", e.target.value)}
            />
            <p>{5200 - inputs.description.userInput.length} characters remaining</p>
          </div>
        )}
        {inputs.description.userInput && (
          <>
            <TextField
              inputType="textarea"
              style={{ height: 300 }}
              id="brochureText"
              placeholder="Enter a short description (max 1000 characters) that will be used for brochure text’"
              label="Brochure Text"
              value={inputs.description.brochureText}
              onChange={e => changeInput("description.brochureText", e.target.value)}
              maxLength={1300}
              helperText={`${5200 - inputs.description.brochureText.length} characters remaining`}
            />
          </>
        )}
      </GridContainer>
    </Wrapper>
  );
};

export default DescriptionTab;

const Wrapper = styled.div`
  #heading {
    border: 1px solid #e0e0e0;
    padding-left: 10px;
  }
`;
