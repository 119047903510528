import React, { Fragment } from "react";
import styled from "styled-components";

import { SanityBlogInner } from "@graphql-types";
import {
  colors,
  fontWeights,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT
} from "@util/constants";
import { Link, Image } from "@global";
import { em } from "@util/helper";
import { useHandleQuery } from "@api";

interface Props {
  content: SanityBlogInner;
}

const Wrapper = styled.div`
  width: 100%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    h5{
      font-size: ${em(18, 16)};
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 300px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding-bottom: 115%;
  position: relative;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
  background: ${colors.black};

  a{
    &:after{
      content: 'Read More';
      display: inline-block;

      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      border: 2px solid currentColor;
      border-radius: 50px;
      color: ${colors.white};
      padding: 13px 40px;
      pointer-events: none;
      transform: translateX(-50%) translateY(-30%);
      opacity: 0;
      transition: transform 0.3s ease, opacity 0.3s ease;
      white-space: nowrap;
    }
    &:hover{
      .gatsby-image-wrapper{
        opacity: 0.5;
      }
      &:after{
        transform: translateX(-50%) translateY(-50%);
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-bottom: 20px;
  }
`;

const Label = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 30px;
  background: ${colors.know};
  color: ${colors.white};
  border-radius: 0 100px 100px 0;
  padding: 10px 20px 10px 15px;
`;

const Tags = styled.p`
  font-weight: ${fontWeights.semibold};
  margin-bottom: 15px;
`;

const Separator = styled.span`
  display: inline-block;
  margin: 0 10px;
`;

const ArticleCard = ({ content }: Props) => {

  const { title, categories, image, slug } = content;
  const handles = useHandleQuery();

  return (
    <Wrapper>
      {image &&
        <ImageWrapper>
          <Link internalSlug={`/${handles.know?.pageMeta?.slug?.current}/${slug?.current}`} >
            <Image data={image} isBackground />
            <Label>Know</Label>
          </Link>
        </ImageWrapper>
      }
      {categories && categories.length > 0 &&
        <Tags>
          {categories.map((category, i) => {
            if (!category?.title) return;

            return <Fragment key={`category-${i}`}>{category.title}{categories.length - 1 !== i && <Separator>|</Separator>}</Fragment>
          })}
        </Tags>
      }
      {title &&
        <Link internalSlug={`/${handles.know}/${slug}`}>
          <h5>{title}</h5>
        </Link>
      }

    </Wrapper >
  );
};

export default ArticleCard;