export const defProps = {
  firstName: {
    theme: "filled" as const,
    id: "firstName",
    name: "firstName",
    label: "First Name",
    placeholder: "First Name",
    autocomplete: "given-name",
  },
  lastName: {
    theme: "filled" as const,
    id: "lastName",
    label: "Last Name",
    name: "lastName",
    placeholder: "Last Name",
    autocomplete: "family-name",
  },
  email: {
    theme: "filled" as const,
    id: "email",
    type: "email",
    name: "email",
    placeholder: "Enter your Email",
    label: "Email",
    autocomplete: "email",
  },
  password: {
    theme: "filled" as const,
    id: "password",
    type: "password",
    name: "password",
    placeholder: "Enter your Password",
    label: "Password",
  },
  phone: {
    theme: "filled" as const,
    id: "phone",
    label: "Phone",
    type: "tel",
    name: "phone",
    placeholder: "Phone starting with +64",
    autocomplete: "phone",
  },
};

export const regex = {};

/** Format a input (e.g. remove spaces from email or password fields) */
export const formatInput = (inputName: string, value: unknown) => {
  if (typeof value === "string") {
    switch (inputName) {
      case "email":
        return value.replace(/ /g, "");
    }
  }

  return value;
};

/** Validate the input value, return a error string message if invalid, or an empty string if valid */
export const validateInput = (inputName: string, value: unknown): string => {
  if (value === undefined) return "";

  if (typeof value === "string") {
    switch (inputName) {
      case "email":
        if (value.length < 1) return "Required";
        break;
    }
  }

  return "";
};

export const validateAllInputs = (inputs: Record<string, unknown>, ignore?: string[]) => {
  let errorObj: Record<string, string> = {};

  Object.keys(inputs)
    .filter(el => !ignore || !ignore?.includes(el))
    .forEach(input => {
      const error = validateInput(input, inputs[input as keyof typeof inputs]);
      if (error) errorObj[input as keyof typeof inputs] = error;
    });

  return Object.keys(errorObj).length ? errorObj : null;
};
