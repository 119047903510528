import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";

import { useStore } from "@state/store";
import { Button, Drawer, Link } from "@global";
import { useHandleQuery } from "@api";
import { HomeSellLogo } from "@util/svg";
import { Container } from "@util/standard";
import { executePaymentRedirect, useRemoveCartItem } from "@util/cartHooks";
import { colorsRGB } from "@util/constants";
import Account from "./account";

interface Props {
  path: string;
}

const CartDrawer = ({ path }: Props) => {
  const [loading, setLoading] = useState(false);
  const { cart, cartOpen, checkoutInitiated, user } = useStore();
  const { go } = useHandleQuery();

  useEffect(() => {
    useStore.setState({ cartOpen: false });
  }, [path]);

  const handleContinueClick = async () => {
    if (user?.id) {
      setLoading(true);
      await executePaymentRedirect();
      setLoading(false);
    }
  };

  return (
    <Drawer isCart visible={cartOpen} onClose={() => useStore.setState({ cartOpen: false })}>
      {cart.totalItems > 0 ? (
        <>
          <Wrapper isVisible>
            <h4>Cart</h4>
            <Container margin="35px 0 0" width="100%" flexFlow="column nowrap">
              {cart.packages.map((pack, i) => {
                return (
                  <Fragment key={`cartlineitem-${i}`}>
                    <LineItem>
                      <Link internalLink={go}>
                        <HomeSellLogo type={pack.type} />
                      </Link>
                      <Container alignItems="flex-start">
                        <Container width="100%" flexFlow="column nowrap">
                          <Title>
                            {pack.tier} Package{" "}
                            {pack.items && pack.items.length > 0 && <>&ndash; Customised</>}
                          </Title>
                          <p>${pack.totalPrice} incl. GST</p>
                        </Container>
                        <button className="remove" onClick={() => useRemoveCartItem(i)}>
                          Remove
                        </button>
                      </Container>
                    </LineItem>
                    {pack.items && pack.items.length > 0 && (
                      <LineItemAddOns>
                        {pack.items.map(item => {
                          if (!item) return;

                          return (
                            <Container
                              key={`cart-${item.title}`}
                              alignItems="flex-start"
                              margin="0 0 15px"
                            >
                              <Container width="100%" flexFlow="column nowrap">
                                <Title>{item.title}</Title>
                                {(item.variant || item.quantity > 1) && (
                                  <p>
                                    {item.variant
                                      ? `Option: ${item.variant.title}`
                                      : `Quantity: ${item.quantity}`}
                                  </p>
                                )}
                              </Container>
                              {/* <p>${item.totalPrice}</p> */}
                            </Container>
                          );
                        })}
                      </LineItemAddOns>
                    )}
                  </Fragment>
                );
              })}
            </Container>

            <Button
              loading={loading}
              onClick={handleContinueClick}
              margin="35px 0 0"
              linkText={user?.id ? "Proceed to checkout" : "Sign Up And Purchase"}
            />
          </Wrapper>
          {!(checkoutInitiated && user?.id) && (
            <Account isCart onLoggedIn={() => executePaymentRedirect()} />
          )}
        </>
      ) : (
        <NoItemWrapper>
          <p>There are no items in the cart.</p>
          <Button internalLink={go} linkText="Browse HomeSell Go" />
        </NoItemWrapper>
      )}
    </Drawer>
  );
};

export default CartDrawer;

export const Wrapper = styled.div<{
  isVisible: boolean;
  isInvert?: boolean;
}>`
  min-height: calc(90vh - 100px);
  transition: transform 0.3s ease, opacity 0.3s ease;

  display: flex;
  flex-flow: column nowrap;

  ${({ isVisible, isInvert }) =>
    isVisible
      ? `
    opacity: 1;
    transform: translateX(0);
  `
      : `
    position: absolute;
    left: -10000%;
    pointer-events: none;
    opacity: 0;
    transform: ${isInvert ? `translateX(100%)` : `translateX(-100%)`};
  `}
`;

const NoItemWrapper = styled.div`
  text-align: center;
`;

const LineItem = styled.div`
  border-bottom: 1.5px solid ${colorsRGB.main(0.2)};
  padding: 35px 0 30px;

  &:first-child {
    border-top: 1.5px solid ${colorsRGB.main(0.2)};
  }

  svg {
    height: 17px;
    margin-bottom: 15px;
  }

  .remove {
    text-decoration: underline;
    font-size: 14px;
    text-underline-offset: 3px;
    opacity: 1;

    &:hover {
      opacity: 0.5;
    }
  }
`;

const Title = styled.p`
  margin-bottom: 0;
  text-transform: capitalize;
`;

const LineItemAddOns = styled.div`
  padding: 25px 0 5px;
  border-bottom: 1.5px solid ${colorsRGB.main(0.2)};
`;
