import { SanityOnboarding } from "@graphql-types";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { FormContext, initialState, submitToSanity, TABS } from "./utils";
import Tabs from "./tabs";
import { Button } from "@global";
import { mediaQuery } from "@styles/mediaQueries";
import InformationTab from "./informationTab";
import DescriptionTab from "./descriptionTab";
import SellerTab from "./sellerTab";
import { colorsRGB } from "@util/constants";
import { em } from "@util/helper";
import { navigate } from "gatsby";
import { useStore } from "@state/store";

interface Props {
  pageData: SanityOnboarding;
}

const PropertyOnboarding = (props: Props) => {
  const [openTab, setOpenTab] = useState(0);
  const [inputs, setInputs] = useState(initialState);
  const [submitting, setSubmitting] = useState(false);
  const refWrapper = useRef<HTMLDivElement>(null);
  const userData = useStore(s => s.user);
  const setUser = useStore(s => s.setUser);

  const handleChangeInput = (field: string, value: any) => {
    const fieldArr = field.split(".");

    setInputs((p: any) => ({
      ...p,
      [fieldArr[0]]: fieldArr[1]
        ? {
            ...(p[fieldArr[0]] ?? {}),
            [fieldArr[1]]: value,
          }
        : value,
    }));
  };

  const handleChangeTab = async (dir: "next" | "prev" | number) => {
    let tabNum = typeof dir === "number" ? dir : dir === "next" ? openTab + 1 : openTab - 1;
    if (tabNum < 0) tabNum = 0;
    if (tabNum > TABS.length - 1) {
      return handleSubmit();
    }

    setOpenTab(tabNum);
    refWrapper.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const propId = await submitToSanity(inputs, userData, props.pageData);
    setSubmitting(false);

    if (propId) {
      setUser({ ...userData!, propertyIds: [...(userData?.propertyIds ?? []), propId] });
      setInputs(initialState);
      setTimeout(() => {
        navigate("/account");
      }, 3000);
    }
  };

  return (
    <FormContext.Provider
      value={{
        pageData: props.pageData,
        inputs,
        changeInput: handleChangeInput,
        openTab,
        changeTab: handleChangeTab,
      }}
    >
      <Wrapper ref={refWrapper}>
        <Tabs openTab={openTab} disabled={submitting} onChange={handleChangeTab} />
        {[<InformationTab />, <DescriptionTab />, <SellerTab />][openTab]}
        <div className="nav-buttons">
          {openTab === 0 ? null : (
            <Button
              theme="baseOutline"
              width="fit-content"
              disabled={submitting}
              linkText={`Back to ${TABS[openTab - 1]?.title}`}
              onClick={() => handleChangeTab("prev")}
            />
          )}
          <Button
            width="fit-content"
            disabled={submitting}
            linkText={
              TABS[openTab + 1]
                ? `Continue to ${TABS[openTab + 1]?.title}`
                : submitting
                ? "Submitting..."
                : "Submit"
            }
            onClick={() => handleChangeTab("next")}
          />
        </div>
      </Wrapper>
    </FormContext.Provider>
  );
};

export default PropertyOnboarding;

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto 70px;
  padding: 20px;

  ${mediaQuery.tabletDown} {
    margin: 0 0 70px;
    width: 100%;
  }

  .nav-buttons {
    display: flex;
    gap: 20px;
    margin-top: 50px;

    ${mediaQuery.tabletDown} {
      flex-direction: column;
    }
  }

  .tab-wrapper {
    max-width: 720px;
    width: 100%;

    textarea {
      width: 100%;
    }

    input,
    select {
      width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid ${colorsRGB.main(0.4)};
      padding: 10px 0;
      font-size: ${em(20)};

      &:active,
      &:focus {
        outline: none;
      }

      & + button {
        background: none;
      }
    }

    hr {
      margin: 70px 0;
    }
  }
`;
