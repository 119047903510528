import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./header";
import Footer from "./footer";
import { GlobalStyle, Main } from "@styles/globalStyles";
import { PageContext } from "@util/types";
import CartDrawer from "@shared/drawer/cartDrawer";
import { PageProps } from "gatsby";
import { pages } from "@util/constants";

const queryClient = new QueryClient();
interface Props extends PageProps {
  pageContext: PageContext;
}

const plainPageConditions = (props: Props) => {
  const conditionsArr = [
    props.location.pathname.indexOf(pages.paymentSuccessful) > -1,
    //Add new conditions here
  ];

  return conditionsArr.includes(true);
};

const headerOverlayConditions = (props: Props) => {
  //Default to be overlay because 404 page requires header to be overlayed
  //Add rules where if any condition returns true, header will NOT be overlayed
  const conditionsArr = [
    props.location.pathname.indexOf(pages.account) > -1,
    props.location.pathname.indexOf("preview") > -1,
    //Add new conditions here
  ];

  return !conditionsArr.includes(true);
};

function Layout(props: Props) {
  const { children, pageContext } = props;
  const [headerIsOverlay, setHeaderIsOverlay] = useState(true);
  const [headerIsDark, setHeaderIsDark] = useState(false);

  useEffect(() => {
    if (!props) return;
    setHeaderIsOverlay(pageContext?.headerIsOverlay ?? headerOverlayConditions(props));
    setHeaderIsDark(pageContext?.headerIsDark ?? !headerOverlayConditions(props));
  }, [props]);

  const plainPage = plainPageConditions(props);

  if (plainPage) {
    return (
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        <Main>{children}</Main>
        <ToastContainer position="bottom-right" />
      </QueryClientProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <Header headerIsOverlay={headerIsOverlay} headerIsDark={headerIsDark} />
      <CartDrawer path={pageContext.pagePath} />
      <Main>{children}</Main>
      <ToastContainer position="bottom-right" />
      <Footer />
    </QueryClientProvider>
  );
}

export default Layout;
