import React from "react";
import styled from "styled-components";

interface Props {
  options: { title: string; value: string }[];
  value: string;
  onChange: (val: string) => void;
  id: string;
  label?: string;
}

const Toggle = ({ options, label, value, onChange, id }: Props) => {
  return (
    <Wrapper>
      {label && <p>{label}</p>}
      <div className="options-list">
        {options.map(option => {
          return (
            <div className="option">
              <input
                type="radio"
                checked={value === option.value}
                onChange={() => onChange(option.value)}
                id={`${id}-${option.value}`}
              />
              <label htmlFor={`${id}-${option.value}`}>{option.title}</label>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default Toggle;

const Wrapper = styled.div`
  & > p {
    margin-bottom: 10px;
  }
  .options-list {
    display: flex;
    gap: 30px;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;

    .option {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
`;
