import { ChangeRequest, Order, PackageProp } from "@state/types";
import { createDocument } from "@util/firebase";
import { returnTimestampFromSeconds } from "@util/helper";
import { Container, P } from "@util/standard";
import React, { useState } from "react";
import Button from "./button";
import firebase from "gatsby-plugin-firebase";
import { useStore } from "@state/store";

interface Props {
  notes:
    | {
        note: StringConstructor;
        timestamp: any;
        fromUser?: string | undefined;
        fromUserName?: string | undefined;
      }[]
    | undefined;
  order?: Order;
  line?: PackageProp;
  index?: number;
  changeRequest?: ChangeRequest;
  isAdmin?: boolean;
}

const Note = ({ notes, order, line, index, changeRequest, isAdmin = false }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newNote, setNewNote] = useState("");
  const { setShouldUpdateOrders, user, setShouldUpdateFirebaseData } = useStore();

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleOnChange = (e: any) => {
    setNewNote(e.target.value);
  };

  const handleSaveNote = async () => {
    const newNoteObject = {
      note: newNote,
      timestamp: new Date(),
    };

    const newNotes = () => {
      if (!notes) {
        return [newNoteObject];
      }

      return [...notes, newNoteObject];
    };

    const updatedPackage = {
      ...line,
      admin: { ...line.admin, notes: newNotes() },
    };

    const orderToUpdate = {
      ...order,
      packages: order.packages.map((orderPackage, packageIndex) => {
        if (packageIndex == index) {
          return updatedPackage;
        }
        return orderPackage;
      }),
    };

    console.log({ orderToUpdate });

    await createDocument("orders", orderToUpdate, order?.uuid);
    setIsEditing(false);
    setShouldUpdateOrders(true);
  };

  const handleUpdateChangeRequest = async () => {
    const newNoteObject = {
      note: newNote,
      timestamp: new Date(),
      fromUser: user?.id,
      fromUserName: `${user?.firstName} ${user?.lastName}`,
    };

    const newNotes = () => {
      if (!notes) {
        return [newNoteObject];
      }

      return [...notes, newNoteObject];
    };

    const updatedChangeRequest = {
      ...changeRequest,
      notes: newNotes(),
    };

    await createDocument("changeRequest", updatedChangeRequest, changeRequest?.id);
    setIsEditing(false);
    setShouldUpdateFirebaseData(true);
  };

  const buttonText = () => {
    if (changeRequest && isAdmin) {
      return "Respond to user";
    }
    if (changeRequest && !isAdmin) {
      return "Reply";
    }
    return "Add note";
  };

  return (
    <Container flexDirection="column" width="100%">
      <hr />
      <P margin="0px" fontWeight="bold">
        {changeRequest ? "Correspondance" : "Notes"}
      </P>
      {notes &&
        notes.length > 0 &&
        notes.map((item, index) => {
          if (item == null || item == undefined) return null;
          const formattedDate = returnTimestampFromSeconds(item.timestamp?.seconds);
          return (
            <Container flexDirection="column" margin="10px 0px" key={index}>
              <P margin="0" fontSize={13}>
                {formattedDate} | {item.fromUserName && `${item.fromUserName}`}
              </P>
              <P margin="0px">{item.note}</P>
            </Container>
          );
        })}
      {isEditing ? (
        <Container width="100%" flexDirection="column" margin="30px auto">
          <P fontWeight="bold">{changeRequest ? "Message" : "New note"}</P>
          <textarea onChange={handleOnChange} />
          <Button
            onClick={changeRequest ? handleUpdateChangeRequest : handleSaveNote}
            linkText="Save note"
            margin="10px 10px auto auto"
          />
        </Container>
      ) : (
        <Button
          onClick={() => handleClick()}
          linkText={buttonText()}
          width="fit-content"
          margin="0px 10px auto auto"
        />
      )}
    </Container>
  );
};

export default Note;
