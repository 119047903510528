import { CartItem, Package } from "@util/types";
import { useStore } from "@state/store";
import { createDocument, updateDocument } from "./firebase";
import { pages } from "./constants";
import { toast } from "react-toastify";
import { generateString } from "./helper";
import { sanityClient } from "@lib/sanityClient";
import { SanityProperty } from "@graphql-types";

export const useAddToCart = (pack: Package, items?: CartItem[]) => {
  const original = useStore.getState().cart;
  const isCustomised = Boolean(items?.length);

  const itemObjects = items?.map(item => {
    const totalPrice = (item.variant ? item.variant?.price ?? 0 : item.price ?? 0) * item.quantity;

    return {
      title: item.title,
      quantity: item.quantity,
      totalPrice: totalPrice,
      sku: item.sku,
      price: item.price,
      variant: item.variant ?? null,
    };
  });

  const totalPackagePrice =
    itemObjects && itemObjects.length > 0
      ? pack.price + itemObjects?.map(obj => obj.totalPrice).reduce((a, b) => a + b)
      : pack.price;

  const packages = [
    ...original.packages,
    {
      type: pack.type,
      tier: pack.tier,
      price: pack.price,
      quantity: 1,
      totalPrice: totalPackagePrice,
      customised: isCustomised,
      items: itemObjects ?? null,
    },
  ];

  const cartObject = {
    packages: packages,
    totalItems: packages.length,
    totalPrice:
      packages.length > 0 ? packages.map(pack => pack.totalPrice).reduce((a, b) => a + b) : 0,
    uuid: original.uuid,
  };

  useStore.setState({
    cart: cartObject,
    cartOpen: true,
    checkoutInitiated: false,
  });
};

export const useRemoveCartItem = (index: number) => {
  const original = useStore.getState().cart;
  const packages = original.packages.filter((obj, i) => i !== index);

  const cartObject = {
    packages: packages,
    totalItems: packages.length,
    totalPrice:
      packages.length > 0 ? packages.map(pack => pack.totalPrice).reduce((a, b) => a + b) : 0,
    uuid: packages.length > 0 ? original.uuid : generateString(22),
  };

  useStore.setState({
    cart: cartObject,
    cartOpen: true,
    checkoutInitiated: false,
  });
};

export const executePaymentRedirect = async () => {
  const { cart, user } = useStore.getState();
  useStore.setState({ checkoutInitiated: false });

  //Create draft order
  if (!cart || !user?.id) return;

  console.log({ ...cart, uid: user.id, createdAt: new Date().toISOString(), status: "draft" });
  try {
    await createDocument(
      "orders",
      { ...cart, uid: user.id, createdAt: new Date().toISOString(), status: "draft" },
      cart.uuid,
    );
    const updateData = user.orders ? [...user.orders, cart.uuid] : [cart.uuid];
    await updateDocument("users", user.id, { orders: updateData });
    useStore.setState({
      user: { ...user, orders: updateData },
    });

    const res = await sanityClient
      .patch(property._id)
      .set({ notes: formattedData })
      .commit<SanityProperty>();

    const requestQuery = {
      totalPrice: cart.totalPrice,
      uuid: cart.uuid,
      customerDetails: `${user?.firstName} ${user?.lastName}`,
      orderDetails: `${cart.packages
        .map(item => `${item.type} - ${item.tier} x ${item.quantity}`)
        .join(", ")}`,
      dynamicUrl: `${window.location.origin}${pages.paymentSuccessful}`,
      merchantSession: generateString(12),
    };

    const response = await fetch(`/.netlify/functions/getPaystationUrl`, {
      method: "POST",
      body: JSON.stringify(requestQuery),
    });

    const value = await response.json();
    console.log("value", value);
    const url = value?.InitiationRequestResponse?.DigitalOrder;

    if (url) {
      return (window.location.href = url);
    } else {
      throw new Error("Error on generate purchase URL");
    }
  } catch (err) {
    toast.error("Error on purchase package, please try again");
    console.log(err);
  }
};
