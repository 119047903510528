import { Maybe, SanityExternalPropertyLink } from "@graphql-types";
import { Container } from "@util/standard";
import React from "react";
import Button from "../button";

interface Props {
  data: Maybe<SanityExternalPropertyLink>[];
}

const ExternalLinks = ({ data }: Props) => {
  if (!data) return null;

  return (
    <Container alignItems="flex-start" flexDirection="column" margin="0 0 50px">
      <h4>External Links</h4>
      <Container flexWrap="wrap" margin="0" style={{ gap: "10px" }}>
        {data.map(link => {
          if (!link) return null;
          return (
            <Button
              key={link.url}
              linkText={`${link.title}`}
              theme="baseOutline"
              margin="0 "
              externalLink={link.url}
            />
          );
        })}
      </Container>
    </Container>
  );
};

export default ExternalLinks;
