export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const MAX_SCREEN = 1900;

export const mediaQueryWidth = {
  laptopUp: `(min-width: ${LAPTOP_BREAKPOINT + 1}px)`,
  laptopDown: `(max-width: ${LAPTOP_BREAKPOINT}px)`,
  twentyUp: `(min-width: ${TWENTY_INCH_SCREEN + 1}px)`,
  twentyDown: `(max-width: ${TWENTY_INCH_SCREEN}px)`,
  smallLaptopUp: `(min-width: ${SMALL_LAPTOP_BREAKPOINT + 1}px)`,
  smallLaptopDown: `(max-width: ${SMALL_LAPTOP_BREAKPOINT}px)`,
  tabletUp: `(min-width: ${TABLET_BREAKPOINT + 1}px)`,
  tablet: `(min-width: ${TABLET_BREAKPOINT + 1}px) and (max-width: ${LAPTOP_BREAKPOINT}px)`,
  tabletDown: `(max-width: ${TABLET_BREAKPOINT}px)`,
  ipadUp: `(min-width: ${IPAD_BREAKPOINT + 1}px)`,
  ipad: `(min-width: ${IPAD_BREAKPOINT + 1}px) and (max-width: ${TABLET_BREAKPOINT}px)`,
  ipadDown: `(max-width: ${IPAD_BREAKPOINT}px)`,
  mobileUp: `(min-width: ${MOBILE_BREAKPOINT + 1}px)`,
  mobileDown: `(max-width: ${MOBILE_BREAKPOINT}px)`,
};

const m = `@media only screen and`;
export const mediaQuery = {
  mobileDown: `${m} ${mediaQueryWidth.mobileDown}`,
  mobileUp: `${m} ${mediaQueryWidth.mobileUp}`,
  ipadDown: `${m} ${mediaQueryWidth.ipadDown}`,
  ipad: `${m} ${mediaQueryWidth.ipad}`,
  ipadUp: `${m} ${mediaQueryWidth.ipadUp}`,
  tabletDown: `${m} ${mediaQueryWidth.tabletDown}`,
  tablet: `${m} ${mediaQueryWidth.tablet}`,
  tabletUp: `${m} ${mediaQueryWidth.tabletUp}`,
  smallLaptopDown: `${m} ${mediaQueryWidth.smallLaptopDown}`,
  smallLaptopUp: `${m} ${mediaQueryWidth.smallLaptopUp}`,
  laptopDown: `${m} ${mediaQueryWidth.laptopDown}`,
  laptopUp: `${m} ${mediaQueryWidth.laptopUp}`,
  twentyUp: `${m} ${mediaQueryWidth.twentyUp}`,
  twentyDown: `${m} ${mediaQueryWidth.twentyDown}`,
  print: `@media print`,
};
