import React, { useState } from "react";
import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Controller } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/controller";

import { Maybe, SanityImageWithMeta } from "@graphql-types";
import { Container } from "@util/standard";
import { colors, MOBILE_BREAKPOINT, SMALL_LAPTOP_BREAKPOINT } from "@util/constants";
import { Image } from "@global";
import { SwiperStyle } from "../swiper";
import { useCheckScreenWidth } from "@util/hooks";
import { em, getPackageCategory } from "@util/helper";
import { Color, LogoType } from "@util/types";
import { usePropertyContext } from "./utils";
import { mediaQuery } from "@styles/mediaQueries";

interface Props {
  photos?: Maybe<Maybe<string>[]>;
  categories?: Maybe<Maybe<string>[]>;
  status?: Maybe<string>;
}

const PhotosList = ({ data }: { data: (string | SanityImageWithMeta | null)[] }) => {
  if (!data) return null;

  return (
    <>
      {data.map(photo => {
        if (!photo) return;
        const isUrl = typeof photo === "string";

        return (
          <SwiperSlide key={isUrl ? photo : photo?._key}>
            <ImageWrapper>
              <Image data={{}} url={isUrl ? photo : photo.asset?.url ?? ""} isBackground />
            </ImageWrapper>
          </SwiperSlide>
        );
      })}
    </>
  );
};

const getPhotoUrl = (val?: string | SanityImageWithMeta | null) => {
  if (!val) return undefined;
  let url = typeof val === "string" ? val : val?.asset?.url;
  if (url && !url.startsWith("http")) url = `https://${url}`;
  return url ?? undefined;
};

const Gallery = () => {
  const { property } = usePropertyContext();
  const { photos, photosUrls, status } = property;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { isMobileWidth } = useCheckScreenWidth();
  const allPhotos = [...(photos ?? []), ...(photosUrls ?? [])];

  // TODO Check featured
  const packageCategory = getPackageCategory(property);
  const isFeatured = false; // Boolean(categories?.includes("Featured"));
  const isSold = Boolean(status === "unconditional" || status === "sold");

  return (
    <SwiperWrapper>
      <Container width="100%" margin="0 0 15px" tabletMargin="0 0 8px" position="relative">
        <SwiperStyle
          slidesPerView={1}
          spaceBetween={0}
          modules={[Navigation, Controller]}
          controller={{ control: thumbsSwiper }}
          loop
          navigation
          isCustomNav
        >
          {allPhotos.map(photo => {
            if (!photo) return null;
            const url = getPhotoUrl(photo);

            return (
              <SwiperSlide key={url}>
                <ImageWrapper>
                  <Image data={{}} url={url} isBackground />
                </ImageWrapper>
              </SwiperSlide>
            );
          })}
        </SwiperStyle>
        {packageCategory && <Tag type={packageCategory}>{packageCategory}</Tag>}
        {(isFeatured || isSold) && (
          <SaleTag type={packageCategory}>{isSold ? "Sold" : "Featured"}</SaleTag>
        )}
      </Container>
      <Container width="100%">
        <Swiper
          slidesPerView={2}
          spaceBetween={8}
          modules={[Controller]}
          loop
          watchSlidesProgress
          onSwiper={setThumbsSwiper}
          breakpoints={{
            750: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
          }}
        >
          {allPhotos?.slice(isMobileWidth ? 2 : 3, allPhotos.length).map(photo => {
            if (!photo) return null;
            const url = getPhotoUrl(photo);

            return (
              <SwiperSlide key={url}>
                <ImageWrapper>
                  <Image data={{}} url={url} isBackground />
                </ImageWrapper>
              </SwiperSlide>
            );
          })}
          {allPhotos?.slice(0, isMobileWidth ? 2 : 3).map(photo => {
            if (!photo) return null;
            const url = getPhotoUrl(photo);

            return (
              <SwiperSlide key={url}>
                <ImageWrapper>
                  <Image data={{}} url={url} isBackground />
                </ImageWrapper>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </SwiperWrapper>
  );
};

export default Gallery;

const Tag = styled.div<{
  type: Color;
}>`
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 0;

  background: ${({ type }) => colors[type]};
  color: ${colors.white};
  font-size: ${em(22, 16)};
  text-transform: capitalize;
  padding: 13px 25px;
  border-radius: 0 100px 100px 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 8px 20px;
  }
`;

const SwiperWrapper = styled(Container)`
  flex-flow: column nowrap;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 50px;

  ${mediaQuery.tabletDown} {
    margin-top: 30px;
  }

  .swiper {
    width: 100%;

    .swiper-button-next,
    .swiper-button-prev {
      top: auto;
      bottom: 25px;

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20C38 29.9411 29.9411 38 20 38ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1783C17.04 12.754 17.6705 12.6967 18.0948 13.0503L23.7184 17.7366C24.6779 18.5362 24.6779 20.0099 23.7184 20.8095L18.0948 25.4958C17.6705 25.8494 17.04 25.7921 16.6864 25.3678C16.3328 24.9435 16.3902 24.3129 16.8144 23.9594L22.438 19.2731L16.8144 14.5867C16.3902 14.2332 16.3328 13.6026 16.6864 13.1783Z' fill='white'/%3E%3C/svg%3E%0A");
      }

      &:hover:after {
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.95431 0 0 8.9543 0 20Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38.5C9.78273 38.5 1.5 30.2173 1.5 20C1.5 9.78273 9.78273 1.5 20 1.5C30.2173 1.5 38.5 9.78273 38.5 20C38.5 30.2173 30.2173 38.5 20 38.5ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1782C17.04 12.7539 17.6705 12.6966 18.0948 13.0502L23.7184 17.7365C24.6779 18.5361 24.6779 20.0098 23.7184 20.8094L18.0948 25.4957C17.6705 25.8493 17.04 25.7919 16.6864 25.3677C16.3328 24.9434 16.3902 24.3128 16.8144 23.9593L22.438 19.2729L16.8144 14.5866C16.3902 14.233 16.3328 13.6025 16.6864 13.1782Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }

    .swiper-button-prev {
      left: auto;
      right: 70px;
    }

    .swiper-button-next {
      right: 15px;
    }
  }
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    .swiper-button-next {
      left: auto;
      right: 15px;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .swiper {
      padding: 0;
    }
  }
`;

const SaleTag = styled.div<{ type: LogoType }>`
  position: absolute;
  z-index: 1;
  top: 10%;
  right: -40%;
  width: 100%;
  text-align: center;
  transform: rotate(45deg);

  background: ${props => (props.type ? colors[props.type] : colors.main)};
  color: ${colors.white};
  font-size: ${em(15, 16)};
  padding: 10px 27px 8px;
  border-radius: 0 30px 0 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 10px 25px;
  }
`;

const ImageWrapper = styled.div`
  padding-bottom: 56%;
  position: relative;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 80%;
  }
`;
