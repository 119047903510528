import React from "react";
import { Container } from "@util/standard";
import { Order } from "@state/types";
import { PropertyCard } from "@global";
import { formatPropertyAddress } from "@util/helper";
import styled from "styled-components";
import { SanityProperty } from "@graphql-types";

interface Props {
  data: SanityProperty;
  order?: Order;
}

const PropertyPreview = ({ data, order }: Props) => {
  const packageObject = order?.packages?.[0];

  if (!data) return null;
  return (
    <Container flexDirection="column" width="100%" key={data?._id}>
      <h5>{formatPropertyAddress(data?.address)}</h5>
      <Container flexDirection="row" isMobileColumn>
        <Container width="50%" flexDirection="column" mobileWidth="100%">
          <PropertyCard
            key={data._id}
            isAccount
            title={`${data?.address?.street} ${data?.address?.unit}`}
            propertyId={data.propertyId ?? ""}
            data={data}
            overrideType={packageObject ? packageObject.type : null}
          />
        </Container>
        {packageObject && (
          <StyledPackageWrapper
            width="40%"
            mobileWidth="100%"
            backgroundColor="go"
            borderRadius={20}
            margin="0px 0px 0px 20px"
            flexDirection="column"
            padding="50px"
          >
            {/* {packageObject.type == "go" ? (
                <img className="logo" src={assets.go} style={{ width: "200px" }} />
              ) : (
                <img />
              )} */}
            <div className="package">
              <span className="h5 capitalize">
                Homesell {packageObject.type} Package - {packageObject.tier}
              </span>
              <span>Total Price: ${packageObject.totalPrice}</span>
              {packageObject.items &&
                packageObject.items.length > 0 &&
                packageObject.items.map(item => <li>{item.title}</li>)}
            </div>
          </StyledPackageWrapper>
        )}
      </Container>
    </Container>
  );
};

export default PropertyPreview;

const StyledPackageWrapper = styled(Container)`
  .logo {
    width: 200px;
    height: auto;
  }

  .package {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
`;
