import React, { ReactNode } from "react";
import styled from "styled-components";
//@ts-ignore
import ReactCollapsy from "react-collapsy";
import "react-collapsy/lib/index.css";

import { colorsRGB, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  title: string;
  children: ReactNode;
}

const StyledAccordion = styled(ReactCollapsy)<{}>`
  && {
    margin: 0;
    border: none;
    border-raidus: 0px;
    border-bottom: 1.5px solid ${colorsRGB.main(0.2)};
    cursor: pointer;
  }

  &:last-of-type {
    border-bottom: none;
  }

  .Accordion__header {
    background: transparent;
    padding: 35px 140px 35px 0;
    cursor: pointer;
    width: 100%;
    border-radius: 0;
    line-height: 1.2;
    height: auto;
    color: inherit;
    font-family: inherit;
    margin-bottom: 0;

    &:not(.Accordion__header--collapsed) {
      & + .Accordion__body {
        padding-bottom: 35px;
      }
    }

    &.Accordion__header--collapsed:after {
      transform: rotate(0);
    }

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5.68632 6.62359C6.08612 7.10334 6.82297 7.10334 7.22277 6.62359L11.9091 0.999998' stroke='%23133456' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      margin: auto;
      background-size: 100% 100%;
      right: 0;
      width: 13px;
      height: 13px;
      transform: rotate(180deg);
      transition: transform 0.3s ease;
    }
  }

  .Accordion__body {
    padding: 0;
  }
  .Accordion__header--collapsed {
    height: max-content;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .Accordion__header {
      padding: 25px 48px 25px 0;
    }
  }
`;

export default ({ children, title }: Props) => {
  return (
    <StyledAccordion title={title} headerClass="h5">
      {children}
    </StyledAccordion>
  );
};
