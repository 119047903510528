import { createContext, Dispatch, SetStateAction, useContext } from "react";
import {
  Maybe,
  SanityFileAsset,
  SanityImageAsset,
  SanityNote,
  SanityOpenHome,
  SanityProduct,
  SanityProperty,
  SanityTier,
} from "@graphql-types";
import { toast } from "react-toastify";
import { sanityClient } from "@lib/sanityClient";
import { generateString, getSanityProperty } from "@util/helper";
import { CartItem } from "@util/types";
import { useStore } from "@state/store";
import { addWeeks, parseISO, format } from "date-fns";

export interface PropertyContext {
  property: SanityProperty;
  loading: boolean | string;
  isAdmin: boolean;
  setLoading: Dispatch<SetStateAction<boolean | string>>;
  setProperty: Dispatch<SetStateAction<SanityProperty>>;
}

export const PropertyContext = createContext<PropertyContext>({
  property: {},
  inputs: null,
} as unknown as PropertyContext);

export const usePropertyContext = () => useContext(PropertyContext);

export const savePropertyChanges = async (
  inputs: SanityProperty,
  fields: string[],
  property: SanityProperty,
) => {
  if (!property._id) return;

  const formatted: Partial<SanityProperty> = {};

  fields.forEach(field => {
    let value = inputs[field as keyof SanityProperty] ?? null;

    if (["saleDetails"].includes(field)) {
      value = {
        ...value,
        expectedSalePrice: value.expectedSalePrice ? Number(value.expectedSalePrice) : null,
        price: value.expectedSalePrice ? Number(value.price) : null,
      };
    }

    formatted[field as keyof SanityProperty] = value;
  });

  try {
    await sanityClient.patch(property._id).set(formatted).commit<SanityProperty>();
    toast.success("Changes saved!");
    return { ...property, ...formatted };
  } catch (err: any) {
    console.log("Error on upload photos: ", err);
    toast.error("Error on upload, please try again");
    return null;
  }
};

export const uploadPropertyPhotos = async (photos: File[], property: SanityProperty) => {
  if (!photos?.length || !property._id) return null;

  try {
    const res = await Promise.all(photos.map(file => sanityClient.assets.upload("image", file)));

    const formattedPhotos = res.map(asset => ({
      _type: "imageWithMeta",
      _key: generateString(14),
      altText: "",
      asset: {
        _type: "reference",
        _ref: asset._id,
      },
    }));

    await sanityClient
      .patch(property._id)
      .setIfMissing({ photos: [] })
      .append("photos", formattedPhotos)
      .commit();

    toast.success("Photos uploaded successfully!");

    return [
      ...(property.photos ?? []),
      ...formattedPhotos.map((el, index) => ({
        ...el,
        asset: res[index] as SanityImageAsset,
      })),
    ];
  } catch (err: any) {
    console.log("Error on upload photos: ", err);
    toast.error("Error on upload, please try again");
    return null;
  }
};

export const changePhotoOrder = async (photos: SanityImageAsset[], property: SanityProperty) => {
  if (!photos?.length || !property._id) return null;

  const constructredImages = photos.map((photo, index) => ({
    _key: generateString(14),
    _type: "imageWithMeta",
    asset: {
      _type: "reference",
      _ref: photo.asset._id,
    },
    altText: "",
  }));

  const matchingProperty = await sanityClient.fetch(
    ` *[_type == "property" && propertyId == "${property._id}"]`,
  );

  if (matchingProperty.length === 0) return null;

  await sanityClient
    .patch(`${matchingProperty[0]._id}`)
    .setIfMissing({ photos: [] })
    .set({ photos: constructredImages })
    .commit();

  toast.success("Photos updated successfully!");
  return [...photos];
};

export const uploadPropertyFiles = async (
  field: string,
  files: File[],
  property: SanityProperty,
) => {
  if (!files?.length || !property._id) return null;

  try {
    const res = await Promise.all(files.map(file => sanityClient.assets.upload("file", file)));

    const formattedFiles = res.map(asset => ({
      _key: generateString(14),
      _type: "attachedFile",
      fileName: asset.originalFilename?.split(".").slice(0, -1).join("."),
      file: {
        _type: "file",
        asset: {
          _type: "reference",
          _ref: asset._id,
        },
      },
    }));

    await sanityClient
      .patch(property._id)
      .setIfMissing({ [field]: [] })
      .append(field, formattedFiles)
      .commit();

    toast.success("Files uploaded successfully!");

    return [
      ...(property[field as keyof SanityProperty] ?? []),
      ...formattedFiles.map((el, index) => ({
        ...el,
        file: {
          ...el.file,
          asset: res[index] as SanityFileAsset,
        },
      })),
    ];
  } catch (err: any) {
    console.log("Error on upload files: ", err);
    toast.error("Error on upload, please try again");
    return null;
  }
};

export const addOrUpdateOpenHome = async (
  inputs: Partial<SanityOpenHome>,
  property: SanityProperty,
) => {
  if (!inputs || !property._id) return null;

  const { startDate, startTime, endTime, repeats = 1 } = inputs;

  // Function to generate multiple open home objects based on the number of repeats
  const generateRepeatingOpenHomes = () => {
    const openHomesArray = [];
    for (let i = 0; i < repeats; i++) {
      const newStartDate = addWeeks(parseISO(startDate), i);
      const formattedStartDate = format(newStartDate, "yyyy-MM-dd");

      openHomesArray.push({
        ...inputs,
        _key: generateString(14),
        _type: "openHome",
        startDate: formattedStartDate, // Adjust date by adding i weeks
      });
    }
    return openHomesArray;
  };

  // Add or update open homes
  const formattedData = inputs._key
    ? property.openHomes?.map(el => (el?._key === inputs._key ? inputs : el))
    : [...(property.openHomes ?? []), ...generateRepeatingOpenHomes()];

  try {
    const res = await sanityClient
      .patch(property._id)
      .set({ openHomes: formattedData })
      .commit<SanityProperty>();

    toast.success("Open Home added successfully!");

    return {
      ...property,
      openHomes: res.openHomes,
    };
  } catch (err: any) {
    console.log("Error on add: ", err);
    toast.error("Error on add open home");
    return null;
  }
};

export const addCorrespondenceNote = async (
  inputs: Partial<SanityNote>,
  property: SanityProperty,
) => {
  if (!inputs || !property._id) return null;

  const formattedInputs = {
    ...inputs,
    date: inputs.date ?? new Date().toISOString().split("T")[0],
    _type: "note",
  };

  const formattedData = inputs._key
    ? property.notes?.map(el => (el?._key === inputs._key ? formattedInputs : el))
    : [...(property.notes ?? []), { ...formattedInputs, _key: generateString(14) }];

  try {
    const res = await sanityClient
      .patch(property._id)
      .set({ notes: formattedData })
      .commit<SanityProperty>();

    toast.success("Note added successfully!");

    return {
      ...property,
      notes: res.notes,
    };
  } catch (err: any) {
    console.log("Error on add note: ", err);
    toast.error("Error on add note");
    return null;
  }
};

export const deleteArrayElement = async (
  key: Maybe<string> | undefined,
  field: string,
  property: SanityProperty,
) => {
  console.log("key: ", key, property);

  if (!key || !property._id) return null;

  try {
    await sanityClient
      .patch(property._id)
      .unset([`${field}[_key=="${key}"]`])
      .commit();

    return {
      ...property,
      [field]: property[field as keyof SanityProperty].filter((el: any) => el._key !== key),
    };
  } catch (err: any) {
    console.log("Error on remove: ", err);
    toast.error("Error on remove item");
    return null;
  }
};

export const approveListing = async (propertyID?: Maybe<string> | undefined) => {
  if (!propertyID) return;

  try {
    const propertyData = (
      await getSanityProperty(propertyID, "publishStatus, order {status}")
    )?.[0];

    if (
      propertyData?.order?.status !== "paid" ||
      ["approved", "published"].includes(propertyData.publishStatus ?? "")
    ) {
      toast.error("This property cannot be published");
      return false;
    }

    await sanityClient
      .patch(propertyID)
      .set({ publishStatus: "approved" })
      .commit<SanityProperty>();

    toast.success("Property approved for publishing");
    return true;
  } catch (err: any) {
    console.log("Error on approve listing: ", err);
    toast.error("Error on approve listing");
    return false;
  }
};

export const getTierBasePrice = (tierDetails: Maybe<SanityTier> | undefined) => {
  if (!tierDetails) return 0;
  const packagePrice = tierDetails.price ?? 0;
  const defaultCustomisableProductsPrice = tierDetails.customisableProductsDefault
    ? tierDetails.customisableProductsDefault?.length > 0
      ? tierDetails.customisableProductsDefault
          ?.map(obj => {
            if (!obj) return 0;

            const multiplier = obj.defaultQuantity ?? 1;
            const price =
              obj.product?.variants && obj.product?.variants.length > 0
                ? obj.product?.variants.find(v => v?.sku === obj.defaultSku)?.price ??
                  obj.product?.variants[0]?.price ??
                  0
                : obj.product?.price ?? 0;

            return price * multiplier;
          })
          .reduce((a, b) => a + b)
      : 0
    : 0;

  return packagePrice - defaultCustomisableProductsPrice;
};

export const generateCartItem = (
  product: SanityProduct,
  defaultQuantity?: Maybe<number>,
  defaultSku?: Maybe<string>,
) => {
  const variant =
    product?.variants &&
    product.variants.length > 0 &&
    (product.variants.find(v => v?.sku === defaultSku) ?? product.variants[0]);
  return {
    title: product?.title ?? "",
    quantity: defaultQuantity ?? 1,
    sku: product?.sku,
    price: product?.price,
    variant: variant
      ? {
          sku: variant.sku ?? "",
          title: variant.title ?? "",
          price: variant.price ?? 0,
        }
      : null,
  } as CartItem; // asserting CartItem type when you generate the CartItem will give you the type when you call this function
};

export const createOrder = async (
  selectedTier: string,
  basePrice: number,
  items: CartItem[],
  property: SanityProperty,
) => {
  const user = useStore.getState().user;

  if (!user?.id || !property?._id) {
    toast.error("Error on validate user, please login again");
    return null;
  }

  const propertyData = (await getSanityProperty(property._id, "order {status}"))?.[0];

  if (propertyData?.order?.status === "paid") {
    toast.error("This order is already paid and cannot be changed");
    return null;
  }

  const itemObjects = items?.map(item => {
    const totalPrice = (item.variant ? item.variant?.price ?? 0 : item.price ?? 0) * item.quantity;

    const data = {
      title: item.title,
      quantity: item.quantity,
      totalPrice: totalPrice,
      sku: item.sku,
      price: item.price,
      _key: generateString(12),
    } as any;

    if (item.variant) data.variant = item.variant;
    return data;
  });

  const totalPackagePrice =
    itemObjects && itemObjects.length > 0
      ? basePrice + itemObjects?.map(obj => obj.totalPrice).reduce((a, b) => a + b)
      : basePrice;

  const orderId = generateString(20);

  const data = {
    uid: user?.id,
    total: totalPackagePrice,
    items: itemObjects,
    createdAt: new Date().toISOString(),
    tier: selectedTier,
    status: "unpaid",
    id: orderId,
  };

  try {
    await sanityClient.patch(property._id).set({ order: data }).commit<SanityProperty>();
    const res = await createPaymentUrl(data.total, data.id, property);
    console.log("createPaymentUrl", res);
    return data;
  } catch (err) {
    toast.error("Error on purchase package, please try again");
    console.log(err);
    return null;
  }
};

export const cancelOrder = async (property: SanityProperty) => {
  if (!property?._id) return;

  try {
    const propertyData = (await getSanityProperty(property._id, "order {status}"))?.[0];

    if (propertyData?.order?.status !== "unpaid") {
      toast.error("This order is already paid and cannot be cancelled");
      return false;
    }
    await sanityClient.patch(property._id).unset(["order"]).commit<SanityProperty>();
    return true;
  } catch (err) {
    toast.error("Error on delete order, please try again");
    console.log(err);
    return false;
  }
};

export const createPaymentUrl = async (total: number, id: string, property: SanityProperty) => {
  const user = useStore.getState().user;

  try {
    if (!total || !id || !property?._id) return "error";

    const propertyData = (await getSanityProperty(property._id, "order {status}"))?.[0];

    if (propertyData?.order?.status === "paid") {
      return "paid";
    }

    const requestQuery = {
      totalPrice: total,
      merchantRef: `${property._id}--${id}`,
      customerDetails: `${user?.firstName} ${user?.lastName}`,
      dynamicUrl: `${window.location.origin}/account/property`,
      dynamicPostUrl: `${window.location.origin}/.netlify/functions/paystationPurchaseConfirmation`,
    };

    const response = await fetch(`/.netlify/functions/getPaystationUrl`, {
      method: "POST",
      body: JSON.stringify(requestQuery),
    });

    const value = await response.json();
    const url = value?.InitiationRequestResponse?.DigitalOrder;

    if (url) {
      window.open(url, "_blank");
    } else {
      throw new Error("No URL");
    }
    return "success";
  } catch (err) {
    toast.error("Error on generate payment URL, please try again");
    console.log(err);
    return "error";
  }
};
