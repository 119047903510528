import { PackageTier } from "@util/types";

export const BUTTON_HEIGHT = 53;
export const HEADER_HEIGHT = 100;

// screen sizes
export const SMALLER_MOBILE = 500;
export const MOBILE_BREAKPOINT = 750;
export const IPAD_BREAKPOINT = 850;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  main: "#133456", //Dark Blue
  pro: "#F15B5B", //Pink
  go: "#FAA61A", //Orange
  know: "#149B9E", //Turquoise
  current: "currentColor",
  errorRed: "#DB2525",
  lightGrey: "#F6F6F6",
  lightBlue: "rgba(19, 52, 86, 0.1)",
  inputBg: "#E7EBEE",
  overlay: "#515151",
  gridBorder: "#D0D6DD",
  navy: "#133456",
};

export const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
};

export const PRIMARY_COLOR = colors.main;
export const SECONDARY_COLOR = colors.black;

export const FONT_BODY = "proxima-nova";
export const FONT_BODY_WEIGHT = fontWeights.normal;
export const FONT_HEADER = "proxima-nova";
export const FONT_HEADER_WEIGHT = fontWeights.normal;

export const fontSizes = {
  p: { default: 16, mobile: 14 },
  h1: { default: 55, mobile: 40 },
  h2: { default: 45, mobile: 28 },
  h3: { default: 35, mobile: 28 },
  h4: { default: 28, mobile: 20 },
  h5: { default: 22, mobile: 16 }, //Subheading
};

export const colorsRGB = {
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.2"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  main: (opacity?: number) => `rgba(19, 52, 86, ${opacity ?? "0.2"})`,
  pro: (opacity?: number) => `rgba(241, 91, 91, ${opacity ?? "0.2"})`,
  go: (opacity?: number) => `rgba(250, 166, 26, ${opacity ?? "0.2"})`,
  know: (opacity?: number) => `rgba(20, 155, 158, ${opacity ?? "0.2"})`,
};

export const propertySaleTypes = [
  { title: "Asking Price", value: "askingPrice" },
  { title: "Enquiries Over", value: "enquiriesOver" },
  { title: "Auction", value: "auction" },
  { title: "By Tender", value: "byTender" },
  { title: "Price by Negotiation", value: "priceByNegotiation" },
  { title: "Deadline Sale", value: "deadlineSale" },
];

// define paths to asset
export const assets = {
  go: "./assets/go.svg",
  pro: "./assets/pro.svg",
  minus: "./assets/homesell-minus.svg",
  plus: "./assets/homesell-plus.svg",
  tooltip: "./assets/tooltip.svg",
};

export const pages = {
  account: "/account",
  orders: "/account/orders",
  paymentSuccessful: "/paymentSuccessful",
  orderLog: "/admin/order-log",
  changeRequests: "/admin/change-requests",
  proOrders: "/account/pro-orders",
};

export const buttonStyle = {
  base: {
    bg: `${colors.main}`,
    text: `${colors.white}`,
    border: `${colors.main}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.main}`,
    hoverText: `${colors.main}`,
  },
  white: {
    bg: `${colors.white}`,
    text: `${colors.main}`,
    border: `${colors.white}`,
    hoverBg: `${colorsRGB.white(0.1)}`,
    hoverBorder: `${colors.white}`,
    hoverText: `${colors.white}`,
  },
  baseOutline: {
    bg: `${colors.transparent}`,
    text: `${colors.main}`,
    border: `${colors.main}`,
    hoverBg: `${colors.main}`,
    hoverBorder: `${colors.main}`,
    hoverText: `${colors.white}`,
  },
  whiteOutline: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.white}`,
    hoverText: `${colors.main}`,
  },
  go: {
    bg: `${colors.go}`,
    text: `${colors.white}`,
    border: `${colors.go}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.go}`,
    hoverText: `${colors.go}`,
  },
  goOutline: {
    bg: `${colors.transparent}`,
    text: `${colors.go}`,
    border: `${colors.go}`,
    hoverBg: `${colors.go}`,
    hoverBorder: `${colors.go}`,
    hoverText: `${colors.white}`,
  },
  pro: {
    bg: `${colors.pro}`,
    text: `${colors.white}`,
    border: `${colors.pro}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.pro}`,
    hoverText: `${colors.pro}`,
  },
  proOutline: {
    bg: `${colors.transparent}`,
    text: `${colors.pro}`,
    border: `${colors.pro}`,
    hoverBg: `${colors.pro}`,
    hoverBorder: `${colors.pro}`,
    hoverText: `${colors.white}`,
  },
  know: {
    bg: `${colors.know}`,
    text: `${colors.white}`,
    border: `${colors.know}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.know}`,
    hoverText: `${colors.know}`,
  },
  knowOutline: {
    bg: `${colors.transparent}`,
    text: `${colors.know}`,
    border: `${colors.know}`,
    hoverBg: `${colors.know}`,
    hoverBorder: `${colors.know}`,
    hoverText: `${colors.white}`,
  },
};

export const tierArr = ["basic", "advanced", "premium"] as PackageTier[];

export const vaultIds = {
  contactType: {
    packageEnquiry: 353095,
    contactForm: 353097,
    propertyEoI: 353096,
    homesellGoEnquiry: 353095,
    homesellGoPackagePurchase: 365254,
    homesellGoPropertyListed: 907784,
    homesellProEnquiry: 907781,
  },
  sourceOfEnquiry: {
    packageEnquiry: 76574,
    contactForm: 76576,
    propertyEoI: 76575,
  },
  note: {
    websiteEnquiry: 34596,
    expressionOfInterest: 46681,
    openHomeFeedback: 31087,
    homsellGoPackagePurchased: 53843,
  },
  user: {
    chrisCaldwell: 165197,
    contactOwner: 241221,
  },
  contact: {
    //For testing purposes
    mel: 95432207,
  },
};

export const openHomeTimeOptions = [
  { title: "08:00 AM", value: "08:00" },
  { title: "08:15 AM", value: "08:15" },
  { title: "08:30 AM", value: "08:30" },
  { title: "08:45 AM", value: "08:45" },
  { title: "09:00 AM", value: "09:00" },
  { title: "09:15 AM", value: "09:15" },
  { title: "09:30 AM", value: "09:30" },
  { title: "09:45 AM", value: "09:45" },
  { title: "10:00 AM", value: "10:00" },
  { title: "10:15 AM", value: "10:15" },
  { title: "10:30 AM", value: "10:30" },
  { title: "10:45 AM", value: "10:45" },
  { title: "11:00 AM", value: "11:00" },
  { title: "11:15 AM", value: "11:15" },
  { title: "11:30 AM", value: "11:30" },
  { title: "11:45 AM", value: "11:45" },
  { title: "12:00 PM", value: "12:00" },
  { title: "12:15 PM", value: "12:15" },
  { title: "12:30 PM", value: "12:30" },
  { title: "12:45 PM", value: "12:45" },
  { title: "01:00 PM", value: "13:00" },
  { title: "01:15 PM", value: "13:15" },
  { title: "01:30 PM", value: "13:30" },
  { title: "01:45 PM", value: "13:45" },
  { title: "02:00 PM", value: "14:00" },
  { title: "02:15 PM", value: "14:15" },
  { title: "02:30 PM", value: "14:30" },
  { title: "02:45 PM", value: "14:45" },
  { title: "03:00 PM", value: "15:00" },
  { title: "03:15 PM", value: "15:15" },
  { title: "03:30 PM", value: "15:30" },
  { title: "03:45 PM", value: "15:45" },
  { title: "04:00 PM", value: "16:00" },
  { title: "04:15 PM", value: "16:15" },
  { title: "04:30 PM", value: "16:30" },
  { title: "04:45 PM", value: "16:45" },
  { title: "05:00 PM", value: "17:00" },
  { title: "05:15 PM", value: "17:15" },
  { title: "05:30 PM", value: "17:30" },
  { title: "05:45 PM", value: "17:45" },
  { title: "06:00 PM", value: "18:00" },
  { title: "06:15 PM", value: "18:15" },
  { title: "06:30 PM", value: "18:30" },
  { title: "06:45 PM", value: "18:45" },
  { title: "07:00 PM", value: "19:00" },
  { title: "07:15 PM", value: "19:15" },
  { title: "07:30 PM", value: "19:30" },
  { title: "07:45 PM", value: "19:45" },
  { title: "08:00 PM", value: "20:00" },
];

export const repeatOptions = [
  { title: "1 week", value: "1" },
  { title: "2 weeks", value: "2" },
  { title: "3 weeks", value: "3" },
  { title: "4 weeks", value: "4" },
  { title: "5 weeks", value: "5" },
  { title: "6 weeks", value: "6" },
  { title: "7 weeks", value: "7" },
  { title: "8 weeks", value: "8" },
  { title: "9 weeks", value: "9" },
  { title: "10 weeks", value: "10" },
];
