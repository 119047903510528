import { assets, colors } from "@util/constants";
import { toDollar } from "@util/helper";
import { Section } from "@util/standard";
import React, { useState } from "react";
import styled from "styled-components";
import BlocksContent from "../blocksContent";
import { useSavingsCalculator } from "@util/savingsCalculatorHooks";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";
import { mediaQuery } from "@styles/mediaQueries";

const SavingsCalculator = () => {
  const {
    savingsOutput,
    setSavingsOutput,
    priceInput,
    setPriceInput,
    tooltipVisible,
    setTooltipVisible,
    calculatorData,
    goSavings,
    proSavings,
  } = useSavingsCalculator();

  const [savingsOutputType, setSavingsOutputType] = useState("pro");

  if (calculatorData == null) return null;
  const { firstTextInput, secondTextInput, thirdTextInput, toolTip } =
    calculatorData.sanitySavingsCalculator;

  const handleToggleTooltip = () => {
    setTooltipVisible(!tooltipVisible);
  };

  const handleUpdate = (event: any) => {
    setSavingsOutputType(event.target.value);
  };

  return (
    <Section>
      <StyledSavingsCalculator>
        <span className="first-input-line">
          {/* <p>By using Homesell</p> */}
          {/* <select onChange={handleUpdate}>
            <option value="go">Homesell Go</option>
            <option value="pro">Homesell Pro</option>
          </select> */}
        </span>
        <p className="center">{firstTextInput}</p>

        <span className="highlight">{toDollar(priceInput)} property</span>
        <div className="range-slider">
          <input
            type="range"
            min="400000"
            max="7000000"
            value={priceInput}
            onChange={e => setPriceInput(parseInt(e.target.value))}
            step="50000"
          />
        </div>
        <span className="first-input-line">
          <p>{secondTextInput}</p>
          <span className="first-input-line">
            <span className="savings-output highlight">
              {toDollar(savingsOutputType == "go" ? goSavings : proSavings)}
            </span>
            <img
              className="tooltip-icon"
              src={assets.tooltip}
              alt="tool tip"
              onClick={handleToggleTooltip}
            />
          </span>
        </span>

        <span className="third-input-line">
          {toolTip && (
            <StyledTooltip tooltipVisible={tooltipVisible} className="tooltip-modal">
              <BlocksContent data={toolTip} />
            </StyledTooltip>
          )}
        </span>
      </StyledSavingsCalculator>
    </Section>
  );
};

export default SavingsCalculator;

const StyledSavingsCalculator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 45px;
  max-width: 700px;
  margin: 0 auto;

  span {
    width: fit-content;
  }

  .center {
    text-align: center;
  }

  p {
    font-size: 35px;
    font-weight: 600;
    margin: auto 0px !important;

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      font-size: 28px;
    }
  }
  .first-input-line {
    display: flex;
    justify-content: center;
    align-items: center;

    select {
      margin-left: 28px;
      padding: 15px 155px 15px 20px;
    }

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      flex-direction: column;
    }
  }

  .highlight {
    margin: 0px 20px;
    display: inline;
    border-radius: 100px;
    padding: 12px 38px;
    line-height: 1.6;
    background: rgba(19, 52, 86, 0.1);
    font-size: 45px;
    font-weight: 700;

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      font-size: 25px;
      margin: 0;
    }
  }

  .range-slider {
    input[type="range"]::-webkit-slider-runnable-track {
      height: 6px;
      background: ${colors.main};
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 37px;
      height: 37px;
      background: ${colors.main};
      cursor: pointer;
      border-radius: 50%;
      margin-top: -15px;
    }

    input {
      -webkit-appearance: none;
      width: 100%;
      padding: 0;
      position: relative;
      outline: none;

      &:before {
        content: "";
        background: url(${assets.minus});
        width: 37px;
        height: 37px;
        display: block;
        position: absolute;
        top: -15px;
        left: -10px;
      }

      &:after {
        content: "";
        background: url(${assets.plus});
        width: 37px;
        height: 37px;
        display: block;
        position: absolute;
        top: -15px;
        right: -10px;
      }
    }
    width: 100%;
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      width: 80%;
      margin: auto;
    }
  }

  .third-input-line {
    text-align: center;
  }

  .tooltip-icon {
    cursor: pointer;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
  }
`;

const StyledTooltip = styled.span<{ tooltipVisible: boolean }>`
  display: ${props => (props.tooltipVisible ? "block" : "none")};

  padding: 45px;
  background: #d9d9d9;
  border-radius: 10px;

  ${mediaQuery.tabletDown} {
    padding: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  a {
    font-size: 16px;
    font-weight: 400;
  }
`;
