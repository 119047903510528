import { colors } from "@util/constants";
import { Close } from "@util/svg";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "./button";
import { mediaQuery } from "@styles/mediaQueries";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onChangeFiles: (files: File[]) => void;
  onDelete: (files: File[]) => void;
  files: File[];
  onCancel?: () => void;
  onUpload?: () => void;
  disabled?: boolean;
}

const DragAndDropInput = ({
  files,
  onDelete,
  onChangeFiles,
  onCancel,
  onUpload,
  disabled,
  ...rest
}: Props) => {
  const [hovering, setHovering] = useState(false);
  const refInput = useRef<HTMLInputElement>(null);

  const handleDragEnterEvent = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setHovering(true);
  };

  const handleDragLeaveEvent = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setHovering(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.dataTransfer.files.length && !disabled) {
      onChangeFiles(Array.from(e.dataTransfer.files));
      e.dataTransfer.clearData();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.files?.length && !disabled) {
      onChangeFiles(Array.from(e.target.files));
      if (refInput.current) refInput.current.value = "";
    }
  };

  const handleDelete = (index: number) => {
    if (!files || disabled) return;
    onDelete(files?.filter((_, i) => i !== index) ?? []);
  };
  return (
    <Wrapper className={`drag-and-drop ${hovering && "hovering"}`}>
      <div
        className="drop-area"
        onDragOver={handleDragEnterEvent}
        onDragEnter={handleDragEnterEvent}
        onDragEnd={handleDragLeaveEvent}
        onDragLeave={handleDragLeaveEvent}
        onDrop={handleDrop}
      >
        <input {...rest} ref={refInput} onChange={handleChange} type="file" />
        <div className="button-area">
          <button onClick={() => refInput.current?.click()}>Choose a file</button>
          <span className="dragndrop"> or drag it here</span>
        </div>
        <p>Supports JPEG, JPG, PNG and PDF</p>
      </div>
      {files?.length ? (
        <div className="files">
          {Array.from(files).map((file, index) => (
            <div className="file" key={index}>
              {file.type.includes("image") ? (
                <img className="file-img" src={URL.createObjectURL(file)} alt="file" />
              ) : (
                <div className="file-img no-img">
                  <p>{file.name.split(".").slice(-1)[0]}</p>
                </div>
              )}
              <p>
                <b>{file.name}</b>
              </p>
              <p>{Math.round(file.size / 1024)} KB</p>
              <button onClick={() => handleDelete(index)}>
                <Close color="errorRed" />
              </button>
            </div>
          ))}
        </div>
      ) : null}
      {files?.length && (onCancel || onUpload) ? (
        <div className="upload-buttons">
          <Button disabled={disabled} theme="baseOutline" onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={disabled} onClick={onUpload}>
            Upload
          </Button>
        </div>
      ) : null}
    </Wrapper>
  );
};

export default DragAndDropInput;

const Wrapper = styled.div`
  width: 600px;
  margin: 0 auto;
  max-width: 100%;

  ${mediaQuery.tabletDown} {
    width: 100%;
  }

  .upload-buttons {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .files {
    display: flex;
    gap: 10px;
    width: 100%;
    overflow: auto;

    .file {
      width: 100px;
      position: relative;

      button {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
      }

      &:hover {
        button {
          opacity: 1;
        }
      }

      .file-img {
        background-color: #f1f1f1;
        width: 100%;
        height: 70px;
        object-fit: cover;
        border-radius: 8px;
        margin-bottom: 6px;

        &.no-img {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
        }
      }

      p {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        font-size: 13px;
      }
    }
  }
  &.hovering {
    .drop-area {
      border-color: ${colors.main};
      background-color: rgba(19, 52, 86, 0.05);
    }
  }

  .drop-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 100px 20px;
    border: 1px dashed #ccc;
    border-radius: 10px;
    margin-bottom: 20px;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
    }

    p {
      color: #333;
      font-size: 13px;
    }

    button {
      font-weight: bold;
    }
  }
`;
