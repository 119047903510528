import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const accountPropertyQuery = () => {
  const { sanityAccountProperty }: Query = useStaticQuery(graphql`
    {
      sanityAccountProperty {
        resources {
          title
          file {
            asset {
              _id
              _updatedAt
              url
              title
              originalFilename
            }
            _type
            _key
          }
        }
        resourcesHeading {
          ...sanityBlockContent
        }
        buyPackageHeading {
          ...sanityBlockContent
        }
        buyPackageButtons {
          ...sanityLink
        }
      }
    }
  `);

  return sanityAccountProperty;
};

export default accountPropertyQuery;
