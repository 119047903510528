import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const FooterQuery = () => {
  const FooterQuery: Query = useStaticQuery(graphql`
    {
      sanitySiteConfig {
        defaultSEO {
          ...sanitySeo
        }
        defaultMoreInfoCard {
          ...sanityFullWidthImage
        }
        defaultFeaturedArticles {
          ...sanityFeaturedArticles
        }
      }
      sanityFooter {
        newsletterTitle
        menuLists {
          _key
          _type
          title
          links {
            ...sanityLink
          }
        }
        socialLinks {
          ...sanityImageConstrained
        }
      }
    }
  `);

  return FooterQuery;
};

export default FooterQuery;
