import React from "react";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import { Maybe, SanityGeoLocation } from "@graphql-types";
import { Container } from "@util/standard";

interface Props {
  geoLocation?: Maybe<SanityGeoLocation>;
}

const Marker = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 35px;
  height: 35px;
  user-select: none;
  transform: translateX(-50%);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='30' viewBox='0 0 24 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7236 0C5.24588 0 0 5.23854 0 11.7072C0 19.6352 11.7236 30.0047 11.7236 30.0047C11.7236 30.0047 23.4472 19.6352 23.4472 11.7072C23.4425 5.23854 18.1966 0 11.7236 0ZM13.3114 19.406H10.1358V15.0047H13.3114V19.406ZM17.3067 17.9186V11.9317L11.7236 7.83442L6.14049 11.9317V17.9186C4.43558 16.3891 3.35361 14.1768 3.35361 11.7072C3.35361 7.09074 7.10067 3.34892 11.7236 3.34892C16.3465 3.34892 20.0936 7.09074 20.0936 11.7072C20.0889 14.1768 19.0116 16.3891 17.3067 17.9186Z' fill='%23FAA61A'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  &:hover {
    z-index: 1;
  }
`;

const Map = ({ geoLocation }: Props) => {
  if (!geoLocation || !geoLocation.latitude || !geoLocation.longitude) return null;

  return (
    <Container width="100%" height="500px" tabletHeight="340px" margin="0">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAP ?? "" }}
        defaultZoom={15}
        defaultCenter={{
          lat: geoLocation.latitude,
          lng: geoLocation.longitude,
        }}
      >
        <Marker />
      </GoogleMapReact>
    </Container>
  );
};

export default Map;
