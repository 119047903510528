import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Select, TextField } from "@global";
import { GridContainer } from "@util/standard";
import { emailValidator } from "@util/helper";
import { useStore } from "@state/store";
import { defProps } from "@util/inputs";

interface Props {
  subject?: string;
  isSingleColumn?: boolean;
}

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};

const encode = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const ContactForm = ({ subject, isSingleColumn }: Props) => {
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const user = useStore(s => s.user);

  const handleChangeInput = (key: string, value: unknown) => {
    setInputs(p => ({
      ...p,
      [key]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      !emailValidator(inputs.email) ||
      !inputs.firstName ||
      !inputs.lastName ||
      !inputs.message ||
      !inputs.phone
    ) {
      toast.error("Please make sure all fields are entered and try again!");
      return;
    }

    const form = event.target as HTMLFormElement;

    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact-form", ...inputs, uid: user?.id ?? "" }),
      });

      if (!response.ok) {
        throw new Error("Invalid response");
      }

      setLoading(false);
      setClickedSubmit(true);
      form.reset();
    } catch (error) {
      console.log("Error on submit: ", error);
      toast.error("Error on submit, please try again");
    }
  };

  if (clickedSubmit) {
    return (
      <>
        <h4>
          Thank you for your message.
          <br />
          We will be in contact as soon as possible.
        </h4>
        <Button linkText="View Selling Advice" internalSlug={`/homesell-know/selling`} />
      </>
    );
  }
  return (
    <form
      data-netlify="true"
      name="contact-form"
      method="post"
      onSubmit={handleSubmit}
      netlify-honeypot="spam-trap"
    >
      <GridContainer repeat={isSingleColumn ? 1 : 2} gap="15px" margin="0 0 15px" width="100%">
        <TextField
          {...defProps.firstName}
          onChange={e => handleChangeInput("firstName", e.target.value)}
          value={inputs.firstName}
          required
        />
        <TextField
          {...defProps.lastName}
          onChange={e => handleChangeInput("lastName", e.target.value)}
          value={inputs.lastName}
          required
        />
      </GridContainer>
      <GridContainer repeat={isSingleColumn ? 1 : 2} gap="15px" margin="0 0 15px" width="100%">
        <TextField
          {...defProps.email}
          onChange={e => handleChangeInput("email", e.target.value)}
          value={inputs.email}
          required
        />
        <TextField
          {...defProps.phone}
          onChange={e => handleChangeInput("phone", e.target.value)}
          value={inputs.phone}
          required
        />
      </GridContainer>

      {subject ? (
        <input name="subject" type="hidden" value={subject} />
      ) : (
        <GridContainer repeat={1} margin="0 0 15px" width="100%">
          <Select
            label="Subject"
            theme="filled"
            hideEmptyOption
            value={inputs.subject}
            onChange={e => handleChangeInput("subject", e.target.value)}
            id="subject"
            options={[
              { title: "General Enquiry", value: `General Enquiry` },
              { title: "Selling Enquiry", value: `Selling Enquiry` },
              { title: "Buying Enquiry", value: `Buying Enquiry` },
            ]}
          />
        </GridContainer>
      )}
      <GridContainer repeat={1} margin="0 0 35px" width="100%">
        <TextField
          label="Provide more details"
          theme="filled"
          id="message"
          placeholder="Message"
          onChange={e => handleChangeInput("message", e.target.value)}
          value={inputs.message}
          rows={4}
          inputType="textarea"
        />
      </GridContainer>
      <Button type="submit" linkText="Submit" loading={loading} />
    </form>
  );
};

export default ContactForm;
