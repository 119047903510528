import { Maybe } from "@graphql-types";
import { colors } from "@util/constants";
import React from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import styled from "styled-components";

type BaseProps = React.InputHTMLAttributes<HTMLInputElement> &
  React.TextareaHTMLAttributes<HTMLTextAreaElement>;

interface Props extends BaseProps {
  label?: string;
  id: string;
  rows?: number;
  inputType?: "default" | "textarea" | "currency";
  helperText?: Maybe<string> | undefined;
  currencyProps?: CurrencyInputProps;
  theme?: "outlined" | "filled";
  onValueChange?: (value: string | undefined) => void;
}

const TextField = ({
  label,
  id,
  name,
  currencyProps,
  helperText,
  inputType = "default",
  onValueChange,
  theme = "outlined",
  placeholder,
  required,
  ...rest
}: Props) => {
  const formattedPlaceholder = placeholder && required && !label ? `${placeholder}*` : placeholder;
  return (
    <Wrapper className={`text-field ${theme}`}>
      {label && (
        <label htmlFor={id}>
          {label}
          {required ? "*" : ""}
        </label>
      )}
      {inputType === "currency" ? (
        //@ts-ignore
        <CurrencyInput
          {...rest}
          {...currencyProps}
          placeholder={formattedPlaceholder}
          name={name ?? id}
          id={id}
          required={required}
          onValueChange={onValueChange}
        />
      ) : inputType === "textarea" ? (
        <textarea
          {...rest}
          placeholder={formattedPlaceholder}
          required={required}
          name={name ?? id}
          id={id}
        />
      ) : (
        <input
          {...rest}
          placeholder={formattedPlaceholder}
          required={required}
          name={name ?? id}
          id={id}
        />
      )}
      {helperText && <span className="helper-text">{helperText}</span>}
    </Wrapper>
  );
};

export default TextField;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;

  &.filled {
    textarea,
    input {
      background: ${colors.inputBg};
      border: none;
    }
  }

  input {
    width: 100%;
  }

  label {
    font-weight: 500;
  }

  .helper-text {
    font-size: 13px;
  }
`;
