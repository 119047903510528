import { Maybe } from "@graphql-types";
import { colors } from "@util/constants";
import React from "react";
import styled from "styled-components";

interface Props extends React.InputHTMLAttributes<HTMLSelectElement> {
  label?: string;
  id: string;
  theme?: "outlined" | "filled";
  hideEmptyOption?: boolean;
  options: { title: Maybe<string> | undefined; value: Maybe<string> | undefined }[];
  description?: Maybe<string> | undefined;
}

const Select = ({
  label,
  theme,
  required,
  placeholder,
  id,
  name,
  options,
  hideEmptyOption,
  description,
  ...rest
}: Props) => {
  const formattedPlaceholder = placeholder && required && !label ? `${placeholder}*` : placeholder;

  return (
    <Wrapper className={`select ${theme}`}>
      {label && (
        <label htmlFor={id}>
          {label}
          {required ? "*" : ""}
        </label>
      )}
      <select
        {...rest}
        name={name ?? id}
        id={id}
        required={required}
        placeholder={formattedPlaceholder}
      >
        {!hideEmptyOption && <option value="">Please Select</option>}
        {options.map(opt => (
          <option key={opt.value} value={opt.value ?? ""}>
            {opt.title ?? "Option"}
          </option>
        ))}
      </select>
      {description && <p className="helper-text">{description}</p>}
    </Wrapper>
  );
};

export default Select;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;

  select {
    width: 100%;
  }

  &.filled {
    select {
      background: ${colors.inputBg};
      border: none;
    }
  }

  label {
    font-weight: 500;
  }

  .helper-text {
    font-size: 13px;
  }
`;
