import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const HandleQuery = () => {
  const handleQuery: Query = useStaticQuery(graphql`
    {
      sanityPropertyTemplate {
        slug {
          current
        }
      }
      sanityCareersPage {
        pageMeta {
          ...sanitySlug
        }
      }
      sanityContactPage {
        pageMeta {
          ...sanitySlug
        }
      }
      sanityServiceProvidersPage {
        pageMeta {
          ...sanitySlug
        }
      }
      sanityPrivacyPolicyPage {
        pageMeta {
          ...sanitySlug
        }
      }
      allSanityLockedPage {
        nodes {
          _id
          pageMeta {
            slug {
              current
            }
          }
        }
      }
      allSanityPackageLanding {
        nodes {
          _id
          pageMeta {
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const packageNodes = handleQuery.allSanityPackageLanding.nodes;
  const lockedPageNodes = handleQuery.allSanityLockedPage.nodes;

  const handles = {
    career: handleQuery.sanityCareersPage,
    contact: handleQuery.sanityContactPage,
    service: handleQuery.sanityServiceProvidersPage,
    policy: handleQuery.sanityPrivacyPolicyPage,
    go: packageNodes.find(node => node._id === "homeSellGo"),
    pro: packageNodes.find(node => node._id === "homeSellPro"),
    know: lockedPageNodes.find(node => node._id === "homeSellKnowPage"),
    testimonial: lockedPageNodes.find(node => node._id === "testimonialsPage"),
    team: lockedPageNodes.find(node => node._id === "ourTeamPage"),
    notFound: lockedPageNodes.find(node => node._id === "404"),
    property: handleQuery.sanityPropertyTemplate,
  };

  return handles;
};

export default HandleQuery;
