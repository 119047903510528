import React, { CSSProperties } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

import { Maybe, SanityImageWithMeta } from "@graphql-types";

const StyledImage = styled(GatsbyImage)<{
  width: string | undefined;
  filter: string | undefined;
}>`
  width: ${props => props.width ?? "auto"};
  ${({ filter }) => filter && `filter: ${filter};`}
`;

interface Overlayprops {
  overlayOpacity: string;
}

const BackgroundImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
`;

const Overlay = styled.div<Overlayprops>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, ${props => (props.overlayOpacity ? props.overlayOpacity : 0.5)}),
    transparent
  );
`;
interface Props {
  data: Maybe<SanityImageWithMeta> | null;
  minHeight?: string;
  isBackground?: boolean;
  width?: string;
  height?: string;
  onClick?: () => void;
  objectFit?: CSSProperties["objectFit"];
  margin?: string;
  filter?: string;
  url?: string;
  overlay?: string;
  overlayOpacity?: string;
}

const ImageObject = (props: Props) => {
  const { objectFit } = props;
  const imageData = props.data?.asset?.gatsbyImageData;
  if (props.isBackground && imageData) {
    return (
      <BackgroundImageWrapper>
        <StyledImage
          image={imageData}
          style={{ margin: props.margin, height: "100%" }}
          width={props.width}
          filter={props.filter}
          itemProp="image"
          alt={props.data?.altText ?? "bg-image"}
          onClick={props.onClick}
          objectFit={objectFit ?? "cover"}
        />
        {props.overlay && <Overlay overlayOpacity={props.overlay} />}
      </BackgroundImageWrapper>
    );
  }

  if (props.isBackground && props.url) {
    return (
      <BackgroundImageWrapper>
        <img
          src={props.url}
          style={{
            margin: props.margin,
            height: "100%",
            objectFit: objectFit ?? "cover",
            width: "100%",
          }}
        />
      </BackgroundImageWrapper>
    );
  }

  if (imageData) {
    return (
      <StyledImage
        image={imageData}
        style={{ margin: props.margin }}
        width={props.width}
        filter={props.filter}
        itemProp="image"
        alt={props.data?.altText ?? "gatsby-image"}
        onClick={props.onClick}
        objectFit={objectFit ?? "contain"}
      />
    );
  }

  return null;
};

const Image = (props: Props) => {
  if (!props.data && !props.url) return null;

  // const { link } = props.data.link;
  const newWindow = props?.data?.link && props.data.link.indexOf("http") > -1;

  if (props?.data?.link) {
    if (newWindow) {
      return (
        <a href={props?.data?.link}>
          <ImageObject {...props} />
        </a>
      );
    }

    return (
      <GatsbyLink to={props?.data?.link}>
        <ImageObject {...props} />
      </GatsbyLink>
    );
  }

  return <ImageObject {...props} />;
};

export default Image;
