import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const NotFoundQuery = () => {
  const { sanityLockedPage }: Query = useStaticQuery(graphql`
    {
      sanityLockedPage(_id: { eq: "404" }) {
        pageMeta {
          ...sanityPageMeta
        }
      }
    }
  `);

  return sanityLockedPage;
};

export default NotFoundQuery;
