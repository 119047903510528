import { Button, TextField, Toggle } from "@global";
import { GridContainer } from "@util/standard";
import React, { useState } from "react";
import { emailValidator } from "@util/helper";
import { SanityProperty } from "@graphql-types";
import { toast } from "react-toastify";
import { createDocument } from "@util/firebase";
import styled from "styled-components";
import { useStore } from "@state/store";
import { defProps } from "@util/inputs";

interface Props {
  property: SanityProperty;
}

const SALE_CONDITIONS_OPTIONS = [
  { title: "Finance", value: "Finance" },
  { title: "LIM", value: "LIM" },
  { title: "Building Report", value: "Building Report" },
  { title: "Title", value: "Title" },
  { title: "Toxicology Report", value: "Toxicology Report" },
  { title: "Sale of own property", value: "Sale of own property" },
  { title: "Solicitors approval", value: "Solicitors approval" },
  { title: "Other", value: "Other" },
];

const YES_NO_OPTIONS = [
  { title: "Yes", value: "yes" },
  { title: "No", value: "no" },
];

const INITIAL_STATE = {
  company: "",
  firstName: "",
  lastName: "",
  nominee: "",
  email: "",
  phone: "",
  address: "",
  solicitorsDetails: "",
  gst: "",
  resident: "",
  price: "",
  deposit: "",
  conditionsOfSale: "",
  conditionsOfSaleOther: "",
  conditionsToBeSatisfied: "",
  proposedDate: "",
};

const PropertyEoiForm = ({ property }: Props) => {
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const user = useStore(s => s.user);

  const handleChangeInput = (key: string, value: string) => {
    setInputs(p => ({
      ...p,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!emailValidator(inputs.email) || !inputs.firstName || !inputs.lastName || !inputs.phone) {
      toast.error("Please make sure all fields are entered and try again!");
      return;
    }

    setLoading(true);

    const formattedData = {
      ...inputs,
      propertyId: property.propertyId ?? "",
      uid: user?.id ?? "",
      createdAt: new Date().toISOString(),
    };

    const docId = await createDocument("expressionOfInterest", formattedData);
    if (!docId) {
      toast.error("Error on send Expression of Interest, please try again");
      setLoading(false);
      return;
    }

    if (property.sellerDetails?.mainEmail) {
      try {
        await fetch(`/.netlify/functions/sendEOIEmail`, {
          method: "POST",
          body: JSON.stringify({
            inputs,
            to: {
              email: property.sellerDetails?.mainEmail,
              name: `${property.sellerDetails?.mainFirstName} ${property.sellerDetails?.mainLastName}`,
            },
          }),
        });
      } catch (error) {
        console.log("Error on send email to seller: ", error);
      }
    }

    try {
      await fetch(`/.netlify/functions/sendEOIResponseEmail`, {
        method: "POST",
        body: JSON.stringify({
          inputs,
        }),
      });
    } catch (error) {
      console.log("Error on send confirmation email: ", error);
    }

    setLoading(false);
    setClickedSubmit(true);
  };

  if (clickedSubmit)
    return (
      <>
        <h3>
          Thank you for your message.
          <br />
          We will be in contact.
        </h3>
        <Button linkText="Back to Browse Properties" internalSlug={`/properties`} />
      </>
    );
  return (
    <Wrapper>
      <h4>Expression of Interest</h4>
      <div className="inputs">
        <GridContainer gap="15px" repeat={1}>
          <h5>Buyer Details</h5>
          <TextField
            label="Name/s (full names/s, company name or trust name including full names of trustees)"
            theme="filled"
            id="company"
            onChange={e => handleChangeInput("company", e.target.value)}
            value={inputs.company}
            placeholder="Company"
          />
          <GridContainer repeat={2} gap="15px" width="100%">
            <TextField
              {...defProps.firstName}
              onChange={e => handleChangeInput("firstName", e.target.value)}
              value={inputs.firstName}
              required
            />
            <TextField
              {...defProps.lastName}
              onChange={e => handleChangeInput("lastName", e.target.value)}
              value={inputs.lastName}
              required
            />
          </GridContainer>
        </GridContainer>
        <Toggle
          id="nominee"
          label="And/or nominee (this is optional)?"
          options={YES_NO_OPTIONS}
          value={inputs.nominee}
          onChange={val => handleChangeInput("nominee", val)}
        />
        <TextField
          {...defProps.email}
          onChange={e => handleChangeInput("email", e.target.value)}
          value={inputs.email}
          required
        />
        <TextField
          {...defProps.phone}
          onChange={e => handleChangeInput("phone", e.target.value)}
          value={inputs.phone}
          required
        />
        <TextField
          label="Address"
          theme="filled"
          id="address"
          onChange={e => handleChangeInput("address", e.target.value)}
          value={inputs.address}
          placeholder="Address"
        />
        <TextField
          label="Solicitors details (name, firm, phone, email)"
          theme="filled"
          inputType="textarea"
          id="solicitorsDetails"
          onChange={e => handleChangeInput("solicitorsDetails", e.target.value)}
          value={inputs.solicitorsDetails}
          placeholder="Solicitors Details"
        />
        <Toggle
          label="Will you be registered for GST in respect of this property transaction?"
          options={YES_NO_OPTIONS}
          value={inputs.gst}
          id="gst"
          onChange={val => handleChangeInput("gst", val)}
        />
        <GridContainer gap="10px" repeat={1} width="100%">
          <p>
            Are you
            <br />
            a) a NZ, Australian or Singaporean citizen or <br />
            b) a permanent NZ resident living in NZ* or
            <br />
            c) hold a NZ resident visa and live in NZ *
          </p>
          <Toggle
            options={YES_NO_OPTIONS}
            value={inputs.resident}
            id="resident"
            onChange={val => handleChangeInput("resident", val)}
          />
          <i>
            *You have been in NZ for at least 12 months and spent the last 183 days of the past 12
            months in NZ
          </i>
        </GridContainer>
        <hr />

        <GridContainer gap="15px" repeat={1} width="100%">
          <h5>Offer Details</h5>
          <TextField
            theme="filled"
            inputType="currency"
            id="price"
            label="Price"
            onValueChange={val => handleChangeInput("price", val ?? "")}
            value={inputs.price}
            placeholder="Price offered $"
          />
          <TextField
            theme="filled"
            id="deposit"
            onChange={e => handleChangeInput("deposit", e.target.value)}
            value={inputs.deposit}
            placeholder="Deposit ($ amount or % amount)"
          />
        </GridContainer>
        <GridContainer repeat={1} width="100%" gap="30px">
          <Toggle
            label="Do you have any conditions?"
            options={SALE_CONDITIONS_OPTIONS}
            id="conditionsOfSale"
            value={inputs.conditionsOfSale}
            onChange={val => handleChangeInput("conditionsOfSale", val)}
          />
          {inputs.conditionsOfSale === "Other" && (
            <TextField
              theme="filled"
              id="deposit"
              onChange={e => handleChangeInput("conditionsOfSaleOther", e.target.value)}
              value={inputs.conditionsOfSaleOther}
              placeholder="Other (Please Specify)"
            />
          )}
        </GridContainer>
        <GridContainer gap="15px" repeat={1} width="100%">
          <TextField
            theme="filled"
            id="conditionsToBeSatisfied"
            rows={5}
            inputType="textarea"
            onChange={e => handleChangeInput("conditionsToBeSatisfied", e.target.value)}
            value={inputs.conditionsToBeSatisfied}
            placeholder="Conditions to be satisfied by (expressed as number of working days or a set date)"
          />
          <TextField
            theme="filled"
            rows={5}
            id="proposedDate"
            inputType="textarea"
            onChange={e => handleChangeInput("proposedDate", e.target.value)}
            value={inputs.proposedDate}
            placeholder="Proposed date of Settlement/Possession"
          />
        </GridContainer>
        <p>
          <i>
            DISCLAIMER: This document is an expression of interest only. It does not in any way form
            a legally binding offer or agreement.. The information should be provided to either/both
            parties' solicitors or the HomeSell Pro agent who will then complete a formal Agreement
            for Sale and Purchase of Real Estate which may result in a binding agreement.
          </i>
        </p>
        <Button onClick={handleSubmit} loading={loading} theme="base" margin="20px 0 0 0">
          Submit
        </Button>
      </div>
    </Wrapper>
  );
};

export default PropertyEoiForm;

const Wrapper = styled.div`
  p {
    margin-bottom: 5px;
  }

  hr {
    margin: 0;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;
