import firebase from "gatsby-plugin-firebase";
import { toast } from "react-toastify";
import { emailValidator } from "./helper";
import { User } from "../state/types";

export const createDocument = async (collection: string, data: any, id?: string) => {
  const firestore = firebase.firestore();
  const docCol = firestore.collection(collection);

  try {
    if (id) {
      await docCol.doc(id).set(data, { merge: true });
      return id;
    } else {
      const docRef = docCol.doc();
      await docRef.set({ ...data, id: docRef.id });
      return docRef.id;
    }
  } catch (err: any) {
    toast.error(err?.message ?? "Something went wrong, please try again later.");
    return null;
  }
};

export const getDocument = async <T>(collection: string, id: string): Promise<T | null> => {
  if (!collection || !id) return null;

  const firestore = firebase.firestore();
  const docRef = firestore.collection(collection).doc(id);

  try {
    const doc = await docRef.get();
    const data = doc.data() as T;

    if (!data) throw new Error();

    return data;
  } catch (err: any) {
    toast.error(err?.message ?? "Something went wrong, please try again later.");
    return null;
  }
};

export const updateDocument = async (collection: string, id: string, data: any) => {
  const firestore = firebase.firestore();
  const docRef = firestore.collection(collection).doc(id);

  console.log({ docRef });

  try {
    await docRef.update(data);
    return true;
  } catch (err: any) {
    console.log({ err });

    toast.error(err?.message ?? "Something went wrong, please try again later.");
    return false;
  }
};

export const getDocsCollection = async <T = any>(
  docRef: firebase.firestore.Query<firebase.firestore.DocumentData>,
) => {
  try {
    const snap = await docRef.get();
    const docs =
      snap.docs?.map(doc => (doc?.exists ? { ...doc.data(), id: doc.id } : null)).filter(Boolean) ??
      [];
    return docs as T[];
  } catch (err: any) {
    toast.error("Please enter a valid email and password");
    return null;
  }
};

export const queryCollection = <T = any>(
  collection: string,
  fieldPath: string,
  operator: "<" | "<=" | "==" | ">" | ">=",
  value: string,
): Promise<T[] | null> => {
  const firestore = firebase.firestore();
  const docRef = firestore.collection(collection).where(fieldPath, operator, value);
  return getDocsCollection<T>(docRef);
};

export const createUserWithEmailAndPassword = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
) => {
  console.log(email, password, firstName, lastName);
  if (!firstName || !lastName || !email || !password || !emailValidator(email)) {
    toast.error("Please enter a valid email and password");
    return null;
  }

  try {
    const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);

    if (!user?.uid || !user.email) throw new Error();

    const userData = formatUserDataToSave(user.uid, user.email ?? "", firstName, lastName);
    await createDocument("users", userData, user.uid);
    return userData;
  } catch (err: any) {
    toast.error(err?.message ?? "Something went wrong, please try again later.");
    return null;
  }
};

//createOrSigninUserWithGoogle
export const signInWithExternalProvider = async (type: string) => {
  const provider =
    type === "google"
      ? new firebase.auth.GoogleAuthProvider()
      : new firebase.auth.FacebookAuthProvider();

  try {
    const { user } = await firebase.auth().signInWithPopup(provider);

    if (!user?.uid || !user.email) throw new Error();

    const userData = await getDocument<User>("users", user.uid);

    if (userData) {
      return userData;
    } else {
      const nameArr = user.displayName?.split(" ");
      const userData = formatUserDataToSave(
        user.uid,
        user.email,
        nameArr?.[0] ?? "",
        nameArr?.slice(1).join(" ") ?? "",
      );
      await createDocument("users", userData, user.uid);
      return userData;
    }
  } catch (err: any) {
    toast.error(err?.message ?? "Something went wrong, please try again later.");
    return null;
  }
};

export const signInWithEmailAndPassword = async (email?: string, password?: string) => {
  if (!email || !password || !emailValidator(email)) {
    toast.error("Please enter a valid email and password");
    return null;
  }

  try {
    const { user } = await firebase.auth().signInWithEmailAndPassword(email, password);

    if (!user?.uid) throw new Error();

    const userData = await getDocument<User>("users", user.uid);

    if (!userData) throw new Error();

    return userData;
  } catch (err: any) {
    toast.error(err?.message ?? "Something went wrong, please try again later.");
    return null;
  }
};

export const resetPassword = async (email: string) => {
  if (!email || !emailValidator(email)) {
    toast.error("Please enter a valid email address.");
    return false;
  }

  try {
    await firebase.auth().sendPasswordResetEmail(email);
    toast.success("Password reset confirmation has been sent. Please check your email.");
    return true;
  } catch (err: any) {
    toast.error(err.message ?? "Something went wrong, please try again later.");
    return false;
  }
};

const formatUserDataToSave = (uid: string, email: string, firstName: string, lastName: string) => {
  return {
    id: uid,
    email: email,
    firstName,
    lastName,
    createdAt: new Date().toISOString(),
  } as User;
};
