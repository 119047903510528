import React, { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { colors, colorsRGB, MOBILE_BREAKPOINT } from "@util/constants";
import { Container, P } from "@util/standard";
import { Minus, Plus } from "@util/svg";

const QuantityContainer = styled(Container)<{
  fontSize: string;
  mobileFontSize: string;
}>`
  border: 1px solid ${colors.main};
  border-radius: 5px;
  font-size: ${({ fontSize }) => fontSize};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

const QuantityButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3.2em;
  color: ${colors.main};
  cursor: pointer;
  border: none;

  svg {
    width: 14px;
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};

  &:hover {
    background: ${colorsRGB.main(0.3)};
  }
`;

const StyledInput = styled.input`
  width: 5em;
  box-sizing: border-box;
  padding: 0.7em;
  text-align: center;
  border: none;
  font-size: 1em;

  &:active,
  &:focus {
    outline: none;
  }
`;

interface Props {
  defaultQuantity?: number;
  quantityMax?: number;
  fontSize?: string;
  mobileFontSize?: string;
  disabled?: boolean;
  onChange?: (arg?: any) => void;
}

const QuantityInput = ({
  defaultQuantity,
  quantityMax,
  fontSize,
  mobileFontSize,
  disabled,
  onChange,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<number>(defaultQuantity ?? 1);
  const inputEl = useRef(null);

  useEffect(() => {
    setQuantity(defaultQuantity ?? 1);
  }, [defaultQuantity]);

  const increment = (e: MouseEvent) => {
    e.preventDefault();
    if (quantityMax && quantity >= quantityMax) {
      setError("You have reached the maximum available stock");
      return;
    }
    setError(null);
    if (setQuantity) {
      setQuantity(quantity + 1);
    }
  };

  const decrement = (e: MouseEvent) => {
    e.preventDefault();
    setError(null);
    if (setQuantity && quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  if (onChange) {
    useEffect(() => {
      onChange(inputEl);
    }, [quantity]);
  }

  return (
    <Container flexDirection="column" alignItems="flex-start">
      <QuantityContainer
        fontSize={fontSize ?? "14px"}
        mobileFontSize={mobileFontSize ?? "12px"}
        justifyContent="center"
        alignItems="stretch"
      >
        <QuantityButton disabled={disabled} name="quantity-decrease" onClick={decrement}>
          <Minus color="current" />
        </QuantityButton>
        {/* Use default quantity when on cart page, use useState on Product Form */}
        <StyledInput
          ref={inputEl}
          type="text"
          name="quantity"
          className="quantity-input"
          value={quantity}
          readOnly
        />
        <QuantityButton disabled={disabled} name="quantity-increase" onClick={increment}>
          <Plus color="current" />
        </QuantityButton>
      </QuantityContainer>
      {error && (
        <P fontSize={12} color="errorRed">
          {error}
        </P>
      )}
    </Container>
  );
};

export default QuantityInput;
