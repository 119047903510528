import { Cart, ChangeRequest, Order, SearchParams, User } from "./types";
import { devtools, persist } from "zustand/middleware";

import create from "zustand";
import { generateString } from "@util/helper";
import { toast } from "react-toastify";

export const defaultSearchParams = {
  searched: false,
  bathrooms: [],
  bedrooms: [],
  maxPrice: null,
  minPrice: null,
  region: null,
  searchTerm: null,
  types: [],
};

export const defaultCart = () => {
  return {
    packages: [],
    totalItems: 0,
    totalPrice: 0,
    uuid: generateString(12),
  };
};

interface State {
  searchParams: SearchParams;
  cart: Cart;
  cartOpen: boolean;
  checkoutInitiated: boolean;
  user?: User | null;
  setUser: (user: User) => void;
  orderLog?: Array<Order> | null;
  changeRequests?: Array<ChangeRequest | null>;
  userChangeRequests?: Array<ChangeRequest | null>;
  setOrderLog: (orderLog: Array<Order>) => void;
  setChangeRequests: (changeRequest: Array<ChangeRequest>) => void;
  setUserChangeRequests: (userChangeRequests: Array<ChangeRequest>) => void;
  //Updates
  shouldUpdateFirebaseData: boolean;
  setShouldUpdateFirebaseData: (shouldUpdateFirebaseData: boolean) => void;
  shouldUpdateOrders: boolean;
  setShouldUpdateOrders: (shouldUpdateOrders: boolean) => void;
  enquiryFormOpen?: boolean;
  setEnquiryFormOpen: (enquiryFormOpen: boolean) => void;
  logout: () => void;
}

export const store = persist<State>(
  set => ({
    //initial state
    searchParams: defaultSearchParams,
    cart: defaultCart(),
    cartOpen: false,
    checkoutInitiated: false,
    user: null,
    setUser: user => set({ user }),
    orderLog: [],
    changeRequests: [],
    setOrderLog: orderLog => set({ orderLog }),
    setChangeRequests: changeRequests => set({ changeRequests }),
    shouldUpdateOrders: true,
    setShouldUpdateOrders: shouldUpdateOrders => set({ shouldUpdateOrders }),
    shouldUpdateChangeRequests: true,
    userChangeRequests: [],
    setUserChangeRequests: userChangeRequests => set({ userChangeRequests }),
    shouldUpdateFirebaseData: true,
    setShouldUpdateFirebaseData: shouldUpdateFirebaseData => set({ shouldUpdateFirebaseData }),
    setEnquiryFormOpen: enquiryFormOpen => {
      console.log(enquiryFormOpen);

      return set({ enquiryFormOpen: enquiryFormOpen });
    },
    logout: () => {
      const timer = setTimeout(() => {
        set({
          user: null,
          cartOpen: false,
          checkoutInitiated: false,
        });
        toast.success("You have been logged out");
      }, 1000);
      return () => clearTimeout(timer);
    },
  }),
  {
    name: "homesell", // unique name
    getStorage: () => localStorage,
  },
);

export const useStore = create(
  process.env.GATSBY_NODE_ENV !== "production" ? devtools(store) : store,
);
