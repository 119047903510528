import React, { Dispatch, FormEvent, MouseEvent, SetStateAction, useState } from "react";
import styled from "styled-components";
import { Container, Form, GridContainer } from "@util/standard";
import { Button, Link, TextField } from "@global";
import { useStore } from "@state/store";
import { Chevron } from "@util/svg";
import {
  resetPassword,
  signInWithEmailAndPassword,
  signInWithExternalProvider,
} from "@util/firebase";
import { toast } from "react-toastify";
import { User } from "@state/types";
import { defProps } from "@util/inputs";

interface Props {
  isCart?: boolean;
  setIsCreateAccount: Dispatch<SetStateAction<boolean>>;
  onLoggedIn?: () => void;
}

interface PasswordResetProps {
  setResetPassword: Dispatch<SetStateAction<boolean>>;
}

const PasswordReset = ({ setResetPassword }: PasswordResetProps) => {
  const [loading, setLoading] = useState(false);

  const handleResetPasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!e.currentTarget?.elements) return;

    const email = (e.currentTarget.elements as any)?.email?.value as string;

    setLoading(true);
    await resetPassword(email);
    setLoading(false);
    setResetPassword(false);
  };

  return (
    <>
      <Heading></Heading>
      <Form onSubmit={handleResetPasswordSubmit}>
        <h4>Reset Password</h4>
        <p>
          Enter your email below and we&rsquo;ll send you an email with a link to reset your
          password.
        </p>
        <GridContainer repeat={1} margin="0 0 15px" width="100%">
          <TextField {...defProps.email} label="" required />
        </GridContainer>

        <Button type="submit" linkText="Reset Password" loading={loading} margin="10px 0 0" />
      </Form>
      <BackLink externalLink="" onClick={() => setResetPassword(false)} margin="35px 0 0">
        <Chevron type="left" margin="0 18px 0 0" color="current" />
        <span>Back to login</span>
      </BackLink>
    </>
  );
};

const LoginForm = ({ isCart, setIsCreateAccount, onLoggedIn }: Props) => {
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const setUser = useStore(s => s.setUser);

  const handleCartClick = (e: MouseEvent) => {
    e.preventDefault();
    useStore.setState({ checkoutInitiated: false });
    setResetPassword(false);
  };

  const handleCreateAccountClick = (e: MouseEvent) => {
    e.preventDefault();
    setIsCreateAccount(true);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!e.currentTarget?.elements) return;

    const { email, password } = e.currentTarget.elements as any;

    setLoading(true);
    const user = await signInWithEmailAndPassword(email.value, password.value);
    handlePostLogin(user);
  };

  const handleExternalLogin = async (type: string) => {
    setLoading(true);
    const user = await signInWithExternalProvider(type);
    handlePostLogin(user);
  };

  const handlePostLogin = (user: User | null) => {
    setLoading(false);
    if (user) {
      setUser(user);
      onLoggedIn && onLoggedIn();
      toast.success("Succesfully Signed In");
    }
  };

  return (
    <LoadingWrapper loading={loading}>
      {isCart && (
        <BackLink externalLink="" onClick={handleCartClick}>
          <Chevron type="left" margin="0 18px 0 0" color="current" />
          <span>Back</span>
        </BackLink>
      )}

      {resetPassword ? (
        <PasswordReset setResetPassword={setResetPassword} />
      ) : (
        <>
          <Heading>
            <h4>Login to My HomeSell</h4>
          </Heading>

          <Form onSubmit={handleSubmit}>
            <GridContainer repeat={1} margin="0 0 15px" width="100%">
              <TextField {...defProps.email} label="" required />
            </GridContainer>
            <GridContainer repeat={1} margin="0 0 15px" width="100%">
              <TextField {...defProps.password} label="" autoComplete="current-password" required />
            </GridContainer>
            <Button
              type="submit"
              linkText={isCart ? "Login and Proceed to Payment" : "Login to My HomeSell"}
              loading={loading}
              margin="10px 0 0"
            />
          </Form>
          <Link
            animatedSvg
            externalLink=""
            onClick={() => setResetPassword(true)}
            margin="15px 0 0"
            fontSize="0.9em"
          >
            <span className="login-button">Forgotten password?</span>
          </Link>

          <hr />
          <Container flexFlow="column" width="100%" margin="0 0 35px">
            <SocialButton
              isGoogle
              linkText="Sign In through Google"
              onClick={() => handleExternalLogin("google")}
              theme="baseOutline"
            />
            <SocialButton
              isFacebook
              linkText="Sign In through Facebook"
              onClick={() => handleExternalLogin("facebook")}
              theme="baseOutline"
              margin="15px 0 0"
            />
          </Container>

          <Link animatedSvg externalLink="" onClick={handleCreateAccountClick}>
            <span className="login-button">Create Account</span>
            <Chevron type="right" margin="0 0 0 18px" color="current" />
          </Link>
        </>
      )}
    </LoadingWrapper>
  );
};

export default LoginForm;

export const LoadingWrapper = styled.div<{
  loading: boolean;
}>`
  transition: opacity 0.3s ease;

  ${({ loading }) =>
    loading &&
    `
    pointer-events: none;
    opacity: 0.8;
  `}
`;

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;

  svg {
    transition: ease transform 0.3s;
    vertical-align: middle;
  }
  &:hover {
    svg {
      transform: translateX(-3px);
    }
  }
`;

export const SocialButton = styled(Button)<{
  isGoogle?: boolean;
  isFacebook?: boolean;
}>`
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: "";
      display: inline-block;
      margin: 0 10px 0 0;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      ${({ isGoogle }) =>
        isGoogle &&
        `
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.83332 7.83333V12H15.5C14.6667 14.25 12.5 15.75 10 15.75C6.75 15.75 4.16667 13.1667 4.16667 9.91667C4.16667 6.66667 6.75 4.08333 10 4.08333C11.25 4.08333 12.4167 4.5 13.4167 5.16667L13.75 5.41667L16.25 2.16667L15.9167 1.91667C14.1667 0.666666 12.1667 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10V7.91667H9.83332V7.83333Z' fill='%23133456'/%3E%3C/svg%3E%0A");
      `}
      ${({ isFacebook }) =>
        isFacebook &&
        `
        background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.25223 0.0295365C1.78056 0.279852 0.486729 1.48203 0.0848422 2.97255C0.00319565 3.27537 0 3.59549 0 11.4902C0 19.3849 0.00319565 19.705 0.0848422 20.0079C0.268885 20.6904 0.635529 21.3198 1.1462 21.8298C1.63208 22.315 2.11926 22.6119 2.81307 22.8456L3.19565 22.9744L7.34774 22.9872L11.4998 23V18.6616V14.3232L10.0933 14.3113L8.68676 14.2995V12.3892V10.4789L10.0933 10.467L11.4998 10.4551L11.5 9.5231C11.5001 8.91365 11.5234 8.44839 11.5672 8.17879C11.9025 6.11366 13.3524 4.48672 15.1662 4.14017C15.4345 4.08888 15.902 4.07378 17.22 4.07378H18.9263V5.98407V7.89436H17.3539C16.3494 7.89436 15.7358 7.91167 15.6549 7.94232C15.4658 8.01383 15.3261 8.16037 15.2076 8.41127C15.107 8.62418 15.1014 8.68397 15.101 9.5462L15.1006 10.4564H17.0135H18.9263V12.3892V14.3219H17.0135H15.1006V18.6594V22.9969L17.2723 22.9965C18.5484 22.9964 19.562 22.9771 19.7302 22.9498C21.0149 22.7416 22.2383 21.7567 22.7338 20.5319C23.0204 19.8232 22.9997 20.5319 22.9997 11.4453V3.19729L22.8706 2.81524C22.4823 1.66529 21.7237 0.806868 20.6647 0.319137C19.9284 -0.0199513 20.7533 0.00913009 11.6349 0.000769759C7.14182 -0.00336546 3.36965 0.00957958 3.25223 0.0295365Z' fill='%23133456'/%3E%3C/svg%3E%0A");
      `}
    }
  }
  &:hover {
    span {
      &:before {
        ${({ isGoogle }) =>
          isGoogle &&
          `
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.83332 7.83333V12H15.5C14.6667 14.25 12.5 15.75 10 15.75C6.75 15.75 4.16667 13.1667 4.16667 9.91667C4.16667 6.66667 6.75 4.08333 10 4.08333C11.25 4.08333 12.4167 4.5 13.4167 5.16667L13.75 5.41667L16.25 2.16667L15.9167 1.91667C14.1667 0.666666 12.1667 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10V7.91667H9.83332V7.83333Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
        `}
        ${({ isFacebook }) =>
          isFacebook &&
          `
          background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.25223 0.0295365C1.78056 0.279852 0.486729 1.48203 0.0848422 2.97255C0.00319565 3.27537 0 3.59549 0 11.4902C0 19.3849 0.00319565 19.705 0.0848422 20.0079C0.268885 20.6904 0.635529 21.3198 1.1462 21.8298C1.63208 22.315 2.11926 22.6119 2.81307 22.8456L3.19565 22.9744L7.34774 22.9872L11.4998 23V18.6616V14.3232L10.0933 14.3113L8.68676 14.2995V12.3892V10.4789L10.0933 10.467L11.4998 10.4551L11.5 9.5231C11.5001 8.91365 11.5234 8.44839 11.5672 8.17879C11.9025 6.11366 13.3524 4.48672 15.1662 4.14017C15.4345 4.08888 15.902 4.07378 17.22 4.07378H18.9263V5.98407V7.89436H17.3539C16.3494 7.89436 15.7358 7.91167 15.6549 7.94232C15.4658 8.01383 15.3261 8.16037 15.2076 8.41127C15.107 8.62418 15.1014 8.68397 15.101 9.5462L15.1006 10.4564H17.0135H18.9263V12.3892V14.3219H17.0135H15.1006V18.6594V22.9969L17.2723 22.9965C18.5484 22.9964 19.562 22.9771 19.7302 22.9498C21.0149 22.7416 22.2383 21.7567 22.7338 20.5319C23.0204 19.8232 22.9997 20.5319 22.9997 11.4453V3.19729L22.8706 2.81524C22.4823 1.66529 21.7237 0.806868 20.6647 0.319137C19.9284 -0.0199513 20.7533 0.00913009 11.6349 0.000769759C7.14182 -0.00336546 3.36965 0.00957958 3.25223 0.0295365Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
        `}
      }
    }
  }
`;

export const Heading = styled.div`
  margin: 35px 0;
`;
