import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const PackageCustomizeTiers = () => {
  const { sanityPackageLanding }: Query = useStaticQuery(graphql`
    {
      sanityPackageLanding(pageMeta: { slug: { current: { eq: "homesell-go" } } }) {
        tiers {
          ...sanityTier
          customisableProductsDefault {
            product {
              ...sanityProduct
            }
            defaultSku
            defaultQuantity
          }
          customisableProductsAddOn {
            ...sanityProduct
          }
        }
      }
    }
  `);

  return sanityPackageLanding;
};

export default PackageCustomizeTiers;
