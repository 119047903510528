import styled from "styled-components";
import { CSSProperties } from "react";

import { colors, colorsRGB, fontWeights, PRIMARY_COLOR, TWENTY_INCH_SCREEN } from "./constants";
import { BackgroundColor, Color, FontWeights } from "./types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";
import { mediaQuery } from "@styles/mediaQueries";

export const Overlay = styled.div<{ zIndex?: number }>`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: ${props => props.zIndex ?? 3};
  left: 0;
  top: 0;
  background-color: ${colorsRGB.black(0.5)};
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  width?: number;
  lineHeight?: number;
  underlined?: boolean;
  fontWeight?: FontWeights;
  opacity?: number;
  display?: CSSProperties["display"];
  whiteSpace?: CSSProperties["whiteSpace"];
  cursor?: CSSProperties["cursor"];
  padding?: string;
  textAlign?: CSSProperties["textAlign"];
  letterSpacing?: CSSProperties["letterSpacing"];
  margin?: string;
  userSelect?: string;
}>`
  background-color: transparent;
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : fontWeights.normal)};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ userSelect }) => userSelect && `user-select: ${userSelect};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  bold?: boolean;
  hoverColor?: Color;
  hoverOpacity?: number;
  opacity?: number;
  margin?: string;
  tabletMargin?: string;
  padding?: string;
  display?: CSSProperties["display"];
  width?: string;
  animatedSvg?: boolean;
}>`
  color: ${props => (props.color ? colors[props.color] : "inherit")};
  display: ${({ display }) => (display ? `${display}` : `inline-block`)};
  ${({ width }) => width && `width: ${width};`}
  ${({ underlined }) => (underlined ? "text-decoration: underline;" : "text-decoration: none;")}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ hoverColor, hoverOpacity }) =>
    (hoverColor || hoverOpacity) &&
    `&:hover {
      ${hoverColor ? `color: ${colors[hoverColor]};` : ``}
      ${hoverOpacity ? `opacity: ${hoverOpacity};` : ``}
  }`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin}`};
  }

  ${({ animatedSvg }) =>
    animatedSvg &&
    `
    svg{
      transition: ease transform 0.3s;
      vertical-align: middle;
    }
    &:hover{
      svg{
        transform: translateX(3px);
      }
    }
  `}
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  width?: string;
  height?: string;
  opacity?: number;
  shouldShowMobile?: boolean;
}>`
  border-left: 1.5px solid ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  height: ${props => (props.height ? props.height : `auto`)};
  width: ${props => (props.width ? props.width : `20px`)};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
`;

export const Separator = styled.div<{
  color?: Color;
  height?: string;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  marginBottom?: number;
  width?: string;
}>`
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`};
  border-bottom: ${props => props.thickness ?? 3}px solid
    ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ width }) => width && `width: ${width ?? "100%"};`}
  height: ${props => props.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Container = styled.div<{
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: CSSProperties["flexDirection"];
  tabletFlexFlow?: CSSProperties["flexFlow"];
  mobileFlexFlow?: CSSProperties["flexFlow"];
  flex?: CSSProperties["flex"];
  flexGrow?: CSSProperties["flexGrow"];
  flexWrap?: CSSProperties["flexWrap"];
  flexFlow?: CSSProperties["flexFlow"];
  alignItems?: CSSProperties["alignItems"];
  tabletAlignItems?: CSSProperties["alignItems"];
  mobileAlignItem?: CSSProperties["alignItems"];
  alignSelf?: CSSProperties["alignSelf"];
  mobileAlignSelf?: CSSProperties["alignSelf"];
  borderRadius?: number;
  overflow?: CSSProperties["overflow"];
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  tabletPadding?: string;
  tabletWidth?: string;
  mobileWidth?: string;
  justifyContent?: CSSProperties["justifyContent"];
  mobileJustifyContent?: CSSProperties["justifyContent"];
  width?: string;
  maxWidth?: string;
  height?: string;
  tabletHeight?: string;
  opacity?: number;
  margin?: string;
  tabletMargin?: string;
  padding?: string;
  cursor?: CSSProperties["cursor"];
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  zIndex?: number;
  display?: CSSProperties["display"];
  backgroundSize?: CSSProperties["backgroundSize"];
  position?: CSSProperties["position"];
  borderBottom?: string;
}>`
  display: ${props => props.display ?? "flex"};
  flex-direction: ${props => props.flexDirection ?? "initial"};
  justify-content: ${props => props.justifyContent ?? "initial"};
  align-items: ${props => props.alignItems ?? "initial"};
  align-self: ${props => props.alignSelf ?? "initial"};
  overflow: ${props => props.overflow ?? "initial"};
  border-radius: ${props => props.borderRadius ?? 0}px;

  ${({ position }) => position && `position: ${position};`}
  ${({ backgroundSize }) => backgroundSize && `background-size: ${backgroundSize};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ flexFlow }) => flexFlow && `flex-flow: ${flexFlow};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ img }) => img && `background-image: url(${img});`}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) => backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isMobileColumn }) =>
      isMobileColumn &&
      `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}

    ${({ tabletFlexFlow }) => tabletFlexFlow && `flex-flow: ${tabletFlexFlow};`}
    ${({ tabletAlignItems }) => tabletAlignItems && `align-items: ${tabletAlignItems};`}
    ${({ tabletPadding }) => tabletPadding && `padding: ${tabletPadding};`}
    ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
    ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
    ${({ tabletHeight }) => tabletHeight && `height: ${tabletHeight};`};
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileFlexFlow }) => mobileFlexFlow && `flex-flow: ${mobileFlexFlow};`}
    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
    ${({ mobileAlignSelf }) => mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
    ${({ mobileAlignItem }) => mobileAlignItem && `align-items: ${mobileAlignItem}`}
    ${({ mobileJustifyContent }) =>
      mobileJustifyContent && `justify-content: ${mobileJustifyContent}`}
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
  }
`;

export const GridContainer = styled.div<{
  gridTemplateColumns?: string;
  width?: string;
  height?: string;
  margin?: string;
  repeat?: number;
  mobileRepeat?: number;
  tabletRepeat?: number;
  gap?: string;
  columnGap?: string;
  rowGap?: string;
  mobileColumnGap?: number;
  mobileRowGap?: number;
  rtl?: boolean;
  overflow?: CSSProperties["overflow"];
  position?: CSSProperties["position"];
}>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns, repeat }) =>
    gridTemplateColumns ? gridTemplateColumns : `repeat(${repeat ?? 4}, 1fr)`};
  ${({ gap }) => gap && `grid-gap: ${gap};`}
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap};`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ rtl }) => rtl && `direction: rtl;`}
  ${({ position }) => position && `position: ${position};`}

  ${props =>
    props.tabletRepeat &&
    `
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      grid-template-columns: repeat(${props.tabletRepeat}, 1fr);
    }
  `}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(${props => props.mobileRepeat ?? 1}, 1fr);
    ${({ mobileColumnGap }) => mobileColumnGap && `grid-column-gap: ${mobileColumnGap}px;`}
    ${({ mobileRowGap }) => mobileRowGap && `grid-row-gap: ${mobileRowGap}px;`}
  }
`;

export const TextButton = styled(P)<{
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  lineHeight?: number;
  margin?: string;
}>`
  line-height: ${props => props.lineHeight ?? 18}px;
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  color: ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  cursor: pointer;
  margin: ${props => props.margin ?? "0"};
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:focus {
    outline: 0;
  }
  &:hover {
    /* color: ${props => (props.hoverColor ? colors[props.hoverColor] : colors.main)}; */
  }
`;

export const GiveMeSomeSpace = styled.div<{ space?: number }>`
  padding: ${props => props.space ?? 10}px;
`;

export const AbsoluteWrapper = styled.div<{
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  zIndex?: number;
}>`
  position: absolute;
  ${({ left }) => left && `left: ${left};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ top }) => top && `top: ${top};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
`;

export const FullHeightContainer = styled(Container)<{
  height?: string | undefined;
  width?: string | undefined;
}>`
  flex-direction: column;
  position: relative;
  width: ${props => props.width ?? "100%"};
  height: ${props => props.height ?? "100vh"};
  overflow: hidden;
`;

export const MobileContainer = styled(Container)<{ isMobile?: Boolean }>`
  display: none;

  ${({ isMobile }) =>
    isMobile
      ? `
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      display: flex;
    }
  `
      : `
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      display: flex;
    }
  `}
`;

export const DesktopContainer = styled(Container)<{ isMobile?: Boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? `
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      display: none;
    }
  `
      : `
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      display: none;
    }
  `}
`;

export const Span = styled.span<{
  fontSize?: number;
  display?: CSSProperties["display"];
  margin?: string;
}>`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ display }) => display && `display: ${display};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const PageWidth = styled(Container)<{ paddingMob?: number }>`
  flex-flow: column nowrap;
  width: 70%;
  margin: auto;
  padding: 0 20px;

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    width: 90%;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    max-width: 850px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 668px;
    padding: 0 ${props => (props.paddingMob ? props.paddingMob : 20)}px;
  }
`;

export const ButtonWrapper = styled(Container)<{
  justifyContent?: CSSProperties["justifyContent"];
}>`
  flex-flow: row wrap;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "center")};
  align-items: center;
  margin-bottom: -10px;
  margin-right: -10px;

  button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export const Section = styled.section<{
  background?: BackgroundColor;
  isTop?: boolean;
}>`
  padding: ${({ isTop }) => (isTop ? `55px 0 120px` : `120px 0`)};

  ${({ background }) =>
    background &&
    `
    background: ${colors[background]};
  `}

  ${mediaQuery.tabletDown} {
    padding: ${({ isTop }) => (isTop ? `35px 0 60px` : `60px 0`)};
  }
`;

export const SwiperButton = styled.button<{ isInverted?: boolean }>`
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
  &:after {
    width: 40px;
    height: 40px;
    content: "";
    display: inline-block;
    ${({ isInverted }) =>
      isInverted
        ? `
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.95431 0 0 8.9543 0 20Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38.5C9.78273 38.5 1.5 30.2173 1.5 20C1.5 9.78273 9.78273 1.5 20 1.5C30.2173 1.5 38.5 9.78273 38.5 20C38.5 30.2173 30.2173 38.5 20 38.5ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1782C17.04 12.7539 17.6705 12.6966 18.0948 13.0502L23.7184 17.7365C24.6779 18.5361 24.6779 20.0098 23.7184 20.8094L18.0948 25.4957C17.6705 25.8493 17.04 25.7919 16.6864 25.3677C16.3328 24.9434 16.3902 24.3128 16.8144 23.9593L22.438 19.2729L16.8144 14.5866C16.3902 14.233 16.3328 13.6025 16.6864 13.1782Z' fill='white'/%3E%3C/svg%3E%0A");
    `
        : `
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20C38 29.9411 29.9411 38 20 38ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1782C17.04 12.7539 17.6705 12.6966 18.0948 13.0502L23.7184 17.7365C24.6779 18.5361 24.6779 20.0098 23.7184 20.8094L18.0948 25.4957C17.6705 25.8493 17.04 25.7919 16.6864 25.3677C16.3328 24.9434 16.3902 24.3128 16.8144 23.9593L22.438 19.2729L16.8144 14.5866C16.3902 14.233 16.3328 13.6025 16.6864 13.1782Z' fill='%23133456'/%3E%3C/svg%3E%0A");
    `}
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: background-image 0.3s ease;
  }

  &:hover {
    opacity: 1;
    &:after {
      ${({ isInverted }) =>
        isInverted
          ? `
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20C38 29.9411 29.9411 38 20 38ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1782C17.04 12.7539 17.6705 12.6966 18.0948 13.0502L23.7184 17.7365C24.6779 18.5361 24.6779 20.0098 23.7184 20.8094L18.0948 25.4957C17.6705 25.8493 17.04 25.7919 16.6864 25.3677C16.3328 24.9434 16.3902 24.3128 16.8144 23.9593L22.438 19.2729L16.8144 14.5866C16.3902 14.233 16.3328 13.6025 16.6864 13.1782Z' fill='%23133456'/%3E%3C/svg%3E%0A");
      `
          : `
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.95431 0 0 8.9543 0 20Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 38.5C9.78273 38.5 1.5 30.2173 1.5 20C1.5 9.78273 9.78273 1.5 20 1.5C30.2173 1.5 38.5 9.78273 38.5 20C38.5 30.2173 30.2173 38.5 20 38.5ZM20 40C8.95431 40 0 31.0457 0 20C0 8.9543 8.95431 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6864 13.1782C17.04 12.7539 17.6705 12.6966 18.0948 13.0502L23.7184 17.7365C24.6779 18.5361 24.6779 20.0098 23.7184 20.8094L18.0948 25.4957C17.6705 25.8493 17.04 25.7919 16.6864 25.3677C16.3328 24.9434 16.3902 24.3128 16.8144 23.9593L22.438 19.2729L16.8144 14.5866C16.3902 14.233 16.3328 13.6025 16.6864 13.1782Z' fill='white'/%3E%3C/svg%3E%0A");
      `}
    }
  }
`;

export const PrevButton = styled(SwiperButton)`
  &:after {
    transform: rotate(180deg);
  }
`;

export const NextButton = styled(SwiperButton)`
  margin-left: 20px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-left: 15px;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  p {
    margin-bottom: 10px;
    line-height: 1.4;
  }

  input,
  textarea,
  select {
    width: 100%;
  }

  input,
  textarea {
    background: ${colors.inputBg};
    border: none;
  }
`;

export const HeaderWrapper = styled.div`
  margin: 0 0 80px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 0 50px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

export const PropertyWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px 20px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 375px;
    margin: auto;
    grid-template-columns: 1fr;
  }
`;
