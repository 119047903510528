import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const HeaderQuery = () => {
  const { sanityNavigation }: Query = useStaticQuery(graphql`
    {
      sanityNavigation {
        menuItems {
          ... on SanityMenuItem {
            _key
            _type
            title
            image {
              ...sanityImageConstrained
            }
            links {
              ...sanityLink
            }
            ctasWithImage {
              ... on SanityCta {
                ...sanityCta
              }
              ... on SanityCtaWithImage {
                ...sanityCtaWithImage
              }
            }
          }
          ... on SanityLink {
            ...sanityLink
          }
        }
      }
    }
  `);

  return sanityNavigation;
};

export default HeaderQuery;
