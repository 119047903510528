import React from "react";
import styled from "styled-components";

import { useFooterQuery } from "@api";
import { PageWidth, GridContainer } from "@util/standard";
import { Link, Image } from "@global";
import { colors, fontWeights, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { em } from "@util/helper";

interface Props { }

const Wrapper = styled.div`
  background: ${colors.main};
  color: ${colors.white};
  padding: 120px 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 70px 0;
  }
`;

const FooterGrid = styled(GridContainer)`
  grid-template-areas: 
    "newsletter none none none none";

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-gap: 35px;
    grid-template-areas: 
      "newsletter newsletter"
      "none none"
      "none none";
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-gap: 35px 20px;
  }
`;

const NewsletterWrapper = styled.div`
  grid-area: newsletter;

  ul{
    display: flex;
    li{
      width: 23px;
      margin-right: 20px;
    }
  }
`;

const FooterTitle = styled.p`
  font-size: ${em(20, 16)};
  font-weight: ${fontWeights.bold};
`;

const NewsletterTitle = styled(FooterTitle)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: ${em(24, 16)};
  }
`;

const Footer = (props: Props) => {

  const footerData = useFooterQuery().sanityFooter;

  if (!footerData) return null;

  const { newsletterTitle, menuLists, socialLinks } = footerData;

  return (
    <Wrapper>
      <PageWidth>
        <FooterGrid width="100%" gridTemplateColumns="1fr auto auto auto auto" gap="6%">
          <NewsletterWrapper>
            <NewsletterTitle>{newsletterTitle}</NewsletterTitle>
            {socialLinks && socialLinks.length > 0 &&
              <ul>
                {socialLinks.map(link => {
                  if (!link) return;

                  return (
                    <li key={link._key}>
                      <Image data={link} />
                    </li>
                  );
                })}
              </ul>
            }
          </NewsletterWrapper>

          {menuLists && menuLists.map(menuList => {

            if (!menuList) return;

            return (
              <div key={menuList._key}>
                <FooterTitle>{menuList.title}</FooterTitle>
                {menuList.links && menuList.links.length > 0 &&
                  <ul>
                    {menuList.links.map(link => {
                      if (!link) return;

                      return (
                        <li key={link._key}>
                          <Link {...link} margin="0 0 10px" hoverOpacity={0.5} />
                        </li>
                      );
                    })}
                  </ul>
                }
              </div>
            );
          })}
        </FooterGrid>
      </PageWidth>
    </Wrapper>

  );
};

export default Footer;
