import React, { Dispatch, FormEvent, MouseEvent, SetStateAction, useState } from "react";
import { Container, Form, GridContainer } from "@util/standard";
import { Button, Link, TextField } from "@global";
import { useStore } from "@state/store";
import { Chevron } from "@util/svg";
import { createUserWithEmailAndPassword, signInWithExternalProvider } from "@util/firebase";
import { BackLink, Heading, LoadingWrapper, SocialButton } from "./loginForm";
import { toast } from "react-toastify";
import { User } from "@state/types";
import { defProps } from "@util/inputs";

interface Props {
  isCart?: boolean;
  setIsCreateAccount: Dispatch<SetStateAction<boolean>>;
  onLoggedIn?: () => void;
}

const RegisterForm = ({ isCart, setIsCreateAccount, onLoggedIn }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleCartClick = (e: MouseEvent) => {
    e.preventDefault();
    useStore.setState({ checkoutInitiated: false });
  };

  const handleLoginClick = (e: MouseEvent) => {
    e.preventDefault();
    setIsCreateAccount(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!e.currentTarget?.elements) return;

    const { email, password, firstName, lastName } = e.currentTarget.elements as any;

    setLoading(true);
    const userData = await createUserWithEmailAndPassword(
      email.value,
      password.value,
      firstName.value,
      lastName.value,
    );
    handlePostLogin(userData);
    if (userData) {
      e.currentTarget.reset();
    }
  };

  const handleExternalLogin = async (type: string) => {
    setLoading(true);
    const userData = await signInWithExternalProvider(type);
    handlePostLogin(userData);
  };

  const handlePostLogin = (user: User | null) => {
    setLoading(false);
    if (user) {
      useStore.setState({ user });
      onLoggedIn && onLoggedIn();
      toast.success("Succesfully Signed Up");
    }
  };

  return (
    <LoadingWrapper loading={loading}>
      {isCart && (
        <BackLink externalLink="" onClick={handleCartClick}>
          <Chevron type="left" margin="0 18px 0 0" color="current" />
          <span>Back</span>
        </BackLink>
      )}

      <Heading>
        <h4>Make an account to continue adding a property</h4>
      </Heading>

      <Form onSubmit={handleSubmit}>
        <GridContainer repeat={2} gap="15px" margin="0 0 15px" width="100%">
          <TextField {...defProps.firstName} label="" required />
          <TextField {...defProps.lastName} label="" required />
        </GridContainer>
        <GridContainer repeat={1} margin="0 0 15px" width="100%">
          <TextField {...defProps.email} label="" required />
        </GridContainer>
        <GridContainer repeat={1} margin="0 0 15px" width="100%">
          <TextField {...defProps.password} label="" autoComplete="new-password" required />
        </GridContainer>
        <Button
          type="submit"
          linkText={isCart ? "Register and Proceed to Payment" : "Create Account"}
          loading={loading}
          margin="10px 0 0"
        />
      </Form>

      <hr />
      <Container flexFlow="column" width="100%" margin="0 0 35px">
        <SocialButton
          isGoogle
          linkText="Sign Up through Google"
          onClick={() => handleExternalLogin("google")}
          theme="baseOutline"
        />
        <SocialButton
          isFacebook
          linkText="Sign Up through Facebook"
          onClick={() => handleExternalLogin("facebook")}
          theme="baseOutline"
          margin="15px 0 0"
        />
      </Container>

      <Link animatedSvg externalLink="" onClick={handleLoginClick}>
        <span className="login-button">Login to My HomeSell</span>
        <Chevron type="right" margin="0 0 0 18px" color="current" />
      </Link>
    </LoadingWrapper>
  );
};

export default RegisterForm;
