import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { Maybe, SanityProduct, SanityTier } from "@graphql-types";
import { CartItem } from "@util/types";
import { Container, GridContainer } from "@util/standard";
import { colors, tierArr } from "@util/constants";
import { Button, Select } from "@global";
import { generateCartItem, getTierBasePrice } from "../utils";
import ProductLines from "./productLines";

interface Props {
  tierDetails: Maybe<SanityTier> | undefined;
  setCurrentTier: Dispatch<SetStateAction<string>>;
  setProduct: React.Dispatch<React.SetStateAction<SanityProduct | undefined>>;
  onPurchase: (basePrice: number, items: CartItem[]) => void;
  currentTier: string;
  loading: boolean;
}

const getDefaultCartItems = (tierDetails: Maybe<SanityTier> | undefined) => {
  if (!tierDetails) return [];
  const cartItems = tierDetails.customisableProductsDefault
    ?.map(obj => {
      if (!obj) return null;

      const { product, defaultQuantity, defaultSku } = obj;
      if (!product) return null;

      return generateCartItem(product, defaultQuantity, defaultSku);
    })
    .filter(obj => obj !== null) as Array<CartItem>;

  return cartItems;
};

const PackageCustomiser = ({
  tierDetails,
  loading,
  setCurrentTier,
  currentTier,
  setProduct,
  onPurchase,
}: Props) => {
  const basePrice = getTierBasePrice(tierDetails);
  const defaultCartItems = getDefaultCartItems(tierDetails);
  const [localCartItems, setLocalCartItems] = useState(defaultCartItems);
  const [price, setPrice] = useState(tierDetails?.price ?? 0);

  const handleTierChange = (tier: string) => {
    setCurrentTier(tier);
  };

  useEffect(() => {
    setLocalCartItems(defaultCartItems);
  }, [tierDetails]);

  useEffect(() => {
    if (!localCartItems?.length) setPrice(basePrice);

    const totalAddOns =
      localCartItems.length > 0
        ? localCartItems
            .map(
              item =>
                (item?.variant ? item?.variant.price ?? 0 : item?.price ?? 0) *
                (item?.quantity ?? 1),
            )
            .reduce((a, b) => a + b)
        : 0;
    setPrice(basePrice + totalAddOns);
  }, [localCartItems]);

  const handlePurchaseClick = () => {
    onPurchase(basePrice, localCartItems);
  };

  return (
    <Wrapper>
      <div className="top-area">
        <Select
          id="select-pack"
          label="Select Package"
          value={currentTier}
          onChange={e => handleTierChange(e.currentTarget.value)}
          options={tierArr.map(tier => ({ value: tier, title: `HomeSell Go ${tier}` }))}
        />
        <div className="h2">${price}</div>
      </div>
      {tierDetails?.customisableProductsDefault && (
        <GridContainer repeat={1} className="default-grid" width="100%">
          {tierDetails.customisableProductsDefault.map((reference, i) => {
            if (!reference) return;

            return (
              <ProductLines
                key={`customisableProductsDefault-${i}`}
                tierDetails={tierDetails}
                product={reference.product}
                index={i}
                setLocalCartItems={setLocalCartItems}
                setProduct={setProduct}
                isDefault
                defaultQuantity={reference.defaultQuantity}
                defaultSku={reference.defaultSku}
                included={true}
              />
            );
          })}
        </GridContainer>
      )}
      {tierDetails?.customisableProductsAddOn && (
        <GridContainer repeat={1} width="100%">
          {tierDetails.customisableProductsAddOn.map((product, i) => (
            <ProductLines
              key={`customisableProductsAddOn-${i}`}
              tierDetails={tierDetails}
              product={product}
              index={i}
              setLocalCartItems={setLocalCartItems}
              setProduct={setProduct}
              included={false}
            />
          ))}
        </GridContainer>
      )}
      <Container flexDirection="column" alignItems="flex-end" margin="20px 0 0">
        <p className="h4">Total: ${price}</p>
        <Button loading={loading} onClick={handlePurchaseClick} linkText="Purchase" />
      </Container>
    </Wrapper>
  );
};

export default PackageCustomiser;

const Wrapper = styled.div`
  .top-area {
    display: flex;
    margin: 30px 0;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .select {
      width: fit-content;
    }
  }

  .default-grid {
    border-top: 2px solid ${colors.gridBorder};
  }
`;
