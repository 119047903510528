import { colors } from "@util/constants";
import React from "react";
import styled from "styled-components";
import { TABS } from "./utils";
import { mediaQuery } from "@styles/mediaQueries";

interface Props {
  openTab: number;
  onChange: (val: number) => void;
  disabled: boolean;
}

const Tabs = ({ openTab, onChange, disabled }: Props) => {
  return (
    <Wrapper>
      <div className="tabs">
        {TABS.map((tab, index) => (
          <button
            disabled={disabled}
            onClick={() => onChange(index)}
            key={tab.id}
            className={`tab ${index <= openTab ? "selected" : ""}`}
          >
            <div className="top">
              <div className="left-bar" />
              <div className="circle" />
              <div className="right-bar" />
            </div>
            <p>{tab.title}</p>
          </button>
        ))}
      </div>
    </Wrapper>
  );
};

export default Tabs;

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 100px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .tabs {
    margin: 0 auto;
    width: fit-content;
    display: flex;
    justify-content: center;
  }

  .tab {
    max-width: 200px;
    margin: 0;
    display: flex;
    flex-direction: column;

    &.selected {
      .right-bar,
      .left-bar,
      .circle {
        background-color: ${colors.main};
      }
    }

    .top {
      display: flex;
      align-items: center;
      gap: 12px;
      margin: 32px 0;
      width: 100%;

      ${mediaQuery.tabletDown} {
        margin: 12px 0;
      }
    }
    .circle {
      border: 1px solid #ccc;
      width: 20px;
      height: 20px;
      min-width: 20px;
      border-radius: 50%;
      transition: background-color 300ms;
    }

    .right-bar,
    .left-bar {
      width: 100%;
      height: 1px;
      min-width: 50px;
      background-color: #ccc;
      transition: background-color 300ms;
    }

    p {
      width: 100%;
      padding: 0 20px;
      text-align: center;
    }
  }
`;
